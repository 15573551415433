import styled, { css } from 'styled-components';
import { FlexBetweenContainer } from 'components/Core';

export const Tabs = styled(FlexBetweenContainer)`
  gap: 8px;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
  background-color: white;
`;

export const TabLabel = styled.div`
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: stretch;
  text-align: center;
  justify-content: center;
  align-self: center;
  text-transform: uppercase;
  opacity: 0.2;
  transform: rotate(-90deg);
`;

export const Tab = styled(FlexBetweenContainer)`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  flex-grow: 1;
  color: var(--primaryBlack);
  font-weight: 700;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: var(--primaryBlack3);
  }

  & svg:not(.unstyled) {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ iconColor }) => iconColor ?? 'var(--primaryBlack30)'};
    }
    g {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--primaryBlue10);
      border: none;
      color: var(--primaryBlue);

      & svg:not(.unstyled) {
        path {
          fill: ${({ iconSelectedColor }) => iconSelectedColor ?? 'var(--primaryBlue)'};
        }
      }

      &:hover {
        cursor: pointer;
        background-color: var(--primaryBlue10);
      }
    `}
`;
