import React from 'react';
import { usePageContext, PAGE_CONTEXT_VALUES, useStateWithStorage } from 'utils/hooks';
import { Filters } from 'shared/Filters';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { TRANSACTION_TABLE_COLUMN_KEY } from 'shared/TransactionTable';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { TransactionsPage } from '../TransactionsPage';

export const UnconfirmedTransactionsTab = () => {
  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.transactions.key,
  });

  const { dataFilter, setDataFilter, metadataFilter, setMetadataFilter } = urlState;

  const [
    hiddenTransactionColumns,
    setHiddenTransactionColumns,
  ] = useStateWithStorage('transaction-table-hidden-columns', [
    TRANSACTION_TABLE_COLUMN_KEY.ID,
    TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID,
    TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
    TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
    TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
  ]);

  const additionalTransactionsFilters = {
    params: {
      confirmed: false,
      includeCount: true,
      autoconfirmed: false,
      archived: false,
    },
  };

  return (
    <GlobalTogglesContextProvider urlState={urlState}>
      <TransactionsPage
        widgetView
        transactionsConfirmation="To be confirmed"
        transactionsGroupBy={GROUP_BY_OPTIONS.NONE}
        hiddenTransactionColumns={hiddenTransactionColumns}
        setHiddenTransactionColumns={setHiddenTransactionColumns}
        additionalTransactionsFilters={additionalTransactionsFilters}
        filtersBar={
          <Filters
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
            showTransactionsSettings
            showConditions
            hideTimeSelection
            hidePeriodSelection
          />
        }
      />
    </GlobalTogglesContextProvider>
  );
};
