import React, { useState } from 'react';
import { Form, useFormikContext } from 'formik';

import { CancelButton, SaveButton, DuplicateButton } from 'components/Buttons';
import { CustomDatePicker, FormikCustomCheckbox, FormikCustomInput } from 'components/Controls';
import { Centerer, FlexEndContainer, Flexer } from 'components/Core';
import { HelpCircleIcon, InfoIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { Header } from 'shared/Layout';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { useMVP } from 'utils/hooks';
import { USAGE_BASED_BILLING_SCHEMES } from 'views/Billing/UsageBasedEngine/PricingPlans/consts';

import { SUBSCRIPTION_EDIT_MODAL_MODE } from '../consts';
import { AdditionalSettingsSection } from './AdditionalSettingsSection';
import { ChildCustomersSelector } from './ChildCustomersSelector';
import { CustomerSelector } from './CustomerSelector';
import { DiscountSection } from './DiscountSection';
import { PreviousGroupSelector } from './PreviousGroupSelector';
import { PricingPlanSelector } from './PricingPlanSelector';
import { ProrateSection } from './ProrateSection';
import {
  CustomFlexBasis,
  FlexBasis,
  FormFooter,
  FormRow,
  InfoText,
  InfoTextWrapper,
  OverflowDiv,
  Seperator,
  Wrapper,
} from '../styles';
import { TransactionSection } from './TransactionSection';
import { convertSubscriptionToTransaction, checkDisableAggregate } from './utils';

/**
 * Relationship between pricing plans, usage subscriptions, and transactions:
 * 1. Each usage subscription is directly tied to a pricing plan (1:1 relationship)
 * 2. When no pricing plan is selected, usage_subscriptions will be empty
 * 3. Each usage subscription generates transactions through convertSubscriptionToTransaction:
 *    - An overage transaction is always created for each subscription
 *    - Prepaid transactions is only existed in edit mode, since it's created in the server side
 * 4. When a pricing plan is selected, transactions are automatically generated
 *    and the first transaction is selected by default
 */
export const SubscriptionForm = ({ isLoading, mode, onClose, onDuplicate }) => {
  const { values, setFieldValue, handleSubmit, getFieldMeta } = useFormikContext();
  const isMvp = useMVP();

  const isCreating = mode === SUBSCRIPTION_EDIT_MODAL_MODE.CREATE;

  const transactions = values?.usage_subscriptions?.flatMap(convertSubscriptionToTransaction) ?? [];
  const [selectedTransactionId, setSelectedTransactionId] = useState(transactions?.[0]?._id);
  const selectedTransaction = transactions.find((t) => t?._id === (selectedTransactionId ?? transactions?.[0]?._id));
  const hasSelectedPricingPlan = !!selectedTransaction;
  const [shouldDisableAggregate, setShouldDisableAggregate] = useState(
    values?.usage_subscriptions.some(checkDisableAggregate),
  );

  return (
    <OverflowDiv>
      <Form>
        <Wrapper>
          <Header
            data-cy="edit-subscription-modal__title"
            headerTitle={`${isCreating ? 'Create' : 'Edit'} Usage-based Subscription`}
          />
          <FormRow>
            <FlexBasis>
              <PreviousGroupSelector />
            </FlexBasis>
            <FlexBasis>
              <PricingPlanSelector
                values={values}
                setSelectedTransactionId={setSelectedTransactionId}
                selectedTransaction={selectedTransaction}
                transactions={transactions}
                setShouldDisableAggregate={setShouldDisableAggregate}
              />
            </FlexBasis>

            <FlexBasis>
              <FormikCustomInput
                name="trial_units"
                data-cy="subscription-create-modal__trial-units"
                label={
                  <Centerer>
                    <span>Trial Units</span>
                    {!values?.previous_group_id && (
                      <TooltipContainer toolTipContent="We will only start counting after the customer has used up this number of units">
                        <HelpCircleIcon />
                      </TooltipContainer>
                    )}
                  </Centerer>
                }
                placeholder="Enter trial units"
                type="number"
                isDisabled={!!values?.previous_group_id}
              />
            </FlexBasis>
            <FlexBasis>
              <CustomDatePicker
                meta={getFieldMeta('start_date')}
                name="start_date"
                label="Start Date"
                selected={values?.start_date ? formatDateForDatepicker(values?.start_date) : null}
                onChange={(value) => setFieldValue('start_date', updateDateFromDatePicker(value))}
              />
            </FlexBasis>
            <FlexBasis>
              <CustomDatePicker
                meta={getFieldMeta('end_date')}
                name="end_date"
                label="End Date"
                selected={values?.end_date ? formatDateForDatepicker(values?.end_date) : null}
                onChange={(value) => setFieldValue('end_date', updateDateFromDatePicker(value))}
              />
            </FlexBasis>
          </FormRow>
          {isMvp && <AdditionalSettingsSection shouldDisableAggregate={shouldDisableAggregate} />}
          <FormRow>
            <CustomFlexBasis width={values?.aggregate_usage_on_parent ? '25%' : '100%'}>
              <CustomerSelector isDisabled={!isCreating} />
            </CustomFlexBasis>
            {values?.aggregate_usage_on_parent && (
              <CustomFlexBasis width="75%">
                <ChildCustomersSelector />
              </CustomFlexBasis>
            )}
          </FormRow>
          <ProrateSection />
          <PrepaidTransactionSyncCheckbox />
          {!!values?.previous_group_id && <PreviousGroupInfoText />}
          <Seperator />
          {hasSelectedPricingPlan && <DiscountSection />}
        </Wrapper>

        {hasSelectedPricingPlan && (
          <TransactionSection
            transactions={transactions}
            selectedTransaction={selectedTransaction}
            setSelectedTransactionId={setSelectedTransactionId}
            isCreating={isCreating}
          />
        )}

        <FormFooter>
          <FlexEndContainer>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            {!isCreating && <DuplicateButton onClick={onDuplicate} />}
            <SaveButton loading={isLoading} data-cy="usage-subscription-modal__save-button" onClick={handleSubmit} />
          </FlexEndContainer>
        </FormFooter>
      </Form>
    </OverflowDiv>
  );
};

const PrepaidTransactionSyncCheckbox = () => {
  const { values } = useFormikContext();

  const hasPrepaidPricingPlan = values?.usage_subscriptions?.some((subscription) =>
    subscription.pricing_plan?.tiers?.some((t) => t.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID),
  );

  return (
    hasPrepaidPricingPlan && (
      <FormRow>
        <Flexer gap="12px">
          <TooltipContainer
            toolTipContent={
              <>
                Subscript will not auto-sync the following fields of the prepaid transaction:
                <br />
                <b> start date, end date, MRR, product, date, and recognition</b>
              </>
            }
            fontSize="12px"
            width={450}
          >
            <FormikCustomCheckbox
              name={'skip_prepaid_transaction_sync'}
              label="Disable auto-sync for prepaid transactions"
            />
          </TooltipContainer>
        </Flexer>
      </FormRow>
    )
  );
};

const PreviousGroupInfoText = () => {
  return (
    <InfoTextWrapper>
      <InfoIcon size="40px" />
      <InfoText>
        The units paid for but not used from the previous subscription will be used as the trial units of this
        subscription. These units will not be invoiced again, but recognized revenue will be calculated at the price
        point from the previous subscription.
      </InfoText>
    </InfoTextWrapper>
  );
};
