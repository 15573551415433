import React, { useContext, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { BinIcon } from 'components/Icons';
import { ModalContainer, Modal, ModalFooter } from 'components/Modal';
import { Spacer, Column } from 'components/Core';
import { useUsageSubscriptionGroupsAPI } from 'api/usageBasedEngine';
import { getCustomerDisplayName } from 'models/customer';
import { useAnalytics } from 'utils/hooks';

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

const Wrapper = styled.div`
  padding: 0 50px 10px 50px;

  display: flex;
  flex-direction: column;
`;

const CancelButton = styled.div`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
`;

const RemoveButton = styled.div`
  background: var(--primaryRed);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
`;

const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Banner = styled.div`
  background-color: var(--primaryRed5);
  border-radius: 8px;
  padding: 16px;

  display: flex;
`;

const GreyText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
`;

const BoldText = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const GreyBoldText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const SubscriptionDeleteModal = ({ onClose, selectedGroup }) => {
  const { orgId } = useContext(AppContext);
  const firstSubscription = selectedGroup.usage_subscriptions[0];

  const { operations } = useUsageSubscriptionGroupsAPI({
    orgId,
    autoFetch: false,
  });

  return (
    <ModalContainer>
      <Modal
        overflow="visible"
        width="440px"
        minHeight="300px"
        data-cy="delete-subscription-modal"
        height="auto"
        maxHeight="auto"
      >
        <>
          <Wrapper>
            <Header data-cy="delete-subscription-modal__title">Remove Subscription</Header>

            <GreyText> Do you really want to remove the following item?</GreyText>
            <GreyText> This cannot be undone </GreyText>

            <Spacer height="8px" />

            <Banner>
              <BinIcon size="20px" />
              <Spacer width="10px" />
              <Column horizontal="start">
                <BoldText>
                  {getCustomerDisplayName({
                    customerName: firstSubscription.customer.name,
                    customerId: firstSubscription.customer.id,
                    customerEmail: firstSubscription.customer.email,
                  })}
                </BoldText>

                {selectedGroup.usage_subscriptions.map((subscription) => (
                  <GreyBoldText>
                    {`${subscription.pricing_plan.name}${
                      subscription.start_date ? dayjs(subscription.start_date).format('MMM DD, YYYY') : ''
                    }- ${subscription.end_date ? dayjs(subscription.end_date).format('MMM DD, YYYY') : 'No end date'}`}
                  </GreyBoldText>
                ))}
              </Column>
            </Banner>
          </Wrapper>
          <ModalFooter>
            <FormButtonsRow>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
              <RemoveButton
                onClick={async () => {
                  await operations.deleteUsageSubscriptionGroup.mutateAsync({ id: selectedGroup.id });
                  onClose();
                }}
                data-cy="delete-usage-subscription-modal__remove-button"
              >
                Remove
                <Spacer width="10px" />
                <BinIcon size="20px" fill="white" />
                <Spacer width="10px" />
              </RemoveButton>
            </FormButtonsRow>
          </ModalFooter>
        </>
      </Modal>
    </ModalContainer>
  );
};

export const useSubscriptionDeleteModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams) => {
      trackEvent({ name: EVENTS.OPEN_DELETE_USAGE_SUBSCRIPTION_MODAL });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <SubscriptionDeleteModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    SubscriptionDeleteModal: Modal,
  };
};
