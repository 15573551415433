import React, { useContext, useMemo, useState, useCallback } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { toNumber, omit, cloneDeep } from 'lodash';
import { AppContext } from 'AppContext';

import { usePricingPlansAPI } from 'api/usageBasedEngine';
import { Flexer } from 'components/Core';
import { TrashIcon } from 'components/Icons';
import { FormikCustomSelector } from 'components/Controls';
import { useMVP } from 'utils/hooks';
import {
  FlexGrow,
  Table,
  TableContainer,
  TableHeaderWrapper,
  TableHeader,
  TableBody,
  TableCellWrapper,
  TableCell,
  AddButton,
  NumberTag,
  DeleteButton,
} from './styles';

export const ProductEventSection = ({ eventNameData, isLoadingEvents, isCreate }) => {
  const isMvp = useMVP();
  const { organizations } = useContext(AppContext);
  const {
    operations: { deletePricingPlan },
  } = usePricingPlansAPI({
    orgId: organizations[0]?.id,
    autoFetch: false,
  });

  const eventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);
  const { values, setFieldValue } = useFormikContext();

  const productOptions =
    organizations[0]?.products?.map((productObject) => ({
      label: productObject.name,
      value: Number(productObject.id),
    })) ?? [];

  const [draftProductEventPairs, setDraftProductEventPairs] = useState(
    values.aggregated_pricing_plan_info
      ? values.aggregated_pricing_plan_info.product_ids?.map((product_id, index) => ({
          product_id,
          event_name: values.aggregated_pricing_plan_info.event_names?.[index] ?? '',
        }))
      : [],
  );

  const setNewTiers = useCallback(
    (newValues = values) => {
      // Get the tiers for the main product of the pricing plan (values.pricing_plan.id)
      const tiers = !isCreate
        ? newValues.tiers.filter((tier) => toNumber(tier.pricing_plan_id) === toNumber(newValues.id))
        : newValues.tiers;

      tiers.forEach((tier) => {
        const childTiers = cloneDeep(tier.child_tiers);
        tier.child_tiers = [];

        for (const [index, pricing_plan_id] of (newValues.child_pricing_plan_ids ?? []).entries()) {
          const productId = newValues.aggregated_pricing_plan_info.product_ids[index + 1] ?? -1; // ignore the first product id, as it's the main product
          const currentChildTier = childTiers.find((t) => t.product_id === productId);

          tier.child_tiers.push({
            ...omit(tier, ['id', 'child_tiers', 'amount']),
            // add parent tier info if already present, checking currentChildTier first to avoid persistence issues when deleting a product for example
            ...(currentChildTier ?? childTiers?.[index] ?? {}),
            pricing_plan_id,
            product_id: productId,
          });
        }
      });

      setFieldValue('tiers', tiers);
    },
    [values, setFieldValue, isCreate],
  );

  const addProductEventPair = useCallback(() => {
    setFieldValue(`aggregated_pricing_plan_info.product_ids`, [
      ...(values.aggregated_pricing_plan_info?.product_ids ?? []),
      -1,
    ]);
    setFieldValue(`aggregated_pricing_plan_info.event_names`, [
      ...(values.aggregated_pricing_plan_info?.event_names ?? []),
      '',
    ]);

    if (values.aggregated_pricing_plan_info?.product_ids?.length > 0) {
      setFieldValue('child_pricing_plan_ids', [...(values.child_pricing_plan_ids ?? []), -1]);
    }
    setDraftProductEventPairs([...(draftProductEventPairs ?? []), { product_id: -1, event_name: '' }]);

    setNewTiers();
  }, [values, setFieldValue, draftProductEventPairs, setNewTiers]);

  const handleChangeProductId = (product_id, index) => {
    const newProductIds = [...(values.aggregated_pricing_plan_info?.product_ids ?? [])];
    newProductIds[index] = product_id;

    if (index === 0) {
      setFieldValue('product_id', product_id);
    }
    setFieldValue(`aggregated_pricing_plan_info.product_ids`, newProductIds);
    setFieldValue(`product_ids`, newProductIds);
    setDraftProductEventPairs((prev) => {
      const newPairs = [...prev];
      newPairs[index] = {
        product_id: product_id,
        event_name: prev[index].event_name,
      };
      return newPairs;
    });

    const newValues = {
      ...values,
      aggregated_pricing_plan_info: {
        ...(values.aggregated_pricing_plan_info ?? {}),
        product_ids: newProductIds,
      },
    };
    setNewTiers(newValues);
  };

  return (
    <TableContainer>
      <FieldArray name="aggregated_pricing_plan_info">
        {({ form: { values } }) => (
          <Table>
            <TableHeaderWrapper>
              <TableCellWrapper>
                <TableHeader width="50px">#</TableHeader>
                <TableHeader width="430px">PRODUCT</TableHeader>
                <TableHeader width="430px">EVENT</TableHeader>
                <TableHeader width="120px">
                  {isMvp && (
                    <AddButton data-cy="pricing-plan-modal__add-product-event-pair" onClick={addProductEventPair}>
                      Add Product
                    </AddButton>
                  )}
                </TableHeader>
              </TableCellWrapper>
            </TableHeaderWrapper>
            <TableBody>
              {draftProductEventPairs.map((draftProductEventPair, index) => {
                if (!draftProductEventPair) {
                  return null;
                }
                const { product_id, event_name } = draftProductEventPair;

                return (
                  <tr key={index}>
                    <TableCell width="50px">
                      <NumberTag>{index + 1}</NumberTag>
                    </TableCell>
                    <TableCell width="430px">
                      <FlexGrow>
                        <FormikCustomSelector
                          placeholder="Select product"
                          name={`aggregated_pricing_plan_info.product_ids.${index}`}
                          value={
                            product_id
                              ? {
                                  label: productOptions.find((option) => option.value === product_id)?.label,
                                  value: product_id,
                                }
                              : null
                          }
                          options={productOptions}
                          handleChange={(option) => {
                            handleChangeProductId(option ? option.value : null, index);
                          }}
                          width="100%"
                          errorWithoutTooltip
                        />
                      </FlexGrow>
                    </TableCell>
                    <TableCell width="430px">
                      <FlexGrow>
                        <FormikCustomSelector
                          placeholder="Select or create event name"
                          name={`aggregated_pricing_plan_info.event_names.${index}`}
                          value={event_name ? { label: event_name, value: event_name } : null}
                          options={eventNames.map((eventName) => ({
                            label: eventName,
                            value: eventName,
                          }))}
                          handleChange={(option) => {
                            if (index === 0) {
                              setFieldValue('event_name', option ? option.value : null);
                            }
                            setFieldValue(
                              `aggregated_pricing_plan_info.event_names.${index}`,
                              option ? option.value : null,
                            );
                            setFieldValue(`event_names.${index}`, option ? option.value : null);
                            setDraftProductEventPairs((prev) => {
                              const newPairs = [...prev];
                              newPairs[index] = {
                                product_id: prev[index].product_id,
                                event_name: option ? option.value : null,
                              };
                              return newPairs;
                            });
                          }}
                          width="100%"
                          creatable
                          isLoading={isLoadingEvents}
                          errorWithoutTooltip
                        />
                      </FlexGrow>
                    </TableCell>
                    <TableCell width="120px">
                      <Flexer justifyContent="flex-end">
                        {index !== 0 && (
                          <DeleteButton
                            onClick={async () => {
                              const currentParentPricingPlanId = values.child_pricing_plan_ids[index - 1];
                              if (currentParentPricingPlanId && currentParentPricingPlanId !== -1) {
                                // call the delete pricing plan api
                                await deletePricingPlan.mutateAsync({ id: currentParentPricingPlanId });
                              }

                              const newParentPricingPlanIds = [...values.child_pricing_plan_ids];
                              newParentPricingPlanIds.splice(index - 1, 1);

                              const newProductIds = [...values.aggregated_pricing_plan_info.product_ids];
                              newProductIds.splice(index, 1);

                              const newEventNames = [...values.aggregated_pricing_plan_info.event_names];
                              newEventNames.splice(index, 1);

                              const newDraftPairs = [...draftProductEventPairs];
                              newDraftPairs.splice(index, 1);

                              setFieldValue('child_pricing_plan_ids', newParentPricingPlanIds);
                              setFieldValue('aggregated_pricing_plan_info.product_ids', newProductIds);
                              setFieldValue('aggregated_pricing_plan_info.event_names', newEventNames);
                              setDraftProductEventPairs(newDraftPairs);

                              const newValues = {
                                ...values,
                                child_pricing_plan_ids: newParentPricingPlanIds,
                                aggregated_pricing_plan_info: {
                                  ...values.aggregated_pricing_plan_info,
                                  product_ids: newProductIds,
                                  event_names: newEventNames,
                                },
                              };
                              setNewTiers(newValues);
                            }}
                          >
                            Delete
                            <TrashIcon />
                          </DeleteButton>
                        )}
                      </Flexer>
                    </TableCell>
                  </tr>
                );
              })}
            </TableBody>
          </Table>
        )}
      </FieldArray>
    </TableContainer>
  );
};
