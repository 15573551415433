import { useState } from 'react';
import styled from 'styled-components';

import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { BILLING_SUB_TABS } from 'views/Configuration/Tabs/SubscriptBillingTab/SubTabsHeader/SubTabsHeader';

import { PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const PopoverLinkContentsWrapper = styled.div`
  position: absolute;
  top: ${(props) => props.top ?? '-20px'};
  left: ${(props) => props.left ?? '190px'};
  width: ${(props) => props.width ?? '200px'};
  padding: 20px;
`;

export const BillingConfigSubLinksPopover = ({ title, pageHeaderView, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const subTabs = [
    {
      title: 'General Settings',
      to: `/configure?tab=${CONFIGURATION_TABS.SUBSCRIPT_BILLING}&subTab=${BILLING_SUB_TABS.GENERAL_SETTINGS}`,
      showArrowRight: !pageHeaderView,
    },
    {
      title: 'Invoice Settings',
      to: `/configure?tab=${CONFIGURATION_TABS.SUBSCRIPT_BILLING}&subTab=${BILLING_SUB_TABS.INVOICE_SETTINGS}`,
      showArrowRight: !pageHeaderView,
    },
    {
      title: 'Collection Sequence',
      to: `/configure?tab=${CONFIGURATION_TABS.SUBSCRIPT_BILLING}&subTab=${BILLING_SUB_TABS.COLLECTION_SEQUENCE}`,
      showArrowRight: !pageHeaderView,
    },
  ];

  return (
    <Wrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Subscript Billing',
          description: 'Settings related to your invoices',
          showArrowRight: !pageHeaderView,
        }}
        disableLink={true}
      />

      {isOpen && (
        <PopoverLinkContentsWrapper>
          <PopoverLinkContents title={'Subscript Billing'} minWidth="100%">
            {subTabs.map((tab) => (
              <PopoverLinkContentsItem key={tab.title} title={title} item={tab} width="100%" onClick={onClose} />
            ))}
          </PopoverLinkContents>
        </PopoverLinkContentsWrapper>
      )}
    </Wrapper>
  );
};
