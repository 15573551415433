import { DISCOUNT_TYPE } from 'views/Billing/consts';

export const DISCOUNT_TYPE_OPTIONS = [
  {
    label: 'Percentage',
    value: DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: 'Flat amount',
    value: DISCOUNT_TYPE.FLAT_AMOUNT,
  },
];
