import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { useShareAPI } from 'api/share';
import { Centerer, Spacer } from 'components/Core';
import { extractMonthFromPickedDate, monthStringToDate } from 'utils/dateUtils';
import { useClickOutside } from 'utils/hooks';
import { getBlobImageFromRef } from 'utils/hooks/useScreenshotDownload';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { CircleLoader } from 'components/Loaders';
import { GreyDot } from 'components/Icons';
import { ReactComponent as SendIcon } from 'images/arrow-up-circle.svg';
import { ReactComponent as PreviewIcon } from 'images/file-invoice.svg';
import { useAnalyticsIntegrations } from 'shared/ReportSubscribe';
import { PERIOD_OPTIONS } from 'shared/Filters';
import { ReportShare } from './ReportShare';

export const REPORT_SHARE_TABS = {
  SLACK: 'slack',
  EMAIL: 'email',
};

export const ReportShareModal = ({ onClose, chartRef, headerTitle, report, pageToShare }) => {
  const { orgId, appSettings, metadataFilter, dataFilter } = useContext(AppContext);
  const { user = {} } = useAuth0();

  const [chartImage, setChartImage] = useState();

  const metrics = report?.options?.metricsSetting;
  const snapshotMetrics = [
    ...new Set(report?.options?.metricsSetting?.flatMap((setting) => setting.selectedMetrics) || []),
  ];
  const reportId = report?.id;

  // eslint-disable-next-line
  useEffect(async () => {
    const chartImage = await getBlobImageFromRef({ refCurrent: chartRef?.current });
    setChartImage(chartImage);
  }, [chartRef]);

  const { shareEmail, shareSlack } = useShareAPI({
    autoFetch: false,
    orgId,
    appSettings,
    endMonth:
      dataFilter.period === PERIOD_OPTIONS.custom
        ? monthStringToDate(extractMonthFromPickedDate(dataFilter?.endMonth))
        : dataFilter?.endMonth,
    startMonth:
      dataFilter.period === PERIOD_OPTIONS.custom
        ? monthStringToDate(extractMonthFromPickedDate(dataFilter?.startMonth))
        : dataFilter?.startMonth,
    metadataFilter: report ? report.options.metadataFilter : metadataFilter,
    pageToShare: pageToShare ?? headerTitle,
  });

  const { data: integrationTypes, isLoading, isFetching } = useIntegrationsAPI({ orgId });
  const { analyticsIntegrations, updateAnalyticsIntegration } = useAnalyticsIntegrations({
    integrationTypes,
  });

  const [selectedTab, setSelectedTab] = useState(REPORT_SHARE_TABS.EMAIL);
  const [manuallyAddedEmails, setManuallyAddedEmails] = useState([]);

  const modalRef = useClickOutside(() => onClose());

  const handleShareModal = () => {
    if (selectedTab === REPORT_SHARE_TABS.EMAIL) {
      shareEmail({ reportId, metrics, snapshotMetrics, chartImage, manuallyAddedEmails });
    } else if (selectedTab === REPORT_SHARE_TABS.SLACK) {
      shareSlack({ reportId, metrics });
    }
    onClose();
  };

  const handlePreview = () => {
    if (selectedTab === REPORT_SHARE_TABS.EMAIL) {
      shareEmail({
        reportId,
        snapshotMetrics,
        metrics,
        chartImage,
        manuallyAddedEmails,
        sendPreviewEmailTo: user?.email,
      });
    }
  };

  return (
    <>
      <ModalContainer data-cy="report-share-modal">
        <Modal ref={modalRef} overflow="visible" height="auto" width="740px">
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle padding="0 32px">
              <ModalTitleText compact>
                <b>Share Report</b>
              </ModalTitleText>
            </ModalTitle>
          </ModalHeader>

          <ModalBody height="600px" paddingLeft="0" paddingRight="0">
            <Spacer height="2px" />
            {isLoading || isFetching ? (
              <Centerer>
                <CircleLoader width={24} height={24} />
              </Centerer>
            ) : (
              <ReportShare
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                manuallyAddedEmails={manuallyAddedEmails}
                setManuallyAddedEmails={setManuallyAddedEmails}
                analyticsIntegrations={analyticsIntegrations}
                updateAnalyticsIntegration={updateAnalyticsIntegration}
                handleShareModal={handleShareModal}
              />
            )}
          </ModalBody>

          <ModalFooter style={{ alignItems: 'center' }} flexEnd noFixedHeight padding="12px 36px">
            <ModalButton style={{ margin: 0 }} onClick={onClose}>
              Cancel
            </ModalButton>

            <GreyDot />
            <ModalButton
              disabled={selectedTab === REPORT_SHARE_TABS.SLACK}
              style={{ margin: '0 8px 0 0' }}
              onClick={handlePreview}
            >
              Preview
              <PreviewIcon style={{ marginLeft: 8 }} />
            </ModalButton>
            <ModalButton
              style={{ margin: 0 }}
              className="primary"
              onClick={handleShareModal}
              data-cy="report-share-modal__submit-button"
            >
              Send
              <SendIcon style={{ marginLeft: 8 }} />
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};
