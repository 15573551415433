/* eslint-disable */
// TODO: ^ Remove this by the end of the full implementation of Usage-based engine
import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useGlobalFilter, useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { difference, omitBy, isEmpty, isUndefined, toNumber } from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AppContext } from 'AppContext';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { TableColumnSettings } from 'shared/Common';
import { FilePlus } from 'components/Icons';
import { InlineButton } from 'components/Buttons';
import { Spacer, FlexerColumn } from 'components/Core';
import { customGlobalFilter, RowActionPopover, TableActionCell } from 'components/Table';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { useUsageSubscriptionGroupsAPI } from 'api/usageBasedEngine';
import { usePagination as usePaginationButtons, useStateWithStorage, useUrlQuery } from 'utils/hooks';
import { SubscriptionsTable } from './SubscriptionsTable';
import { CustomerField, FadedText, NewSubscriptionButton, StyledLink, TrialUnitsText } from './styles';
import { SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID, SUBSCRIPTION_EDIT_MODAL_MODE } from './consts';
import { useSubscriptionEditModal } from './SubscriptionEditModal';
import { useSubscriptionDeleteModal } from './SubscriptionDeleteModal';
import { useSubscriptionDuplicateModal } from './SubscriptionDuplicateModal';
import { TableContainerLayout } from '../../Common/TableContainerLayout';
import { useEditPricingPlanModal } from '../PricingPlans/PricingPlanModal/EditPricingPlanModal';

const InvoiceCellContent = ({
  invoicingFrequency,
  transactions,
  openInvoicingScheduleModal,
  isProrated,
  customerId,
  isAggregateUsageOnParent,
}) => {
  const invoiceScheduleId = transactions[0]?.invoicing_schedule_id;
  const defaultFrequency = Object.values(INVOICING_FREQUENCIES).includes(invoicingFrequency)
    ? invoicingFrequency
    : undefined;

  return transactions?.length > 0 ? (
    <StyledLink
      data-cy={`subscriptions-table__invoice-${transactions[0]?.name}`}
      onClick={() => {
        // If aggregate usage is on parent, we need to make sure the first transaction is the one that belongs to the parent customer
        const includedTransactions = isAggregateUsageOnParent
          ? new Set([
              ...(transactions.filter((transaction) => toNumber(transaction.customer_id) === toNumber(customerId)) ||
                []),
              ...transactions,
            ])
          : transactions;

        openInvoicingScheduleModal(
          invoiceScheduleId
            ? {
                invoicingSchedule: { id: invoiceScheduleId },
              }
            : {
                includedTransactions: [...includedTransactions],
                options: {
                  defaultFrequency,
                  autoSetAutoCharge: true,
                  prorateInvoices: isProrated,
                },
              },
        );
      }}
    >
      {invoiceScheduleId ? 'Open Invoice' : 'Create Invoice'}
    </StyledLink>
  ) : (
    <FadedText>Requires a transaction to create invoice</FadedText>
  );
};

export const SubscriptionsTableContainer = ({ customer }) => {
  const {
    orgId,
    formatDateWithLocale,
    appSettings: { rollup },
  } = useContext(AppContext);

  const { openModal: openEditModal, SubscriptionEditModal } = useSubscriptionEditModal();
  const { openModal: openDeleteModal, SubscriptionDeleteModal } = useSubscriptionDeleteModal();
  const { openModal: openDuplicateModal, SubscriptionDuplicateModal } = useSubscriptionDuplicateModal();

  const query = useUrlQuery();
  const pricingPlanName = query.get('pricingPlanName');

  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState([]);

  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePaginationButtons({
    cacheSuffix: 'usage-subscriptions-page',
  });

  const {
    data,
    isFetching,
    operations: { refetch: refetchGroups, bulkDeleteUsageSubscriptionGroups, createTransactions },
  } = useUsageSubscriptionGroupsAPI({
    orgId,
    params: omitBy(
      {
        'filters[customerIds]': customer ? [customer?.id] : undefined,
        'filters[searchQuery]': searchQuery || undefined,
        'filters[rollup]': rollup,
        'pagination[page]': currentPageIndex,
        'pagination[limit]': pageSize,
        scopes: [
          'pagination_data',
          'previous_group',
          'usage_subscriptions',
          'pricing_plans',
          'customers',
          'pricing_plan_tiers',
          'transactions',
          'discounts',
          'previous_subscriptions',
        ],
        orderBy: sortBy?.length > 0 ? [sortBy[0].id, sortBy[0].desc ? 'DESC' : 'ASC'] : ['updated_at', 'DESC'],
      },
      isUndefined,
    ),
  });

  const dataForTable = useMemo(() => data?.data ?? [], [data]);

  const { openModal: openEditPricingPlanModal, EditPricingPlanModal } = useEditPricingPlanModal();
  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose: refetchGroups,
  });

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => <Checkbox {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'checkbox',
        id: 'checkbox',
        width: 32,
        Cell: ({ row }) => (
          <Checkbox
            data-cy={`subscriptions-table__checkbox--${row.original?.usage_subscriptions[0]?.customer?.name}`}
            checked={row.isSelected}
            onClick={() => {
              row.toggleRowSelected();
            }}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Customer',
        accessor: 'customer_name',
        id: 'customer_name',
        width: 150,
        Cell: ({ row }) => {
          const customer = row.original?.usage_subscriptions[0]?.customer;
          return (
            <CustomerField>
              <InlineButton
                onClick={() => window.open(`/customers/${customer?.id}`, '_blank')}
                isSecondary
                data-cy={`subscriptions-table__customer-${customer?.name}`}
              >
                {customer?.name}
              </InlineButton>
            </CustomerField>
          );
        },
      },
      {
        Header: 'Pricing Plan',
        accessor: 'pricing_plan',
        id: 'pricing_plan',
        width: 150,
        Cell: ({ row }) => (
          <FlexerColumn>
            {row.original?.usage_subscriptions?.map(({ pricing_plan: pricingPlan }) => (
              <StyledLink
                onClick={() => {
                  openEditPricingPlanModal({ pricingPlan });
                }}
              >
                {pricingPlan?.name}
              </StyledLink>
            ))}
          </FlexerColumn>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        id: 'start_date',
        width: 150,
        Cell: ({ row }) => {
          const startDate = row.original?.usage_subscriptions[0]?.start_date;
          return startDate ? formatDateWithLocale(startDate) : <FadedText>No start date</FadedText>;
        },
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        id: 'end_date',
        width: 150,
        Cell: ({ row }) => {
          const endDate = row.original?.usage_subscriptions[0]?.end_date;
          return endDate ? formatDateWithLocale(endDate) : <FadedText>No end date</FadedText>;
        },
      },
      {
        Header: 'Trial Units',
        accessor: 'trial_units',
        id: 'trial_units',
        width: 100,
        Cell: ({ row }) => <TrialUnitsText>{row.original?.usage_subscriptions[0]?.trial_units}</TrialUnitsText>,
      },
      {
        Header: 'Invoice',
        accessor: 'invoice',
        id: 'invoice',
        width: 100,
        Cell: ({ row }) => {
          const firstSubscription = row.original?.usage_subscriptions[0];
          const transactions = row.original?.usage_subscriptions?.flatMap(({ transactions }) => transactions) ?? [];
          return (
            <InvoiceCellContent
              invoicingFrequency={firstSubscription.pricing_plan.invoicing_frequency}
              customerId={firstSubscription.customer_id}
              transactions={transactions}
              openInvoicingScheduleModal={openInvoicingScheduleModal}
              isProrated={firstSubscription.is_prorated}
              isAggregateUsageOnParent={firstSubscription.aggregate_usage_on_parent}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Transactions',
        accessor: 'transactions',
        id: 'transactions',
        width: 200,
        Cell: ({ row }) => {
          const firstSubscription = row.original?.usage_subscriptions[0];
          return isEmpty(firstSubscription?.transactions) ? (
            createTransactions.isLoading ? (
              <FadedText>Creating new transactions... </FadedText>
            ) : (
              <>
                <FadedText>No transaction associated. </FadedText>
                <StyledLink
                  data-cy={`subscriptions-table__transaction-create-${firstSubscription?.customer?.name}`}
                  key={row.original.id}
                  onClick={() => createTransactions.mutateAsync({ id: row.original?.id })}
                >
                  Create transactions
                </StyledLink>
              </>
            )
          ) : (
            <FlexerColumn>
              {row.original.usage_subscriptions.flatMap((subscription) =>
                subscription.transactions.map((transaction) => (
                  <StyledLink
                    data-cy={`subscriptions-table__transaction--${transaction.name}`}
                    key={transaction.id}
                    target="_blank"
                    href={`/transactions/${transaction.id}`}
                  >
                    {transaction.name}
                  </StyledLink>
                )),
              )}
            </FlexerColumn>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: 'actions',
        id: 'actions',
        width: 65,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onEditClick={() => {
                openEditModal({
                  selectedGroup: row.original,
                  mode: SUBSCRIPTION_EDIT_MODAL_MODE.EDIT,
                  openDuplicateModal,
                });
              }}
              onDeleteClick={() => {
                openDeleteModal({ selectedGroup: row.original });
              }}
              dataCyPrefix={'usage-subscriptions'}
            />
          </TableActionCell>
        ),
        disableSortBy: true,
      },
    ],
    [createTransactions.isLoading],
  );

  const [hiddenColumns, setHiddenColumns] = useStateWithStorage('subscriptions-table-hidden-columns', []);

  const tableProps = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      initialState: {
        hiddenColumns,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // These values are returned from the useTable hook, but we need to store them in state
  // For this reason, we have these useEffect hooks
  useEffect(() => {
    const hiddenColumns = difference(
      columns.map((c) => c.id),
      tableProps.visibleColumns.map((vc) => vc.id),
    );

    setHiddenColumns(hiddenColumns);
  }, [columns, tableProps.visibleColumns, setHiddenColumns]);

  useEffect(() => {
    setSortBy(tableProps.state.sortBy);
  }, [tableProps.state.sortBy]);

  useEffect(() => {
    if (pricingPlanName) {
      setSearchQuery(pricingPlanName);
    }
  }, [pricingPlanName]);

  return (
    <>
      <TableContainerLayout
        columnsSettingsComponent={
          <>
            <NewSubscriptionButton
              onClick={() => {
                openEditModal({
                  selectedGroup: customer ? { customer_name: customer.name, customer_id: customer.id } : undefined,
                  mode: SUBSCRIPTION_EDIT_MODAL_MODE.CREATE,
                });
              }}
            >
              <FilePlus />
              <Spacer width="4px" />
              <div style={{ width: 105 }}> New Subscription </div>
            </NewSubscriptionButton>
            <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
            <TableColumnSettings
              tableName="subscriptions"
              numberValue={pageSize}
              handleShowResultsChange={(option) => setPageSize(option.value)}
              columnsTitles={SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID}
              allColumns={tableProps.allColumns}
            />
          </>
        }
        key={`search_${pricingPlanName}`}
        initialValue={pricingPlanName}
        setSearchQuery={setSearchQuery}
        isFetching={isFetching}
        rowsCount={data?.metadata?.totalCount}
        currentPageIndex={currentPageIndex ?? 1}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={data?.metadata?.maxPage ?? 0}
        placeholder="Search by customer or pricing plan"
      >
        <SubscriptionsTable
          tableProps={tableProps}
          openInvoicingScheduleModal={openInvoicingScheduleModal}
          openDuplicateModal={openDuplicateModal}
          bulkDelete={bulkDeleteUsageSubscriptionGroups}
        />
      </TableContainerLayout>

      <SubscriptionEditModal />
      <SubscriptionDeleteModal />
      <EditPricingPlanModal />
      <InvoicingScheduleModal />
      <SubscriptionDuplicateModal />
    </>
  );
};
