import * as Yup from 'yup';

export const SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID = {
  customer: 'Customer',
  pricing_plan: 'Pricing Plan',
  start_date: 'Start Date',
  end_date: 'End Date',
  trial_units: 'Trial Units',
  invoice: 'Invoice',
  transaction: 'Transaction',
};

export const USAGE_SUBSCRIPTION_METADATA_KEYS = {
  SKIP_PREPAID_TRANSACTION_SYNC: 'skip_prepaid_transaction_sync',
};

export const SUBSCRIPTION_EDIT_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const SUBSCRIPTION_DUPLICATE_MODAL_MODE = {
  DUPLICATE: 'duplicate',
};

const subscriptionSchema = Yup.object({
  pricing_plan_id: Yup.number().required(),
  overage_transaction_name: Yup.string().nullable(),
  overage_transaction_metadata: Yup.object().nullable(),
  prepaid_transaction_name: Yup.string().nullable(),
  prepaid_transaction_metadata: Yup.object().nullable(),
  discount_id: Yup.number().nullable(),
  discount: Yup.object()
    .nullable()
    .default(null)
    .when([], {
      is: (value) => value !== null,
      then: (schema) =>
        schema.shape({
          type: Yup.string().required('Please, select a discount type'),
          value: Yup.number().required('Please, enter the discount value'),
        }),
    }),
});

export const subscriptionGroupValidationSchema = Yup.object({
  start_date: Yup.date().typeError('Please, enter a start date').required('Please, enter a start date'),
  customer_id: Yup.number().typeError('Please, select a customer').required('Please, select a customer'),
  previous_group_id: Yup.number().nullable().typeError('Please, select a previous subscription'),

  end_date: Yup.date().nullable().typeError('Please, enter an end date'),
  trial_units: Yup.number().nullable().typeError('Please, enter a number'),

  has_discount: Yup.boolean().nullable(),
  is_prorated: Yup.boolean().nullable(),
  prorate_by_amount: Yup.boolean().nullable(),
  aggregate_usage_on_parent: Yup.boolean().nullable(),
  child_customer_ids: Yup.array().of(Yup.number()).nullable(),
  skip_prepaid_transaction_sync: Yup.boolean().nullable(),

  usage_subscriptions: Yup.array()
    .of(subscriptionSchema)
    .min(1, 'Please, select a pricing plan')
    .required('Please, select a pricing plan'),
});
