import { useContext, useEffect } from 'react';
import { useTable, useExpanded, useGridLayout } from 'react-table';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { GoalsContext } from 'shared/Goals';
import { ReportBuilderContext } from 'views/Dashboard/ReportBuilder/ReportBuilderContext';
import { Spacer } from 'components/Core';
import { TooltipContainer, DIRECTIONS } from 'components/Tooltip';
import {
  TooltipColumn,
  TooltipRowText12,
  TooltipRowWrapper,
  TooltipVerticalSeparator,
} from 'components/Tooltip/styles';
import { PointerIcon } from 'components/Icons';
import { ReactTableDashboardCell, ReactTableDashboardHeaderColumn, SnapshotTable } from './styles';

const COLLAPSABLE_ROWS_BY_INDEX = {
  0: SNAPSHOT_METRICS.REVENUE.label,
  1: SNAPSHOT_METRICS.SAAS.label,
  2: SNAPSHOT_METRICS.UNIT_ECONOMICS.label,
  3: SNAPSHOT_METRICS.EFFICIENCY.label,
};

export const Table = ({ columns, data, tableRef }) => {
  const {
    pageReportState: { collapseRows, fixedHeader },
    onEditCollapseRows,
  } = useContext(ReportBuilderContext);
  const { selectedGoals } = useContext(GoalsContext);
  const { getTableProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded, toggleRowExpanded } = useTable(
    {
      columns,
      data: data?.rows,
    },
    useExpanded,
    useGridLayout,
  );

  const handleClickCell = (row) => {
    if (Object.keys(COLLAPSABLE_ROWS_BY_INDEX).includes(row.id)) {
      const newCollapseRows = new Set(collapseRows);
      !!row.isExpanded
        ? newCollapseRows.add(COLLAPSABLE_ROWS_BY_INDEX[row.id])
        : newCollapseRows.delete(COLLAPSABLE_ROWS_BY_INDEX[row.id]);
      onEditCollapseRows(Array.from(newCollapseRows));
    }
  };

  useEffect(() => {
    if (collapseRows.length === 0) {
      // if there is no collapsed rows, expand all
      toggleAllRowsExpanded(true);
    } else {
      // if there are collapsed rows, expand/collapse them accordingly
      Object.entries(COLLAPSABLE_ROWS_BY_INDEX).forEach(([index, title]) => {
        collapseRows.includes(title) ? toggleRowExpanded(index, false) : toggleRowExpanded(index, true);
      });
    }
  }, [data, collapseRows, toggleAllRowsExpanded, toggleRowExpanded]);

  return (
    <>
      <TooltipContainer
        width={220}
        hideArrow
        tooltipStyles={{ borderRadius: '8px' }}
        backgroundColor={'var(--opaquePrimaryBlack50)'}
        yOffset={25}
        direction={DIRECTIONS.TOP}
        toolTipContent={
          <TooltipRowWrapper>
            <TooltipColumn>
              <PointerIcon fill={'white'} />
            </TooltipColumn>
            <Spacer width="8px" />
            <TooltipVerticalSeparator noPadding />
            <Spacer width="8px" />
            <TooltipColumn>
              <TooltipRowText12>{'Click any cell to drill down'}</TooltipRowText12>
            </TooltipColumn>
          </TooltipRowWrapper>
        }
      >
        <SnapshotTable ref={tableRef} id="business-revenue">
          <div
            {...getTableProps()}
            isFixedHeaderScrolling={fixedHeader}
            style={{
              display: 'grid',
              width: 'max-content',
              // The useGridLayout hook does not recalculate the number of columns after changing them (we can change it in settings modal),
              // so we do it manually
              gridTemplateColumns: `${'max-content '.repeat(columns.length)}`,
            }}
          >
            {headerGroups.map((headerGroup) => {
              return headerGroup.headers.map((column, i) => (
                <ReactTableDashboardHeaderColumn
                  isFixedHeaderScrolling={fixedHeader}
                  firstCell={!i}
                  isQuarter={column.id.includes('-Q')}
                  isYearly={column.id.includes('-Y')}
                  isCumulative={column.id.includes('CUMULATIVE')}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </ReactTableDashboardHeaderColumn>
              ));
            })}
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return row.cells.map((cell, cellIndex) => (
                <ReactTableDashboardCell
                  isFixedHeaderScrolling={fixedHeader}
                  cellWithActiveGoal={selectedGoals[row.original.title]?.length > 0}
                  headRow={!row.depth && row.index}
                  firstRow={!rowIndex}
                  firstCell={!cellIndex}
                  isQuarter={cell.column.id.includes('-Q')}
                  isYearly={cell.column.id.includes('-Y')}
                  isCumulative={cell.column.id.includes('CUMULATIVE')}
                  {...cell.getCellProps()}
                  onClick={() => handleClickCell(row)}
                >
                  {cell.render('Cell')}
                </ReactTableDashboardCell>
              ));
            })}
          </div>
        </SnapshotTable>
      </TooltipContainer>
    </>
  );
};
