/*eslint no-unused-vars: "off"*/
import { isUndefined, omitBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { CACHE_KEY as ACCOUNTING_REVENUE_CACHE_KEY } from 'api/accountingRevenue/hooks';
import {
  createContract,
  bulkCreateContracts,
  deleteContract,
  getContracts,
  addContractAttachment as _addContractAttachment,
  removeContractAttachment as _removeContractAttachment,
  updateContract,
  interpretContract as _interpretContract,
  bulkDeleteContracts as _bulkDeleteContracts,
  getContractById,
  removeTransactionFromContract as _removeTransactionFromContract,
  addTransactionsToContract as _addTransactionsToContract,
  updateContractAllocations as _updateContractAllocations,
  updateContractAllocationsAndTransactionAccountingRecognitions as _updateContractAllocationsAndTransactionAccountingRecognitions,
  getDefaultContractAllocations as _getDefaultContractAllocations,
} from './requests';
import { useToasts } from 'components/Toasts';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';

export const CACHE_KEY = 'contracts';

export const useContractsAPI = ({
  orgId,
  scopes = [],
  page,
  limit,
  orderBy,
  searchQuery,
  customerNameSearchQuery,
  contractIds,
  transactionIds,
  customerIds,
  status,
  autoFetch = true,
  enableToasts = true,
  refetchInterval,
  onInterpretComplete,
}) => {
  const params = omitBy(
    {
      scopes: ['pagination_data', ...scopes],
      orderBy,
      'pagination[page]': page,
      'pagination[limit]': limit,
      'filters[customerIds]': customerIds,
      'filters[transactionIds]': transactionIds,
      'filters[contractIds]': contractIds,
      'filters[status]': status,
      'filters[searchQuery]': searchQuery,
      'filters[customerNameSearchQuery]': customerNameSearchQuery,
    },
    isUndefined,
  );
  const dataKey = [CACHE_KEY, orgId, JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getContracts({ orgId, params }),
    {
      enabled: autoFetch,
      refetchInterval,
      refetchOnMount: refetchInterval > 0,
      staleTime: refetchInterval > 0 ? 0 : undefined,
    },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const addContract = useMutation(({ data }) => createContract({ orgId, body: data }), {
    onError: (err, _newContract) => {
      pushError(err, 'Failed to create contract.');
    },

    onSuccess: () => {
      pushToast('Successfully created contract!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkAddContracts = useMutation(({ data }) => bulkCreateContracts({ orgId, body: data }), {
    onError: (err, _newContract) => {
      pushError(err, 'Failed to bulk create contracts.');
    },

    onSuccess: () => {
      pushToast(
        'Successfully bulk created contracts, check back after a while to see if they have been processed',
        'success',
        10000,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const editContract = useMutation(({ id, data }) => updateContract({ orgId, contractId: id, body: data }), {
    onError: (err, _newContract) => {
      pushError(err, 'Failed to update Contract.');
    },

    onSuccess: (_data, variables) => {
      !variables.disableToast && pushToast('Successfully updated Contract!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const addContractAttachment = useMutation(
    ({ id, data }) => _addContractAttachment({ orgId, contractId: id, body: data }),
    {
      onError: (err, _newContract) => {
        pushError(err, 'Failed to add Contract attachment.');
      },

      onSuccess: (_data, variables) => {
        !variables.disableToast && pushToast('Successfully added Contract attachment!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeContractAttachment = useMutation(
    ({ id, attachmentS3Key }) => _removeContractAttachment({ orgId, contractId: id, attachmentS3Key }),
    {
      onError: (err) => {
        pushError(err, 'Failed to remove Contract attachment.');
      },

      onSuccess: () => {
        pushToast('Successfully removed Contract attachment!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeContract = useMutation(
    ({ id, archiveTransactions = false }) => deleteContract({ orgId, contractId: id, archiveTransactions }),
    {
      onError: (err) => {
        pushError(err, 'Failed to remove Contract.');
      },

      onSuccess: () => {
        pushToast('Successfully removed Contract!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const bulkDeleteContracts = useMutation(
    ({ data, archiveTransactions = false }) => _bulkDeleteContracts({ orgId, data, archiveTransactions }),
    {
      onError: (err) => {
        pushError(err, 'Failed to remove contracts.');
      },

      onSuccess: () => {
        pushToast('Successfully removed contracts!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const interpretContract = useDelegatedMutationPolling({
    mutationFn: ({ id }) => _interpretContract({ orgId, contractId: id }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to interpret Contract.', -1);
      },

      onSuccess: () => {
        pushToast('Successfully interpreted Contract!', 'success', -1);
        onInterpretComplete?.();
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  return {
    data: data?.data,
    metadata: data?.metadata,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      addContract,
      bulkAddContracts,
      editContract,
      addContractAttachment,
      removeContractAttachment,
      removeContract,
      bulkDeleteContracts,
      interpretContract,
    },
  };
};

export const useContractAPI = ({ orgId, contractId, scopes = [], enabled = true, enableToasts = true }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, orgId, contractId],
    () => getContractById({ orgId, contractId, params: { scopes } }),
    {
      enabled,
    },
  );

  const history = useHistory();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const addTransactionsToContract = useMutation(
    ({ transactionIds }) => _addTransactionsToContract({ orgId, contractId, transactionIds }),
    {
      onError: (err) => {
        pushError(err, 'Failed to add transaction(s)');
      },

      onSuccess: () => {
        pushToast('Successfully added transaction(s)!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries('transactions');
      },
    },
  );

  const removeTransactionFromContract = useMutation(
    ({ transactionId }) => _removeTransactionFromContract({ orgId, contractId, transactionId }),
    {
      onError: (err) => {
        pushError(err, 'Failed to remove transaction');
      },

      onSuccess: () => {
        pushToast('Successfully removed transaction!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries('transactions');
      },
    },
  );

  const archiveContract = useMutation(
    ({ archiveTransactions = false }) => deleteContract({ orgId, contractId, archiveTransactions }),
    {
      onError: (err) => {
        pushError(err, 'Failed to delete Contract.');
      },

      onSuccess: () => {
        pushToast('Successfully deleted Contract!', 'success');

        history.push('/contracts');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const updateContractAllocations = useMutation(({ body }) => _updateContractAllocations({ orgId, contractId, body }), {
    onError: (err) => {
      pushError(err, 'Failed to update contract allocations');
    },

    onSuccess: () => {
      pushToast('Successfully updated contract allocations!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
      // need to also invalidate `useAccountingSpreadsForContractAPI`
      queryClient.invalidateQueries([ACCOUNTING_REVENUE_CACHE_KEY, orgId, 'accounting_spreads', contractId]);
    },
  });

  const updateContractAllocationsAndTransactionAccountingRecognitions = useMutation(
    ({ body }) => _updateContractAllocationsAndTransactionAccountingRecognitions({ orgId, contractId, body }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update contract allocations');
      },

      onSuccess: () => {
        pushToast('Successfully updated contract allocations!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
        // need to also invalidate `useAccountingSpreadsForContractAPI`
        queryClient.invalidateQueries([ACCOUNTING_REVENUE_CACHE_KEY, orgId, 'accounting_spreads', contractId]);
        // need to invalidate `useTransactionsAPI`
        queryClient.invalidateQueries('transactions');
      },
    },
  );

  const getDefaultContractAllocations = useMutation(
    ({ transactionIds }) => _getDefaultContractAllocations({ orgId, contractId, transactionIds }),
    {
      onError: (err) => {
        pushError(err, 'Failed to get default contract allocations');
      },

      onSuccess: () => null,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      archiveContract: archiveContract.mutateAsync,
      addTransactionsToContract: addTransactionsToContract.mutateAsync,
      removeTransactionFromContract: removeTransactionFromContract.mutateAsync,
      updateContractAllocations: updateContractAllocations.mutateAsync,
      getDefaultContractAllocations: getDefaultContractAllocations.mutateAsync,
      updateContractAllocationsAndTransactionAccountingRecognitions:
        updateContractAllocationsAndTransactionAccountingRecognitions.mutateAsync,
      refetch,
      invalidate: () => queryClient.invalidateQueries(CACHE_KEY),
    },
  };
};
