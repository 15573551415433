import { getInvoiceItemsForInvoices } from 'api/billing/requests';

export const searchInvoiceItems = async ({ searchQuery, orgId, invoiceIds }) => {
  const invoiceItemsList = await getInvoiceItemsForInvoices({
    orgId,
    invoiceIds,
    params: {
      searchQuery,
    },
  });

  const invoiceItems = invoiceItemsList?.map((invoiceItem) => ({
    label: `${invoiceItem?.id} - Amount: ${invoiceItem?.amount} - Description: ${invoiceItem?.description}`,
    value: invoiceItem?.id,
    allFields: invoiceItem,
  }));

  return invoiceItems;
};
