import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'AppContext';
import dayjs from 'dayjs';
import { AdditionalPurpleText, Column, Row } from 'components/Core/Elements';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES } from 'consts/integrations';
import { EVENTS } from 'consts/analytics';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { useAnalytics } from 'utils/hooks';
import { ReportSubscribeModal, useAnalyticsIntegrations } from 'shared/ReportSubscribe';
import { ReactComponent as SlackIcon } from 'images/brand-slack.svg';
import { ReactComponent as MailIcon } from 'images/mailbox.svg';
import { ReactComponent as SwitcherOn } from 'images/switcher-on.svg';
import { ReactComponent as SwitcherOff } from 'images/switcher-off.svg';
import decorImage from 'images/metrics-share-banner__right.png';
import {
  ReportsBannerText,
  ReportsBannerTitle,
  ReportsBannerWrapper,
  CloseReportBannerButton,
  ReportsConnectionButton,
  ActiveTag,
  ReportsBannerTopLeftDecorImage,
} from './styles';

const LOCAL_STORAGE_BANNER_DISMISS_KEY = 'reports-subscribe-banner-dismiss-date';

export const ReportsSubscribeBanner = () => {
  const { organizations } = useContext(AppContext);
  const orgId = organizations?.[0].id;
  const { data: integrationTypes } = useIntegrationsAPI({ orgId });
  const { trackEvent } = useAnalytics();
  const { analyticsIntegrations, updateAnalyticsIntegration } = useAnalyticsIntegrations({
    integrationTypes,
  });

  const bannerDismissDate = localStorage.getItem(LOCAL_STORAGE_BANNER_DISMISS_KEY);

  const isSlackConnected =
    analyticsIntegrations[INTEGRATION_SERVICES.SLACK] &&
    !analyticsIntegrations[INTEGRATION_SERVICES.SLACK].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC];

  const isEmailConnected =
    analyticsIntegrations[INTEGRATION_SERVICES.EMAIL] &&
    !analyticsIntegrations[INTEGRATION_SERVICES.EMAIL].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC];

  const [showBanner, setShowBanner] = useState(false);
  const [showReportSubscribeModal, setShowReportSubscribeModal] = useState(false);

  useEffect(() => {
    //Show banner when user is on the dashboard more that 60sec
    const timeOnPageTimer = setTimeout(() => {
      if (!isSlackConnected || !isEmailConnected) {
        if (bannerDismissDate) {
          //If user dismiss banner, when we show it only after one week to not be annoying
          dayjs().diff(dayjs(bannerDismissDate, 'YYYY-MM-DD'), 'day') > 7 && setShowBanner(true);
        } else {
          setShowBanner(true);
        }
      }
    }, 60000);

    //Close banner automatically if user turn on all connections
    if (isSlackConnected && isEmailConnected) {
      setShowBanner(false);
    }

    return () => {
      clearTimeout(timeOnPageTimer);
    };
  }, [isSlackConnected, isEmailConnected, bannerDismissDate]);

  return showBanner ? (
    <ReportsBannerWrapper>
      <ReportsBannerTopLeftDecorImage />
      <Row horizontal="space-between">
        <Column style={{ padding: '28px 32px' }} horizontal="flex-start">
          <ReportsBannerTitle>
            Do you want to <AdditionalPurpleText>receive updates</AdditionalPurpleText> on your metrics?
          </ReportsBannerTitle>
          <ReportsBannerText>
            Subscript will send reports <b>every Monday at 10:00 AM</b> (New York timezone) by slack and email.
          </ReportsBannerText>

          <Row>
            <ReportsConnectionButton
              connected={isSlackConnected}
              onClick={() => {
                trackEvent({
                  name: EVENTS.ADD_SLACK_REPORTS_CONNECTION_FROM_BANNER,
                });
                updateAnalyticsIntegration({ service: INTEGRATION_SERVICES.SLACK, active: !isSlackConnected });
              }}
            >
              {isSlackConnected && <ActiveTag>Active</ActiveTag>}
              <SlackIcon style={{ marginRight: 8 }} />
              Turn on Slack
              {isSlackConnected ? <SwitcherOn style={{ marginLeft: 8 }} /> : <SwitcherOff style={{ marginLeft: 8 }} />}
            </ReportsConnectionButton>

            <ReportsConnectionButton
              connected={isEmailConnected}
              onClick={() => {
                trackEvent({
                  name: EVENTS.ADD_EMAIL_REPORTS_CONNECTION_FROM_BANNER,
                });
                setShowReportSubscribeModal(true);
              }}
            >
              {isEmailConnected && <ActiveTag>Active</ActiveTag>}
              <MailIcon style={{ marginRight: 8 }} />
              Turn on Email
              {isEmailConnected ? <SwitcherOn style={{ marginLeft: 8 }} /> : <SwitcherOff style={{ marginLeft: 8 }} />}
            </ReportsConnectionButton>

            <CloseReportBannerButton
              onClick={() => {
                localStorage.setItem(LOCAL_STORAGE_BANNER_DISMISS_KEY, JSON.stringify(dayjs()));
                setShowBanner(false);
              }}
            >
              Dismiss
            </CloseReportBannerButton>
          </Row>
        </Column>

        <img style={{ maxHeight: 198 }} src={decorImage} alt="decor" />
      </Row>

      {showReportSubscribeModal && (
        <ReportSubscribeModal immediatelyToggleEmailIntegration onClose={() => setShowReportSubscribeModal(false)} />
      )}
    </ReportsBannerWrapper>
  ) : null;
};
