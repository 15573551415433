import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import logo from 'images/logo.svg';
import { ReactComponent as AvatarIcon } from 'images/avatar-icon.svg';
import { ReactComponent as ButtonIcon } from 'images/wizard_button_icon.svg';
import { ReactComponent as CheckIcon } from 'images/wizard_check_icon.svg';
import { ReactComponent as FinishIcon } from 'images/wizard_finish_icon.svg';
import bcgImage from 'images/wizard_bcg_image.png';
import keyImage from 'images/wizard_third_step_image.png';
import { ErrorBar } from 'components/Blocks';
import { Loader } from 'components/Loaders';
import { StyledAvatar } from 'shared/Layout/Header/style';
import { anonymousIntegrationLogin, getIntegrationsAnonymous } from 'api/integrations';
import { setLocalStorage } from 'utils/storageUtils';
import { useUrlQuery } from 'utils/hooks';
import { getLocalStorage, WIZARD_LOCAL_STORAGE } from './storage';
import { MainLogo } from './MainLogo';
import { GLIntegrationButtons, CRMIntegrationButtons, BillingIntegrationButtons } from './IntegrationButtons';

const WizardWrapper = styled.div`
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
`;

const WizardHead = styled.div`
  background: linear-gradient(180deg, var(--primaryGreen) 0%, var(--secondaryGreen) 100%);
  border-bottom: 8px solid rgba(255, 255, 255, 0.5);
  box-shadow: 10px 0px 40px rgba(26, 68, 42, 0.12);
  height: 357px;
  padding: 36px 135px;
`;

const WizardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WizardMainLogo = styled(MainLogo)`
  width: auto;

  img {
    margin-right: 16px;
  }

  div {
    font-size: 16px;
    line-height: 20px;
  }
`;

const HeaderTitle = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
`;

const WizardBody = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 40px 40px 200px var(--primaryBlack5);
  border-radius: 40px;
  margin: 36px auto 0;
  padding: 60px 100px;
  max-width: 970px;
  min-height: 75vh;

  .bcg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .bcgKey {
    width: 250px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
`;

const WizardSteps = styled.div`
  display: flex;
  margin-bottom: 60px;
  align-items: center;

  svg {
    display: flex;
  }
`;

const StepNumber = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 100px;
  background: ${(props) => (props.active ? 'var(--primaryBlack)' : 'var(--primaryBlack10)')};
  color: ${(props) => (props.active ? 'white' : 'var(--primaryBlack)')};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--primaryBlack);
  opacity: 0.1;
  margin: 0 10px;
`;

const StepSubtitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 30px;
`;

const ActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  background: var(--primaryBlue);
  box-shadow: 0px 4px 10px rgba(57, 161, 220, 0.2);
  border-radius: 100px;
  padding: 10px 20px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 60px;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};

  svg {
    margin-left: 14px;
  }
`;

const StepBody = styled.div`
  font-weight: bold;
  font-size: 42px;
  line-height: 60px;

  span {
    color: var(--primaryGreen);
  }
`;

const ThirdStep = styled.div`
  text-align: center;
`;

const StyledFinishIcon = styled.div`
  svg {
    rect {
      fill-opacity: ${(props) => props.active && '1'};
    }

    path {
      fill: ${(props) => props.active && 'white'};
    }
  }
`;

const WizzardIntegrationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const HeaderUserInfo = styled.div`
  display: flex;

  div {
    margin-right: 20px;
    text-align: right;
  }

  h5 {
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 6px;
  }

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.5;
  }
`;

const Wizard = () => {
  const { storedWizardUserInfo } = getLocalStorage();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [integrationTypes, setIntegrationTypes] = useState([]);

  let query = useUrlQuery();

  const haveError = query.get('error');

  const service = query.get('service');

  const user = haveError
    ? storedWizardUserInfo
    : {
        name: query.get('name'),
        company: query.get('company'),
        orgId: query.get('orgId'),
        id: query.get('id'),
      };

  !haveError && setLocalStorage(WIZARD_LOCAL_STORAGE.USER_INFO, JSON.stringify(user));

  /*
      Google Analytics in production
  */
  useEffect(() => {
    // only trigger in production
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      window.gtag('event', 'wizard', {
        event_category: 'access',
        event_label: 'wizard',
      });
    }
  }, []);

  useEffect(() => {
    const getIntegrations = async () => {
      const integrations = await getIntegrationsAnonymous(user.orgId);
      setIntegrationTypes(integrations);
      if (
        integrations.find((integration) => integration.type === 'CRM') &&
        integrations.find((integration) => integration.type === 'GL') &&
        integrations.find((integration) => integration.type === 'Billing')
      ) {
        setCurrentStep(3);
      } else if (integrations.length) {
        setCurrentStep(2);
      }

      setIsLoading(false);
    };

    getIntegrations();

    if (haveError) {
      setCurrentStep(2);
    }
  }, [user.orgId, haveError, setIntegrationTypes]);

  const handleConnect = () => {
    anonymousIntegrationLogin(user.id, selectedIntegration);
  };

  return (
    <WizardWrapper>
      <WizardHead>
        <WizardHeader>
          <WizardMainLogo>
            <a href="/">
              <img src={logo} alt="logo" width="46" height="46" />
              <div>Subscript</div>
            </a>
          </WizardMainLogo>
          <HeaderTitle>Set up Configuration</HeaderTitle>
          <HeaderUserInfo>
            <div>
              <h5>{user.name}</h5>
              <span>{user.company}</span>
            </div>
            <StyledAvatar className="flexer">
              <AvatarIcon />
            </StyledAvatar>
          </HeaderUserInfo>
        </WizardHeader>
        <WizardBody>
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40, marginTop: 20 }} />
            </div>
          ) : (
            <>
              <WizardSteps>
                {currentStep === 2 || currentStep === 3 ? (
                  <CheckIcon width="100" />
                ) : (
                  <StepNumber active={currentStep === 1}>1</StepNumber>
                )}
                <Line />
                {currentStep === 3 ? <CheckIcon width="100" /> : <StepNumber active={currentStep === 2}>2</StepNumber>}
                <Line />
                <StyledFinishIcon active={currentStep === 3}>
                  <FinishIcon />
                </StyledFinishIcon>
              </WizardSteps>
              {currentStep === 1 && (
                <>
                  <div>
                    <StepSubtitle>
                      Hello, {user.name} from {user.company}!
                    </StepSubtitle>
                    <StepBody>
                      Welcome to the <span>Subscript</span> setup. This will take about 30 seconds, and will let us
                      finish your account setup for you.
                    </StepBody>
                    <ActionButton
                      onClick={() => {
                        setCurrentStep(2);
                      }}
                    >
                      Start configuration
                      <ButtonIcon />
                    </ActionButton>
                  </div>
                  <img src={bcgImage} className="bcg" alt="user" />
                </>
              )}
              {currentStep === 2 && (
                <>
                  <StepBody>
                    Choose and authorize in your <span>external source:</span>
                  </StepBody>

                  <WizzardIntegrationButtonsContainer>
                    <CRMIntegrationButtons
                      handleConnect={handleConnect}
                      selectedIntegration={selectedIntegration}
                      setSelectedIntegration={setSelectedIntegration}
                      disabled={integrationTypes.some((integration) => integration.type === 'CRM')}
                      integrationTypes={integrationTypes}
                    />
                  </WizzardIntegrationButtonsContainer>
                  <WizzardIntegrationButtonsContainer>
                    <GLIntegrationButtons
                      handleConnect={handleConnect}
                      selectedIntegration={selectedIntegration}
                      setSelectedIntegration={setSelectedIntegration}
                      disabled={integrationTypes.some((integration) => integration.type === 'GL')}
                      connectedIntegration={service}
                    />
                    <BillingIntegrationButtons
                      handleConnect={handleConnect}
                      selectedIntegration={selectedIntegration}
                      setSelectedIntegration={setSelectedIntegration}
                      disabled={integrationTypes.some((integration) => integration.type === 'Billing')}
                      connectedIntegration={
                        integrationTypes.find((integration) => integration.type === 'Billing')?.service
                      }
                    />
                  </WizzardIntegrationButtonsContainer>

                  {haveError && (
                    <ErrorBar>
                      <div style={{ width: '90%', marginLeft: 10 }}>
                        <h5 className="mt-1" style={{ fontSize: 16 }}>
                          An error occurred in setting up the integration 😓. Please press back and try again? If it
                          keeps happening, please email us back with the URL that it goes to when you connect and we'll
                          work on figuring it out right away!
                        </h5>
                      </div>
                    </ErrorBar>
                  )}
                  <ActionButton unactive={!integrationTypes.length} onClick={() => setCurrentStep(3)}>
                    Finish Setup
                    <ButtonIcon />
                  </ActionButton>
                </>
              )}
              {currentStep === 3 && (
                <>
                  <ThirdStep>
                    <div>
                      <StepSubtitle>You have successfully completed the setup!</StepSubtitle>
                      <StepBody>
                        Thank you, {user.name && user.name !== 'null' && <span>{user.name}. </span>}
                        We'll work on getting your data set up right away - expect to hear from us in a couple of days.
                      </StepBody>
                    </div>
                  </ThirdStep>
                  <img src={keyImage} className="bcgKey" alt="key" />
                </>
              )}
            </>
          )}
        </WizardBody>
      </WizardHead>
    </WizardWrapper>
  );
};

export default Wizard;
