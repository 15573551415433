import React from 'react';
import styled from 'styled-components';
import { Flexer } from 'components/Core';
import { WidgetTextSectionForm } from '../ReportBuilder/useWidgetModal/WidgetTextSectionForm';
import { CardContainer, SettingsButtonContainer } from '../Common/Card/styled';

const CardBody = styled(Flexer)`
  flex: 1;
  justify-content: space-around;

  font-size: 14px;
  line-height: 20px;

  padding: 20px;
`;

export const DashboardTextSectionCard = ({
  rowStart,
  columnStart,
  size,
  version,
  markdown,
  SettingsButton,
  AddGridCardButton,
}) => {
  return (
    <CardContainer
      rowStart={rowStart}
      columnStart={columnStart}
      size={size}
      direction="column"
      version={version}
      data-cy="dashboard-text-section-card"
    >
      <CardBody>
        <WidgetTextSectionForm markdown={markdown} enabled={false} />
      </CardBody>
      <SettingsButtonContainer>{SettingsButton}</SettingsButtonContainer>
      {AddGridCardButton}
    </CardContainer>
  );
};
