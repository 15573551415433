import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { cssVar } from 'polished';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { CircleLoader } from 'components/Loaders';
import { pluralize } from 'utils/stringUtils';
import { useBillingAgingReportAPI } from 'api/billing/hooks';
import { LoadingSpinnerWrapper } from './styles';

const ChartContainer = styled.div`
  padding: 20px;
  padding-bottom: 0;
  border-radius: 20px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

const ChartTitle = styled.div`
  padding: 14px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-bottom: 1px solid var(--primaryBlack3);
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  align-items: center;
`;

const TitleTotalCounter = styled.div`
  padding: 8px 12px;
  border-radius: 100px;
  border: 1px solid var(--neutralGray);
  background-color: var(--primaryBlack3);
  margin-left: 20px;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--accentGraySecond);
  width: 100%;
  flex-grow: 2;
  height: 100%;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 20px var(--primaryBlack10);
  }
`;

export const ARReportChartWidget = ({ hasAutoCharge }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);
  const { data, isLoading } = useBillingAgingReportAPI({ orgId, asOf: dayjs().endOf('day').toISOString() });

  const history = useHistory();

  defaults.global.defaultFontFamily = 'Nunito Sans';

  const dataForChart = useMemo(() => {
    if (!data || !data.customers) return null;

    const result = {
      current: { customers: 0, amount: 0 },
      '1-30': { customers: 0, amount: 0 },
      '31-60': { customers: 0, amount: 0 },
      '61-90': { customers: 0, amount: 0 },
      '91+': { customers: 0, amount: 0 },
    };

    Object.values(data.customers).forEach((customer) => {
      Object.keys(result).forEach((bucket) => {
        const value = customer[bucket];
        if (value > 0) {
          result[bucket].customers++;
          result[bucket].amount += value;
        }
      });
    });

    return result;
  }, [data]);

  const oneCurrency = data?.currencies?.length === 1;

  if (!dataForChart || !oneCurrency) {
    return null;
  }

  const labels = Object.keys(dataForChart);

  const customersAmountData = {
    type: 'bar',
    label: 'Total amount',
    backgroundColor: (value) => {
      return !value?.dataIndex ? cssVar('--primaryBlue20') : cssVar('--primaryYellow30');
    },
    data: labels.map((bucket) => dataForChart[bucket]?.amount),
    barThickness: hasAutoCharge ? 96 : 66,
    borderWidth: 1,
    borderColor: (value) => {
      return !value?.dataIndex ? cssVar('--primaryBlue15') : cssVar('--primaryYellow15');
    },
    borderRadius: 100,
    borderSkipped: false,
    yAxisID: 'y0',
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 20,
      },
    },
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: cssVar('--primaryBlack70'),
            callback: function (value) {
              return `${value}: ${pluralize(dataForChart[value]?.customers, 'customer')}`;
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'y0',
          position: 'left',
          gridLines: {
            drawBorder: false,
            color: cssVar('--primaryBlack3'),
          },
          ticks: {
            display: false,
          },
        },
      ],
    },

    plugins: {
      datalabels: {
        offset: '5',
        align: 'end',
        anchor: 'end',
        color: cssVar('--primaryBlack50'),
        backgroundColor: cssVar('--neutralGray'),
        font: {
          family: 'Nunito Sans',
          size: 10,
          weight: 800,
        },
        padding: 4,
        borderRadius: 4,
        formatter: function (value) {
          return numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            decimalPlaces: 0,
            currency,
          });
        },
        display: 'auto',
      },
    },
  };

  return (
    <ChartWrapper onClick={() => history.push('/billing/ar-aging-report')}>
      <ChartTitle>
        A/R Aging Report
        <TitleTotalCounter>
          {pluralize(
            Object.values(dataForChart ?? {}).reduce((total, category) => {
              return total + category?.customers;
            }, 0),
            'customer',
          )}
          :{' '}
          {numberFormatter({
            rawValue: data?.all?.total,
            type: NUMBER_FORMATS.CURRENCY,
            decimalPlaces: 0,
            currency,
          })}
        </TitleTotalCounter>
      </ChartTitle>
      {isLoading ? (
        <LoadingSpinnerWrapper data-cy="loader">
          <CircleLoader width="35px" height="35px" isAbsolute isInline={true} />
        </LoadingSpinnerWrapper>
      ) : (
        <ChartContainer>
          <Bar
            height={hasAutoCharge ? 77 : 53}
            data={{
              labels,
              datasets: [customersAmountData],
            }}
            options={options}
          />
        </ChartContainer>
      )}
    </ChartWrapper>
  );
};
