import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  PopoverListItemDescriptionFull,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
} from 'components/Portal';
import { FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { ChevronRight, PlusSignIcon } from 'components/Icons';
import { NewBadge } from 'components/Blocks';

export const POPOVER_LINK_ICON_COLORS = {
  GRAY: 'var(--primaryBlack30)',
  GREEN: 'var(--primaryGreen)',
  YELLOW: 'var(--primaryYellow)',
  RED: 'var(--tertiaryRed)',
};

const HOVER_COLOR_BY_MAIL_COLOR = {
  [POPOVER_LINK_ICON_COLORS.GRAY]: 'var(--primaryBlack)',
  [POPOVER_LINK_ICON_COLORS.GREEN]: 'var(--secondaryGreen)',
  [POPOVER_LINK_ICON_COLORS.YELLOW]: 'var(--secondaryYellow)',
  [POPOVER_LINK_ICON_COLORS.RED]: 'var(--tertiaryRed)',
};

export const StyledPopoverLinkContents = styled.div`
  display: flex;
  gap: 12px;
  padding: 4px;

  background-color: #fff;
  box-shadow: 16px 16px 60px var(--primaryBlack20);
  border-radius: 16px;
  width: 100%;
`;

const ArrowContainer = styled.div`
  width: 16px;
  height: 100%;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const IconStyling = ({ iconColor }) => css`
  svg {
    path {
      fill: ${iconColor ?? 'var(--primaryGreen)'};
    }
  }
`;

const IconHoverStyling = ({ iconColor }) => css`
  svg {
    path {
      fill: ${iconColor ? HOVER_COLOR_BY_MAIL_COLOR[iconColor] : 'var(--secondaryGreen)'};
    }
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 4px;
  max-width: 310px;
  min-width: ${({ minWidth }) => minWidth ?? 'none'};

  a {
    width: 100%;
  }
`;

export const StyledPopoverLinkActionButton = styled(FlexBetweenContainer)`
  align-items: center;
  width: 100%;
  padding: 12px;
  color: var(--primaryGreen);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  background-color: var(--primaryGreen5);
  border-radius: 12px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryGreen);
    }
  }

  &:hover {
    background-color: var(--primaryGreen10);
  }
`;

export const PopoverListItemDescriptionFullEllipsis = styled(PopoverListItemDescriptionFull)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PopoverListItemFullStyled = styled(PopoverListItemFull)`
  min-height: 36px;
  background: ${({ highlighted }) => (highlighted ? 'var(--primaryRed5);' : undefined)};
  margin-bottom: ${({ highlighted }) => (highlighted ? '4px;' : undefined)};
  border: ${({ highlighted }) => (highlighted ? '1px solid var(--primaryRed10);' : undefined)};
  width: ${({ width }) => width ?? 'auto'};

  ${PopoverListItemIconFull} {
    ${({ iconColor }) => IconStyling({ iconColor })}
  }

  ${ArrowContainer} {
    svg {
      path {
        fill: var(--primaryBlack50);
      }
    }
  }

  ${PopoverListItemDescriptionFullEllipsis} {
    color: var(--primaryBlack);
    opacity: 0.5;
  }

  &:hover {
    background: ${({ highlighted }) => (highlighted ? 'var(--primaryRed10);' : 'var(--primaryBlack3);')};
    border: ${({ highlighted }) => (highlighted ? '1px solid transparent;' : undefined)};

    ${PopoverListItemIconFull} {
      ${({ iconColor }) => IconHoverStyling({ iconColor })}
    }

    ${ArrowContainer} {
      svg {
        path {
          fill: var(--primaryBlack);
        }
      }
    }

    ${PopoverListItemDescriptionFullEllipsis} {
      color: var(--primaryBlack);
      opacity: 1;
    }
  }
`;

export const PopoverLinkContentsItem = ({
  title,
  item,
  onClick,
  titleColor,
  iconColor,
  showArrowRight = true,
  highlighted,
  disableLink,
  width,
}) => {
  const MainContent = (props) => (
    <PopoverListItemFullStyled
      data-cy={`navbar__opened-${title}-popover--${item.title}`}
      justifyContent="space-between"
      gap="8px"
      iconColor={iconColor}
      padding="8px"
      highlighted={highlighted}
      width={width}
      {...props}
    >
      <FlexerColumn gap="4px" justifyContent="center" style={{ width: '100%' }}>
        <Flexer gap="8px" alignItems="center" style={{ width: '100%' }}>
          {item.icon && <PopoverListItemIconFull>{item.icon}</PopoverListItemIconFull>}
          <PopoverListItemTitleFull titleColor={titleColor}>
            <Flexer gap="8px" alignItems="center">
              {item.titlePrefix}
              {item.title}
              {item.titleSuffix}
            </Flexer>

            {showArrowRight && (
              <ArrowContainer>
                <ChevronRight />
              </ArrowContainer>
            )}
          </PopoverListItemTitleFull>
          {item.isNewFeature && <NewBadge>New</NewBadge>}
        </Flexer>
        {item.description && (
          <PopoverListItemDescriptionFullEllipsis>{item.description}</PopoverListItemDescriptionFullEllipsis>
        )}
      </FlexerColumn>
    </PopoverListItemFullStyled>
  );

  return disableLink ? (
    <MainContent onClick={onClick} />
  ) : (
    <Link key={item.title} to={item.to ?? '#'} onClick={onClick}>
      <MainContent />
    </Link>
  );
};

export const PopoverLinkActionButton = ({ parentTitle, title, onClick }) => (
  <StyledPopoverLinkActionButton data-cy={`navbar__opened-${parentTitle}-popover--${title}`} onClick={onClick}>
    <span>{title}</span>
    <PlusSignIcon />
  </StyledPopoverLinkActionButton>
);

export const PopoverLinkContents = ({ title, listItems, minWidth, ExtraPopoverSection, children }) => (
  <StyledPopoverLinkContents data-cy={`navbar__opened-${title}-popover`}>
    <LinksWrapper minWidth={minWidth}>
      {listItems?.map((item) => (
        <PopoverLinkContentsItem key={item.title} title={title} item={item} />
      ))}
      {children}
    </LinksWrapper>
    {ExtraPopoverSection}
  </StyledPopoverLinkContents>
);
