import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';
import { validate as validateIsUUID, v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { EVENT_RECOGNITION_TYPES, ORG_CONFIGS } from 'consts/global';
import { GL_INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { useGetAvailableTags, useInvoicingSchedulesAPI } from 'api/billing';
import { generateInvoicesJSON, getInvoicingSchedule, getScheduleWithUnsavedInvoicesData } from 'api/billing/requests';
import { useImportsAPI } from 'api/imports';
import { useCustomerAPI } from 'api/customers';
import { useProductsAPI } from 'api/products';
import { transactionDisplayName } from 'models/transaction';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { useProductsBulkUpdateIncomeAccountModal } from 'views/Configuration/Tabs/ProductsTab/ProductsBulkUpdateIncomeAccountModal';
import { useToasts } from 'components/Toasts';
import { validateFormWithToast } from 'utils/formUtils';
import { groupBy } from 'utils/arrayUtils';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';

import {
  DEFAULT_INVOICE_LANGUAGE,
  INVOICE_ITEM_TYPES,
  INVOICING_FREQUENCIES,
  MANUAL_GL_SERVICE_NAME,
  PRODUCT_IMPORT_METADATA_KEYS,
  SERVICE_WITH_INCOME_ACCOUNT_REF,
} from '../consts';
import { useInvoicePreviewModal } from '../InvoicePreviewModalV2';
import { EMPTY_INVOICING_SCHEDULE } from './consts';
import { InvoicingScheduleLayout } from './InvoicingScheduleLayout';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import {
  getSortedInvoices,
  groupTransactionsByExternalId,
  pushToastByChangedFields,
  getDefaultBillingOption,
  mergeInvoiceFormChangesWithFetchedInvoice,
} from './utils';
import { BillingContext } from '../BillingContext';
import { useReplacedTemplateVariablesModal } from '../InvoiceModal/ReplacedTemplateVariablesModal';
import { getInvoiceData } from '../InvoiceModal/utils';
import { useDuplicateCustomerNameModal } from './useDuplicateCustomerNameModal';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './InvoicingScheduleTabsPanel';
import { CreditNoteContainer } from './CreditNoteContainer';
import { InvoiceFetchEffect } from './InvoiceFetchEffect';

export const InvoicingScheduleContainer = ({
  invoicingScheduleId: passedInvoicingScheduleId,
  includedTransactions: passedIncludedTransactions,
  selectedInvoiceId: passedSelectedInvoiceId,
  selectedReminderId: passedSelectedReminderId,
  selectedCustomer: passedSelectedCustomer,
  closeModal: closeInvoicingScheduleModal,
  initialTab,
  options = {},
  initialOpenRef,
  onInvoicingScheduleCreated,
  onInvoicingScheduleUpdated,
  setSelectedInvoice,
  onInvoicingScheduleDeleted,
}) => {
  const { orgId, integrations, orgConfigs, entities } = useContext(AppContext);
  const { createInvoice, editInvoice, refetchInvoicingScheduleRef } = useContext(BillingContext);
  const {
    operations: { editInvoicingSchedule },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(passedSelectedInvoiceId);
  const isSelectedDraftInvoice = validateIsUUID(selectedInvoiceId);
  const {
    openModal: openDuplicateCustomerNameModal,
    Modal: DuplicateCustomerNameModal,
  } = useDuplicateCustomerNameModal();

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const [expectedNumberOfInvoices, setExpectedNumberOfInvoices] = useState();

  const glIntegrations = integrations?.filter((i) => i.type === INTEGRATION_TYPES.GL);

  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [isInvoiceFetching, setIsInvoiceFetching] = useState(false);
  const [refetchCurrentInvoice, setRefetchCurrentInvoice] = useState();

  const [fetchedSelectedInvoice, setFetchedSelectedInvoice] = useState();

  const { pushToast, pushError } = useToasts();

  const { incomeAccountRefId, billingSenderDefaults = {} } = orgConfigs;

  const invoiceFormRef = useRef();
  const scheduleFormRef = useRef();
  const reminderFormRef = useRef();

  const {
    Modal: InvoicePreviewModal,
    openModal: openInvoicePreviewModal,
    closeModal: closeInvoicePreviewModal,
    isModalOpen: isInvoicePreviewModalOpen,
  } = useInvoicePreviewModal({ orgId, invoiceId: selectedInvoiceId, invoicingScheduleId: passedInvoicingScheduleId });

  const passedTransaction = useMemo(() => passedIncludedTransactions?.[0], [passedIncludedTransactions]);

  const preselectedCustomerId = useMemo(() => passedTransaction?.customer_id ?? passedSelectedCustomer?.id, [
    passedTransaction?.customer_id,
    passedSelectedCustomer?.id,
  ]);
  const preselectedCustomerName = useMemo(() => passedTransaction?.customer_name ?? passedSelectedCustomer?.name, [
    passedTransaction?.customer_name,
    passedSelectedCustomer?.name,
  ]);
  const preselectedCustomerMetadata = useMemo(
    () => passedTransaction?.customerMetadata ?? passedSelectedCustomer?.metadata,
    [passedTransaction?.customerMetadata, passedSelectedCustomer?.metadata],
  );

  const [customer, setCustomer] = useState(
    passedTransaction
      ? { id: passedTransaction.customer_id, name: passedTransaction.customer_name }
      : passedSelectedCustomer
      ? { id: passedSelectedCustomer.id, name: passedSelectedCustomer.name }
      : null,
  );

  const {
    data: customerDetails,
    isLoading: isCustomerDetailsLoading,
    operations: { refetch: refetchCustomer },
  } = useCustomerAPI({
    orgId,
    customerId: customer?.id,
    filters: {
      scopes: ['imports', 'invoicing_details', 'auto_charge_data'],
    },
    enabled: !!customer?.id,
  });

  const { data: productData, refetch: refetchProducts } = useProductsAPI({ orgId, params: { scopes: ['imports'] } });

  // make sure the import exists
  const customerIntegrationId = glIntegrations?.find((integration) => {
    const hasImport = customerDetails?.imports?.some(
      (existingImport) => existingImport.integration_id === integration.id,
    );
    const integrationId = customerDetails?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID];
    // if there is no selected integrationId, we check if there is an associated import
    return (integration.id === integrationId && hasImport) || (!integrationId && hasImport);
  })?.id;

  const initialIntegrationId =
    customerIntegrationId ??
    glIntegrations?.find(
      (integration) =>
        integration.id === preselectedCustomerMetadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID],
    )?.id ??
    glIntegrations?.find((integration) => integration.id === orgConfigs.default_gl)?.id;

  const [includedTransactions, setIncludedTransactions] = useState(
    isEmpty(passedIncludedTransactions) ? [] : passedIncludedTransactions,
  );

  const initialInvoicingSchedule = useMemo(
    () => ({
      ...EMPTY_INVOICING_SCHEDULE,
      customer_id: preselectedCustomerId,
      customer_name: preselectedCustomerName,
      invoicing_frequency:
        options.defaultFrequency ??
        (EVENT_RECOGNITION_TYPES.includes(passedTransaction?.recognition) ? INVOICING_FREQUENCIES.EVENT_BASED : null),
      ...(options.autoSetAutoCharge && {
        auto_charge: !!customer?.has_active_payment_method,
      }),
      language: preselectedCustomerMetadata?.invoice_language ?? DEFAULT_INVOICE_LANGUAGE,
      external_invoice_template_id: orgConfigs[ORG_CONFIGS.INVOICE_TEMPLATE_ID],
      integration_id: initialIntegrationId ?? glIntegrations?.[0]?.id,
      entity_id: billingSenderDefaults?.default_entity_id?.toString() ?? entities[0]?.id,
      transaction_options: (includedTransactions ?? []).reduce(
        (acc, trx) => ({
          ...acc,
          [trx?.id]: {
            allowPastInvoices: true,
            billingAdvanceArrearsOption: getDefaultBillingOption(trx.recognition),
          },
        }),
        {},
      ),
      prorate_invoices: options.prorateInvoices ?? false,
    }),
    [
      preselectedCustomerId,
      preselectedCustomerName,
      options.defaultFrequency,
      options.autoSetAutoCharge,
      options.prorateInvoices,
      passedTransaction?.recognition,
      customer?.has_active_payment_method,
      preselectedCustomerMetadata?.invoice_language,
      orgConfigs,
      initialIntegrationId,
      glIntegrations,
      billingSenderDefaults?.default_entity_id,
      entities,
      includedTransactions,
    ],
  );

  // invoicingSchedule is initially passed into this component
  // currentInvoicingSchedule is new invoicingSchedule witch replace initial invoicingSchedule in state,
  // invoicingScheduleFormValues is current transaction form values, we use it to get
  //   draft form values and update it on every form change
  const [currentInvoicingSchedule, setCurrentInvoicingSchedule] = useState(initialInvoicingSchedule);
  const [invoicingScheduleFormValues, setInvoicingScheduleFormValues] = useState();

  const glIntegration = glIntegrations.find(
    (integration) => integration.id === invoicingScheduleFormValues?.integration_id,
  );

  const invoicingService = getServiceCategory(glIntegration?.service);
  const invoicingServiceDisplayName = GL_INTEGRATION_SERVICES.includes(invoicingService)
    ? getIntegrationDisplayName(glIntegration)
    : '';
  const usesGLConfiguredTaxCodes =
    (glIntegration?.metadata?.useMappedTaxCodes || glIntegration?.metadata?.automaticTaxCalculation) ?? false;

  // Left side tabs panel selected tab
  const [selectedTabsPanelTab, setSelectedTabsPanelTab] = useState(
    initialTab ?? INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES,
  );

  const [allowResend, setAllowResend] = useState(false);

  // used in remind view
  const [selectedReminder, setSelectedReminder] = useState(undefined);
  const [reminderFormValues, setReminderFormValues] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);

  const [showUnsavedWarning, setShowUnsavedWarning] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTransactionInvoicesPreviewModal, setShowTransactionInvoicesPreviewModal] = useState(false);
  const [detachInvoicesBeforeRemoveSchedule, setDetachInvoicesBeforeRemoveSchedule] = useState(false);

  const [invoiceUpdateLoading, setInvoiceUpdateLoading] = useState(false);

  const [invoiceFormValues, setInvoiceFormValues] = useState();
  const [isHistoryTabCollapsed, setIsHistoryTabCollapsed] = useState(true);
  const [bulkChangeInvoices, setBulkChangeInvoices] = useState([]);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const { data: productImports } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'product',
      chifferObjectIds: includedTransactions?.map((transaction) => transaction.product_id)?.filter((id) => !!id) ?? [],
      integrationIds: glIntegration?.id ? [glIntegration.id] : [],
    },
    autoFetch: !!includedTransactions && !!glIntegration?.id,
  });

  const productImportsByProductId = useMemo(
    () => groupBy(productImports ?? [], 'chiffer_object_id', { uniqueness: true }),
    [productImports],
  );

  const {
    OpenReplacedTemplateVariablesModalButton,
    ReplacedTemplateVariablesModal,
  } = useReplacedTemplateVariablesModal({ orgId, invoiceId: selectedInvoiceId, invoiceData: invoiceFormValues });

  const isScheduleDraft =
    !currentInvoicingSchedule?.id || !invoicingScheduleFormValues?.invoices?.some((invoice) => invoice?.id);

  const {
    data: glInvoiceImports,
    isLoading: isGlInvoiceImportsLoading,
    isFetching: isGlInvoiceImportsFetching,
  } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'invoice',
      chifferObjectIds: invoicingScheduleFormValues?.invoices?.map((invoice) => invoice.id)?.filter((id) => !!id) ?? [],
    },
    autoFetch: !!(invoicingScheduleFormValues && currentInvoicingSchedule?.id && invoicingService),
  });

  const [loading, setLoading] = useState(false);
  const fetchInvoicingScheduleRef = useRef(() => {});

  useEffect(() => {
    if (
      passedSelectedReminderId &&
      fetchedSelectedInvoice?.id &&
      (selectedReminder === undefined ||
        (isInvoicePreviewModalOpen && selectedReminder && selectedReminder?.id === passedSelectedReminderId))
    ) {
      const reminder = fetchedSelectedInvoice?.reminders?.find(
        (reminder) => reminder.id === passedSelectedReminderId && !reminder?.sent_at,
      );

      setSelectedReminder(reminder);
      openInvoicePreviewModal();
    }
  }, [
    passedSelectedReminderId,
    selectedReminder,
    fetchedSelectedInvoice,
    openInvoicePreviewModal,
    isInvoicePreviewModalOpen,
  ]);

  const safeCloseModal = (values) => {
    if (isEqual(currentInvoicingSchedule, values) && !isScheduleDraft) {
      closeInvoicingScheduleModal && closeInvoicingScheduleModal();
    } else {
      setShowUnsavedWarning(true);
    }
  };

  useEffect(() => {
    if (currentInvoicingSchedule?.customer_id) {
      setCustomer({ id: currentInvoicingSchedule?.customer_id, name: currentInvoicingSchedule?.customer_name });
    }

    if (
      invoicingScheduleFormValues?.invoices?.length &&
      !fetchedSelectedInvoice &&
      !selectedInvoiceId &&
      selectedTabsPanelTab !== INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS
    ) {
      // auto select first invoice after modal open
      const firstInvoice = getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices })?.[0];
      setFetchedSelectedInvoice(firstInvoice);
      setSelectedInvoiceId(firstInvoice?.id ?? firstInvoice?.unsavedId);
    }
  }, [
    currentInvoicingSchedule,
    fetchedSelectedInvoice,
    invoicingScheduleFormValues,
    selectedInvoiceId,
    selectedTabsPanelTab,
  ]);

  useEffect(() => {
    if (
      invoicingScheduleFormValues &&
      customerDetails &&
      (customerDetails.name !== invoicingScheduleFormValues?.customer_name ||
        customerDetails.id !== invoicingScheduleFormValues?.customer_id)
    ) {
      setInvoicingScheduleFormValues({
        ...invoicingScheduleFormValues,
        customer_name: customerDetails.name,
        customer_id: customerDetails.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails, invoicingScheduleFormValues, setInvoicingScheduleFormValues]);

  // This is for backwards compatibility with old schedules that don't have transaction_options set
  useEffect(() => {
    if (isNil(currentInvoicingSchedule?.transaction_options) && includedTransactions?.length) {
      setCurrentInvoicingSchedule({
        ...currentInvoicingSchedule,
        transaction_options: includedTransactions.reduce(
          (acc, trx) => ({
            ...acc,
            [trx.id]: {
              allowPastInvoices: true,
              billingAdvanceArrearsOption: getDefaultBillingOption(trx.recognition),
            },
          }),
          {},
        ),
      });
    }
  }, [currentInvoicingSchedule, includedTransactions]);

  const refetchInvoicingSchedule = useCallback(
    async ({ silently = false, saveInvoicesFormChanges = true } = {}) => {
      if (!silently) {
        setLoading(true);
      }

      const invoicingScheduleFromBackend = await getInvoicingSchedule({
        orgId,
        invoicingScheduleId: passedInvoicingScheduleId,
        params: {
          scopes: ['transactions', 'invoices'],
          invoiceScopes: ['auto_charge_data', 'external_invoice', 'reminders'],
        },
      });

      const draftInvoices = invoicingScheduleFormValues?.invoices?.filter((invoice) => invoice?.unsavedId) ?? [];

      const invoices = [
        ...draftInvoices,
        ...(invoicingScheduleFromBackend?.invoices ?? [])?.map((invoice) =>
          saveInvoicesFormChanges && (invoice?.id ?? invoice?.unsavedId) !== selectedInvoiceId
            ? mergeInvoiceFormChangesWithFetchedInvoice({
                invoice,
                invoicingScheduleFormValues,
              })
            : invoice,
        ),
      ];

      const externalTemplateId =
        invoices?.find((invoice) => !!invoice.metadata?.external_invoice_template_id && !invoice.sent_at)?.metadata
          ?.external_invoice_template_id ??
        invoices?.find((invoice) => !!invoice.metadata?.external_invoice_template_id)?.metadata
          ?.external_invoice_template_id;

      // after refetch schedule draft invoices should be in place
      setCurrentInvoicingSchedule({
        ...invoicingScheduleFromBackend,
        invoices,
        ...(externalTemplateId && {
          external_invoice_template_id: externalTemplateId,
        }),
        integration_id: invoicingScheduleFromBackend?.integration_id ?? null,
        entity_id:
          invoicingScheduleFromBackend?.entity_id ??
          billingSenderDefaults.default_entity_id?.toString() ??
          entities[0]?.id,
      });

      setIncludedTransactions(
        groupTransactionsByExternalId({
          transactions: invoicingScheduleFromBackend.transactions,
          asArray: true,
        }).flat(),
      );

      if (!silently) {
        setLoading(false);
      }
    },
    [
      orgId,
      passedInvoicingScheduleId,
      invoicingScheduleFormValues,
      selectedInvoiceId,
      billingSenderDefaults.default_entity_id,
      entities,
    ],
  );

  refetchInvoicingScheduleRef.current = refetchInvoicingSchedule;

  const {
    openModal: handleUpdateBulkIncomeAccount,
    ProductsBulkUpdateIncomeAccountModal,
  } = useProductsBulkUpdateIncomeAccountModal({
    onSuccess: passedInvoicingScheduleId ? refetchInvoicingSchedule : null,
  });

  // if only the invoicing schedule ID is passed, fetch it so we have the full object
  useEffect(() => {
    if (passedInvoicingScheduleId) {
      refetchInvoicingSchedule();
    }
    // eslint-disable-next-line
  }, [passedInvoicingScheduleId, orgId]);

  const fillScheduleWithDraftInvoices = useCallback(
    async ({ invoices, invoicingSchedule, frequency, allowPastInvoices, applyRuleset } = {}) => {
      const scheduleWithFilledInvoiceData = await getScheduleWithUnsavedInvoicesData({
        orgId,
        body: {
          ...invoicingScheduleFormValues,
          ...invoicingSchedule,
          invoices: invoices ?? invoicingScheduleFormValues?.invoices,
          invoicing_frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
          transactions: invoicingScheduleFormValues?.transactions ?? includedTransactions,
        },
        params: {
          applyRuleset,
        },
      });

      setCurrentInvoicingSchedule({ ...scheduleWithFilledInvoiceData, allow_past_invoices: allowPastInvoices });

      const newInvoices = scheduleWithFilledInvoiceData?.invoices || [];
      const firstInvoice = getSortedInvoices({ invoices: newInvoices })[0];
      setFetchedSelectedInvoice(firstInvoice);
      setSelectedInvoiceId(firstInvoice?.id ?? firstInvoice?.unsavedId);
    },
    [includedTransactions, invoicingScheduleFormValues, orgId],
  );

  const validateInvoiceFormAndExecuteAction = async ({ action, formRefCurrent }) => {
    const isFormValid = await validateFormWithToast({
      formCurrent: formRefCurrent ?? invoiceFormRef?.current,
      pushToast,
    });

    if (isFormValid) {
      setInvoiceUpdateLoading(true);
      try {
        await action();
      } catch (err) {
        if (
          err.response?.data?.errors?.message === `Duplicate customer name exists in ${invoicingServiceDisplayName}.`
        ) {
          openDuplicateCustomerNameModal({ customer: customerDetails });
        } else {
          throw err;
        }
      } finally {
        // Even if request fails we end the loading
        setInvoiceUpdateLoading(false);
      }
    }
  };

  useEffect(() => {
    const recalculateExpectedNumberOfInvoices = async () => {
      if (invoicingScheduleFormValues?.id && includedTransactions?.length) {
        try {
          const { data } = await generateInvoicesJSON({
            orgId,
            body: {
              customerId: includedTransactions?.[0]?.customer_id,
              parentCustomerIds: [...new Set(includedTransactions.map((transaction) => transaction.customer_id))],
              transactionIds: includedTransactions.map(({ id }) => id),
              frequency: invoicingScheduleFormValues?.invoicing_frequency,
              allowPastInvoices: false,
              invoicingSchedule: invoicingScheduleFormValues,
              prorateInvoices: invoicingScheduleFormValues?.prorate_invoices,
            },
          });

          setExpectedNumberOfInvoices(data?.length);
        } catch (err) {
          console.error({ message: err.message, component: 'InvoiceScheduledContainer', stack: err });
        }
      }
    };
    recalculateExpectedNumberOfInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    includedTransactions,
    orgId,
    invoicingScheduleFormValues?.id,
    invoicingScheduleFormValues?.invoicing_frequency,
    pushError,
  ]);

  const performInvoicingScheduleUpdate = async (invoicingScheduleData) => {
    setInvoiceUpdateLoading(true);

    try {
      const invoiceSchedule = { ...currentInvoicingSchedule, ...invoicingScheduleData };

      const invoicingSchedule = await editInvoicingSchedule.mutateAsync({
        id: invoiceSchedule.id,
        data: invoiceSchedule,
        disableToast: true,
      });

      if (setSelectedInvoice) {
        const firstInvoice = getSortedInvoices({ invoices: invoicingSchedule?.invoices })?.[0];
        setSelectedInvoice(firstInvoice);
      }

      onInvoicingScheduleUpdated(invoicingSchedule);

      pushToastByChangedFields({ pushToast, invoicingScheduleData, invoice: fetchedSelectedInvoice });
    } catch (err) {
      pushError(err, 'Failed to update Invoicing Schedule.');
      console.error({ message: err.message, component: 'InvoicingScheduleModal', stack: err });
    }

    setInvoiceUpdateLoading(false);
  };

  const handleImportInvoicesSave = useCallback(
    async (invoices) => {
      const prevInvoices = invoicingScheduleFormValues?.invoices ?? [];

      const newInvoices = invoices
        .filter((invoice) => !prevInvoices.some((i) => i.external_id === invoice.external_id))
        .map((invoice) => ({
          ...invoice,
          date: dayjs.utc(invoice.date).toDate(),
          unsavedId: uuidv4(),
          invoice_items: invoice?.invoice_items?.map((item) => {
            const isTypedTransaction = Object.values(INVOICE_ITEM_TYPES).includes(item.type);
            const transaction =
              (includedTransactions ?? []).find((transaction) => transaction.id === item?.transaction_id) ??
              (includedTransactions ?? []).find((transaction) => transaction.product_name === item?.name) ??
              includedTransactions[0];
            return {
              id: Math.random() * 100000,
              ...item,
              product_id: isTypedTransaction ? null : transaction?.product_id ?? null,
              transaction_id: isTypedTransaction ? null : transaction?.id ?? null,
            };
          }),
          importedOriginalInvoiceItems: invoice?.invoice_items,
          importedOriginalTotal: invoice?.amount ?? invoice?.Total,
          importedOriginalDate: invoice?.date,
          importedOriginalNumber: invoice?.invoice_number,
          is_imported: true,
          imported_service: invoicingService,
        }));

      if (invoicingScheduleFormValues?.id) {
        for (const invoice of newInvoices) {
          await createInvoice({
            data: {
              ...getInvoiceData(invoice),
              invoicing_schedule_id: invoicingScheduleFormValues?.id,
              customer_id: invoicingScheduleFormValues?.customer_id,
            },
          });
        }
        refetchInvoicingSchedule();
      } else {
        const updatedInvoices = [...prevInvoices, ...newInvoices].sort(
          (a, b) => dayjs.utc(a.date).valueOf() - dayjs.utc(b.date).valueOf(),
        );

        await fillScheduleWithDraftInvoices({
          invoices: updatedInvoices,
          frequency: invoicingScheduleFormValues?.invoicing_frequency,
        });
      }
    },
    [
      createInvoice,
      fillScheduleWithDraftInvoices,
      includedTransactions,
      invoicingScheduleFormValues?.customer_id,
      invoicingScheduleFormValues?.id,
      invoicingScheduleFormValues?.invoices,
      invoicingScheduleFormValues?.invoicing_frequency,
      invoicingService,
      refetchInvoicingSchedule,
    ],
  );

  const disableSaveScheduleButton =
    SERVICE_WITH_INCOME_ACCOUNT_REF.includes(invoicingService) &&
    !incomeAccountRefId &&
    includedTransactions.some(
      (transaction) =>
        !productImportsByProductId[transaction?.product_id]?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT],
    );

  const isSelectedInvoiceImported = useMemo(
    () =>
      (invoiceFormValues?.is_imported &&
        getServiceCategory(invoiceFormValues?.import_service ?? invoiceFormValues?.imported_service) ===
          getServiceCategory(invoicingService)) ||
      glInvoiceImports?.some(
        (importedInvoice) =>
          selectedInvoiceId &&
          importedInvoice.chiffer_object_id === selectedInvoiceId &&
          importedInvoice.integration_id === glIntegration?.id,
      ),
    [
      glIntegration?.id,
      glInvoiceImports,
      invoiceFormValues?.import_service,
      invoiceFormValues?.imported_service,
      invoiceFormValues?.is_imported,
      invoicingService,
      selectedInvoiceId,
    ],
  );

  const invoicesCreatedInGl = useMemo(() => {
    if (!currentInvoicingSchedule?.id) {
      return invoicingScheduleFormValues?.invoices?.filter((invoice) => invoice.is_imported) ?? [];
    }

    const fromGlImports =
      glInvoiceImports?.filter(
        (invoice) => invoice.provider_name === invoicingService && invoice.metadata?.is_historical,
      ) ?? [];

    return (
      invoicingScheduleFormValues?.invoices?.filter(
        (invoice) =>
          fromGlImports.some((importedInvoice) => importedInvoice.chiffer_object_id === invoice.id) ||
          invoice.is_imported,
      ) ?? []
    );
  }, [currentInvoicingSchedule?.id, glInvoiceImports, invoicingScheduleFormValues?.invoices, invoicingService]);

  const productOptions = useMemo(
    () =>
      productData?.products.map((p) => {
        const existingGlImport = (p?.imports ?? []).find(
          (i) => getServiceCategory(i.provider_name) === invoicingService,
        );

        return {
          label: p.display_name || p.name,
          name: p.name,
          subLabel: existingGlImport
            ? `${existingGlImport?.metadata?.product_name || '<No Name>'} / ${existingGlImport?.provider_object_id}`
            : invoicingService && invoicingService !== MANUAL_GL_SERVICE_NAME
            ? 'New product'
            : `${p.name} / ${p.id}`,
          subValue: existingGlImport?.provider_object_id,
          value: p.id,
          metadata: p.metadata,
        };
      }),
    [productData?.products, invoicingService],
  );

  const productsByProductId = useMemo(() => groupBy(productData?.products ?? [], 'id', { uniqueness: true }), [
    productData?.products,
  ]);

  const transactionOptions = useMemo(
    () =>
      includedTransactions.map((t) => ({
        label: transactionDisplayName({ transaction: t, currency: t.currency }),
        recognition: t.recognition,
        value: t.id,
        transaction: t, // used by credit note selector
      })),
    [includedTransactions],
  );

  return (
    <>
      {/* 
        InvoiceFetchEffect updates fetchedSelectedInvoice only when data actually changes.
        This is necessary because for invoices in processing state, we perform refetches 
        every few seconds, but we don't want to re-render the invoicing schedule modal 
        on every processing invoice status check. We can't use this as a hook, because
        hook re-renders component on every useQuery refetch
      */}
      <InvoiceFetchEffect
        orgId={orgId}
        invoiceId={selectedInvoiceId}
        isSelectedDraftInvoice={isSelectedDraftInvoice}
        invoicingScheduleFormValues={invoicingScheduleFormValues}
        setFetchedSelectedInvoice={setFetchedSelectedInvoice}
        setIsInvoiceLoading={setIsInvoiceLoading}
        setIsInvoiceFetching={setIsInvoiceFetching}
        refetchCurrentInvoice={refetchCurrentInvoice}
        setRefetchCurrentInvoice={setRefetchCurrentInvoice}
      />
      <InvoicingScheduleContext.Provider
        value={{
          closeModal: closeInvoicingScheduleModal,
          onInvoicingScheduleCreated,
          onInvoicingScheduleUpdated,
          onInvoicingScheduleDeleted,

          invoicingScheduleFormValues,
          setInvoicingScheduleFormValues,
          currentInvoicingSchedule,
          setCurrentInvoicingSchedule,
          allowResend,
          setAllowResend,

          selectedTabsPanelTab,
          setSelectedTabsPanelTab,

          disableSaveScheduleButton,

          fillScheduleWithDraftInvoices,

          performInvoicingScheduleUpdate,

          closeInvoicePreviewModal,
          openInvoicePreviewModal,
          InvoicePreviewModal,
          isInvoicePreviewModalOpen,

          isHistoryTabCollapsed,
          setIsHistoryTabCollapsed,

          OpenReplacedTemplateVariablesModalButton,
          ReplacedTemplateVariablesModal,

          isScheduleDraft,

          // 'defaultFrequency' allows the invoices to be generated instantly (skipping the frequency dropdown menu)
          defaultFrequency: options.defaultFrequency,
          initialOpenRef,

          bulkChangeInvoices,
          setBulkChangeInvoices,

          orgId,
          invoicingService,
          invoicingServiceDisplayName,
          glIntegration,
          customer,
          setCustomer,
          customerDetails,
          isCustomerDetailsLoading,
          refetchCustomer,
          customerIntegrationId,
          includedTransactions,
          setIncludedTransactions,
          preselectedCustomer: !!preselectedCustomerId || !!passedInvoicingScheduleId,
          usesGLConfiguredTaxCodes,

          productData,
          refetchProducts,
          productOptions,
          productsByProductId,
          transactionOptions,
          productImportsByProductId,

          selectedInvoiceId,
          setSelectedInvoiceId,

          isInvoiceLoading,
          isInvoiceFetching,

          isSelectedInvoiceImportedLoading: isGlInvoiceImportsLoading || isGlInvoiceImportsFetching,
          isSelectedInvoiceImported,
          invoicesCreatedInGl,

          fetchedSelectedInvoice,
          setFetchedSelectedInvoice,
          refetchCurrentInvoice,
          refetchInvoicingSchedule,

          invoiceFormValues,
          setInvoiceFormValues,

          invoiceFormRef,
          reminderFormRef,
          scheduleFormRef,

          invoiceUpdateLoading,
          setInvoiceUpdateLoading,

          validateInvoiceFormAndExecuteAction,

          reminderFormValues,
          setReminderFormValues,
          reminderLoading,
          setReminderLoading,
          selectedReminder,
          setSelectedReminder,

          showUnsavedWarning,
          setShowUnsavedWarning,
          showDeleteModal,
          setShowDeleteModal,
          showTransactionInvoicesPreviewModal,
          setShowTransactionInvoicesPreviewModal,
          loading,
          setLoading,
          fetchInvoicingScheduleRef,
          safeCloseModal,

          expectedNumberOfInvoices,
          setExpectedNumberOfInvoices,

          handleUpdateBulkIncomeAccount,

          editInvoice,

          disableSubmitButton,
          setDisableSubmitButton,

          transactionMetadataTags,
          customerMetadataTags,
          transactionCustomerMetadataTags,

          detachInvoicesBeforeRemoveSchedule,
          setDetachInvoicesBeforeRemoveSchedule,

          handleImportInvoicesSave,
        }}
      >
        <CreditNoteContainer
          customerId={customer?.id}
          onAddCreditNote={() => setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES)}
          currency={invoicingScheduleFormValues?.currency}
          invoices={invoicingScheduleFormValues?.invoices}
          invoicingScheduleId={invoicingScheduleFormValues?.id}
        >
          <InvoicingScheduleLayout />
        </CreditNoteContainer>
        <ProductsBulkUpdateIncomeAccountModal />
        <DuplicateCustomerNameModal />
      </InvoicingScheduleContext.Provider>
    </>
  );
};
