import React, { useRef } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Row } from 'components/Core';
import { useRevenueSpreadsAPI } from 'api/revenueSpreads';
import { ReactComponent as CaseIcon } from 'images/briefcase.svg';
import SpreadsTable from 'views/Spreads/SpreadsTable';
import { ModalContainer, Modal, ModalCloseIcon, ModalHeader, ModalTitle, ModalBody } from 'components/Modal';

const MonthIndicator = styled.span`
  margin-left: 8px;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: var(--secondaryYellow);
`;

const CaseIconContainer = styled.div`
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
`;

export const SpreadsModal = ({
  closeModal,
  organizations,
  cohortStartDate,
  cohortEndDate,
  cohortPeriodType,
  customerIds,
  segmentDescriptor,
  selectedMonth,
  spreadTypes,
  metadataFilter,
  rollup = false,
  isCommitted = false,
  isARR,
}) => {
  const spreadsModal = useRef();

  const cohortStartDateDayjs = dayjs(cohortStartDate);
  const cohortEndDateDayjs = dayjs(cohortEndDate);

  const {
    data,
    shownCustomers,
    isLoading,
    operations: { refetch, loadMoreCustomers, sortCustomers, handleExport },
  } = useRevenueSpreadsAPI({
    orgId: organizations[0].id,
    params: {
      startDate: dayjs(cohortStartDate).format('YYYY-MM-D'),
      endDate: dayjs().format('YYYY-MM-D'),
      cohortStartDate,
      cohortEndDate,
      spreadTypes,
      rollup,
      isCommitted,
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      cohortPeriodType,
    },
    body: { customerIds },
  });

  let modalTitle = cohortStartDateDayjs.isSame(cohortEndDate, 'month')
    ? `Customers from ${cohortStartDateDayjs.format('MMM YYYY')}`
    : `Customers who started between ${cohortStartDateDayjs.format('MMM YYYY')} and ${cohortEndDateDayjs.format(
        'MMM YYYY',
      )}`;
  if (segmentDescriptor) {
    modalTitle += ` - ${segmentDescriptor}`;
  }

  return (
    <ModalContainer className="flexer">
      <Modal ref={spreadsModal} width="90%" overflow="scroll" height="90vh">
        <ModalHeader>
          <ModalCloseIcon onClose={closeModal} data-cy={'spreads-modal__close-button'} />
          <ModalTitle>
            <Row style={{ fontSize: '20px', lineHeight: '26px' }}>
              <CaseIconContainer>
                <CaseIcon />
              </CaseIconContainer>

              <span style={{ marginLeft: 8 }}>
                <b>{modalTitle}</b>
              </span>
              {selectedMonth ? <MonthIndicator>{`(Month ${selectedMonth + 1})`}</MonthIndicator> : null}
            </Row>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {/* Add chart and metrics cards at the top */}
          <SpreadsTable
            isARR={isARR}
            organizations={organizations}
            spreadsData={data ?? []}
            isLoading={isLoading}
            sortCustomers={sortCustomers}
            shownCustomers={shownCustomers}
            loadMoreCustomers={loadMoreCustomers}
            fetchSpreadsData={refetch}
            handleExport={handleExport}
            selectedMonth={selectedMonth}
            cohortsDrilldown={true}
            isMultiMonthCohortDrilldown={!cohortStartDateDayjs.isSame(cohortEndDate, 'month')}
            cohortPeriodType={cohortPeriodType}
          />
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
