import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { CACHE_KEY as CONTRACTS_CACHE_KEY } from 'api/contracts';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions';
import { useToasts } from 'components/Toasts';

import * as requests from './requests';

export const CACHE_KEY = 'billing';

export const usePricingPlansAPI = ({
  orgId,
  params = {},
  autoFetch = true,
  enableToasts = true,
  redirectOnSuccess = true,
}) => {
  const dataKey = [CACHE_KEY, 'pricingPlans', orgId, JSON.stringify(params)];
  const history = useHistory();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getPricingPlans({ orgId, params }),
    {
      enabled: autoFetch,
    },
  );

  const createPricingPlan = useMutation(({ data }) => requests.createPricingPlan({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully created pricing plan!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
    },
  });

  const editPricingPlan = useMutation(({ data, id }) => requests.updatePricingPlan({ orgId, body: data, id }), {
    onError: (err) => {
      pushError(err, 'Failed to update pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully updated pricing plan!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  const deletePricingPlan = useMutation(({ id }) => requests.deletePricingPlan({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted pricing plan!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
    },
  });

  const bulkDeletePricingPlans = useMutation(
    ({ ids }) => requests.bulkDeletePricingPlans({ orgId, pricingPlanIds: ids }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk delete pricing plans.');
      },

      onSuccess: () => {
        pushToast('Successfully bulk deleted pricing plans!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      },
    },
  );

  const bulkUpload = useMutation(({ data }) => requests.bulkCreatePricingPlans({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload pricing plans.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded pricing plans!', 'success');

      if (redirectOnSuccess) {
        setTimeout(() => {
          history.push('/billing/usage-based-engine?tab=pricing-plans');
        }, 1000);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(CONTRACTS_CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createPricingPlan,
      editPricingPlan,
      deletePricingPlan,
      bulkDeletePricingPlans,
      bulkUpload: bulkUpload.mutateAsync,
    },
  };
};

export const useUsageSubscriptionAPI = ({
  orgId,
  id,
  params = {},
  autoFetch = true,
  queryOptions = {},
  enableToasts = true,
}) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const dataKey = [CACHE_KEY, 'usageSubscriptions', orgId, id, JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getUsageSubscription({ orgId, id, params }),
    {
      enabled: autoFetch,
      ...queryOptions,
    },
  );

  const recalculateUsageSubscription = useMutation(() => requests.recalculateUsageSubscription({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to recalculate spreads for usage subscription.');
    },

    onSuccess: () => {
      pushToast('Successfully started recalculating spreads for usage subscription!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      recalculate: recalculateUsageSubscription,
    },
  };
};

export const useUsageSubscriptionGroupsAPI = ({ orgId, params = {}, autoFetch = true, enableToasts = true }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();

  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, 'usageSubscriptionGroups', orgId, JSON.stringify(params)],
    () => requests.getUsageSubscriptionGroups({ orgId, params }),
    {
      enabled: autoFetch,
    },
  );

  const createUsageSubscriptionGroup = useMutation(
    ({ data }) => requests.createUsageSubscriptionGroup({ orgId, body: data }),
    {
      onError: (err) => {
        pushError(err, 'Failed to create usage subscription.');
      },

      onSuccess: () => {
        pushToast('Successfully created usage subscription!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      },
    },
  );

  const editUsageSubscriptionGroup = useMutation(
    ({ id, data }) => requests.editUsageSubscriptionGroup({ orgId, id, body: data }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update usage subscription.');
      },

      onSuccess: () => {
        pushToast('Successfully updated usage subscription!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      },
    },
  );

  const createTransactions = useMutation(
    ({ id }) => requests.createTransactionsForUsageSubscriptionGroup({ orgId, id }),
    {
      onError: (err) => {
        pushError(err, 'Failed to create transactions for subscription.');
      },

      onSuccess: () => {
        pushToast('Successfully created transactions for usage subscription!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      },
    },
  );

  const deleteUsageSubscriptionGroup = useMutation(({ id }) => requests.deleteUsageSubscriptionGroup({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete usage subscription.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted usage subscription!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const bulkDeleteUsageSubscriptionGroups = useMutation(
    ({ ids }) => requests.bulkDeleteUsageSubscriptionGroups({ orgId, ids }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk delete usage subscriptions.');
      },

      onSuccess: () => {
        pushToast('Successfully bulk deleted usage subscriptions!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptionGroups', orgId]);
      },
    },
  );

  const bulkUpload = useMutation(
    ({ data, params }) => requests.bulkCreateUsageSubscriptionGroups({ orgId, body: data, params }),
    {
      onError: (err) => {
        pushError(err, 'Failed to upload subscriptions.');
      },

      onSuccess: () => {
        pushToast('Successfully uploaded subscriptions!', 'success');
      },

      onSettled: async () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      bulkUpload,
      createUsageSubscriptionGroup,
      editUsageSubscriptionGroup,
      deleteUsageSubscriptionGroup,
      bulkDeleteUsageSubscriptionGroups,
      createTransactions,
    },
  };
};

export const useUsageEventsAPI = ({ orgId, params, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getUsageEvents({ orgId, params }),
    { enabled: autoFetch },
  );

  const createUsageEvent = useMutation(({ data }) => requests.createUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create customer usage event.');
    },

    onSuccess: () => {
      pushToast(
        'Successfully created customer usage event! Related usage subscriptions and invoices will also be updated within a few minutes.',
        'success',
        10000,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageEvents', orgId]);
    },
  });

  const deleteUsageEvent = useMutation(({ data }) => requests.createUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to delete customer usage event.');
    },

    onSuccess: () => {
      pushToast(
        'Successfully deleted customer usage event! Related usage subscriptions and invoices will also be updated within a few minutes.',
        'success',
        10000,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageEvents', orgId]);
    },
  });

  const bulkUpload = useMutation(({ data }) => requests.bulkCreateUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload usage events.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded usage events!', 'success');
    },

    onSettled: async () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkDeleteUsageEvents = useDelegatedMutationPolling({
    mutationFn: async ({ filters, data, filtersCount }) =>
      requests.bulkDeleteUsageEvents({ orgId, filters, data, filtersCount }),
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Bulk deleting customer usage events is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish deleting customer usage events. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Deleting customer usage events is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(
          err,
          `Failed to delete customer usage events. ${err?.response?.data?.error ?? err.message ?? err}`,
          -1,
        );
      },

      onSuccess: (response) => {
        pushToast(
          'Successfully bulk deleted customer usage events! Related usage subscriptions and invoices will also be updated within a few minutes.',
          'success',
          -1,
        );
        queryClient.invalidateQueries([CACHE_KEY, 'usageEvents', orgId]);
        return response;
      },
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createUsageEvent,
      deleteUsageEvent,
      bulkDeleteUsageEvents: bulkDeleteUsageEvents.mutateAsync,
      bulkUpload: bulkUpload.mutateAsync,
    },
  };
};

export const useUniqueUsageEventNamesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, 'eventNames'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getUniqueEventNames({ orgId }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useUniqueUsageEventSourcesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, 'eventSources'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getUniqueEventSources({ orgId }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useEvaluatePricingPlanFormulaAPI = ({
  orgId,
  params,
  autoFetch = true,
  enableToasts = true,
  onSuccess,
  onError,
}) => {
  const bodyRef = useRef(params);

  const dataKey = [CACHE_KEY, 'pricingPlans', orgId, 'formula', params];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.evaluatePricingPlanFormula({ orgId, body: bodyRef.current }),
    {
      enabled: autoFetch && !!bodyRef.current,

      onError: (err) => {
        pushError(err, 'Failed to evaluate formula');
        onError && onError(err);
      },

      onSuccess: (data) => {
        pushToast('Successfully evaluated formula!', 'success');
        onSuccess && onSuccess(data);
      },
    },
  );

  useEffect(() => {
    bodyRef.current = params;
  }, [params]);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      evaluateFormula: (body) => {
        bodyRef.current = body;
        refetch();
      },
    },
  };
};

export const useDiscountsAPI = ({ orgId, params = {}, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'discounts', orgId, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getDiscounts({ orgId, params }),
    { enabled: autoFetch },
  );

  const createDiscount = useMutation(({ data }) => requests.createDiscount({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create discount.');
    },

    onSuccess: () => {
      pushToast('Successfully created discount!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'discounts', orgId]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createDiscount,
    },
  };
};

export const useDiscountAPI = ({ orgId, id, params = {}, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'discounts', orgId, id, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => requests.getDiscount({ orgId, id, params }),
    { enabled: autoFetch },
  );

  const editDiscount = useMutation(({ data, id }) => requests.updateDiscount({ orgId, body: data, id }), {
    onError: (err) => {
      pushError(err, 'Failed to update discount.');
    },

    onSuccess: () => {
      pushToast('Successfully updated discount!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  const deleteDiscount = useMutation(({ id }) => requests.deleteDiscount({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete discount.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted discount!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      editDiscount,
      deleteDiscount,
    },
  };
};
