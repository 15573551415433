export const AI_DESCREPANCIES_TABLE_COLUMNS = {
  id: {
    key: 'id',
    name: 'Id',
    isFilterable: false,
    isHideable: false,
  },
  created_at: {
    key: 'created_at',
    name: 'Created At',
    isFilterable: false,
    isHideable: false,
  },
  date: {
    key: 'date',
    name: 'Date',
    isFilterable: true,
    isHideable: true,
  },
  name: {
    key: 'name',
    name: 'Name',
    isFilterable: true,
    isHideable: false,
  },
  amount: {
    key: 'amount',
    name: 'Total',
    isFilterable: true,
    isHideable: true,
  },
  recurring_amount: {
    key: 'recurring_amount',
    name: 'Recurring',
    isFilterable: true,
    isHideable: true,
  },
  seats: {
    key: 'seats',
    name: 'Seats',
    isFilterable: true,
    isHideable: true,
  },
  customer_name: {
    key: 'customer_name',
    name: 'Customer',
    isFilterable: true,
    isHideable: true,
  },
  product_name: {
    key: 'product_name',
    name: 'Product',
    isFilterable: true,
    isHideable: true,
  },
  recognition: {
    key: 'recognition',
    name: 'Type',
    isFilterable: true,
    isHideable: true,
  },
  start_date: {
    key: 'start_date',
    name: 'Start',
    isFilterable: true,
    isHideable: true,
  },
  end_date: {
    key: 'end_date',
    name: 'End',
    isFilterable: true,
    isHideable: true,
  },
  metadata: {
    key: 'metadata',
    name: null,
    isFilterable: true,
    isHideable: null,
  },
};

export const AI_DESCREPANCIES_TABLE_COLUMN_TITLES_BY_ID = {
  [AI_DESCREPANCIES_TABLE_COLUMNS.id.key]: AI_DESCREPANCIES_TABLE_COLUMNS.id.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.created_at.key]: AI_DESCREPANCIES_TABLE_COLUMNS.created_at.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.date.key]: AI_DESCREPANCIES_TABLE_COLUMNS.date.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.name.key]: AI_DESCREPANCIES_TABLE_COLUMNS.name.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.amount.key]: AI_DESCREPANCIES_TABLE_COLUMNS.amount.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.recurring_amount.key]: AI_DESCREPANCIES_TABLE_COLUMNS.recurring_amount.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.seats.key]: AI_DESCREPANCIES_TABLE_COLUMNS.seats.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.customer_name.key]: AI_DESCREPANCIES_TABLE_COLUMNS.customer_name.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.product_name.key]: AI_DESCREPANCIES_TABLE_COLUMNS.product_name.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.recognition.key]: AI_DESCREPANCIES_TABLE_COLUMNS.recognition.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.start_date.key]: AI_DESCREPANCIES_TABLE_COLUMNS.start_date.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.end_date.key]: AI_DESCREPANCIES_TABLE_COLUMNS.end_date.name,
  [AI_DESCREPANCIES_TABLE_COLUMNS.metadata.key]: AI_DESCREPANCIES_TABLE_COLUMNS.metadata.name,
};
