import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { useInvoicesAPI } from 'api/billing';
import { AppContext } from 'AppContext';
import { FlexEndContainer } from 'components/Core';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalTitle } from 'components/Modal';
import { ReactComponent as CheckIcon } from 'images/circle-check-white.svg';
import { AUTO_CHARGE_DATE_COMPARISON, AUTO_CHARGE_DATE_REFERENCE_TO_LABEL } from 'views/Billing/consts';

const BodyText = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const useUpdateInvoicesAutoChargeDateModal = () => {
  const { orgId } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [autoChargeInvoiceSendingDefaults, setAutoChargeInvoiceSendingDefaults] = useState(null);

  const openModal = (autoChargeInvoiceSendingDefaults) => {
    setAutoChargeInvoiceSendingDefaults(autoChargeInvoiceSendingDefaults);
    setModalOpen(true);
  };

  const {
    operations: { bulkUpdateChargeDate },
  } = useInvoicesAPI({
    orgId,
    autoFetch: false,
  });

  const closeModal = () => {
    setModalOpen(null);
  };

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await bulkUpdateChargeDate.mutateAsync({});
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
    setModalOpen(null);
  }, [setModalOpen, bulkUpdateChargeDate]);

  const UpdateInvoicesAutoChargeDateModal = useCallback(
    () =>
      modalOpen ? (
        <ModalContainer data-cy="update-invoices-auto-charge-date-modal">
          <Modal height="auto" width="500px">
            <ModalTitle>
              <b>Do you want to update the existing invoices auto charge date?</b>
            </ModalTitle>

            <ModalBody>
              <BodyText>
                This will update the auto charge date for all auto-charge not paid invoices to the{' '}
                <b>
                  {autoChargeInvoiceSendingDefaults?.charging_date_comparison !== AUTO_CHARGE_DATE_COMPARISON.EQUAL
                    ? `${autoChargeInvoiceSendingDefaults?.charging_date_offset} days ${autoChargeInvoiceSendingDefaults?.charging_date_comparison}`
                    : ''}{' '}
                  {AUTO_CHARGE_DATE_REFERENCE_TO_LABEL[autoChargeInvoiceSendingDefaults?.charging_date_reference]}
                </b>
              </BodyText>
            </ModalBody>

            <ModalFooter noFixedHeight padding="13px 21px">
              <FlexEndContainer>
                <ModalButton data-cy="update-invoices-auto-charge-date-modal__close-button" onClick={closeModal}>
                  Close
                </ModalButton>
                <ModalButton
                  data-cy="update-invoices-auto-charge-date-modal__submit-button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className="primary"
                >
                  {isLoading ? 'Confirming...' : 'Confirm'}
                  <CheckIcon style={{ marginLeft: '4px' }} />
                </ModalButton>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        </ModalContainer>
      ) : null,
    [modalOpen, handleSubmit, isLoading, autoChargeInvoiceSendingDefaults],
  );

  return {
    isModalOpen: !!modalOpen,
    openModal,
    Modal: UpdateInvoicesAutoChargeDateModal,
  };
};
