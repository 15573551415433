import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { Modal, ModalContainer } from 'components/Modal';
import { FlexerRow } from 'components/Core';
import { AppContext } from 'AppContext';
import { ReactComponent as CheckIcon } from 'images/check-white-no-circle.svg';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { Filters } from 'shared/Filters';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { Button } from 'components/Buttons';
import { useLLMReportSettingsAPI } from 'api/monthlyBreakdown';
import { COLORS } from 'consts/colors';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { ChartVisualizerDataSetting } from './ChartVisualizerDataSetting';
import { ChartVizualizerContext } from './ChartVizualizerContext';

const TopPositionedModalContainer = styled(ModalContainer)`
  align-items: flex-start;
  padding-top: 40px;
`;

const ThinModal = styled(Modal)`
  height: auto;
  min-height: 60px;
  max-height: ${(props) => (props.isResponseShowing ? '80vh' : '60px')};
  display: flex;
  flex-direction: column;
  width: 1140px;
  padding: 16px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 20px var(--primaryBlack15);
  transition: all 0.3s ease;
`;

const AIIconWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(180deg, #ffc27a 0%, #ff62dd 48%, #6d48ff 100%);
  color: var(--light100);
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

const BorderlessInput = styled(Input)`
  border: none;
  box-shadow: none;

  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
  }

  .ant-input {
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    font-style: italic;
  }

  input::placeholder {
    font-style: italic;
  }
`;

const CheckIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--darkGreen);
  box-shadow: 0px 4px 24px 0px var(--darkGreen20));
  margin-top: 2px;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 10px;
  cursor: pointer;
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const ResponseText = styled.div`
  color: var(--primaryBlack);
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
`;

export const ChartVisualizerAIModal = ({ open, onClose }) => {
  const { orgId, appSettings } = useContext(AppContext);
  const { setSelectedMetrics, dataFilter, setDataFilter } = useContext(ChartVizualizerContext);
  const [query, setQuery] = useState('');
  const [responseData, setResponseData] = useState(undefined);

  const { isLoading, refetch } = useLLMReportSettingsAPI({
    orgId,
    query,
    enabled: false,
  });

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      processQuery(query);
    }
  };

  const processQuery = async (queryText) => {
    if (!queryText.trim()) return;
    setResponseData(undefined);

    try {
      const result = await refetch();
      if (result.data) {
        setResponseData(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setQuery('');
    setResponseData(undefined);
    onClose();
  };

  const handleGenerateReport = () => {
    if (!responseData) return;

    if (responseData.metrics?.length > 0) {
      setSelectedMetrics(responseData.metrics);
    }

    if (responseData.period_start && responseData.period_end) {
      const startMonth = (() => {
        const [month, year] = responseData.period_start.split('-');
        return new Date(parseInt(year), parseInt(month) - 1, 1);
      })();

      const endMonth = (() => {
        const [month, year] = responseData.period_end.split('-');
        return new Date(parseInt(year), parseInt(month) - 1, 1);
      })();

      setDataFilter({
        ...dataFilter,
        period: 'Custom',
        startMonth,
        endMonth,
      });
    }

    appSettings.setIsARR(responseData.annual_recurring_revenue);
    appSettings.setIsCommitted(responseData.committed);
    appSettings.setRollup(responseData.rollup);
    appSettings.setOptimisticAnalytics(responseData.optimistic);
    appSettings.setCountInfluxAsRenewed(responseData.flux);

    handleClose();
  };

  return open ? (
    <TopPositionedModalContainer>
      <ThinModal isResponseShowing={!!responseData} hasSecondData={!!responseData?.metrics?.[1]}>
        <FlexerRow style={{ width: '100%' }}>
          <AIIconWrapper>AI</AIIconWrapper>
          <BorderlessInput
            placeholder="Enter a prompt for the Subscript AI to generate any report..."
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
            autoFocus
          />
          <CloseIconWrapper
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </CloseIconWrapper>
          <CheckIconWrapper onClick={() => processQuery(query)} style={{ cursor: isLoading ? 'wait' : 'pointer' }}>
            <CheckIcon />
          </CheckIconWrapper>
        </FlexerRow>
        {responseData && (
          <>
            <ResponseWrapper>
              <ResponseText>Here're your report settings, review them and proceed to generating the data:</ResponseText>
              <ChartVisualizerDataSetting data1={responseData.metrics?.[0]} data2={responseData.metrics?.[1]} />
              <GlobalTogglesContextProvider
                urlState={{
                  isARR: responseData.annual_recurring_revenue,
                  isCommitted: responseData.committed,
                  rollup: responseData.rollup,
                  optimisticAnalytics: responseData.optimistic,
                  countInfluxAsRenewed: responseData.flux,
                  setIsARR: (value) => {
                    setResponseData((prev) => ({ ...prev, annual_recurring_revenue: value }));
                  },
                  setIsCommitted: (value) => {
                    setResponseData((prev) => ({ ...prev, committed: value }));
                  },
                  setRollup: (value) => {
                    setResponseData((prev) => ({ ...prev, rollup: value }));
                  },
                  setOptimisticAnalytics: (value) => {
                    setResponseData((prev) => ({ ...prev, optimistic: value }));
                  },
                  setCountInfluxAsRenewed: (value) => {
                    setResponseData((prev) => ({ ...prev, flux: value }));
                  },
                }}
              >
                <Filters
                  hidePeriodSelection
                  applyDraftAutomatically
                  dataFilter={{
                    period: 'Custom',
                    startMonth: (() => {
                      const [month, year] = responseData.period_start.split('-');
                      return new Date(parseInt(year), parseInt(month) - 1, 1);
                    })(),
                    endMonth: (() => {
                      const [month, year] = responseData.period_end.split('-');
                      return new Date(parseInt(year), parseInt(month) - 1, 1);
                    })(),
                  }}
                  setDataFilter={(value) => {
                    setResponseData((prev) => ({
                      ...prev,
                      period_start: `${value.startMonth.getMonth() + 1}-${value.startMonth.getFullYear()}`,
                      period_end: `${value.endMonth.getMonth() + 1}-${value.endMonth.getFullYear()}`,
                    }));
                  }}
                />
              </GlobalTogglesContextProvider>
            </ResponseWrapper>
            <FlexerRow justifyContent="flex-end" gap="8px">
              <Button
                filled
                color={COLORS.GREY}
                onClick={() => {
                  handleClose();
                }}
              >
                <div style={{ fontWeight: 700 }}>Cancel</div>
              </Button>
              <Button filled color={COLORS.GREEN} active={true} onClick={handleGenerateReport}>
                <FlexerRow gap="8px">
                  <div style={{ fontWeight: 700 }}>Generate Report</div>
                  <ArrowRight />
                </FlexerRow>
              </Button>
            </FlexerRow>
          </>
        )}
      </ThinModal>
    </TopPositionedModalContainer>
  ) : null;
};
