import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { INVOICE_ITEM_TYPES } from './consts';

dayjs.extend(utc);

const isDateAfterToday = (value) => {
  const utcDate = dayjs(value).utc().format('YYYY-MM-DD');

  return dayjs(utcDate).isAfter(dayjs(), 'day');
};

export const VALIDATION_SCHEMA = ({ isPreviewModal, sendFromService }) =>
  Yup.object({
    customer_id: Yup.string().required('Please select a customer'),
    date: Yup.date().typeError('Date must be a valid date').required('Please select a date'),
    send_date: Yup.date().when(['auto_send', 'sent_at'], {
      is: (auto_send, sent_at) => !!auto_send && !sent_at,
      then: Yup.date()
        .typeError('Date must be a valid date')
        .required('Please select a date')
        .test('after-today', 'Scheduled send date must be a valid date in the future', isDateAfterToday),

      otherwise: Yup.date().typeError('Date must be a valid date').nullable(),
    }),
    memo: Yup.string().nullable(),
    days_to_pay: Yup.number().when(['paid_at'], {
      is: (paid_at) => !paid_at,
      then: Yup.number()
        .typeError('Days to pay must be valid number')
        .required('Please select a number of days to pay'),
      otherwise: Yup.number().nullable(),
    }),

    // Must be a valid date in the future if auto_charge is true
    auto_charge_date: Yup.date().when(['auto_charge'], {
      // Only when auto_charge is true
      is: (auto_charge) => !!auto_charge,

      // Date can be tomorrow or beyond
      // If the user wants to charge today, they can follow the 'manually charge' flow instead
      then: Yup.date()
        .typeError('Auto-charge date must be a valid date')
        .required('Please select an auto-charge date')
        .test('after-today', 'Auto charge date must be a valid date in the future', isDateAfterToday),

      // If auto_charge is false, date can be null
      otherwise: Yup.date().nullable(),
    }),
    payment_options: Yup.array().of(Yup.string()).nullable(),
    email_addresses: Yup.array().when(['auto_send', 'sent_at', 'paid_at', 'auto_charge'], {
      is: (auto_send, sent_at, paid_at, auto_charge) => (!sent_at && !paid_at && !auto_charge) || !!auto_send,
      then: Yup.array().of(Yup.string()).required().min(1, 'Please provide at least one email address'),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    email_subject: Yup.string().when(['auto_send', 'sent_at', 'paid_at'], {
      is: (auto_send, sent_at, paid_at) =>
        ((!sent_at && !paid_at) || !!auto_send) && isPreviewModal && sendFromService !== INTEGRATION_SERVICES.XERO,
      then: Yup.string().typeError('Please enter an email subject').required('Please enter an email subject'),
      otherwise: Yup.string().nullable(),
    }),
    email_body: Yup.string().when(['auto_send', 'sent_at', 'paid_at'], {
      is: (auto_send, sent_at, paid_at) =>
        ((!sent_at && !paid_at) || !!auto_send) && isPreviewModal && sendFromService !== INTEGRATION_SERVICES.XERO,
      then: Yup.string().typeError('Please enter an email body').required('Please enter an email body'),
      otherwise: Yup.string().nullable(),
    }),
    invoice_items: Yup.array().when(['paid_at', 'is_imported'], {
      // We don't really care what the line items are if we're just marking it as something or getting rid of it
      is: (paid_at, is_imported) => !paid_at || is_imported,
      then: Yup.array().of(
        Yup.object().shape({
          transaction_id: Yup.string().when('type', {
            is: (val) => Object.values(INVOICE_ITEM_TYPES).includes(val),
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Invoice item needs a transaction'),
          }),
          product_id: Yup.string().when('type', {
            is: (val) => Object.values(INVOICE_ITEM_TYPES).includes(val),
            then: Yup.string().nullable(),
            otherwise: Yup.string().typeError('Invoice item needs a product').required('Invoice item needs a product'),
          }),
          amount: Yup.number().typeError('Invoice item needs an amount').required('Invoice item needs an amount'),
          seats: Yup.number().nullable(true),
          description: Yup.string().max(1024, 'Description must be less than 1024 characters').nullable(),
        }),
      ),
      otherwise: Yup.array(),
    }),
  });

export const VALIDATION_REMIND_SCHEMA = Yup.object({
  to_emails: Yup.array().of(Yup.string()).required().min(1, 'Please provide at least one email address'),
  subject: Yup.string().typeError('Please enter an email subject').required('Please enter an email subject'),
  body: Yup.string().typeError('Please enter an email body').required('Please enter an email body'),
});
