import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import {
  Modal,
  ModalBodyMargin,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Spacer } from 'components/Core';
import { Divider } from 'components/Core/Lines';
import { FormikCustomCheckbox, FormikCustomSelector, FormikCustomRadio } from 'components/Controls';
import { CARD_SIZES } from '../Card';

const HUGE_CARD_METRICS = {
  CHART_VISUALIZER: {
    value: 'chart_visualizer',
    label: 'Chart Visualizer',
  },
  REVENUE_WATERFALL: {
    value: 'revenue_waterfall',
    label: 'Revenue waterfall',
  },
};

const EditMetricCardModal = ({ onClose, onSubmitted, metric, metricOptions }) => {
  const isHugeCard = metric.size === CARD_SIZES.HUGE;

  const handleSubmit = (data) => {
    onClose();
    if (onSubmitted) {
      if (data.id === HUGE_CARD_METRICS.REVENUE_WATERFALL.value) {
        delete data.selectedMetrics;
      }
      onSubmitted(data);
    }
  };

  return (
    <ModalContainer data-cy="edit-metric-card-modal">
      <Modal height="auto" width="440px">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle>
            <ModalTitleText>
              <b>Edit Metric Card</b>
            </ModalTitleText>
            <ModalSubtitleText>Customize your page</ModalSubtitleText>
          </ModalTitle>
        </ModalHeader>
        <Formik
          initialValues={metric}
          validationSchema={Yup.object({
            id: Yup.string().required('Please, select a metric'),
            isTTM: Yup.bool(),
            selectedMetrics: Yup.array().when('id', {
              is: 'chart_visualizer',
              then: Yup.array().required().min(1, 'Please, select both metrics'),
              otherwise: Yup.array().nullable(),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <ModalBodyMargin>
                {!isHugeCard ? (
                  <>
                    <FormikCustomSelector
                      name="id"
                      label="Metric"
                      options={metricOptions}
                      handleChange={(option) => {
                        setFieldValue('id', option.value);
                        if (!option.hasTTMOption) {
                          // If it does not have TTM option we will reset the value
                          setFieldValue('isTTM', false);
                        }
                      }}
                    />
                    <Spacer height="20px" />
                    <FormikCustomCheckbox
                      data-cy="edit-metric-card-modal__isTTM-checkbox"
                      name="isTTM"
                      label="Trailing Twelve Months (TTM)"
                      disabled={!metricOptions.find((option) => option.value === values.id).hasTTMOption}
                    />
                  </>
                ) : (
                  <>
                    <FormikCustomRadio
                      name="id"
                      suffix="edit-metric-card-modal__id-radio"
                      options={Object.values(HUGE_CARD_METRICS)}
                    />
                    <Spacer height="20px" />

                    {values.id === HUGE_CARD_METRICS.CHART_VISUALIZER.value && (
                      <>
                        <FormikCustomSelector
                          name="selectedMetrics.0.id"
                          label="First metric"
                          options={metricOptions}
                          handleChange={(option) => {
                            setFieldValue('selectedMetrics.0.id', option.value);
                            if (!option.hasTTMOption) {
                              // If it does not have TTM option we will reset the value
                              setFieldValue('selectedMetrics.0.isTTM', false);
                            }
                          }}
                        />
                        <Spacer height="20px" />
                        <FormikCustomCheckbox
                          data-cy="edit-metric-card-modal__isTTM-0-checkbox"
                          name="selectedMetrics.0.isTTM"
                          label="Trailing Twelve Months (TTM)"
                          disabled={
                            !metricOptions.find((option) => option.value === values.selectedMetrics?.[0].id)
                              ?.hasTTMOption
                          }
                        />

                        <Spacer height="24px" />
                        <Divider />
                        <Spacer height="24px" />

                        <FormikCustomSelector
                          name="selectedMetrics.1.id"
                          label="Second metric"
                          options={[{ label: 'None' }, ...metricOptions]}
                          handleChange={(option) => {
                            if (!option.value) {
                              // If null we are removing from selectedMetrics
                              setFieldValue('selectedMetrics', [values.selectedMetrics[0]]);
                            } else {
                              setFieldValue('selectedMetrics.1.id', option.value);
                              if (!option.hasTTMOption) {
                                // If it does not have TTM option we will reset the value
                                setFieldValue('selectedMetrics.1.isTTM', false);
                              }
                            }
                          }}
                        />
                        <Spacer height="20px" />
                        {values.selectedMetrics?.length === 2 && (
                          <FormikCustomCheckbox
                            data-cy="edit-metric-card-modal__isTTM-1-checkbox"
                            name="selectedMetrics.1.isTTM"
                            label="Trailing Twelve Months (TTM)"
                            disabled={
                              !metricOptions.find((option) => option.value === values.selectedMetrics?.[1].id)
                                ?.hasTTMOption
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </ModalBodyMargin>

              <ModalFooter flexEnd>
                <ModalButton default onClick={onClose}>
                  Cancel
                </ModalButton>
                <Spacer width="14px" />
                <ModalButton primary onClick={handleSubmit} data-cy="edit-metric-card-modal__save-button">
                  Save
                </ModalButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useEditMetricCardModal = ({ metricOptions, onSubmitted }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [metric, setMetric] = useState();

  const openModal = useCallback(
    (metric) => {
      trackEvent({
        name: EVENTS.OPEN_EDIT_METRIC_CARD_MODAL,
        properties: {
          metricId: metric.id,
        },
      });

      setMetric(metric);
      setShowModal(true);
    },
    [trackEvent, setShowModal],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <EditMetricCardModal
          onClose={() => setShowModal(false)}
          onSubmitted={onSubmitted}
          metric={metric}
          metricOptions={metricOptions}
        />
      ) : (
        <></>
      ),
    [showModal, setShowModal, onSubmitted, metric, metricOptions],
  );

  return {
    openEditMetricCardModal: openModal,
    EditMetricCardModal: Modal,
  };
};
