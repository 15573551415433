import { useCallback, useState } from 'react';
import { ContractFilesModal } from './ContractFilesModal';

export const useContractFilesModal = ({
  transactionIds,
  customerId,
  uploadNew = false,
  readOnly = false,
  forAi = false,
  contractIds,
  processingContractIds,
  setProcessingContractIds,
  finishedProcessing,
  setFinishedProcessing,
  onSubmit,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState([]);

  const openModal = useCallback(
    ({ contracts = [] } = {}) => {
      setShowModal(true);
      setSelectedContracts(contracts);
    },
    [setShowModal],
  );
  const Modal = useCallback(
    () =>
      showModal ? (
        <ContractFilesModal
          selectedContracts={selectedContracts}
          transactionIds={transactionIds}
          customerId={customerId}
          contractIds={contractIds}
          uploadNew={uploadNew}
          forAi={forAi}
          processingContractIds={processingContractIds}
          setProcessingContractIds={setProcessingContractIds}
          setFinishedProcessing={setFinishedProcessing}
          readOnly={readOnly}
          onClose={() => {
            setShowModal(false);
            if (finishedProcessing) {
              setProcessingContractIds([]);
            }
          }}
          onSubmit={() => {
            setShowModal(false);
            onSubmit();
          }}
        />
      ) : null,
    [
      showModal,
      setShowModal,
      transactionIds,
      customerId,
      uploadNew,
      readOnly,
      onSubmit,
      selectedContracts,
      forAi,
      processingContractIds,
      setProcessingContractIds,
      finishedProcessing,
      setFinishedProcessing,
      contractIds,
    ],
  );

  return {
    openContractFilesModal: openModal,
    ContractFilesModal: Modal,
    isModalVisible: showModal,
  };
};
