import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { useContractsAPI } from 'api/contracts';
import { FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { TrashIcon } from 'components/Icons';
import { formatFileSize } from 'components/Controls';
import { AttachmentItem as StyledAttachmentItem, AttachmentItemDetails } from './styles';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt } from 'components/Portal';
import { WordBreak } from 'components/Core/Text';
import styled from 'styled-components';
import { CONTRACT_STATUS } from '../ContractsListView/consts';

const Badge = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 900;
  color: var(--dark100);
  padding: 4px 8px;
  border-radius: 100px;
  background-color: white;
  border: 1px solid var(--dark5);
`;

const PROCESSING_STATUS_TO_TITLE = {
  [CONTRACT_STATUS.PROCESSING]: 'in processing',
  [CONTRACT_STATUS.PARSED]: 'finished',
  [CONTRACT_STATUS.NEEDS_REVIEW]: 'needs review',
};

const ProcessingBadge = ({ status }) => {
  return status && <Badge>{PROCESSING_STATUS_TO_TITLE[status]}</Badge>;
};

export const AttachmentItem = ({ contractId, attachment, onClick, selected, disabled }) => {
  const { orgId } = useContext(AppContext);
  const {
    operations: { removeContractAttachment },
  } = useContractsAPI({
    orgId,
    autoFetch: false,
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleRemoveAttachment = async () => {
    setIsConfirmOpen(false);
    if (contractId) {
      await removeContractAttachment.mutateAsync({ id: contractId, attachmentS3Key: attachment.s3_file_key });
    }
  };

  return (
    <StyledAttachmentItem
      data-cy="contract-files-modal_row"
      selected={selected}
      onClick={onClick}
      isDeletedActive={isConfirmOpen}
    >
      <FlexerColumn width="100%" gap="4px">
        <FlexBetweenContainer width="100%">
          <WordBreak>{attachment?.name}</WordBreak>
          {!disabled && <TrashIcon style={{ flexShrink: 0 }} onClick={() => setIsConfirmOpen(true)} />}
        </FlexBetweenContainer>
        <AttachmentItemDetails>
          <FlexerColumn gap="10px 0" alignItems="flex-start">
            <div>
              Added at {dayjs.utc(attachment?.uploaded_at).format('MMM DD, YYYY')}{' '}
              {attachment?.size ? `(${formatFileSize(attachment.size)})` : ''}
            </div>
            <ProcessingBadge status={attachment.processingStatus} />
          </FlexerColumn>
        </AttachmentItemDetails>
      </FlexerColumn>

      {isConfirmOpen && (
        <Popover darkMode width="200px" XOffset={210} YOffset={34}>
          <PopoverPrompt>Are you sure you want to delete the attachment?</PopoverPrompt>
          <PopoverActions>
            <PopoverButton onClick={() => setIsConfirmOpen(false)}>No</PopoverButton>
            <PopoverButton onClick={handleRemoveAttachment} primary>
              Yes
            </PopoverButton>
          </PopoverActions>
        </Popover>
      )}
    </StyledAttachmentItem>
  );
};
