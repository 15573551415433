import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useAnalytics } from 'utils/hooks';
import { EVENTS } from 'consts/analytics';
import { useOrganizationCountsAPI } from 'api/organizations';
import { AIHint, AlertFilledIcon, CircleCheckIcon } from 'components/Icons';
import { Tab, Tabs } from 'components/Tabs';
import { TRANSACTIONS_PAGES_ROUTES } from '../consts';
import { TabLabel } from 'components/Tabs/styles';
import { Centerer } from 'components/Core';
import { TRANSACTIONS_ALERTS_PAGE_TABS } from './consts';

export const TransactionsAlertsPageTabs = () => {
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { orgId } = useContext(AppContext);
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('tab') || TRANSACTIONS_ALERTS_PAGE_TABS.UNCONFIRMED_TRANSACTIONS;

  const { data } = useOrganizationCountsAPI({
    orgId,
    scopes: ['unresolvedExternalUpdates', 'unconfirmedTransactions', 'aiTransactionsDescrepencies'],
  });

  const unresolvedExternalUpdatesCount = data?.unresolvedExternalUpdates ?? 0;
  const aiTransactionsDescrepenciesCount = data?.aiTransactionsDescrepencies ?? 0;
  const unconfirmedTransactionsCount = data?.unconfirmedTransactions ?? 0;

  const transactionsAlertsPageTabs = [
    {
      label: `Unconfirmed Transactions ${unconfirmedTransactionsCount > 0 ? `(${unconfirmedTransactionsCount})` : ''}`,
      value: TRANSACTIONS_ALERTS_PAGE_TABS.UNCONFIRMED_TRANSACTIONS,
      icon: <AlertFilledIcon />,
      count: unconfirmedTransactionsCount,
    },
    {
      label: (
        <Centerer gap="8px">
          <AIHint className="unstyled" />
          {`Differing from their contracts ${
            aiTransactionsDescrepenciesCount > 0 ? `(${aiTransactionsDescrepenciesCount})` : ''
          }`}
        </Centerer>
      ),
      value: TRANSACTIONS_ALERTS_PAGE_TABS.AI_DESCREPENCIES,
      count: aiTransactionsDescrepenciesCount,
    },
    {
      label: `External Updates ${unresolvedExternalUpdatesCount > 0 ? `(${unresolvedExternalUpdatesCount})` : ''}`,
      value: TRANSACTIONS_ALERTS_PAGE_TABS.EXTERNAL_UPDATES,
      count: unresolvedExternalUpdatesCount,
    },
  ];

  const handleTabClick = (tabValue) => {
    trackEvent({ name: EVENTS.VIEW_TRANSACTIONS_ALERTS_PAGE_TAB, properties: { tab: tabValue } });
    history.push(`${TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_ALERTS}?tab=${tabValue}`);
  };

  return (
    <Tabs width="100%">
      <TabLabel>Type</TabLabel>
      {transactionsAlertsPageTabs.map((tab) => (
        <Tab
          key={tab.value}
          selected={activeTab === tab.value}
          onClick={() => handleTabClick(tab.value)}
          iconColor={tab.count > 0 ? 'var(--tertiaryRed)' : 'var(--primaryBlack30)'}
          iconSelectedColor={tab.count > 0 ? 'var(--tertiaryRed)' : 'var(--primaryBlack30)'}
        >
          <span>{tab.label}</span>
          {tab.count > 0 ? <AlertFilledIcon /> : <CircleCheckIcon />}
        </Tab>
      ))}
    </Tabs>
  );
};
