import styled from 'styled-components';
import { TableIcon } from 'components/Icons';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { FlexerColumn, FlexerRow } from 'components/Core';

const Wrapper = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;

const DataText = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const ChartVisualizerDataSetting = ({ data1, data2 }) => {
  return (
    <Wrapper>
      <TableIcon />
      <FlexerColumn>
        {data1 && (
          <FlexerRow gap="8px">
            Data 1: <DataText>{SNAPSHOT_METRICS[data1].label}</DataText>
          </FlexerRow>
        )}
        {data2 && (
          <FlexerRow gap="8px" style={{ marginTop: '8px' }}>
            Data 2: <DataText>{SNAPSHOT_METRICS[data2].label}</DataText>
          </FlexerRow>
        )}
      </FlexerColumn>
    </Wrapper>
  );
};
