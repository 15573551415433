import React, { useState, useContext, Fragment } from 'react';
import { capitalize } from 'lodash';
import { AppContext } from 'AppContext';
import { ReactComponent as CalculatorIcon } from 'images/bxs-calculator.svg';
import { ReactComponent as AverageIcon } from 'images/bxs-doughnut-chart.svg';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { InfoIcon } from 'components/Icons';
import { Row } from 'components/Core';
import { HeaderCellWrapper, SortIconWrapper } from 'components/Table';
import { NoItems } from 'shared/ErrorBars';
import { CustomerViewModal } from 'views/Customers/CustomerSingle/CustomerViewModal';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { NUMBER_FORMATS } from 'consts/global';
import { reFormatDate } from 'utils/dateUtils';
import { numberFormatter } from 'utils/formatters';
import TableTile from './TableTile';
import { TooltipContainer } from 'components/Tooltip';
import { useToasts } from 'components/Toasts';
import { DATA_TYPES } from 'shared/Filters/utils';
import { getCustomerDisplayName } from 'models/customer';
import { RetentionToolTip } from './RetentionToolTip';
import { getHeatLevel, formatCurrency, SPREADS_PALLETS, getQuartilesFromValues } from './utils';
import {
  LoadMoreButton,
  TableContainer,
  TopRowTile,
  DateHeaderTile,
  StyledTopRow,
  StyledTable,
  TableBody,
  TableHeaderRow,
  TableHeaderTile,
  TableBodyRow,
  LeftTitlesGroup,
} from './SpreadsTable.styles';
import { SegmentRow } from './SegmentRow';

export const Table = ({
  spreadsData,
  cohortsDrilldown,
  cohortPeriodType = 'month',
  selectedMonth,
  isDescSort,
  setIsDescSort,
  sortByMonth,
  setSortByMonth,
  shownCustomers,
  segmentBy,
  organizations,
  loadMoreCustomers,
  fetchSpreadsData,
  setTransactionToShow,
  setCellToExplain,
  // these are only set when this table is summoned from the Dashboard
  tableHeight,
  tableMinHeight,
  isRetentionMetric = false,
  retentionMetricKey,
  retentionMetricTitle,
  dataTypeToShow,
  isRevenueAnnualizable = true, // false for accounting spreads and for customer page so we can show nonrecurring
  isMultiMonthCohortDrilldown = false,
  ...props
}) => {
  const {
    appSettings: { isARR: appIsARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const isARR = props.isARR ?? appIsARR;
  const { pushToast } = useToasts();

  const [customerIdToShow, setCustomerIdToShow] = useState(null);
  const customerRowsToShow =
    dataTypeToShow === DATA_TYPES.seats ? spreadsData.seatsRowsByCustomer : spreadsData.revenueRowsByCustomer;

  let quartiles;
  if (Object.keys(spreadsData).length !== 0) {
    const allRowValues = new Set();
    for (const customerValues of Object.values(customerRowsToShow)) {
      for (const value of Object.values(customerValues)) {
        allRowValues.add(value);
      }
    }

    quartiles = getQuartilesFromValues(Object.values(Array.from(allRowValues)));
  }

  const startingMonthByCustomer = {}; // map of { <customerId>: <month 1 for this customer> }
  if (isMultiMonthCohortDrilldown) {
    for (let customerId of shownCustomers) {
      for (let month of Object.keys(customerRowsToShow[customerId])) {
        if (customerRowsToShow[customerId][month] !== 0) {
          startingMonthByCustomer[customerId] = month;
          break;
        }
      }
    }
  }

  const segmentBreakdowns = spreadsData?.breakdownRowsByCustomer;
  return (
    <>
      {Object.keys(spreadsData).length && Object.keys(spreadsData?.revenueRowsByCustomer).length ? (
        <TableContainer
          data-cy="spread-table"
          cohortsDrilldown={cohortsDrilldown}
          height={tableHeight}
          minHeight={tableMinHeight}
        >
          <StyledTable>
            <TableHeaderRow>
              <TableHeaderTile>{cohortsDrilldown ? cohortPeriodType.toUpperCase() : 'MONTH'}</TableHeaderTile>
              {spreadsData.months.map((column, index) => (
                <TableHeaderTile
                  data-cy="spread-table__month-cell"
                  onClick={() => {
                    if (isMultiMonthCohortDrilldown) return;
                    setIsDescSort && setIsDescSort(column === sortByMonth ? !isDescSort : false);
                    setSortByMonth && setSortByMonth(sortByMonth && isDescSort ? null : column);
                  }}
                  style={!isMultiMonthCohortDrilldown ? { cursor: 'pointer' } : {}}
                  selectedMonth={index === selectedMonth}
                  backgroundColor={index === selectedMonth ? 'var(--primaryYellow)' : 'var(--primaryGray)'}
                  key={column}
                >
                  <DateHeaderTile>
                    <HeaderCellWrapper isSortable isSorted={sortByMonth === column}>
                      {cohortsDrilldown
                        ? `${capitalize(cohortPeriodType)} ${index + 1}`
                        : reFormatDate(column, 'YYYY-MM', 'MMM YY')}
                      {sortByMonth === column && (
                        <SortIconWrapper data-cy="spread-table__sort-icon" isSortedDesc={!isDescSort}>
                          <TotalIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </DateHeaderTile>
                </TableHeaderTile>
              ))}
            </TableHeaderRow>

            <TableBody>
              {/* Sum Row */}
              {spreadsData.totalAmountsEachMonth && (
                <StyledTopRow noMarginBottom>
                  <TopRowTile noBorder>
                    <Row style={{ width: '100%' }} horizontal="space-between">
                      <Row>
                        <CalculatorIcon /> <span style={{ paddingLeft: 8 }}>Sum</span>
                      </Row>
                    </Row>
                  </TopRowTile>
                  {spreadsData.months.map((month, index) => (
                    <TopRowTile
                      key={month}
                      selectedMonth={index === selectedMonth}
                      data-cy="spreads-table__total-column"
                      isMoneyColumn
                    >
                      {isRetentionMetric &&
                      [
                        SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.monthDataKey,
                        SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.monthDataKey,
                      ].includes(retentionMetricKey)
                        ? numberFormatter({
                            type: NUMBER_FORMATS.NUMBER,
                            rawValue: spreadsData.totalAmountsEachMonth?.[month],
                          })
                        : dataTypeToShow === DATA_TYPES.seats
                        ? numberFormatter({
                            type: NUMBER_FORMATS.NUMBER,
                            rawValue: spreadsData.totalSeatsEachMonth?.[month],
                          })
                        : formatCurrency({
                            rawValue: spreadsData.totalAmountsEachMonth?.[month],
                            isARR: isRevenueAnnualizable && isARR,
                            currency,
                            shouldAbbreviate: true,
                          })}
                    </TopRowTile>
                  ))}
                </StyledTopRow>
              )}

              {/* Retention Row */}
              {!isMultiMonthCohortDrilldown && spreadsData.retentionByMonth && (
                <StyledTopRow>
                  <TopRowTile>
                    <Row style={{ width: '100%' }} horizontal="space-between">
                      <Row>
                        <CalculatorIcon /> <span style={{ paddingLeft: 8 }}>Retention</span>
                      </Row>
                    </Row>
                  </TopRowTile>
                  {spreadsData.months.map((month, index) => (
                    <TopRowTile
                      key={month}
                      selectedMonth={index === selectedMonth}
                      data-cy="spreads-table__retention-column"
                    >
                      <TooltipContainer
                        width={250}
                        toolTipContent={
                          <RetentionToolTip
                            spreadsData={spreadsData}
                            month={month}
                            retentionMetricKey={retentionMetricKey}
                            retentionMetricTitle={retentionMetricTitle}
                          />
                        }
                      >
                        {numberFormatter({
                          type: NUMBER_FORMATS.PERCENT,
                          rawValue: spreadsData.retentionByMonth[month],
                        })}
                      </TooltipContainer>
                    </TopRowTile>
                  ))}
                </StyledTopRow>
              )}

              {/* Average Row */}
              {(spreadsData.avgAmountEachMonthWithZeros || spreadsData.avgAmountEachMonth) && (
                <StyledTopRow>
                  <TopRowTile>
                    <Row style={{ width: '100%' }} horizontal="space-between">
                      <Row>
                        <AverageIcon />
                        <span style={{ paddingLeft: 8 }}>Average</span>
                      </Row>
                      <TooltipContainer
                        width={175}
                        toolTipContent={
                          cohortsDrilldown
                            ? 'Averages in any given column include customers whose revenue has gone to $0'
                            : "Averages in any given column don't include $0 customers"
                        }
                      >
                        <Row>
                          <InfoIcon style={{ opacity: 0.3 }} size="16px" />
                        </Row>
                      </TooltipContainer>
                    </Row>
                  </TopRowTile>
                  {spreadsData.months.map((month, index) => (
                    <TopRowTile key={month} selectedMonth={index === selectedMonth} isMoneyColumn>
                      <TooltipContainer
                        width={175}
                        toolTipContent={`${
                          cohortsDrilldown
                            ? spreadsData.totalCustomers
                            : dataTypeToShow === DATA_TYPES.revenue
                            ? spreadsData.nonZeroAmountCustomersPerMonth?.[month]
                            : spreadsData.nonZeroSeatsCustomersPerMonth?.[month]
                        } customers`}
                      >
                        {dataTypeToShow === DATA_TYPES.revenue
                          ? formatCurrency({
                              rawValue: cohortsDrilldown
                                ? spreadsData.avgAmountEachMonthWithZeros?.[month]
                                : spreadsData.avgAmountEachMonth?.[month],
                              isARR: isRevenueAnnualizable && isARR,
                              currency,
                              shouldAbbreviate: true,
                            })
                          : numberFormatter({
                              type: NUMBER_FORMATS.NUMBER,
                              rawValue: spreadsData.avgSeatsEachMonth?.[month],
                            })}
                      </TooltipContainer>
                    </TopRowTile>
                  ))}
                </StyledTopRow>
              )}

              {shownCustomers.map((customerId, index, arr) => {
                const customerDisplayName = getCustomerDisplayName({
                  customerName: spreadsData.customerNamesById[customerId],
                  customerId,
                });

                quartiles = !isRetentionMetric
                  ? quartiles
                  : getQuartilesFromValues(Object.values(spreadsData?.revenueRowsByCustomer?.[customerId] ?? {}));

                return (
                  <Fragment key={customerId}>
                    {/* Main row */}
                    <TableBodyRow data-cy="spreads-table__body-row">
                      {/* Customer name */}
                      <LeftTitlesGroup
                        isSpreadSegmented={segmentBy}
                        isFirstRow={!index}
                        isLastRow={index === arr.length - 1}
                      >
                        <TableTile
                          bold
                          value={
                            customerDisplayName.length < 25
                              ? customerDisplayName
                              : `${customerDisplayName.substring(0, 25)}...`
                          }
                          style={{ width: 250, cursor: 'pointer' }}
                          toolTipText={customerDisplayName.length >= 25 ? customerDisplayName : null}
                          onClick={() => setCustomerIdToShow(customerId)}
                          padding="14px"
                          datacyProp="spreads-table__customer-name-column"
                          left={true}
                        />
                      </LeftTitlesGroup>

                      {customerRowsToShow[customerId] &&
                        Object.keys(customerRowsToShow[customerId]).map((month, index) => {
                          if (isMultiMonthCohortDrilldown && month < startingMonthByCustomer[customerId]) return null;
                          const value = parseFloat(customerRowsToShow[customerId][month]);
                          return (
                            <TableTile
                              primary
                              value={
                                (isRetentionMetric &&
                                  [
                                    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.monthDataKey,
                                    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.monthDataKey,
                                  ].includes(retentionMetricKey)) ||
                                dataTypeToShow !== DATA_TYPES.revenue
                                  ? numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: value })
                                  : formatCurrency({
                                      rawValue: value,
                                      isARR: isRevenueAnnualizable && isARR,
                                      currency,
                                      shouldAbbreviate: true,
                                    })
                              }
                              key={month}
                              heatLevel={getHeatLevel({ quartiles, value })}
                              index={index}
                              data-cy="spreads-table__customer-value-column"
                              palette={SPREADS_PALLETS.MONOCHROMIC}
                              onClick={
                                setCellToExplain
                                  ? () =>
                                      setCellToExplain({
                                        customerId,
                                        month,
                                        amount: value,
                                        customer_name: customerDisplayName,
                                      })
                                  : null
                              }
                              showPointer={!!setCellToExplain}
                              isMoneyColumn={true}
                            />
                          );
                        })}
                    </TableBodyRow>

                    {/* Segment rows */}
                    {segmentBreakdowns && segmentBreakdowns[customerId] ? (
                      <SegmentRow
                        segmentBreakdowns={segmentBreakdowns}
                        customerId={customerId}
                        products={organizations?.[0]?.products ?? []}
                        productCategories={organizations?.[0]?.productCategories ?? []}
                        segmentBy={segmentBy}
                        quartiles={quartiles}
                        isARR={isRevenueAnnualizable && isARR}
                        currency={currency}
                        setTransactionToShow={setTransactionToShow}
                        dataTypeToShow={dataTypeToShow}
                      />
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </StyledTable>
          {shownCustomers.length !== Object.keys(customerRowsToShow).length && (
            <LoadMoreButton onClick={loadMoreCustomers}>Load more</LoadMoreButton>
          )}
        </TableContainer>
      ) : (
        <NoItems
          buttonCb={() => {
            fetchSpreadsData();
            setSortByMonth(null);
            pushToast('Spreads refreshed', 'success');
          }}
          organization={organizations[0].id}
        />
      )}
      {customerIdToShow && (
        <CustomerViewModal customerId={customerIdToShow} onClose={() => setCustomerIdToShow(null)} />
      )}
    </>
  );
};
