import { useInvoiceAPI } from 'api/billing';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

const shouldUpdateInvoiceWithThisChanges = ({ changedFields, invoice, invoiceData }) => {
  // When we do polling taxes, we don't want to rerender the invoice if the job_status is the same
  if (changedFields?.includes('polling_taxes_job_id')) {
    if (invoice?.polling_taxes_job_id && invoiceData?.polling_taxes_job_id) {
      const ignoredFields = ['updated_at', 'polling_taxes_job_id'];
      if (invoice?.metadata?.job_status === invoiceData?.metadata?.job_status) {
        ignoredFields.push('metadata');
      }
      const hasImportantChanges = changedFields?.some((field) => !ignoredFields.includes(field));
      return hasImportantChanges;
    }
  }
  return true;
};

export const InvoiceFetchEffect = ({
  orgId,
  invoiceId,
  isSelectedDraftInvoice,
  invoicingScheduleFormValues,
  setFetchedSelectedInvoice,
  refetchCurrentInvoice: refetchCurrentInvoiceProp,
  setRefetchCurrentInvoice,
  setIsInvoiceLoading,
}) => {
  const [invoiceData, setInvoiceData] = useState(null);

  const {
    data: invoice,
    isLoading,
    operations: { refetch: refetchCurrentInvoice },
  } = useInvoiceAPI({
    orgId,
    invoiceId,
    autoFetch: !isSelectedDraftInvoice,
    enableToasts: false,
  });

  useEffect(() => {
    setIsInvoiceLoading(isLoading);
  }, [isLoading, setIsInvoiceLoading]);

  useEffect(() => {
    if (!refetchCurrentInvoiceProp) {
      setRefetchCurrentInvoice(() => refetchCurrentInvoice);
    }
  }, [refetchCurrentInvoice, setRefetchCurrentInvoice, refetchCurrentInvoiceProp]);

  useEffect(() => {
    const changedFields = Object.keys(invoice ?? {}).filter((key) => !isEqual(invoice?.[key], invoiceData?.[key]));
    if (
      invoice &&
      changedFields?.length > 0 &&
      shouldUpdateInvoiceWithThisChanges({ changedFields, invoice, invoiceData })
    ) {
      setInvoiceData(invoice);
      setFetchedSelectedInvoice(invoice);
    } else if (isSelectedDraftInvoice) {
      const draftInvoice = invoicingScheduleFormValues?.invoices?.find((invoice) => invoice?.unsavedId === invoiceId);
      setInvoiceData(draftInvoice ?? {});
      setFetchedSelectedInvoice(draftInvoice ?? {});
    }

    if (!invoice) {
      setInvoiceData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, setFetchedSelectedInvoice, invoiceId, isSelectedDraftInvoice, invoiceData]);

  return null;
};
