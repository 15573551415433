import { useQuery } from 'react-query';
import { getAiDiscrepancies } from './requests';

export const CACHE_KEY = 'aiDiscrepancies';

export const useAiDiscrepanciesAPI = ({ orgId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, params];

  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    async () => {
      return await getAiDiscrepancies({ orgId, params });
    },
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
};
