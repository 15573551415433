import { useState, useContext, useMemo, useCallback } from 'react';
import { AppContext } from 'AppContext';
import { toNumber, omit } from 'lodash';
import styled from 'styled-components';
import { FieldArray, useField, useFormikContext } from 'formik';
import { components } from 'react-select';

import { useClickOutside } from 'utils/hooks';
import { Centerer, Flexer, FlexerColumn, FlexBetweenContainer } from 'components/Core';
import { InputErrorMessage } from 'components/Blocks';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { ReactComponent as InfinityIcon } from 'images/infinity-icon.svg';
import { ReactComponent as TrashIcon } from 'images/trash-new.svg';
import { ReactComponent as FunctionSymbol } from 'images/function-symbol.svg';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { TooltipContainer } from 'components/Tooltip';
import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { useToasts } from 'components/Toasts';

import { useFormulaSchemeModal } from './FormulaSchemeModal';
import { FormulaDisplayCSS } from './styles';
import { formulaStringToHTMLString } from './utils';
import { initialTiersValue } from '../consts';
import {
  USAGE_BASED_BILLING_SCHEMES,
  PREPAID_RECOGNITION_METHODS,
  PREPAID_RECOGNITION_LABELS,
  PREPAID_RECOGNITION_SUB_LABELS,
  USAGE_BASED_ENGINE_FREQUENCY,
} from '../../consts';

const TableContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeaderWrapper = styled.thead`
  background-color: var(--accentGray);
  position: sticky;
  top: 0;
  z-index: 20;
`;

const TableHeader = styled.th`
  padding: 8px 12px;
  color: var(--primaryBlack50);
  font-weight: 400;
  font-size: 12px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TableBody = styled.tbody``;

const TableCellWrapper = styled.tr``;

const TableCell = styled.td`
  padding: 10px 5px;
  width: ${({ width }) => width ?? 'auto'};
  position: relative;

  &:hover {
    cursor: ${({ cursor }) => cursor ?? 'auto'};
  }
`;

const PrepaidTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  width: 300px;
`;

const StyledRelatedHelpIcon = styled(RelatedHelpIcon)``;

const AddButton = styled.div`
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  padding: 4px;
  border-radius: 4px;
  font-weight: 700;
  width: fit-content;

  &:hover {
    cursor: pointer;
    background-color: var(--primaryBlue20);
  }
`;

const FormulaCell = styled.div``;

const FormulaContent = styled(Flexer)`
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--primaryBlack10);
  padding: 8px 12px;
  background-color: white;
  font-family: 'Ubuntu Mono';
  gap: 5px;

  & span {
    ${FormulaDisplayCSS}

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
  }
`;

const AddFormulaButton = styled(AddButton)`
  padding: 8px 12px;
  border-radius: 8px;
  width: auto;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NumberTag = styled(Centerer)`
  border-radius: 50%;
  background-color: var(--primaryBlack5);
  color: var(--primaryBlack50);
  font-weight: 900;
  width: 22px;
  height: 22px;
  font-size: 12px;
`;

const DeleteButton = styled(Centerer)`
  padding: 5px 10px;
  gap: 5px;
  color: var(--tertiaryRed);
  font-weight: 700;

  g {
    opacity: 1;
  }

  &:hover {
    background-color: var(--tertiaryRed5);
    cursor: pointer;
  }
`;

const TooltipTextWrapper = styled(Flexer)`
  &:hover {
    color: var(--primaryBlack);

    & svg g {
      opacity: 1;
    }
  }
`;

const IconWrapper = styled(Flexer)`
  justify-content: flex-end;
  padding: 0px 10px;
`;

const StyledInfinityIcon = styled(InfinityIcon)`
  path {
    fill: var(--primaryBlack50);
  }
`;

const StyledFunctionSymbol = styled(FunctionSymbol)`
  min-width: 16px;
  max-height: 16px;

  path {
    fill: var(--primaryBlack30);
  }
`;

const LightText = styled.div`
  color: ${({ isSelected }) => (isSelected ? '#FFF' : 'var(--primaryBlack70)')};
  opacity: ${({ isSelected }) => (isSelected ? 0.8 : 1)};
`;

const MaximumUnitsInputCell = ({ draftTieredValue, index, name, handleChange, setDraftTieredValue }) => {
  const meta = useField(name);
  const { setValue } = meta[2];

  const [isActive, setIsActive] = useState(false);

  const triggerRef = useClickOutside(() => {
    if (isActive) {
      const infiniteMaxUnits = draftTieredValue.filter((tier) => !tier.maximum_units && tier.maximum_units !== 0);
      const newTiersValue = [
        ...draftTieredValue
          .filter((tier) => !!tier.maximum_units || tier.maximum_units === 0)
          .sort((a, b) => a.maximum_units - b.maximum_units),
        ...infiniteMaxUnits,
      ];

      setDraftTieredValue(newTiersValue);
      setValue(newTiersValue);
    }

    setIsActive(false);
  });

  const showInput = !!draftTieredValue[index]?.maximum_units || draftTieredValue[index].maximum_units === 0 || isActive;

  return (
    <TableCell width="115px" ref={triggerRef} onClick={() => setIsActive(true)} cursor="pointer">
      {showInput ? (
        <FormikCustomInput
          type="number"
          precision={0}
          name={`${name}.${index}.maximum_units`}
          data-cy="pricing-plan-modal__billing-scheme__maximum_units"
          value={draftTieredValue[index]?.maximum_units}
          handleChange={(value) => isActive && handleChange({ maximum_units: value === '' ? null : value }, index)}
          inputTextAlign="right"
          placeholder="Enter units"
          floatErrors
          errorBottomPosition="-50px"
        />
      ) : (
        <IconWrapper data-cy="pricing-plan-modal__billing-scheme__max-units-toggler">
          <StyledInfinityIcon />
        </IconWrapper>
      )}
    </TableCell>
  );
};

const TierRow = ({
  draftTieredValue,
  indexOfLastPrepaidTier,
  index,
  parentTierIndex,
  name,
  onChange,
  setDraftTieredValue,
  options,
  touched,
  error,
  openFormulaSchemeModal,
  currencySymbol,
  remove,
  isParentTier,
  productIds,
  productIndex,
}) => {
  const { organizations } = useContext(AppContext);

  const productOptions = useMemo(
    () =>
      productIds?.reduce(
        (acc, id) => ({
          ...acc,
          [id]: {
            value: id,
            name: organizations[0]?.products.find((p) => toNumber(p.id) === id)?.name || id,
          },
        }),
        {},
      ),
    [productIds, organizations],
  );

  const productName =
    productIds?.[productIndex] && productIds?.[productIndex] !== -1
      ? productOptions[productIds[productIndex]]?.name ?? '-'
      : '-';

  if (!draftTieredValue[index]) return null;

  return (
    <TableCellWrapper key={index}>
      <TableCell>
        {isParentTier && (
          <NumberTag data-cy={`pricing-plan-modal__billing-scheme__counter-${index}`}>{index + 1}</NumberTag>
        )}
      </TableCell>

      {!isParentTier ? (
        <TableCell></TableCell>
      ) : (
        <MaximumUnitsInputCell
          draftTieredValue={draftTieredValue}
          handleChange={onChange}
          index={index}
          name={name}
          setDraftTieredValue={setDraftTieredValue}
        />
      )}

      <TableCell>
        <FormikCustomInput name={`${name}.${index}.${productIndex}.product_id`} value={productName} isDisabled />
      </TableCell>

      <TableCell width="170px">
        <FormikCustomSelector
          placeholder="Select scheme"
          name={`${name}.${index}.${productIndex ? `${productIndex}.` : ''}billing_scheme`}
          value={options.find((option) => option.value === draftTieredValue[index]?.billing_scheme) ?? null}
          options={options}
          handleChange={(option) => onChange({ billing_scheme: option.value ?? null }, index, parentTierIndex)}
          width="100%"
          floatErrors
          errorBottomPosition="-50px"
          menuWidth="400px"
          components={{
            Option: (props) => {
              const option = props.data;
              return (
                <components.Option {...props}>
                  <FlexerColumn key={option.value}>
                    <div>
                      <b>{option.label}</b>
                    </div>
                    {option.subLabel && <LightText isSelected={props.isSelected}>{option.subLabel}</LightText>}
                  </FlexerColumn>
                </components.Option>
              );
            },
          }}
        />
      </TableCell>

      <TableCell
        width="140px"
        colSpan={draftTieredValue[index].billing_scheme === USAGE_BASED_BILLING_SCHEMES.FORMULA ? '2' : '1'}
      >
        {draftTieredValue[index].billing_scheme === USAGE_BASED_BILLING_SCHEMES.FORMULA ? (
          <FormulaCell
            onClick={() =>
              openFormulaSchemeModal({
                name: `${name}${!isParentTier ? `.${parentTierIndex}.child_tiers` : ''}.${index}.amount_formula`,
                value: draftTieredValue[index].amount_formula ?? '',
                onSave: (value) => onChange({ amount_formula: value }, index, parentTierIndex),
              })
            }
          >
            {draftTieredValue[index].amount_formula ? (
              <FormulaContent>
                <StyledFunctionSymbol />
                <span
                  dangerouslySetInnerHTML={{
                    __html: formulaStringToHTMLString(draftTieredValue[index].amount_formula),
                  }}
                />
              </FormulaContent>
            ) : (
              <AddFormulaButton>Add Formula</AddFormulaButton>
            )}
            {touched?.tiers?.[index]?.amount_formula && error?.[index]?.amount_formula && (
              <InputErrorMessage floatErrors errorBottomPosition="-35px">
                <TriangleIcon />
                {error[index].amount_formula}
              </InputErrorMessage>
            )}
          </FormulaCell>
        ) : (
          <FormikCustomInput
            name={`${name}${!isParentTier ? `.${parentTierIndex}.child_tiers` : ''}.${index}.amount`}
            data-cy="pricing-plan-modal__billing-scheme__amount"
            value={draftTieredValue[index]?.amount ?? 0}
            handleChange={(value) => {
              onChange({ amount: value }, index, parentTierIndex);
            }}
            inputTextAlign="right"
            suffix={currencySymbol}
            customSuffixColor="var(--primaryBlack50)"
            suffixStyle={{
              fontWeight: 400,
            }}
            placeholder="Enter amount"
            floatErrors
            errorBottomPosition="-50px"
            precision={8}
          />
        )}
      </TableCell>

      {draftTieredValue[index].billing_scheme !== USAGE_BASED_BILLING_SCHEMES.FORMULA && (
        <PrepaidTableCell>
          {draftTieredValue[index].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID && (
            <>
              <FormikCustomSelector
                noSort={true}
                name={`${name}.${index}.recognize_prepaid_in_straightline`}
                value={
                  prepaidRecognitionOptions.find(
                    (option) => option.value === draftTieredValue[index]?.recognize_prepaid_in_straightline,
                  ) ?? prepaidRecognitionOptions[0]
                }
                options={prepaidRecognitionOptions}
                handleChange={(option) =>
                  onChange({ recognize_prepaid_in_straightline: option.value }, index, parentTierIndex)
                }
                width="120px"
                floatErrors
                errorBottomPosition="-50px"
                menuWidth="240px"
                components={{
                  Option: (props) => {
                    const option = props.data;
                    return (
                      <components.Option {...props}>
                        <FlexerColumn key={option.value}>
                          <div>
                            <b>{option.label}</b>
                          </div>
                          <LightText isSelected={props.isSelected}>{option.subLabel}</LightText>
                        </FlexerColumn>
                      </components.Option>
                    );
                  },
                }}
              />
              {indexOfLastPrepaidTier === index && (
                <TooltipContainer toolTipContent="If checked, the units that have been prepaid for but have not been fully used can be carried over to the next periods">
                  <FormikCustomCheckbox name="shouldCarryOverUnusedUnits" label="Carry over unused units" />
                </TooltipContainer>
              )}
            </>
          )}
        </PrepaidTableCell>
      )}

      {isParentTier && draftTieredValue.length > 1 && (
        <TableCell width="120px">
          <Flexer justifyContent="flex-end">
            <DeleteButton
              onClick={() => {
                remove(index);
                setDraftTieredValue((prev) => prev.filter((_, i) => i !== index));
              }}
            >
              Delete
              <TrashIcon />
            </DeleteButton>
          </Flexer>
        </TableCell>
      )}
    </TableCellWrapper>
  );
};

const prepaidRecognitionOptions = [PREPAID_RECOGNITION_METHODS.STRAIGHTLINE, PREPAID_RECOGNITION_METHODS.PER_UNIT].map(
  (method) => ({
    value: method,
    label: PREPAID_RECOGNITION_LABELS[method],
    subLabel: PREPAID_RECOGNITION_SUB_LABELS[method],
  }),
);

export const TieredContent = ({ draftTieredValue, handleChange, name, currency, setDraftTieredValue, productIds }) => {
  const [{ value: formikValue }, { error }, { setValue }] = useField(name);
  const { touched, values, setFieldValue } = useFormikContext();

  const { openModal: openFormulaSchemeModal, FormulaSchemeModal } = useFormulaSchemeModal();
  const { pushToast } = useToasts();

  const onChange = useCallback(
    (value, index, parentTierIndex) => {
      const newTiers = formikValue.map((tier, i) => {
        if (parentTierIndex !== undefined && parentTierIndex !== null) {
          if (i === parentTierIndex) {
            tier.child_tiers[index] = {
              ...tier.child_tiers[index],
              ...value,
            };
          }
          return tier;
        }
        if (i === index) {
          return {
            ...tier,
            ...value,
          };
        }
        return tier;
      });
      setValue(newTiers);
      handleChange(newTiers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formikValue],
  );

  const currencySymbol = ISO_CODE_TO_SYMBOL[currency] ?? '$';

  const columns = useMemo(
    () => [
      {
        content: '#',
      },
      {
        content: 'Max. units',
      },
      {
        content: 'Product',
      },
      {
        content: 'Billing Scheme',
      },
      {
        content: (
          <TooltipContainer
            toolTipContent="Enter the prepaid amount if the billing scheme is prepaid. Enter the amount per unit if the billing scheme is per unit. Enter the total amount if the billing scheme is fixed price. Enter the amount formula if the billing scheme is formula."
            hideArrow
          >
            <TooltipTextWrapper alignItems="center" gap="5px">
              <span>Amount</span>
              <StyledRelatedHelpIcon />
            </TooltipTextWrapper>
          </TooltipContainer>
        ),
      },
      {
        content: 'Prepaid',
      },
    ],
    [],
  );

  const indexOfLastPrepaidTier = useMemo(() => {
    return draftTieredValue.findLastIndex((tier) => tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID);
  }, [draftTieredValue]);

  const getBillingSchemeOptions = useCallback(
    () => [
      {
        label: 'Amount per unit',
        value: USAGE_BASED_BILLING_SCHEMES.PER_UNIT,
      },
      {
        label: 'Fixed Price',
        value: USAGE_BASED_BILLING_SCHEMES.FIXED_PRICE,
      },
      {
        label: 'Formula',
        value: USAGE_BASED_BILLING_SCHEMES.FORMULA,
      },
      {
        label: 'Prepaid',
        value: USAGE_BASED_BILLING_SCHEMES.PREPAID,
        subLabel:
          'We`ll charge the prepaid amount at the beginning of a billing period. If there are overage units used, they will be charged at the beginning of the next period',
      },
    ],
    [],
  );

  return (
    <>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <>
            <TableContainer>
              <Table>
                <TableHeaderWrapper>
                  <tr>
                    {columns.map((header, index) => (
                      <TableHeader key={'header' + index}>{header.content}</TableHeader>
                    ))}
                    <TableHeader>
                      <Flexer justifyContent="flex-end" width="100px">
                        <AddButton
                          data-cy="pricing-plan-modal__billing-scheme__add-new-tier"
                          onClick={() => {
                            // Handle the case we already have parent tiers
                            const newTier = {
                              pricing_plan_id: draftTieredValue[0]?.pricing_plan_id,
                              ...initialTiersValue[0],
                            };
                            newTier.child_tiers = draftTieredValue[0]?.child_tiers?.map((tier) => ({
                              ...omit(tier, ['id', 'maximum_units']),
                              ...initialTiersValue[0],
                            }));

                            push(newTier);
                            setDraftTieredValue((prev) => [...prev, newTier]);
                          }}
                        >
                          Add New Tier
                        </AddButton>
                      </Flexer>
                    </TableHeader>
                  </tr>
                </TableHeaderWrapper>

                <TableBody>
                  {draftTieredValue.map((_, index) => {
                    const options = getBillingSchemeOptions();
                    let productIndex = 0;

                    return (
                      <>
                        <TierRow
                          indexOfLastPrepaidTier={indexOfLastPrepaidTier}
                          draftTieredValue={draftTieredValue}
                          index={index}
                          name={name}
                          setDraftTieredValue={setDraftTieredValue}
                          options={options}
                          onChange={onChange}
                          remove={remove}
                          currencySymbol={currencySymbol}
                          touched={touched}
                          error={error}
                          openFormulaSchemeModal={openFormulaSchemeModal}
                          productIds={productIds}
                          productIndex={productIndex}
                          isParentTier
                        />
                        {(draftTieredValue[index].child_tiers ?? []).map((_tier, parentTierIndex) => {
                          productIndex++;

                          return (
                            <TierRow
                              indexOfLastPrepaidTier={indexOfLastPrepaidTier}
                              draftTieredValue={draftTieredValue[index].child_tiers}
                              index={parentTierIndex}
                              parentTierIndex={index}
                              name={name}
                              setDraftTieredValue={setDraftTieredValue}
                              options={options}
                              onChange={onChange}
                              remove={remove}
                              currencySymbol={currencySymbol}
                              touched={touched}
                              productIds={productIds}
                              productIndex={productIndex}
                              openFormulaSchemeModal={openFormulaSchemeModal}
                            />
                          );
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <FormulaSchemeModal />
          </>
        )}
      </FieldArray>

      <FlexBetweenContainer>
        <TooltipContainer
          toolTipContent={
            <span>
              By default, we will bill consumption split incrementally across tiers. Check this box if you want all
              units to be priced based on <em>the single tier that the total units falls within</em>
            </span>
          }
        >
          <FormikCustomCheckbox
            name="use_single_rate_tier"
            label="Use single-rate tiers"
            data-cy="pricing-plan-modal__billing-scheme__use_single_rate_tier"
            onChange={(event) => {
              // Make sure to set use_single_rate_tier when the checkbox changes
              setFieldValue('use_single_rate_tier', event.target.checked);

              // As we don't want to allow the user to select a invoicing frequency which is not monthly for the single rate tier
              if (values.invoicing_frequency !== USAGE_BASED_ENGINE_FREQUENCY.MONTHLY && event.target.checked) {
                // Set monthly
                setFieldValue('invoicing_frequency', USAGE_BASED_ENGINE_FREQUENCY.MONTHLY);
                // Show a message to the user, telling what's happening
                pushToast(
                  `INFO: The interval has been updated to a ${USAGE_BASED_ENGINE_FREQUENCY.MONTHLY} frequency, as single-rate tiers are only compatible with ${USAGE_BASED_ENGINE_FREQUENCY.MONTHLY} frequency.`,
                  'success',
                );
              }
            }}
          />
        </TooltipContainer>
      </FlexBetweenContainer>
    </>
  );
};
