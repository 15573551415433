import React, { useContext } from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { getPricingPlansFromSearch } from 'api/usageBasedEngine';
import { FormikCustomSelector } from 'components/Controls';
import { TrashIcon } from 'components/Icons';
import { isSameId } from 'utils/objectUtils';

import { convertSubscriptionToTransaction, checkDisableAggregate } from './utils';

export const PricingPlanSelector = ({
  setSelectedTransactionId,
  selectedTransaction,
  transactions,
  setShouldDisableAggregate,
}) => {
  const { orgId } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext();

  const value = (values?.usage_subscriptions ?? []).map((subscription) => ({
    ...subscription,
    data: subscription?.pricing_plan,
    label: subscription?.pricing_plan?.name,
    value: subscription?.pricing_plan?.id,
  }));

  return (
    <FieldArray name="usage_subscriptions">
      {({ remove, push }) => (
        <FormikCustomSelector
          label="Pricing Plan"
          placeholder="Select pricing plan"
          name="usage_subscriptions.0.pricing_plan_id"
          errorFieldName={value.length === 0 ? 'usage_subscriptions' : undefined}
          value={value}
          handleChange={(selectedOptions) => {
            if (!selectedOptions) {
              setSelectedTransactionId(null);
              remove(0);
              return;
            }

            // Check if at least one of the pricing plans has multiple products
            for (const option of selectedOptions) {
              if (checkDisableAggregate({ pricing_plan: option.data })) {
                setFieldValue('aggregate_usage_on_parent', false);
                setShouldDisableAggregate(true);
                break;
              } else {
                setShouldDisableAggregate(false);
              }
            }

            if (selectedOptions.length === (values?.usage_subscriptions?.length ?? 0)) return;

            if (selectedOptions.length > (values?.usage_subscriptions?.length ?? 0)) {
              const newPricingPlan = selectedOptions[selectedOptions.length - 1].data;

              const newSubscription = {
                pricing_plan: newPricingPlan,
                pricing_plan_id: newPricingPlan.id,
                overage_transaction_metadata: {},
                pricing_plan_frequency: newPricingPlan.invoicing_frequency,
              };
              push(newSubscription);
              if (!values?.usage_subscriptions?.length) {
                const newSelectedTransaction = convertSubscriptionToTransaction(newSubscription, 0)[0];
                setSelectedTransactionId(newSelectedTransaction?._id);
              }
              return;
            }

            const removedIndex = values?.usage_subscriptions?.findIndex((subscription) => {
              return !selectedOptions.some((option) => option.value === subscription.pricing_plan.id);
            });

            if (
              isSameId(
                selectedTransaction?.pricing_plan_id,
                values?.usage_subscriptions?.[removedIndex].pricing_plan_id,
              )
            ) {
              const newSelectedTransaction = transactions.find((t) =>
                isSameId(t.pricing_plan_id, selectedTransaction?.pricing_plan_id),
              );
              setSelectedTransactionId(newSelectedTransaction?._id);
            }

            remove(removedIndex);
          }}
          loadOptions={(searchQuery, prevOptions, additional) =>
            getPricingPlansFromSearch({
              searchQuery,
              orgId,
              prevOptions,
              additional: {
                ...additional,
                scopes: ['tiers'],
              },
            })
          }
          isMulti
          isPaginateable
          isClearable
          boldValue
          blueVer
          forceShowBorder
          customBackgroundColor="#FFFFFF"
          customBorderRadius={4}
          CustomDeleteIcon={TrashIcon}
        />
      )}
    </FieldArray>
  );
};
