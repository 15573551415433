import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { WIDGET_TYPES } from 'views/Dashboard/ReportBuilder/consts';

export const UP_FOR_RENEWAL_SNAPSHOT_ROWS = new Set([
  SNAPSHOT_METRICS.UP_FOR_RENEWAL.label,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP.label,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP_NET.label,
  SNAPSHOT_METRICS.CUSTOMERS_UP_FOR_RENEWAL.label,
  SNAPSHOT_METRICS.CUSTOMERS_RENEWED.label,
  SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION.label,
  SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION.label,
  SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION.label,
]);

export const SNAPSHOT_ROWS_WITH_TTM = new Set([
  SNAPSHOT_METRICS.LTV_TTM.label,
  SNAPSHOT_METRICS.LTVGMLaden_TTM.label,
  SNAPSHOT_METRICS.CAC_RECURRING_TTM.label,
  SNAPSHOT_METRICS.CAC_ALL_TTM.label,
  SNAPSHOT_METRICS.CEC_RECURRING_TTM.label,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.label,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM.label,
  SNAPSHOT_METRICS.ARPA_TTM.label,
  SNAPSHOT_METRICS.ARPA_NEW_TTM.label,
  SNAPSHOT_METRICS.RULE_OF_40_TTM.label,
  SNAPSHOT_METRICS.BURN_MULTIPLE_TTM.label,
]);

const DASHBARD_CARDS_WITH_TTM = new Set([
  'gross_revenue_retention',
  'arpa',
  'net_revenue_retention',
  'gross_customer_retention',
  'ltv',
]);

export const MAGIC_NUMBER_SNAPSHOT_ROWS = [
  SNAPSHOT_METRICS.MAGIC_NUMBER.label,
  SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label,
];

export const BOOKINGS_ROWS = new Set([SNAPSHOT_METRICS.BOOKINGS.label, SNAPSHOT_METRICS.BOOKINGS_NEW.label]);

const getDataTableSettings = (reportData) => {
  const dataTableSettings =
    reportData?.options?.metricsSetting?.filter(
      (setting) => setting.id === WIDGET_TYPES.DATA_TABLE && setting.active,
    ) || [];

  // Merge selectedMetrics from all data tables
  const selectedMetrics = dataTableSettings.reduce((metrics, table) => {
    return [...metrics, ...(table.selectedMetrics || [])];
  }, []);

  // Merge selectedColumns from all data tables
  const selectedColumns = dataTableSettings.reduce((columns, table) => {
    return { ...columns, ...(table.selectedColumns || {}) };
  }, {});

  return { selectedMetrics, selectedColumns };
};

const requiresUpForRenewal = ({ selectedMetrics }) => {
  return selectedMetrics.some((row) => UP_FOR_RENEWAL_SNAPSHOT_ROWS.has(row));
};

const requiresBookings = ({ selectedMetrics }) => {
  return selectedMetrics.some((row) => BOOKINGS_ROWS.has(row));
};

const requiresMagicNumber = ({ selectedMetrics }) => {
  return selectedMetrics.some((row) => MAGIC_NUMBER_SNAPSHOT_ROWS.includes(row));
};

const requiresTTMMetrics = ({ selectedMetrics, metricsSetting }) => {
  return (
    selectedMetrics.some((row) => SNAPSHOT_ROWS_WITH_TTM.has(row)) ||
    metricsSetting.some((card) => card.active && card.isTTM !== false && DASHBARD_CARDS_WITH_TTM.has(card.id))
  );
};

const requiresSummaryStats = ({ selectedColumns }) => {
  return selectedColumns.quarters || selectedColumns.years || selectedColumns.cumulative;
};

const includeAmountLeftToRecognize = ({ selectedMetrics }) => {
  return selectedMetrics.some((row) => row === SNAPSHOT_METRICS.CUSTOMERS_WITH_OPEN_CONTRACTS.label);
};

const includeNonRenewalUpsell = ({ selectedMetrics }) => {
  return selectedMetrics.some((row) => row === SNAPSHOT_METRICS.NON_RENEWAL_UPSELL.label);
};

const PLUGINS = {
  upForRenewal: requiresUpForRenewal,
  summaryStats: requiresSummaryStats,
  magicNumber: requiresMagicNumber,
  TTMMetrics: requiresTTMMetrics,
  bookings: requiresBookings,
  includeAmountLeftToRecognize,
  includeNonRenewalUpsell,
};

export const getRequiredPlugins = ({ reportData }) => {
  const { selectedMetrics, selectedColumns } = getDataTableSettings(reportData);
  const metricsSetting = reportData?.options?.metricsSetting ?? [];

  const pluginParams = {
    selectedMetrics,
    selectedColumns,
    metricsSetting,
    reportData,
  };

  return Object.keys(PLUGINS)
    .filter((plugin) => PLUGINS[plugin](pluginParams))
    .sort();
};
