import { components } from 'react-select';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from 'images/arrows-bold-down.svg';
import { RECOGNITION_TYPES_GROUPS, RECOGNITION_TYPES_LABELS } from 'consts/global';
import { usePortal } from 'components/Portal';
import { RecognitionIcon } from 'components/Icons';
import { FormikCustomSelector } from 'components/Controls';
import { useClickOutside } from 'utils/hooks';
import { recognitionHandleChange } from '../../utils';
import {
  GroupTitle,
  GroupWrapper,
  SelectorWrapper,
  StyledSelectorMenuBase,
  Description as RecognitionDescription,
  Label as RecognitionLabel,
  Row as RecognitionRow,
  IconWrapper as RecognitionStyledIcon,
  Column as RecognitionText,
} from 'components/Controls/FormikSelectorWithDescription';
import { RecognitionToggler } from './styles';

const StyledSelectorPopover = styled(StyledSelectorMenuBase)`
  position: absolute;
  top: 33px;
  left: 0;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;
`;

const getGroups = (options) => {
  const groups = Object.keys(RECOGNITION_TYPES_GROUPS).map((group) => {
    return {
      key: group,
      ...RECOGNITION_TYPES_GROUPS[group],
      values: RECOGNITION_TYPES_GROUPS[group].values.filter((value) => Object.keys(options).includes(value.key)),
    };
  });

  return groups.filter((group) => group.values.length > 0);
};

export const RecognitionSelect = ({
  values,
  setFieldValue,
  bannerVersion,
  dataCy,
  onSelectRecognition,
  formik,
  options = RECOGNITION_TYPES_LABELS,
}) => {
  const { triggerRef, togglePortal, hidePortal, isPortalVisible, Portal } = usePortal({ xOffset: 20, YOffset: 25 });
  const handleSelectRecognition = (recognition) => {
    recognitionHandleChange(setFieldValue, values)(recognition);
    hidePortal();
  };

  const handlePropsOnSelect = (recognition) => {
    onSelectRecognition(recognition);
    hidePortal();
  };

  const formikSelectorRef = useClickOutside(() => hidePortal());

  const groups = getGroups(options);

  const MenuContent = () => (
    <>
      {groups.map((group) => (
        <GroupWrapper key={group.key}>
          <GroupTitle>{`${group.title}:`}</GroupTitle>
          {group.values.map((value) => (
            <RecognitionRow
              key={value.key}
              selected={value.key === values?.recognition}
              onClick={() => {
                onSelectRecognition ? handlePropsOnSelect(value.key) : handleSelectRecognition(value.key);
              }}
              data-cy={`recognition-selector__option-${value.key}`}
            >
              <RecognitionStyledIcon>
                <RecognitionIcon recognitionType={value.key} width="20px" height="20px" />
              </RecognitionStyledIcon>
              <RecognitionText>
                <RecognitionLabel>{value.label}</RecognitionLabel>
                <RecognitionDescription>{value.description}</RecognitionDescription>
              </RecognitionText>
            </RecognitionRow>
          ))}
        </GroupWrapper>
      ))}
    </>
  );

  const isDisabled = groups.length === 1 && groups[0].values.length === 1;

  return (
    <SelectorWrapper ref={triggerRef}>
      {formik ? (
        <div ref={formikSelectorRef}>
          <FormikCustomSelector
            boldValue
            placeholder={bannerVersion ? 'Select recognition...' : 'Select...'}
            key="recognition"
            name="recognition"
            label="SaaS Rev. Recognition"
            isDisabled={isDisabled}
            value={
              values?.recognition
                ? {
                    label: options[values?.recognition],
                    value: values?.recognition,
                  }
                : null
            }
            isSearchable={false}
            options={Object.entries(options).map(([value, label]) => ({
              label,
              value,
            }))}
            menuWidth="398px"
            dataCy={dataCy ?? 'recognition-selector-toggler'}
            menuIsOpen={isPortalVisible}
            onMenuOpen={togglePortal}
            components={{
              Menu: (props) => {
                return (
                  <components.Menu {...props}>
                    <StyledSelectorMenuBase data-cy="recognition-selector">
                      <MenuContent />
                    </StyledSelectorMenuBase>
                  </components.Menu>
                );
              },
            }}
          />
        </div>
      ) : (
        <>
          <RecognitionToggler
            onClick={togglePortal}
            bannerVersion={bannerVersion}
            empty={!values?.recognition}
            active={isPortalVisible}
            data-cy={dataCy ?? 'recognition-selector-toggler'}
          >
            {values?.recognition ? options[values?.recognition] : bannerVersion ? 'Select recognition...' : 'Select...'}
            <ArrowDown />
          </RecognitionToggler>
          <Portal>
            <StyledSelectorPopover data-cy="recognition-selector">
              <MenuContent />
            </StyledSelectorPopover>
          </Portal>
        </>
      )}
    </SelectorWrapper>
  );
};
