import React from 'react';
import { ReactComponent as AvatarIcon } from 'images/avatar-icon.svg';
import { StyledAvatar } from './style';
import { UserInfo } from '../UserInfo';

export const HeaderUser = () => (
  <>
    <UserInfo isHeaderInfo={true} />
    <StyledAvatar className="flexer">
      <AvatarIcon />
    </StyledAvatar>
  </>
);
