import styled from 'styled-components';
import { Spacer } from 'components/Core';
import { CircleCheckStrokeIcon } from 'components/Icons';

const Wrapper = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
`;

export const ConfirmButton = ({ loading, ...rest }) => (
  <Wrapper disabled={loading} {...rest}>
    {loading ? 'Confirming' : 'Confirm'}
    <Spacer width="5px" />
    <CircleCheckStrokeIcon stroke="transparent" fill="white" size="20px" />
  </Wrapper>
);
