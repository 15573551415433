import dayjs from 'dayjs';
import { useContext } from 'react';
import { Column, Row } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES, EXTERNAL_UPDATE_ACTION_OPTIONS } from 'views/ExternalUpdates/consts';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { TransactionName } from 'views/Billing/Pages/SchedulesExternalUpdates/TransactionsCell';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import {
  DeletedTransactionInfo,
  DismissButton,
  ExternalUpdateBody,
  InlineFlexWarnings,
  TransactionInfoRow,
} from '../styles';
import { useReplaceTransactionModal } from './useReplaceTransactionModal';
import { getInvoicesWithRemovedTransactionLineItem, getTotalAndSendPlusPaidTransactionInvoicesAmounts } from './utils';

export const RemovedTransactionInfoRow = ({
  update,
  modalView,
  deleteTransactions,
  dateFormat,
  defaultOrgCurrency,
}) => {
  const deletedTransaction = deleteTransactions?.[0];

  const transactionName = transactionDisplayNameForBilling(deletedTransaction);

  const invoicesWithRemovedTransactionLineItem = getInvoicesWithRemovedTransactionLineItem({
    update,
    deleteTransactions,
  });

  const { totalInvoicesAmount, totalSentAndPaidInvoicesAmount } = getTotalAndSendPlusPaidTransactionInvoicesAmounts({
    invoicesWithRemovedTransactionLineItem,
    specificTransactionId: deletedTransaction?.id,
  });

  return (
    <TransactionInfoRow modalView={modalView}>
      {!modalView && <TransactionName deleted={true}>{transactionName}</TransactionName>}

      <b>
        {dayjs(deletedTransaction?.start_date).format(dateFormat)} –{' '}
        {deletedTransaction?.end_date ? dayjs(deletedTransaction?.end_date).format(dateFormat) : 'No End Date'}
      </b>

      <GreyDot spacing="0px" />

      <b>
        {numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: totalInvoicesAmount?.total,
          currency: invoicesWithRemovedTransactionLineItem?.[0]?.currency ?? defaultOrgCurrency,
        })}{' '}
        total
      </b>

      <GreyDot spacing="0px" />

      <b>
        {numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: totalSentAndPaidInvoicesAmount?.total,
          currency: invoicesWithRemovedTransactionLineItem?.[0]?.currency ?? defaultOrgCurrency,
        })}{' '}
        in sent and paid invoices
      </b>
    </TransactionInfoRow>
  );
};

export const ExternalUpdateRemoveTransactionsWarning = ({
  update,
  resolveExternalUpdate,
  deleteTransactions,
  defaultOrgCurrency,
  closeModal,
  dateFormat,
  existingInvoices,
}) => {
  const { Modal: ReplaceTransactionModal } = useReplaceTransactionModal({
    update,
    deleteTransactions,
    dateFormat,
    resolveExternalUpdate,
    defaultOrgCurrency,
  });

  const changes = update?.externalUpdate?.update_data;
  const removeScheduleMode =
    changes && changes.invoicesToDelete?.length === existingInvoices?.length && !changes.invoicesToInsert?.length;

  const deletedTransaction = deleteTransactions?.[0];

  const transactionName = transactionDisplayNameForBilling(deletedTransaction);

  const { refetchInvoicingSchedule } = useContext(InvoicingScheduleContext);

  return (
    <Column
      vertical="flex-start"
      data-cy="warnings__remove-transaction-warning"
      horizontal="flex-start"
      style={{ maxHeight: 500, overflow: 'auto' }}
    >
      <div>
        The transaction <InlineFlexWarnings>{transactionName}</InlineFlexWarnings> on which you generate invoices has
        been <InlineFlexWarnings>deleted</InlineFlexWarnings>
      </div>

      <ExternalUpdateBody>
        <DeletedTransactionInfo>
          <div>Deleted transaction:</div>

          <RemovedTransactionInfoRow
            update={update}
            deleteTransactions={deleteTransactions}
            dateFormat={dateFormat}
            defaultOrgCurrency={defaultOrgCurrency}
          />
        </DeletedTransactionInfo>

        {resolveExternalUpdate && (
          <Row horizontal="flex-start">
            <DismissButton
              onClick={async () =>
                await resolveExternalUpdate.mutateAsync({
                  id: update?.externalUpdate?.id,
                  data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.dismiss },
                })
              }
            >
              Dismiss
            </DismissButton>

            <DismissButton
              data-cy="external-update-warning__remove-transaction__apply-remove"
              style={{ marginLeft: 0 }}
              onClick={async () => {
                await resolveExternalUpdate.mutateAsync({
                  id: update?.externalUpdate?.id,
                  data: {
                    actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite,
                    objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.INVOICING_SCHEDULE,
                  },
                });
                if (removeScheduleMode && closeModal) closeModal();
                if (!removeScheduleMode && refetchInvoicingSchedule) await refetchInvoicingSchedule();
              }}
            >
              Delete transaction and {removeScheduleMode ? 'Schedule!' : 'items from schedule'}
            </DismissButton>
          </Row>
        )}
      </ExternalUpdateBody>

      <ReplaceTransactionModal />
    </Column>
  );
};
