import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { SEGMENT_BY_OPTIONS } from 'consts/global';
import { useRevenueSpreadsAPI } from 'api/revenueSpreads';
import SpreadsTable from 'views/Spreads/SpreadsTable';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';

export const CustomerSpreads = ({ customer }) => {
  const { start_date, end_date, hierarchy } = customer;
  const {
    organizations,
    appSettings: { isCommitted, rollup, isARR },
  } = useContext(AppContext);

  const [transactionToShow, setTransactionToShow] = useState(false);

  const customerIds = rollup ? hierarchy.map((c) => c.id) : [customer.id];

  const segmentBy = SEGMENT_BY_OPTIONS.TRANSACTION;

  const {
    data: saasData,
    shownCustomers: saasShownCustomers,
    isLoading: saasIsLoading,
    operations: saasOperations,
  } = useRevenueSpreadsAPI({
    orgId: organizations[0].id,
    params: {
      startDate: dayjs.utc(start_date).format('YYYY-MM-D'),
      endDate: dayjs.utc(end_date).add(1, 'month').format('YYYY-MM-D'),
      isCommitted,
      rollup,
      // Don't support optimistic mode when reviewing spreads on the customer single page due to complexity of
      //  implementation and low demand.
      optimisticAnalytics: false,
      segmentBy,
      includeLastTransactionUpdatedAt: true,
    },
    body: {
      customerIds,
    },
  });

  return (
    <>
      <div style={{ marginTop: 22, marginBottom: 22 }}>
        {isARR && <p>Note: revenue numbers not annualized</p>}
        <SpreadsTable
          segmentBy={segmentBy}
          organizations={organizations}
          spreadsData={saasData ?? []}
          isLoading={saasIsLoading}
          shownCustomers={saasShownCustomers}
          sortCustomers={saasOperations.sortCustomers}
          loadMoreCustomers={saasOperations.loadMoreCustomers}
          fetchSpreadsData={saasOperations.refetch}
          showChart={false}
          setTransactionToShow={setTransactionToShow}
          isARROverride={false}
        />
      </div>
      {!!transactionToShow && (
        <TransactionSingleContent
          mode={TRANSACTION_MODAL_MODE.EDIT}
          transaction={transactionToShow}
          organization={organizations[0]}
          closeModal={() => setTransactionToShow(null)}
          onTransactionUpdated={() => setTransactionToShow(null)}
          onTransactionDeleted={() => setTransactionToShow(null)}
          onTransactionDuplicated={({ transaction }) => setTransactionToShow(transaction)}
        />
      )}
    </>
  );
};
