import React, { useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';

import { AppContext } from 'AppContext';
import { useWaterfallAPI } from 'api/waterfall';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { useAppSetting } from 'utils/hooks';
import { WATERFALL_TYPE_OPTIONS } from 'shared/Filters';
import { RevenueDetailsModal } from 'shared/RevenueDetailsModal';
import { ReactComponent as CircleIcon } from 'images/reports-circle.svg';
import { getARR } from 'views/Waterfall/utils';
import { DashboardContext } from '../DashboardContext';
import { Card, CARD_SIZES, CardHeader } from '../Common/Card';
import { StackedBarChart, CHART_COLORS } from '../Common/StackedBarChart';
import { reshapeDataForTable } from '../DashboardTables/RevenueOrCustomerSnapshotDataTable.utils';
import { HeaderWrapper, HugeChartBackground } from './styles';

const CardHeaderWrapper = styled.div`
  cursor: pointer;

  > div {
    opacity: ${({ active }) => (active ? 1 : 0.5)};

    h3 {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:first-child {
    border-radius: 20px 0 0 0;
  }

  &:last-child {
    border-radius: 0 20px 0 0;
  }

  &:hover {
    background: ${transparentize(0.98, cssVar('--primaryDark'))};
  }
`;

// Map headers order to rows index
const HeadersDataRowsIndex = {
  [REVENUE_METRIC.EXISTING.key]: 2,
  [REVENUE_METRIC.NEW.key]: 4,
  [REVENUE_METRIC.UPSELL.key]: 3,
  [REVENUE_METRIC.DOWNSELL.key]: 1,
  [REVENUE_METRIC.CHURN.key]: 0,
  Total: 5,
};

export const DashboardRevenueWaterfallCard = ({
  rowStart,
  columnStart,
  size,
  SettingsButton,
  version,
  AddGridCardButton,
  children,
}) => {
  const { organizations } = useContext(AppContext);
  const {
    dataFilter: { startMonth, endMonth },
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);
  const {
    loading,
    isARR,
    rollup,
    isCommitted,
    optimisticAnalytics,
    metadataFilter,
    hasConfirmedTransactions,
  } = useContext(DashboardContext);
  const [showWaterFallExistingRow] = useAppSetting('dashboard.dataSnapshot.showExistingRow');

  const [selectedSections, setSelectedSections] = useState({
    [REVENUE_METRIC.EXISTING.key]: showWaterFallExistingRow,
    [REVENUE_METRIC.NEW.key]: true,
    [REVENUE_METRIC.UPSELL.key]: true,
    [REVENUE_METRIC.DOWNSELL.key]: true,
    [REVENUE_METRIC.CHURN.key]: true,
    Total: true,
  });

  const [showRevenueDetailsModal, setShowRevenueDetailsModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const handleHeaderClick = ({ event, key }) => {
    event.stopPropagation();
    const updatedSelectedSections = { ...selectedSections };
    updatedSelectedSections[key] = !selectedSections[key];
    setSelectedSections(updatedSelectedSections);
  };

  const { data, isFetching, isLoading } = useWaterfallAPI({
    orgId: organizations?.[0]?.id,
    startMonth,
    endMonth,
    rollup,
    isCommitted,
    optimisticAnalytics,
    metadataFilter,
  });

  const chartData = useMemo(() => {
    if (data) {
      const waterfallData = isARR ? getARR(data) : data;
      return reshapeDataForTable({
        organizations,
        startDate: dayjs.utc(data.startKey),
        endDate: dayjs.utc(data.endKey),
        rawData: waterfallData.revenueStats,
        showWaterFallExistingRow: true,
        waterfallType: 'Revenue',
      });
    }
    return null;
  }, [isARR, organizations, data]);

  return (
    <>
      <Card
        title="Revenue Waterfall"
        rowStart={rowStart}
        columnStart={columnStart}
        size={size}
        loading={loading}
        hasConfirmedTransactions={hasConfirmedTransactions}
        clickable={false}
        version={version}
        SettingsButton={SettingsButton}
        AddGridCardButton={AddGridCardButton}
      >
        {!loading && !isFetching && !isLoading && chartData && (
          <>
            <HeaderWrapper>
              {Object.entries(HeadersDataRowsIndex).map(([key, rowIndex]) => (
                <CardHeaderWrapper
                  key={key}
                  active={selectedSections[key]}
                  onClick={(event) => handleHeaderClick({ event, key })}
                >
                  <CardHeader
                    data-cy={`dashboard-card--Revenue-Waterfall__header-${key}`}
                    key={key}
                    title={chartData.rows[rowIndex].title}
                    titleIcon={<CircleIcon width={8} height={8} fill={CHART_COLORS[chartData.rows[rowIndex].rowKey]} />}
                    titleTooltip={''}
                    valueType={chartData.rows[rowIndex].dataType}
                    currentValue={
                      chartData.rows[rowIndex].data[chartData.columnHeaders[chartData.columnHeaders.length - 1]]
                    }
                    startingValue={chartData.rows[rowIndex].data[chartData.columnHeaders[0]]}
                    graphLabels={chartData.columnHeaders}
                    hasConfirmedTransactions={hasConfirmedTransactions}
                    size={CARD_SIZES.SMALL}
                  />
                </CardHeaderWrapper>
              ))}
            </HeaderWrapper>
            <HugeChartBackground>
              <StackedBarChart
                data-cy="dashboard-card--Revenue-Waterfall__chart"
                labels={chartData.columnHeaders}
                data={chartData}
                dataTableShowingBy={WATERFALL_TYPE_OPTIONS.revenue}
                isARR={isARR}
                selectedSections={Object.entries(selectedSections)
                  .filter((section) => section[1])
                  .map(([key]) => key)}
                includeLegend={false}
                currency={currency}
                onBarClick={({ dataset, date }) => {
                  const parsedDate = dayjs(date, 'MMM YYYY');
                  const formattedDate = parsedDate.format('YYYY-MM');
                  setSelectedMonth(formattedDate);
                  setSelectedMetric(dataset);
                  setShowRevenueDetailsModal(true);
                }}
              />
            </HugeChartBackground>
          </>
        )}
        {children}
      </Card>

      {showRevenueDetailsModal && (
        <RevenueDetailsModal
          organization={organizations[0]}
          closeModal={() => setShowRevenueDetailsModal(false)}
          selectedMonth={selectedMonth}
          selectedMetric={selectedMetric}
          viewAsARR={isARR}
          optimisticAnalytics={optimisticAnalytics}
        />
      )}
    </>
  );
};
