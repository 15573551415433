import styled from 'styled-components';

import { FlexerColumn, FlexerRow, TextBox } from 'components/Core';
import { FadedText } from 'views/Billing/UsageBasedEngine/Subscriptions/styles';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';

import { columnGenerator } from './columnGenerator';

const WarningIcon = styled(_WarningIcon)`
  height: 12px;
  path {
    fill: var(--primaryRed);
  }
`;

const column = columnGenerator({ entityName: 'subscriptions' });

export const generateColumns = ({ onEditClick, formatDateWithLocale }) => {
  const columns = [
    column.header(),
    column.customer({ addExternalLink: true }),
    {
      Header: 'Pricing Plan',
      accessor: 'pricing_plan_name',
      width: 200,
      Cell: ({ row }) =>
        !!row.original.usage_subscriptions?.length ? (
          <FlexerColumn gap="4px">
            {row.original.usage_subscriptions.map(({ pricing_plan }, index) => (
              <TextBox key={index}>{pricing_plan?.name}</TextBox>
            ))}
          </FlexerColumn>
        ) : (
          <FlexerRow alignItems="center" gap="0 4px">
            <WarningIcon />
            <FadedText>Not specified</FadedText>
          </FlexerRow>
        ),
    },
    column.startDate({ formatter: formatDateWithLocale }),
    column.endDate({ formatter: formatDateWithLocale }),
    {
      Header: 'Trial Units',
      accessor: 'trial_units',
      width: 80,
      Cell: ({ row }) => <>{row.original.trial_units}</>,
    },
    column.actions({ onEditClick: onEditClick }),
  ];

  return columns;
};
