import { Form, useFormikContext } from 'formik';

import { FlexEndContainer, FlexBetweenContainer, TextBox, CentererVertical } from 'components/Core';
import { CancelButton, ConfirmButton } from 'components/Buttons';
import { PencilNewIcon, CopyIcon } from 'components/Icons';

import InfoTable from './InfoTable';
import SubscriptionsTable from './SubscriptionsTable';
import { FormFooter, OverflowDiv, Wrapper, Title } from '../styles';

export const DuplicateForm = ({ isLoading, originalSubscriptions, onClose }) => {
  const {
    values: { subscriptions },
    handleSubmit,
  } = useFormikContext();

  return (
    <OverflowDiv>
      <Form>
        <Wrapper>
          <Title data-cy="duplicate-subscription-modal__title">
            Confirm duplicate subscriptions. The white cells are <span>editable</span>{' '}
            <PencilNewIcon fill="var(--dark50)" />
          </Title>

          <InfoTable />
          <SubscriptionsTable originalSubscriptions={originalSubscriptions} />
        </Wrapper>

        <FormFooter>
          <FlexBetweenContainer>
            <CentererVertical>
              <FlexBetweenContainer gap="5px">
                <CopyIcon fill="var(--dark50)" size="16px" />
                <TextBox fontSize="13px" italic color="var(--dark50)">
                  Subscript will duplicate {subscriptions.length ?? 1} subscriptions. All fields of the new ones except
                  white cells will be the same
                </TextBox>
              </FlexBetweenContainer>
            </CentererVertical>
            <FlexEndContainer>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
              <ConfirmButton
                loading={isLoading}
                data-cy="duplicate-subscription-modal__save-button"
                onClick={handleSubmit}
              />
            </FlexEndContainer>
          </FlexBetweenContainer>
        </FormFooter>
      </Form>
    </OverflowDiv>
  );
};
