import React from 'react';
import { Column, FlexerRow } from 'components/Core';
import { EXTERNAL_UPDATE_TYPES } from 'views/ExternalUpdates/consts';
import { getTableScheduleData } from 'views/Billing/Pages/SchedulesExternalUpdates/utils';
import { InvoiceChangesToApply } from './InvoiceChangesToApply';
import { InlineFlexWarnings } from './styles';

export const InvoiceChanges = ({
  update,
  externalUpdate,
  resolveExternalUpdate,
  removeScheduleMode = false,
  onClose,
  onConfirmUpdate,
  onConfirmNoUpdate,
  customFooter,
}) => {
  const { updateData, invoicingSchedule } = update ?? {};
  const mappedTargetObject = getTableScheduleData(invoicingSchedule);

  const isDeleteUpdate = externalUpdate?.type === EXTERNAL_UPDATE_TYPES.delete;

  return (
    <Column vertical="flex-start" horizontal="flex-start">
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'left', padding: '0 36px' }}>
          {resolveExternalUpdate && (
            <FlexerRow marginBottom="8px">
              Some transactions have changed, which may impact this schedule.{' '}
              {!isDeleteUpdate && <InlineFlexWarnings>Update invoicing schedule data: </InlineFlexWarnings>}
            </FlexerRow>
          )}
          {removeScheduleMode || isDeleteUpdate ? (
            <InlineFlexWarnings style={{ minWidth: !isDeleteUpdate && 480 }}>
              Remove {invoicingSchedule?.customer_name} invoicing schedule!
            </InlineFlexWarnings>
          ) : null}
        </div>

        <InvoiceChangesToApply
          externalUpdate={externalUpdate}
          existingInvoices={mappedTargetObject?.invoices}
          resolveExternalUpdate={resolveExternalUpdate}
          updateData={updateData}
          invoicingSchedule={mappedTargetObject}
          isDeleteUpdate={isDeleteUpdate}
          removeScheduleMode={removeScheduleMode}
          onClose={onClose}
          onConfirmUpdate={onConfirmUpdate}
          onConfirmNoUpdate={onConfirmNoUpdate}
          customFooter={customFooter}
        />
      </div>
    </Column>
  );
};
