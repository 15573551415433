import React from 'react';

import { Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';

export const ExternalUpdatesLoader = ({ isFetching, fetchingTooltipContent }) => {
  if (!isFetching) return <></>;

  return (
    <TooltipContainer toolTipContent={fetchingTooltipContent} hideArrow={true}>
      <CircleLoader data-cy="external-updates__fetching-loader" width={24} height={24} />
      <Spacer width="20px" />
    </TooltipContainer>
  );
};
