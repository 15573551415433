import { columns } from './consts';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableHeaderRow,
  TableRow,
  ArrowIcon,
} from './styles';

const InfoTable = () => {
  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          {columns.map((column) => (
            <TableHeaderColumn key={column.accessor} customWidth={column.width}>
              {column.Header}
            </TableHeaderColumn>
          ))}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        <TableRow bolder>
          <TableCell customWidth="650px">
            Current subscriptions
            <ArrowIcon />
          </TableCell>
          <TableCell primary extraPadding>
            New subscriptions to be created
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default InfoTable;
