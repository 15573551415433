import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as ExternalLinkFullCircledIcon } from 'images/external-link-full-circled.svg';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { CheckIcon, DoughnutIcon, HelpCircleIcon, LoaderIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { PaymentMethodLineItem } from 'shared/PaymentMethods/PaymentMethodLineItem';
import { getAutoChargeJobRunsAt, isInvoiceStatusUnpaid } from 'views/Billing/utils';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { useAutoChargeModal } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleTabsPanel/useAutoChargeModal';
import { INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { SectionTitle } from '../../../InvoiceScheduleWarnings/styles';
import {
  ActionButton,
  AutoChargeInfo,
  FailureWrapper,
  InfoTooltipContentText,
  Line,
  LinkCard,
  LinkSubtext,
  PaymentLink,
  StatusTag,
  SuccessWrapper,
  Wrapper,
} from './styles';
import { GenerateNewPaymentLinkButton } from './GenerateNewPaymentLinkButton';
import { SeePaymentsButton } from './SeePaymentsButton';
import { useGenerateNewPaymentLinkModal } from './useGenerateNewPaymentLinkModal';

const AUTO_CHARGE_STATUS = {
  WITHOUT_ACTIVE_PAYMENT_METHOD: 'without_active_payment_method',
  WITH_ACTIVE_PAYMENT_METHOD: 'with_active_payment_method',
  PROCESSING: 'processing',
  PAID: 'paid',
  UNPAID: 'unpaid',
  FAILED: 'failed',
  FAILED_IN_PAYMENT: 'failed_in_payment',
};

const getAutoChargeStatus = ({ invoicingSchedule, invoice, customer }) => {
  if (!invoice.auto_charge && !invoicingSchedule?.auto_charge) return null;
  if (!!invoice.paid_at) return AUTO_CHARGE_STATUS.PAID;
  if (
    (invoice.auto_charge_payment_status === 'failed' && invoice?.auto_charge_failed_in_payment) ||
    (invoice?.metadata?.[INVOICE_MAGIC_METADATA.PAYMENT_STATUS] === 'failed' &&
      !!invoice?.metadata?.[INVOICE_MAGIC_METADATA.LAST_PAYMENT_ERROR])
  )
    return AUTO_CHARGE_STATUS.FAILED_IN_PAYMENT;
  if (
    invoice?.auto_charge_payment_status === 'failed' ||
    invoice?.metadata?.[INVOICE_MAGIC_METADATA.PAYMENT_STATUS] === 'failed'
  )
    return AUTO_CHARGE_STATUS.FAILED;
  if (
    customer?.has_active_payment_method &&
    (invoice?.auto_charge_payment_status === 'processing' ||
      invoice?.metadata?.[INVOICE_MAGIC_METADATA.PAYMENT_STATUS] === 'processing')
  )
    return AUTO_CHARGE_STATUS.PROCESSING;
  if (
    isInvoiceStatusUnpaid({
      invoice,
      isScheduleAutoCharge: invoicingSchedule?.auto_charge,
    })
  )
    return AUTO_CHARGE_STATUS.UNPAID;
  if (customer?.has_active_payment_method) return AUTO_CHARGE_STATUS.WITH_ACTIVE_PAYMENT_METHOD;
  if (invoicingSchedule?.id) return AUTO_CHARGE_STATUS.WITHOUT_ACTIVE_PAYMENT_METHOD;
  return null;
};

const PaymentMethodInfo = ({ invoice }) => {
  const paymentMethod = invoice?.payment_method_details;
  return paymentMethod ? (
    <SuccessWrapper>
      <PaymentMethodLineItem paymentMethod={paymentMethod} />
    </SuccessWrapper>
  ) : (
    <SuccessWrapper>Credit card of {invoice.customer_name}</SuccessWrapper>
  );
};

const autoChargeStatusComponent = {
  [AUTO_CHARGE_STATUS.WITHOUT_ACTIVE_PAYMENT_METHOD]: ({ invoicingSchedule, openGenerateNewPaymentLinkModal }) => (
    <LinkCard>
      <Centerer gap="8px" padding="12px 16px">
        <StatusTag>Action Required</StatusTag>
        <div>
          Save & Send
          <PaymentLink
            href={invoicingSchedule.auto_charge_payment_link}
            target="_blank"
            data-cy="auto-charge__payment-link"
          >
            Subscript Link
          </PaymentLink>
          to your customer for payment
        </div>
        <ExternalLinkFullCircledIcon />
      </Centerer>
      <Flexer width="100%" gap="8px">
        <SuccessWrapper>
          <GenerateNewPaymentLinkButton openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal} />
        </SuccessWrapper>
      </Flexer>
    </LinkCard>
  ),
  [AUTO_CHARGE_STATUS.WITH_ACTIVE_PAYMENT_METHOD]: ({ invoice, customer, openGenerateNewPaymentLinkModal }) => {
    const autoChargeJobRunsAt = getAutoChargeJobRunsAt();
    const chargingToday =
      dayjs.utc(invoice.auto_charge_date ?? invoice?.date).isSameOrBefore(dayjs(), 'day') &&
      dayjs().isBefore(autoChargeJobRunsAt);
    return (
      <LinkCard>
        <Centerer gap="2px" padding="12px 8px">
          <LinkSubtext>No action needed on your part. </LinkSubtext>
          We'll charge the customer{' '}
          {chargingToday
            ? 'today'
            : `on ${dayjs.utc(invoice.auto_charge_date ?? invoice?.date).format('MMM D, YYYY')}`}{' '}
          at {autoChargeJobRunsAt.format('ha zzz')}.
        </Centerer>
        <Flexer width="100%" gap="8px">
          <PaymentMethodInfo invoice={invoice} />
          <SuccessWrapper>
            More: <SeePaymentsButton customer={customer} />{' '}
            <GenerateNewPaymentLinkButton openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal} />
          </SuccessWrapper>
        </Flexer>
      </LinkCard>
    );
  },
  [AUTO_CHARGE_STATUS.PROCESSING]: ({ invoice, customer, openGenerateNewPaymentLinkModal }) => (
    <LinkCard>
      <Centerer gap="8px" padding="12px 8px">
        <LoaderIcon opacity={0.5} width={16} height={16} />
        <div>The charge has been initiated and is awaiting approval from the customer's bank</div>
      </Centerer>
      <Flexer width="100%" gap="8px">
        <PaymentMethodInfo invoice={invoice} />
        <SuccessWrapper>
          More: <SeePaymentsButton customer={customer} />{' '}
          <GenerateNewPaymentLinkButton openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal} />
        </SuccessWrapper>
      </Flexer>
    </LinkCard>
  ),
  [AUTO_CHARGE_STATUS.UNPAID]: ({ openConfirmAutoChargeModal }) => (
    <LinkCard>
      <Centerer gap="8px" padding="12px 8px">
        Subscript won’t charge this invoice.{' '}
        <ActionButton data-cy="auto-charge__charge-invoice-button" onClick={openConfirmAutoChargeModal}>
          Enable Auto-charge for this invoice
        </ActionButton>
      </Centerer>
    </LinkCard>
  ),
  [AUTO_CHARGE_STATUS.PAID]: ({ invoice, customer }) => (
    <LinkCard>
      <Centerer width="100%" height="100%" gap="8px" style={{ padding: '12px 8px' }}>
        <CheckIcon opacity={0.3} width={16} height={16} />
        <div>
          We have <span style={{ color: 'var(--secondaryGreen)', cursor: 'none' }}>successfully charged</span> the
          customer for this invoice!
        </div>
      </Centerer>
      <Flexer width="100%" gap="8px">
        <PaymentMethodInfo invoice={invoice} />
        <SuccessWrapper>
          More: <SeePaymentsButton customer={customer} />
        </SuccessWrapper>
      </Flexer>
    </LinkCard>
  ),
  [AUTO_CHARGE_STATUS.FAILED]: ({ customer, openGenerateNewPaymentLinkModal }) => (
    <LinkCard>
      <Flexer width="100%" gap="8px">
        <FailureWrapper>
          <div>
            The payment processing <b>failed.</b>
          </div>
        </FailureWrapper>
        <SuccessWrapper>
          More: <SeePaymentsButton customer={customer} />{' '}
          <GenerateNewPaymentLinkButton openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal} />
        </SuccessWrapper>
      </Flexer>
    </LinkCard>
  ),
  [AUTO_CHARGE_STATUS.FAILED_IN_PAYMENT]: ({ invoice, customer, openGenerateNewPaymentLinkModal }) => (
    <LinkCard>
      <Centerer gap="8px" padding="12px 16px">
        <StatusTag>Action Required</StatusTag>
        <div>
          Re-send new
          <PaymentLink href={invoice.invoice_payment_link} target="_blank">
            Subscript Link
          </PaymentLink>
          to your customer for new payment details
        </div>
        <ExternalLinkFullCircledIcon />
      </Centerer>

      <Flexer width="100%" gap="8px">
        <FailureWrapper>
          <div>
            Payment on the customer's card was <b>failed.</b>
          </div>
        </FailureWrapper>
        <SuccessWrapper>
          More: <SeePaymentsButton customer={customer} />{' '}
          <GenerateNewPaymentLinkButton openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal} />
        </SuccessWrapper>
      </Flexer>
    </LinkCard>
  ),
};

const InfoTooltipContent = () => {
  const autoChargeJobRunsAt = getAutoChargeJobRunsAt();
  return (
    <FlexerColumn gap="8px">
      <InfoTooltipContentText>
        <DoughnutIcon />
        Invoices get auto charged on the invoice date set for them. This happens once a day, currently set to{' '}
        {autoChargeJobRunsAt.format('ha (z)')} (but subject to change) each day.
      </InfoTooltipContentText>
      <InfoTooltipContentText>
        <DoughnutIcon /> If you save an invoicing schedule with auto chargeable invoices in the past, the invoices will
        be charged on the next day at {autoChargeJobRunsAt.format('ha (z)')}.
      </InfoTooltipContentText>
      <InfoTooltipContentText>
        <DoughnutIcon /> To manually charge the customer for this invoice, click on the charge button in menu of more
        actions
      </InfoTooltipContentText>
    </FlexerColumn>
  );
};

export const AutoChargeCard = ({ invoice, customer, invoicingSchedule }) => {
  const {
    Modal: GenerateNewPaymentLinkModal,
    openModal: openGenerateNewPaymentLinkModal,
  } = useGenerateNewPaymentLinkModal({ invoice });

  const { scheduleFormRef } = useContext(InvoicingScheduleContext);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const { Modal: ConfirmAutoChargeModal, openModal: openConfirmAutoChargeModal } = useAutoChargeModal({
    setFieldValue,
  });

  const autoChargeStatus = getAutoChargeStatus({ invoicingSchedule, invoice, customer });
  if (!autoChargeStatus) return null;

  const AutoChargeStatusComponent = autoChargeStatusComponent[autoChargeStatus];
  return (
    <>
      <Wrapper>
        <FlexerColumn gap="16px" width="100%">
          <FlexBetweenContainer>
            <SectionTitle>Auto-charge</SectionTitle>
            <TooltipContainer width={320} toolTipContent={<InfoTooltipContent />}>
              <AutoChargeInfo>
                <div>How Subscript will charge the customer</div>
                <HelpCircleIcon />
              </AutoChargeInfo>
            </TooltipContainer>
          </FlexBetweenContainer>

          <Centerer>
            <AutoChargeStatusComponent
              invoicingSchedule={invoicingSchedule}
              invoice={invoice}
              customer={customer}
              openGenerateNewPaymentLinkModal={openGenerateNewPaymentLinkModal}
              openConfirmAutoChargeModal={openConfirmAutoChargeModal}
            />
          </Centerer>
        </FlexerColumn>
      </Wrapper>
      <Line />

      <ConfirmAutoChargeModal />
      <GenerateNewPaymentLinkModal />
    </>
  );
};
