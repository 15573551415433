/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { Fragment, useState, useContext, useCallback, Children, useEffect } from 'react';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import FilterIcon from 'images/adjustments-horizontal.svg';
import { GreyDot } from 'components/Icons';
import { CustomDatePicker } from 'components/Controls';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { Loader } from 'components/Loaders';
import { SelectDropdownButton } from 'components/Buttons';
import { GlobalTogglesContext, GlobalToggles } from 'shared/GlobalToggles';
import { InvalidDateRangeError } from 'shared/ErrorBars';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { deepEqual, getObjectDiff } from 'utils/objectUtils';
import { FILTERS_WITH_PAGE_LOAD, useAnalytics, useClickOutside } from 'utils/hooks';
import { extractMonthFromPickedDate } from 'utils/dateUtils';
import { ResolutionSelectorPopover } from './ResolutionSelectorPopover';
import { TransactionsTypePopover } from './TransactionsTypePopover';
import {
  ApplyFiltersButton,
  FilterContainer,
  FilterHead,
  FilterHeadInner,
  FilterConditionHead,
  ShadowWrapper,
  SettingsContainer,
  HelpButton,
  ConditionsWrapper,
  FilterTextBlock,
} from './styles';
import { MetadataFilter, getInitialMetadataFilterFormValue, METADATA_FILTER_TYPES } from './MetadataFilter';
import { MetadataFilterExplanation } from './MetadataFilterExplanation';
import { onSelectPeriod, PERIOD_OPTIONS } from './utils';
import { FiltersHelpModal } from './FiltersHelpModal';

const DATA_FILTER_KEY = {
  start: 'startMonth',
  end: 'endMonth',
  revenue: 'revenueMonth',
};

export const Filters = ({
  currentPageSegmentKey,
  hideFilters,
  hidePeriodSelection,
  hideTimeSelection,
  hidden,
  showConditions,
  metadataFilterTypes = [METADATA_FILTER_TYPES.CUSTOMERS, METADATA_FILTER_TYPES.TRANSACTIONS],
  showTransactionsSettings,
  showSelectSpecificMonth,
  setLoading,
  showMetadataFilterExplanation = true,
  showGlobalToggles = true,
  removeTopBorderRadius = false,
  removeBottomBorderRadius = false,
  sameMonthAllowed = false,
  applyDraftAutomatically = false,
  isSelectingSegment,
  ...props
}) => {
  const {
    organizations,
    orgId,
    dataFilter: appDataFilter,
    setDataFilter: appSetDataFilter,
    metadataFilter: appMetadataFilter,
    setMetadataFilter: appSetMetadataFilter,
    appSettings: { isARR: appIsARR },
  } = useContext(AppContext);

  const { trackEvent } = useAnalytics();

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { earliestAndLatestSpreadsDates } = organizationMetadata ?? {};

  const { globalTogglesState, draftGlobalToggles, applyDraftGlobalToggles } = useContext(GlobalTogglesContext);

  const [showResolution, setShowResolution] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const dropdownRef = useClickOutside(() => setShowResolution(false));

  const isARR = props.isARR ?? appIsARR;
  const dataFilter = props.dataFilter ?? appDataFilter;
  const setDataFilter = props.setDataFilter ?? appSetDataFilter;
  const metadataFilter = props.metadataFilter ?? appMetadataFilter;
  const _setMetadataFilter = props.setMetadataFilter ?? appSetMetadataFilter;

  const setMetadataFilter = useCallback(
    (filters) => {
      if (!isEmpty(filters.transactionMetadata) || !isEmpty(filters.customerMetadata)) {
        trackEvent({
          name: EVENTS.APPLY_METADATA_FILTERS,
          properties: {
            ...filters,
            filtersCount: (filters?.transactionMetadata?.length ?? 0) + (filters?.customerMetadata?.length ?? 0),
          },
        });
      }
      _setMetadataFilter(filters);
    },
    [_setMetadataFilter, trackEvent],
  );

  const [filtersValid, setFiltersValid] = useState({ metadata: true, conditions: true });
  const [draftDataFilter, setDraftDataFilter] = useState(dataFilter);
  const [metadataFilterFormValues, setMetadataFilterFormValues] = useState(
    getInitialMetadataFilterFormValue({ metadataFilter, metadataFilterTypes }),
  );

  const { resolution, startMonth, endMonth, revenueMonth, period } = draftDataFilter;

  useEffect(() => {
    setDraftDataFilter(dataFilter);
  }, [dataFilter]);

  useEffect(() => {
    setMetadataFilterFormValues(getInitialMetadataFilterFormValue({ metadataFilter, metadataFilterTypes }));
    // eslint-disable-next-line
  }, [metadataFilter]);

  const updateDraftDataFilter = (changes) => setDraftDataFilter({ ...draftDataFilter, ...changes });

  const selectMonths = (date, period) => {
    const key = DATA_FILTER_KEY[period];
    if (key) {
      updateDraftDataFilter({ [key]: date });

      if (sameMonthAllowed) {
        if (key === DATA_FILTER_KEY.end) {
          updateDraftDataFilter({ [key]: dayjs(date).endOf('month').toDate() });
        }
      }
    }
  };

  const selectResolution = (resolution) => updateDraftDataFilter({ resolution });

  const applyDataFilter = useCallback(() => {
    trackEvent({ name: 'Apply filters' });
    if (
      getObjectDiff(draftDataFilter, dataFilter).some((filter) => FILTERS_WITH_PAGE_LOAD.includes(filter)) &&
      setLoading
    ) {
      setLoading(true);
    }

    const newMetadataFilter = {};
    metadataFilterFormValues?.filters?.forEach((filter) => {
      if (!newMetadataFilter[filter?.filterType]) {
        newMetadataFilter[filter?.filterType] = [];
      }
      newMetadataFilter[filter?.filterType].push({
        [filter?.field]: filter?.value,
        _operator: filter?._operator,
        _startMonth: extractMonthFromPickedDate(draftDataFilter.startMonth),
        _endMonth: extractMonthFromPickedDate(draftDataFilter.endMonth),
      });
    });
    setMetadataFilter(newMetadataFilter);

    setDataFilter(draftDataFilter);

    applyDraftGlobalToggles();
  }, [
    applyDraftGlobalToggles,
    dataFilter,
    draftDataFilter,
    metadataFilterFormValues?.filters,
    setLoading,
    trackEvent,
    setDataFilter,
    setMetadataFilter,
  ]);

  const isSomeDraftStateChanged =
    !deepEqual(dataFilter, draftDataFilter, { excludeNil: false }) ||
    !deepEqual(globalTogglesState, draftGlobalToggles, { excludeNil: false }) ||
    !deepEqual(
      metadataFilterFormValues?.filters,
      getInitialMetadataFilterFormValue({ metadataFilter, metadataFilterTypes })?.filters,
      {
        excludeNil: false,
      },
    );

  useEffect(() => {
    if (applyDraftAutomatically && isSomeDraftStateChanged) {
      applyDataFilter();
    }
  }, [isSomeDraftStateChanged, applyDraftAutomatically, applyDataFilter]);

  const datesError = sameMonthAllowed
    ? dayjs(draftDataFilter.startMonth).diff(dayjs(draftDataFilter.endMonth), 'day') >= 0
    : dayjs(draftDataFilter.startMonth)
        .startOf('month')
        .diff(dayjs(draftDataFilter.endMonth).startOf('month'), 'month') > 0;

  // We want this so we know not to disable the button in case the dates somehow are already applied as invalid,
  // otherwise we'd lock out the user from fixing the dates
  const appliedDatesError = sameMonthAllowed
    ? dayjs(dataFilter.startMonth).diff(dayjs(dataFilter.endMonth), 'day') >= 0
    : dayjs(dataFilter.startMonth).startOf('month').diff(dayjs(dataFilter.endMonth).startOf('month'), 'month') >= 0;

  return organizations ? (
    <>
      {!hideTimeSelection && datesError && <InvalidDateRangeError />}
      <FilterContainer
        hidden={hidden}
        removeTopBorderRadius={removeTopBorderRadius}
        removeBottomBorderRadius={removeBottomBorderRadius}
      >
        {isSomeDraftStateChanged ? (
          <ApplyFiltersButton
            isDisabled={!Object.values(filtersValid).every(Boolean) || (datesError && !appliedDatesError)}
            onClick={() => applyDataFilter()}
            data-cy="filter-apply-button"
            type="submit"
          >
            Apply Filters
          </ApplyFiltersButton>
        ) : null}
        <ShadowWrapper noShadow={props?.noShadow}>
          {!hideFilters && (
            <FilterHead>
              <FilterHeadInner>
                <FilterTextBlock>Filters</FilterTextBlock>
                <img src={FilterIcon} alt="" style={{ marginRight: 8 }} />
                {!hidePeriodSelection ? (
                  <Fragment>
                    <FilterConditionHead
                      // Used to toggle the time dropdown for Monthly | Weekly selection
                      onClick={() => (props.resolutionDropdownEnabled ? setShowResolution(!showResolution) : null)}
                      ref={dropdownRef}
                      data-cy="filters__resolution-selector-toggler"
                    >
                      {resolution}
                      <ResolutionSelectorPopover
                        show={showResolution}
                        currentSelection={resolution}
                        onSelect={selectResolution}
                      />
                    </FilterConditionHead>
                    <span>for period: </span>
                  </Fragment>
                ) : showSelectSpecificMonth ? (
                  <>
                    Month:
                    <CustomDatePicker
                      name="single-month"
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      filtersView
                      selected={revenueMonth}
                      onChange={(date) => selectMonths(date, 'revenue')}
                    />
                  </>
                ) : hideTimeSelection ? null : (
                  'Period: '
                )}
                {!hideTimeSelection && (
                  <>
                    <SelectDropdownButton
                      showSelectedDirectly
                      name="filters__period"
                      options={sameMonthAllowed ? PERIOD_OPTIONS : omit(PERIOD_OPTIONS, 'thisMonth')}
                      selected={period}
                      onSelect={(period) =>
                        updateDraftDataFilter(
                          onSelectPeriod({ period: PERIOD_OPTIONS[period], earliestAndLatestSpreadsDates }),
                        )
                      }
                    />
                    {period !== 'Custom' ? (
                      period !== PERIOD_OPTIONS.allTime ? (
                        <span style={{ color: 'rgba(0, 21, 46, 0.5)', marginRight: 8 }}>
                          (
                          {dayjs(`${startMonth.getMonth() + 1}-${startMonth.getFullYear()}`, 'M-YYYY').format(
                            'MMM YYYY',
                          )}{' '}
                          through{' '}
                          {dayjs(`${endMonth.getMonth() + 1}-${endMonth.getFullYear()}`, 'M-YYYY').format('MMM YYYY')})
                        </span>
                      ) : null
                    ) : (
                      <>
                        from
                        <CustomDatePicker
                          name="start-month"
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                          filtersView
                          selected={startMonth}
                          onChange={(date) => selectMonths(dayjs(date).startOf('month').toDate(), 'start')}
                        />
                        to
                        <CustomDatePicker
                          name="end-month"
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                          filtersView
                          selected={endMonth}
                          onChange={(date) => selectMonths(dayjs(date).endOf('month').toDate(), 'end')}
                        />
                      </>
                    )}
                  </>
                )}
                {showTransactionsSettings && (
                  <>
                    <SettingsContainer>
                      <TransactionsTypePopover
                        draftDataFilter={draftDataFilter}
                        updateDraftDataFilter={updateDraftDataFilter}
                      />
                    </SettingsContainer>
                    <GreyDot spacing="4px" />
                  </>
                )}

                {/* TODO: Replace it with a context like in other parts of the application */}
                {Children.map(props.children, (child) =>
                  child ? (
                    React.cloneElement(child, {
                      organizations,
                      isARR,
                      dataFilter,
                      setDataFilter,
                      draftDataFilter,
                      updateDraftDataFilter,
                      setMetadataFilterFormValues,
                    })
                  ) : (
                    <></>
                  ),
                )}
              </FilterHeadInner>
            </FilterHead>
          )}
          {showConditions || currentPageSegmentKey ? (
            <ConditionsWrapper
              hasSegment={draftDataFilter?.[currentPageSegmentKey] || isSelectingSegment}
              showConditions={showConditions}
            >
              {showConditions && (
                <MetadataFilter
                  setFiltersValid={setFiltersValid}
                  metadataFilterFormValues={metadataFilterFormValues}
                  setMetadataFilterFormValues={setMetadataFilterFormValues}
                  metadataFilterTypes={metadataFilterTypes}
                />
              )}
            </ConditionsWrapper>
          ) : null}

          {showConditions && (
            <HelpButton onClick={() => setShowHelpModal(true)}>
              <HowWorksIcon />
              Help
            </HelpButton>
          )}
        </ShadowWrapper>
        {showGlobalToggles && <GlobalToggles />}
      </FilterContainer>
      {showConditions && showMetadataFilterExplanation && <MetadataFilterExplanation metadataFilter={metadataFilter} />}
      {showHelpModal && <FiltersHelpModal hideModal={() => setShowHelpModal(false)} />}
    </>
  ) : (
    <div className="w-100 flexer">
      <Loader containerStyles={{ width: 40 }} />
    </div>
  );
};
