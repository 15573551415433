import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Crepe } from '@milkdown/crepe';
import { Milkdown, MilkdownProvider, useEditor } from '@milkdown/react';
import { replaceAll } from '@milkdown/utils';

import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';

// We can overwrite styles set by the default Crepe theme here
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  position: relative;

  .milkdown {
    background-color: transparent;
    background-size: contain;
    padding-bottom: 6px;

    margin: ${(props) => (props.enabled ? '10px 30px' : '0')};

    .editor {
      padding: ${(props) => (props.enabled ? '10px' : '0')};

      font-weight: 400;
      font-family: 'Nunito Sans';

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 400;
        font-family: 'Nunito Sans';
        margin: 0;
        padding: 0;
      }
    }

    milkdown-link-edit > .link-edit > .input-area,
    milkdown-link-preview > .link-preview > .link-display {
      width: 100px;
    }
  }
`;

const CrepeEditor = ({ markdown, setMarkdown, enabled }) => {
  const { get } = useEditor((root) => {
    const CrepeEditor = new Crepe(
      {
        root,
        features: {
          [Crepe.Feature.Cursor]: true,
          [Crepe.Feature.ListItem]: true,
          [Crepe.Feature.LinkTooltip]: true,
          [Crepe.Feature.ImageBlock]: false,
          [Crepe.Feature.BlockEdit]: false, // TODO: I can't figure out how to disable the menu items that we don't want (Image, Table). Keeping false for now
          [Crepe.Feature.Placeholder]: true,
          [Crepe.Feature.Toolbar]: true,
          [Crepe.Feature.CodeMirror]: false,
          [Crepe.Feature.Table]: false,
          [Crepe.Feature.Latex]: false,
        },
        featureConfigs: {
          [Crepe.Feature.BlockEdit]: {
            slashMenuAdvancedGroupLabel: 'Experimental', // TODO: I'd want to be able to disable the advanced group, but idk how
          },
          [Crepe.Feature.Placeholder]: {
            text: 'Enter text here, you can use markdown to format your text!',
            mode: 'doc',
          },
        },
        defaultValue: markdown ?? '',
      },
      [markdown],
    );

    CrepeEditor.setReadonly(!enabled);

    CrepeEditor.on((listener) => {
      listener.markdownUpdated((_ctx, val, _prevVal) => {
        setMarkdown?.(val);
      });
    });

    return CrepeEditor;
  });

  useEffect(() => {
    // ONLY When the editor is disabled, we want to be able to update the markdown programatically
    if (enabled) return;

    const editor = get();
    editor?.action(replaceAll(markdown));
  }, [markdown, enabled, get]);

  return <Milkdown />;
};

export const WidgetTextSectionForm = ({ markdown, setMarkdown, enabled = true }) => {
  return (
    <Wrapper enabled={enabled}>
      <MilkdownProvider>
        <CrepeEditor markdown={markdown} setMarkdown={setMarkdown} enabled={enabled} />
      </MilkdownProvider>
    </Wrapper>
  );
};
