import { omit } from 'lodash';

export const DEFAULT_INVOICE_ITEMS_MAPPER = {
  id: 'Invoice Item ID',
  invoice_id: 'Invoice ID',
  description: 'Description',
  name: 'Name',
  type: 'Type',
  transactions: 'Transactions',
  product_id: 'Product',
  amount: 'Amount',
  seats: 'Seats',
  metadata: 'Metadata',
};

export const DEFAULT_INVOICE_ITEMS_CREATE_MAPPER = omit(DEFAULT_INVOICE_ITEMS_MAPPER, 'id');

export const DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS = {
  transactions: (value) => {
    const parsed = JSON.parse(value);
    if (!parsed) {
      return undefined;
    }
    const items = Array.isArray(parsed) ? parsed : [parsed];
    return items.map((transaction) => {
      if (typeof transaction === 'string' || typeof transaction === 'number') {
        return {
          transaction_id: String(transaction),
        };
      } else if (typeof transaction === 'object') {
        return {
          transaction_id: String(transaction?.transaction_id),
          initial_amount: transaction?.initial_amount,
        };
      } else {
        return transaction;
      }
    });
  },
};
