export const ACCESSORS = {
  CUSTOMER: 'customer',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  PRICING_PLAN: 'pricing_plan',
  TRIAL_UNITS: 'trial_units',
  NEW_CUSTOMER: 'new_customer',
  NEW_START_DATE: 'new_start_date',
  NEW_END_DATE: 'new_end_date',
  AGGREGATE_USAGE: 'aggregate_usage_on_parent',
  PRORATE: 'prorate_by_amount',
};

export const columns = [
  {
    Header: 'Customer',
    accessor: ACCESSORS.CUSTOMER,
    width: 190,
  },
  {
    Header: 'Start date',
    accessor: ACCESSORS.START_DATE,
    width: 115,
  },
  {
    Header: 'End date',
    accessor: ACCESSORS.END_DATE,
    width: 115,
  },
  {
    Header: 'Pricing plan',
    accessor: ACCESSORS.PRICING_PLAN,
    width: 120,
  },
  {
    Header: 'Trial Units',
    accessor: ACCESSORS.TRIAL_UNITS,
    width: 100,
  },
  {
    Header: 'New customer',
    accessor: ACCESSORS.NEW_CUSTOMER,
    width: 190,
  },
  {
    Header: 'New start d.',
    accessor: ACCESSORS.NEW_START_DATE,
    width: 120,
  },
  {
    Header: 'New end d.',
    accessor: ACCESSORS.NEW_END_DATE,
    width: 120,
  },
  {
    Header: 'Agg. Usage',
    accessor: ACCESSORS.AGGREGATE_USAGE,
    width: 105,
  },
  {
    Header: 'Prorate',
    accessor: ACCESSORS.PRORATE,
    width: 105,
  },
];
