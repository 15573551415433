import styled from 'styled-components';

export const ChartAndTotalRevenueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 28px 0;
`;

export const TableContainer = styled.div`
  ${({ cohortsDrilldown }) => (cohortsDrilldown ? 'overflow-x: scroll;' : 'overflow: scroll;')}
  height: ${({ height }) => height || '70vh'};
  min-height: ${({ minHeight }) => minHeight || '70vh'};
  position: relative;
  margin-top: 10px;
`;

export const CustomerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;

  h1 {
    font-weight: 900;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 38px;
  }
`;

export const TotalCustomers = styled.span`
  margin-left: 13px;
  padding: 8px 10px;
  background-color: var(--accentGray);
  border-radius: 100px;
`;

export const LoadMoreButton = styled.button`
  width: calc(100vw - 344px);
  height: 26px;
  background: var(--neutralBlue);
  border-radius: 4px;
  border: 1px solid rgba(0, 133, 255, 0.1);
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  padding: 6px 10px;
  margin: 23px 0 10px 6px;

  color: var(--primaryBlue);
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`;

export const StyledTable = styled.div`
  width: fit-content;

  .table-data {
    position: relative;

    svg {
      position: absolute;
      margin-left: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TilePercent = styled.div`
  opacity: 0.5;
  text-align: right;
  font-size: 11px;
  line-height: 14px;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const DateHeaderTile = styled.div``;

export const StyledTopRow = styled.div`
  height: 47px;
  margin-bottom: ${(props) => !props.noMarginBottom && '8px'};
  display: flex;
  width: fit-content;
`;

export const TableBodyRow = styled.div`
  font-size: ${(props) => props.rowType === 'segmented' && '12px'};
  line-height: ${(props) => props.rowType === 'segmented' && '16px'};
  display: flex;
  width: fit-content;

  .table-data {
    height: ${(props) => props.rowType === 'segmented' && '32px !important'};
  }
`;
export const LeftTitlesGroup = styled.div`
  position: static;
  display: flex;
  border: 1px solid var(--primaryBlack5);
  border-bottom: none;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  border-top: ${(props) => props.segmentCell && 'none'};
  margin-bottom: ${(props) => props.isLastRow && props.segmentCell && '8px'};
  margin-right: 12px;
  position: sticky;
  left: -3px;
  z-index: 10;

  border-top-left-radius: ${(props) =>
    ((props.isFirstRow && !props.segmentCell) || (props.isSpreadSegmented && !props.segmentCell)) && '8px'};
  border-top-right-radius: ${(props) =>
    ((props.isFirstRow && !props.segmentCell) || (props.isSpreadSegmented && !props.segmentCell)) && '8px'};
  border-bottom-left-radius: ${(props) =>
    ((props.isLastRow && !props.isSpreadSegmented) || (props.isLastRow && props.segmentCell)) && '8px'};
  border-bottom-right-radius: ${(props) =>
    ((props.isLastRow && !props.isSpreadSegmented) || (props.isLastRow && props.segmentCell)) && '8px'};
  border-bottom: ${(props) =>
    ((props.isLastRow && !props.isSpreadSegmented) || (props.isLastRow && props.segmentCell)) &&
    '1px solid var(--primaryBlack5)'};

  & > div:first-child {
    border-top-left-radius: ${(props) => props.isFirstRow && '8px'};
    border-top-right-radius: ${(props) => props.isFirstRow && '8px'};
    border-bottom-left-radius: ${(props) => props.isLastRow && '8px'};
    border-bottom-right-radius: ${(props) => props.isLastRow && '8px'};
    background: white;
    z-index: 10;
    font-weight: 600;
    position: sticky;
    left: 0;
    color: ${(props) => !props.segmentCell && 'var(--primaryBlue)'};
    font-size: ${(props) => props.segmentCell && '12px'};

    &:hover {
      color: ${(props) => !props.segmentCell && 'var(--secondaryBlue)'};
    }
  }
`;

export const TopRowTile = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 14px;
  border: 1px solid white;
  border-radius: 3px;
  color: ${({ selectedMonth }) => selectedMonth && 'var(--secondaryYellow)'};
  box-shadow: ${({ selectedMonth }) => selectedMonth && 'inset 0px 0px 0px 1px var(--primaryYellow)'};
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'var(--primaryBlack5)')};
  font-size: 12px;
  line-height: 16px;

  &:first-child {
    align-items: flex-start;
    border: 1px solid var(--primaryBlack5);
    background-color: white;
    border-bottom: ${(props) => props.noBorder && 'none'};
    border-radius: 8px;
    border-bottom-left-radius: ${(props) => props.noBorder && '0'};
    border-bottom-right-radius: ${(props) => props.noBorder && '0'};
    border-top-left-radius: ${(props) => !props.noBorder && '0'};
    border-top-right-radius: ${(props) => !props.noBorder && '0'};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-right: 12px;
    width: 250px;
  }

  ${({ isMoneyColumn }) =>
    isMoneyColumn &&
    `
    text-align: right;
    width: 110px;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const TableHeaderRow = styled.div`
  height: 42px;
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 20;
`;

export const TableHeaderTile = styled.div`
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: end;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 14px;
  padding-right: 0;
  white-space: nowrap;
  border-radius: ${({ selectedMonth }) => selectedMonth && '3px'};
  color: ${({ selectedMonth }) => (selectedMonth ? 'white' : 'var(--primaryBlack50)')};
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: start;
    background: var(--primaryGray);
    margin-right: 12px;
    width: 250px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
`;

export const ToolTipContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
`;

export const ToolTipRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;

export const ToolTipLabel = styled.span`
  color: white;
  opacity: 0.6;
  font-size: 10px;
`;

export const ToolTipValue = styled.span`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

export const ToolTipDivider = styled.hr`
  border: none;
  position: relative;
  height: 1px;
  margin: 16px 0;
  z-index: 1;
  background-color: var(--primaryBlack5);
  width: 130%;
  left: -20px;
  margin: 10px 0;
  width: 100%;
  background-color: white;
  opacity: 0.05;
  left: 0;
`;
