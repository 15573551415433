import { setNestedObjectValues } from 'formik';
import { isEmpty, set } from 'lodash';

const getColumnValue = ({
  csvColumnsTransformations,
  csvRow,
  columnKey,
  csvColumnsMapper,
  allProducts,
  dateFormat,
}) => {
  const transformer = csvColumnsTransformations?.[columnKey] ?? ((v) => v);
  const rawValue = csvRow[csvColumnsMapper[columnKey]];
  let newValue = rawValue && rawValue.length ? transformer(rawValue, { allProducts, dateFormat }) : null;

  return newValue;
};

export const updateColumnData = ({
  changedColumnMapping,
  csvColumnsMapper,
  setCsvColumnsMapper,
  parsedCsv,
  formRef,
  csvColumnsTransformations,
  allProducts,
  dateFormat,
}) => {
  if (formRef?.current) {
    parsedCsv?.data?.forEach((csvRow, index) => {
      if (index === parsedCsv?.data?.length - 1 && Object.values(csvRow).every(isEmpty)) return null;

      changedColumnMapping.forEach((columnKey) => {
        if (Object.keys(csvRow).includes(csvColumnsMapper[columnKey]) || csvColumnsMapper[columnKey] === null) {
          const newValue = csvColumnsMapper[columnKey]
            ? getColumnValue({
                csvColumnsTransformations,
                columnKey,
                csvColumnsMapper,
                csvRow,
                allProducts,
                dateFormat,
              })
            : null;

          formRef?.current?.setFieldValue(`[${index}].${columnKey}`, newValue, false);

          // We need this hack to additionaly re-render the form with new values
          // to load updated options in an async selectors
          setCsvColumnsMapper({ ...csvColumnsMapper });
        }
      });
    });
  }
};

export const mapFromParsedCsv = ({
  parsedCsv,
  csvColumnsMapper,
  csvColumnsTransformations,
  allProducts,
  dateFormat,
}) => {
  return parsedCsv?.data
    ?.map((csvRow, index) => {
      if (index === parsedCsv?.data?.length - 1 && Object.values(csvRow).every(isEmpty)) return null;
      const newRow = {};
      Object.keys(csvColumnsMapper).forEach((columnKey) => {
        if (Object.keys(csvRow).includes(csvColumnsMapper[columnKey])) {
          const newValue = getColumnValue({
            csvColumnsTransformations,
            columnKey,
            csvColumnsMapper,
            csvRow,
            allProducts,
            dateFormat,
          });

          set(newRow, columnKey, newValue);
        }
      });
      return newRow;
    })
    .filter((row) => row)
    .map((row, index) => ({ ...row, rowIndex: index }));
};

export const fillCellsFromFuzzyResults = ({ fuzzyErrors, formRef }) => {
  Object.entries(fuzzyErrors).forEach(([index, error]) => {
    Object.keys(error).forEach((key) => {
      formRef?.current?.setFieldValue(`[${index}].${key}`, error?.[key]?.name);
    });
  });
};

export const afterUploadFn = ({ result, setFuzzyErrors, formRef, history, setSubmittingForm, backLink }) => {
  if (result?.errors) {
    if (result?.errors?.fuzzyErrors) {
      setFuzzyErrors(result?.errors?.fuzzyErrors);
      fillCellsFromFuzzyResults({ fuzzyErrors: result?.errors?.fuzzyErrors, formRef });
    } else {
      setFuzzyErrors(null);
    }
    if (Object.keys(result?.errors).length > 0) {
      formRef?.current?.setErrors(result.errors);
      formRef?.current?.setTouched?.(setNestedObjectValues(result.errors, true));
    }
    setSubmittingForm(false);
  } else {
    setTimeout(() => {
      if (backLink) {
        history.push(backLink);
      }
      setSubmittingForm(false);
    }, 1000);
  }
};
