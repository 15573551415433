import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { ReactTableColumnWithTooltip } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { getTransactionMRR } from 'shared/TransactionContent/utils';
import { TransactionRecognitionIcon } from 'shared/Icons';
import TransactionTableGroupHeadRow from 'shared/TransactionTable/TransactionTableGroupHeadRow';
import {
  TOOLS_TIP_COLORS,
  TRANSACTION_FIELD_VALUE_BY_ID,
  TRANSACTION_TABLE_COLUMN_KEY,
} from 'shared/TransactionTable/consts';
import { getTransactionFieldValue } from 'shared/TransactionTable/utils';
import { ToolTipRow, DealNameColumn } from 'shared/TransactionTable/styles';
import { columnGenerator } from './columnGenerator';
import dayjs from 'dayjs';

const TransactionToolTipContent = () => (
  <div>
    {Object.keys(TOOLS_TIP_COLORS).map((status) => {
      return (
        <ToolTipRow key={status}>
          <FillDot fill={TOOLS_TIP_COLORS[status]} />
          <span style={{ marginLeft: 10 }}>{status}</span>
        </ToolTipRow>
      );
    })}
  </div>
);

const dateFormatter = (value) => dayjs.utc(value).format('MMM YYYY');

const column = columnGenerator({ entityName: 'transactions' });

export const generateColumns = ({ onTransactionClick, isARR, currencyISOCode }) => {
  const columns = [
    column.header(),
    {
      Header: () => (
        <ReactTableColumnWithTooltip>
          <TooltipContainer width={128} isVisible={true} underline={false} toolTipContent={TransactionToolTipContent()}>
            <TooltipIcon />
          </TooltipContainer>
          Date
        </ReactTableColumnWithTooltip>
      ),
      width: 160,
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DATE,
      Cell: ({ row }) => (
        <TransactionTableGroupHeadRow
          row={row}
          value={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DATE],
            transaction: row.original,
          })}
        />
      ),
    },
    {
      Header: 'Deal name',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME,
      width: 265,
      Cell: ({ row }) => {
        const dealNameValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME],
          transaction: row.original,
        });

        return (
          <DealNameColumn onClick={() => onTransactionClick(row)} data-cy="transaction-table__name-column">
            {dealNameValue}
          </DealNameColumn>
        );
      },
    },
    column.customer({ addExternalLink: true }),
    {
      Header: () => (
        <TooltipContainer
          width={100}
          isVisible={true}
          underline={false}
          toolTipContent={`${isARR ? 'Annual' : 'Monthly'} Recurring Revenue`}
        >
          <span>RR</span>
        </TooltipContainer>
      ),
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECURRING_REVENUE,
      width: 80,
      Cell: ({ row }) => {
        const recurringRevenue = getTransactionMRR(row.original);
        return (
          <div
            style={{ fontWeight: 700, position: 'relative' }}
            data-cy="transaction-table__row__recurring-amount-cell"
          >
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: isARR ? recurringRevenue * 12 : recurringRevenue,
              currency: row.original.currency ?? currencyISOCode,
            })}
          </div>
        );
      },
    },
    {
      Header: 'Total',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.AMOUNT,
      width: 80,
      sortType: (rowA, rowB) => rowA.original.amount - rowB.original.amount,
      Cell: ({ row }) => (
        <div style={{ fontWeight: 700, position: 'relative' }} data-cy="transaction-table__row__total-cell">
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.AMOUNT],
            transaction: row.original,
            currency: row.original.currency ?? currencyISOCode,
          })}
        </div>
      ),
    },
    {
      Header: 'Seats',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.SEATS,
      width: 80,
      Cell: ({ row }) => (
        <div style={{ fontWeight: 700, position: 'relative' }}>
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.SEATS],
            transaction: row.original,
          })}
        </div>
      ),
    },
    {
      Header: 'type',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE,
      width: 60,
      Cell: ({ row }) => (
        <TransactionRecognitionIcon
          type={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE],
            transaction: row.original,
          })}
        />
      ),
    },
    column.product(),
    column.startDate({ columnName: 'Start', formatter: dateFormatter }),
    column.endDate({ columnName: 'End', formatter: dateFormatter }),
    column.actions({ onEditClick: onTransactionClick }),
  ];

  return columns;
};
