import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper } from 'components/Portal';
import { ModalFooter } from 'components/Modal';
import { Button } from 'components/Buttons';
import { ArrowRightCircleIcon, CheckIcon } from 'components/Icons';
import { convertToPlainText } from 'utils/htmlUtils';
import { getServiceCategory } from 'models/integration';
import {
  COMMON_INVOICE_FIELDS,
  INVOICE_STATUSES,
  SERVICE_WITHOUT_INVOICE_SEND_EMAIL,
  SERVICE_WITH_TEST_SEND_EMAIL,
} from '../consts';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { getInvoiceInitialValues, invoiceWasEdited } from '../InvoiceModal/utils';
import { BillingContext } from '../BillingContext';
import { hasReminderFailedToSend, shouldSendEmailFromSubscript } from '../utils';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 700;
  line-height: 20px;
  box-shadow: 2px 2px 12px var(--primaryBlack2);

  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  background: ${({ background }) => background};

  &:hover {
    border: ${({ hoverBorderColor }) => hoverBorderColor && `1px solid ${hoverBorderColor}`};
    background: ${({ hoverBackground }) => hoverBackground};
  }

  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
    path {
      fill: ${({ iconFillColor }) => iconFillColor};
      stroke: ${({ iconStrokeColor }) => iconStrokeColor};
    }
  }
`;

const StyledArrowRightCircleIcon = styled(ArrowRightCircleIcon)`
  width: 16px;
  height: 16px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: #f7f7f8;
`;

export const InvoicePreviewFooter = ({ invoice, modalInvoiceStatus, onClose, isResend }) => {
  const { user = {} } = useAuth0();
  const { orgConfigs } = useContext(AppContext);
  const {
    removeBillingEmailReminder,
    editInvoice,
    updateInvoice,
    testSendInvoice,
    sendInvoice,
    editFutureInvoices,
    markInvoiceAsSent,
    handleRemindSend,
    editBillingEmailReminder,
    testSendReminder,
    dismissReminder,
  } = useContext(BillingContext);

  const {
    currentInvoicingSchedule,
    performInvoicingScheduleUpdate,
    validateInvoiceFormAndExecuteAction,
    selectedReminder,
    setReminderLoading,
    fetchedSelectedInvoice,
    reminderFormRef,
    reminderFormValues,
    closeModal,
    invoicingService,
    setSelectedReminder,
    disableSubmitButton,
  } = useContext(InvoicingScheduleContext);

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const [showMarkAsSentPopover, setShowMarkAsSentPopover] = useState(false);
  const toggleMarkAsSentPopover = () => setShowMarkAsSentPopover((prevValue) => !prevValue);
  const handleMarkAsSent = async () => {
    toggleMarkAsSentPopover();

    await markInvoiceAsSent({ invoice });

    onClose();
  };

  const [editSaveFutureInvoicesPopover, setEditSaveFutureInvoicesPopover] = useState(false);
  const toggleEditFutureInvoicesPopover = () => setEditSaveFutureInvoicesPopover((prevValue) => !prevValue);

  const handleSaveInvoice = async ({ modalClose }) =>
    await validateInvoiceFormAndExecuteAction({
      action: async () => {
        await updateInvoice({
          invoice: invoice,
          integrationId: currentInvoicingSchedule?.integration_id,
        });

        onClose();

        modalClose && closeModal();
      },
    });

  const handleSendInvoice = async () =>
    await validateInvoiceFormAndExecuteAction({
      action: async () => {
        await sendInvoice({
          invoice,
          integrationId: currentInvoicingSchedule?.integration_id,
        });

        onClose();
      },
    });

  const handleSubmit = async () => {
    if (isResend) {
      await handleSendInvoice();
      onClose();
    } else if (modalInvoiceStatus === INVOICE_STATUSES.PAID) {
      //in paid invoices we can change internal_notes and paid date
      await validateInvoiceFormAndExecuteAction({
        action: async () => {
          await editInvoice({
            id: invoice?.id,
            data: {
              internal_notes: invoice.internal_notes,
              paid_at: dayjs.utc(invoice.paid_at).format('YYYY-MM-DD'),
            },
            integrationId: currentInvoicingSchedule?.integration_id,
          });
          onClose();
        },
      });
    } else if ([INVOICE_STATUSES.SENT].includes(modalInvoiceStatus)) {
      await handleSendInvoice();
      onClose();
      return;
    } else if ([INVOICE_STATUSES.REMIND].includes(modalInvoiceStatus)) {
      await validateInvoiceFormAndExecuteAction({
        formRefCurrent: reminderFormRef?.current,
        action: async () => {
          setReminderLoading(true);
          await handleRemindSend({ updatedEmailReminder: reminderFormValues });
          setReminderLoading(false);
          onClose();
          closeModal();
        },
      });
      return;
    } else if (currentInvoicingSchedule?.auto_send) {
      const source = fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {};
      const target = invoice ? getInvoiceInitialValues(invoice) : {};

      if (
        invoiceWasEdited({ source, target, fields: COMMON_INVOICE_FIELDS }) &&
        currentInvoicingSchedule?.invoices?.length > 1
      ) {
        toggleEditFutureInvoicesPopover();
      } else {
        handleEditFutureInvoices(false);
      }
    } else {
      handleEditFutureInvoices(false);
    }
  };

  const stopAutoSendingFutureInvoices = async () => {
    await performInvoicingScheduleUpdate({ auto_send: false });
    onClose();
  };

  const handleEditFutureInvoices = async (shouldEdit) => {
    if (shouldEdit) {
      await validateInvoiceFormAndExecuteAction({
        action: async () => {
          await editFutureInvoices({
            invoice,
            invoicingScheduleInvoices: currentInvoicingSchedule?.invoices,
          });

          onClose();
        },
      });
    }

    if (invoice.auto_send) {
      await handleSaveInvoice({ modalClose: false });
    } else {
      await handleSendInvoice();
    }
  };

  const handleSkip = async () => {
    if (reminderFormValues?.ai_generated) {
      await dismissReminder({ id: reminderFormValues?.id });
    } else {
      await removeBillingEmailReminder({
        emailReminderObject: selectedReminder,
        toDoMessage: selectedReminder?.subject,
      });
    }

    onClose();
  };

  const showSendButton = useMemo(() => {
    if (isResend) return true;
    if (modalInvoiceStatus === INVOICE_STATUSES.REMIND) {
      return !reminderFormValues?.auto_send || hasReminderFailedToSend({ reminder: selectedReminder });
    } else {
      return (
        invoice.auto_charge ||
        sendEmailFromSubscript ||
        !SERVICE_WITHOUT_INVOICE_SEND_EMAIL.includes(getServiceCategory(invoicingService))
      );
    }
  }, [
    invoice.auto_charge,
    invoicingService,
    isResend,
    modalInvoiceStatus,
    reminderFormValues?.auto_send,
    selectedReminder,
    sendEmailFromSubscript,
  ]);

  const isNotSendOrRemindInvoice =
    !invoice.auto_charge && ![INVOICE_STATUSES.SENT, INVOICE_STATUSES.REMIND].includes(modalInvoiceStatus);

  return (
    <StyledFooter width="100%" transparent noFixedHeight padding="12px 40px" topSeparator>
      <FlexBetweenContainer alignItems="center">
        <Flexer>
          {invoice.auto_send && !invoice.auto_charge && (
            <TooltipContainer
              width={220}
              direction={DIRECTIONS.TOP}
              hideArrow
              yOffset={28}
              fontSize="12px"
              toolTipContent={`This will stop automatic sending of future invoices for this contract`}
            >
              <StyledButton hoverBackground="var(--primaryBlack10)" onClick={stopAutoSendingFutureInvoices}>
                Stop Auto-Sending
              </StyledButton>
            </TooltipContainer>
          )}
        </Flexer>

        <Flexer gap="8px" alignItems="center">
          {modalInvoiceStatus === INVOICE_STATUSES.REMIND && !isResend && (
            <StyledButton onClick={handleSkip}>Skip Reminder</StyledButton>
          )}

          {modalInvoiceStatus !== INVOICE_STATUSES.PAID && (
            <>
              {isNotSendOrRemindInvoice && (
                <PopoverWrapper>
                  <StyledButton
                    data-cy="billing__invoice-modal__button--mark-as-sent"
                    onClick={toggleMarkAsSentPopover}
                    hoverBackground="var(--primaryBlack10)"
                    background="var(--primaryBlack3)"
                  >
                    Mark as Sent
                  </StyledButton>
                  {showMarkAsSentPopover && (
                    <Popover XOffset={0} YOffset={-100} zIndex={51} darkMode width="200px">
                      <PopoverPrompt>Are you sure you want to mark this invoice as sent?</PopoverPrompt>
                      <PopoverActions>
                        <PopoverButton
                          data-cy="billing__invoice-modal__popover-button--No"
                          onClick={toggleMarkAsSentPopover}
                        >
                          No
                        </PopoverButton>
                        <PopoverButton
                          data-cy="billing__invoice-modal__popover-button--Yes"
                          onClick={handleMarkAsSent}
                          primary
                        >
                          Yes
                        </PopoverButton>
                      </PopoverActions>
                    </Popover>
                  )}
                </PopoverWrapper>
              )}

              {(sendEmailFromSubscript ||
                invoice.auto_charge ||
                SERVICE_WITH_TEST_SEND_EMAIL.includes(getServiceCategory(invoicingService)) ||
                modalInvoiceStatus === INVOICE_STATUSES.REMIND) && (
                <TooltipContainer
                  width={220}
                  direction={DIRECTIONS.TOP}
                  hideArrow
                  yOffset={28}
                  fontSize="12px"
                  toolTipContent={`Send the email to yourself: ${user?.email}`}
                >
                  <StyledButton
                    hoverBackground="var(--primaryBlack10)"
                    background="var(--primaryBlack3)"
                    onClick={() => {
                      if (modalInvoiceStatus === INVOICE_STATUSES.REMIND && !isResend) {
                        validateInvoiceFormAndExecuteAction({
                          action: async () => {
                            const result = await testSendReminder({ updatedEmailReminder: reminderFormValues });
                            result?.reminder && setSelectedReminder({ ...reminderFormValues, ...result.reminder });
                          },
                        });
                      } else {
                        validateInvoiceFormAndExecuteAction({
                          action: async () => {
                            await testSendInvoice({ invoice, user });
                          },
                        });
                      }
                    }}
                    disabled={disableSubmitButton}
                  >
                    Test Send
                  </StyledButton>
                </TooltipContainer>
              )}

              {![INVOICE_STATUSES.SENT].includes(modalInvoiceStatus) &&
                (!invoice.auto_send || modalInvoiceStatus === INVOICE_STATUSES.REMIND) && (
                  <StyledButton
                    data-cy="invoice-preview-modal__save-button"
                    background="var(--primaryBlack3)"
                    hoverBackground="var(--primaryBlack10)"
                    onClick={async () => {
                      if (modalInvoiceStatus === INVOICE_STATUSES.REMIND) {
                        await validateInvoiceFormAndExecuteAction({
                          formRefCurrent: reminderFormRef?.current,
                          action: async () => {
                            setReminderLoading(true);

                            await editBillingEmailReminder({
                              id: reminderFormValues?.id,
                              send: false,
                              data: {
                                email_cc: reminderFormValues?.email_cc,
                                email_bcc: reminderFormValues?.email_bcc,
                                to_emails: reminderFormValues?.to_emails,
                                scheduled_for: reminderFormValues?.scheduled_for,
                                body: convertToPlainText(reminderFormValues?.body),
                                subject: convertToPlainText(reminderFormValues?.subject),
                                attachments: reminderFormValues?.attachments,
                                auto_send: reminderFormValues?.auto_send,
                              },
                              isPreview: false,
                            });

                            setReminderLoading(false);
                            onClose();
                          },
                        });
                      } else {
                        await handleSaveInvoice({ modalClose: true });
                      }
                    }}
                  >
                    Save Email
                  </StyledButton>
                )}

              {showSendButton && (
                <PopoverWrapper>
                  <TooltipContainer
                    width={210}
                    direction={DIRECTIONS.TOP}
                    hideArrow
                    yOffset={28}
                    fontSize="12px"
                    toolTipContent={'Please check sender settings and configure mailer'}
                    isVisible={disableSubmitButton}
                  >
                    <StyledButton
                      data-cy="invoice-preview-modal__send-button"
                      filled
                      active
                      color={COLORS.GREEN}
                      iconFillColor="#FFF"
                      onClick={handleSubmit}
                      disabled={disableSubmitButton}
                    >
                      {modalInvoiceStatus === INVOICE_STATUSES.SENT || isResend
                        ? 'Resend'
                        : modalInvoiceStatus === INVOICE_STATUSES.REMIND
                        ? 'Remind'
                        : invoice.auto_send
                        ? 'Save Changes'
                        : 'Send Now'}{' '}
                      {invoice.auto_send ? <StyledCheckIcon /> : <StyledArrowRightCircleIcon />}
                    </StyledButton>
                    {editSaveFutureInvoicesPopover && (
                      <Popover XOffset={0} YOffset={-110} zIndex={51} darkMode width="200px">
                        <PopoverPrompt>
                          Do you want to apply saved changes to future invoices for this contract as well?
                        </PopoverPrompt>
                        <PopoverActions>
                          <PopoverButton onClick={() => handleEditFutureInvoices(false)}>No</PopoverButton>
                          <PopoverButton onClick={() => handleEditFutureInvoices(true)} primary>
                            Yes
                          </PopoverButton>
                        </PopoverActions>
                      </Popover>
                    )}
                  </TooltipContainer>
                </PopoverWrapper>
              )}
            </>
          )}
        </Flexer>
      </FlexBetweenContainer>
    </StyledFooter>
  );
};
