import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { FormikCustomInput } from 'components/Controls';
import { FlexerRow, Spacer } from 'components/Core';
import { ExternalLinkIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { INTERNAL_TRANSACTION_METADATA } from 'models/transaction';
import { METADATA_TYPES, useCombinedMetadata, useMetadataActionsModal } from 'shared/Common';
import { MetadataItemFormikInput } from 'shared/Common/MetadataSection';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';

import {
  AddMetadataButton,
  ExternalLinkIconWrapper,
  FormFooter,
  MetadataBadge,
  MetadataBlockWrapper,
  StyledPencilIcon,
  SubscriptionSubtext,
  Tab,
  TabsContainer,
  TransactionLinkWrapper,
  TransactionText,
} from '../styles';

const formatToValueStructure = ({ draft }) =>
  draft.reduce(
    (obj, item) => ({
      ...obj,
      [item.key]: item.value,
    }),
    {},
  );

export const TransactionSection = ({ transactions, selectedTransaction, setSelectedTransactionId, isCreating }) => {
  const { setFieldValue } = useFormikContext();
  const { orgId } = useContext(AppContext);

  const { combinedMetadataOptions } = useCombinedMetadata({
    orgId,
    currentValues: {
      ...Object.fromEntries(
        Object.entries({ ...selectedTransaction?.metadata }).filter(
          ([key]) => !Object.values(INTERNAL_TRANSACTION_METADATA).includes(key),
        ),
      ),
    },
    metadataFilterType: METADATA_FILTER_TYPES.TRANSACTIONS,
  });

  const handleOnCloseMetadataModal = (metadataItem) => {
    if (metadataItem) {
      const metadataItems = {
        ...formatToValueStructure({
          draft: combinedMetadataOptions,
        }),
        [metadataItem.key]: metadataItem.value,
      };

      setFieldValue(selectedTransaction?.metadataKey, metadataItems);
    }
  };

  const { openCreateMetadataModal, MetadataActionsModal } = useMetadataActionsModal({
    organizationId: orgId,
    onClose: handleOnCloseMetadataModal,
  });

  return (
    <FormFooter style={{ marginTop: '20px', display: 'inline-table' }}>
      <FlexerRow alignItems="center">
        <StyledPencilIcon />
        <TransactionText>Edit related transaction{transactions.length > 1 ? 's' : ''}:</TransactionText>
        <TabsContainer>
          {transactions.map((transaction) => (
            <TooltipContainer toolTipContent={transaction.label} key={transaction._id}>
              <Tab
                active={selectedTransaction?._id === transaction._id}
                onClick={() => setSelectedTransactionId(transaction?._id)}
              >
                {transaction.label}
              </Tab>
            </TooltipContainer>
          ))}
        </TabsContainer>
      </FlexerRow>
      <FlexerRow style={{ marginTop: '12px' }} justifyContent="space-between">
        {isCreating ? (
          <SubscriptionSubtext>Subscript will create a new transaction</SubscriptionSubtext>
        ) : (
          <SubscriptionSubtext>Transaction name</SubscriptionSubtext>
        )}
        {selectedTransaction?.id && (
          <TransactionLinkWrapper onClick={() => window.open(`/transactions/${selectedTransaction.id}`, '_blank')}>
            <span style={{ color: '#0075FF', fontWeight: '700' }}>Open Transaction: #{selectedTransaction.id}</span>
            <ExternalLinkIconWrapper>
              <ExternalLinkIcon />
            </ExternalLinkIconWrapper>
          </TransactionLinkWrapper>
        )}
      </FlexerRow>
      <FlexerRow style={{ marginTop: '8px' }}>
        <FormikCustomInput
          key={selectedTransaction.nameKey}
          name={selectedTransaction.nameKey}
          width={'100%'}
          data-cy="subscription-create-modal__transaction-name"
        />
      </FlexerRow>
      <Spacer height="16px" />
      <MetadataBlockWrapper>
        <FlexerRow alignItems="center">
          <MetadataBadge>{combinedMetadataOptions.filter((item) => item.value).length}</MetadataBadge>
          <TransactionText>Transaction Metadata</TransactionText>
          <AddMetadataButton onClick={() => openCreateMetadataModal({ metadataType: METADATA_TYPES.TRANSACTIONS })}>
            Add metadata
          </AddMetadataButton>
        </FlexerRow>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            width: '100%',
          }}
        >
          {combinedMetadataOptions
            .filter((item) => item.value)
            .map((item) => (
              <MetadataItemFormikInput
                key={item.key}
                name={`${selectedTransaction.metadataKey}.${item.key}`}
                metadataType={METADATA_TYPES.TRANSACTIONS}
                organizationId={orgId}
                metadataDataType={item.dataType}
                onDataChange={(newValue) => {
                  setFieldValue(selectedTransaction?.metadataKey, {
                    ...combinedMetadataOptions.reduce(
                      (obj, item) => ({
                        ...obj,
                        [item.key]: item.value,
                      }),
                      {},
                    ),
                    [item.key]: newValue,
                  });
                }}
                item={item}
                disabled={false}
              />
            ))}
        </div>
      </MetadataBlockWrapper>
      <MetadataActionsModal />
    </FormFooter>
  );
};
