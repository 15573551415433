import dayjs from 'dayjs';
import React from 'react';
import { NUMBER_FORMATS } from 'consts/global';
import { CancelButton, SubmitButton } from 'components/Buttons';
import { Centerer, FlexerColumn, FlexerRow, Row } from 'components/Core';
import { ArrowNarrowRightIcon } from 'components/Icons';
import { ReactComponent as CheckIcon } from 'images/check-warning.svg';
import { numberFormatter } from 'utils/formatters';
import {
  ExternalUpdateBody,
  ExternalUpdateBodyTitle,
  ModalFooter,
  InvoiceItem,
  RemoveLabel,
  CreateLabel,
  ChangeLabel,
  InvoiceName,
  InvoicePreviousValue,
  InvoiceNewValue,
  DismissButton,
  ApplyButton,
} from './styles';
import { EXTERNAL_UPDATE_ACTION_OPTIONS, EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';

const formatDate = (date) => dayjs.utc(date)?.format('MMM DD, YYYY');

const formatAmount = (amount, currency) => {
  return numberFormatter({
    currency,
    type: NUMBER_FORMATS.CURRENCY,
    rawValue: amount,
    decimalPlaces: 2,
  });
};

const InvoiceChangesList = ({ changes, existingInvoicesById }) => {
  const { changedInvoices, invoicesToDelete, invoicesToInsert } = changes;

  return (
    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
      {changedInvoices?.map((invoice) => {
        const existingInvoice = existingInvoicesById[invoice.id];

        return (
          <InvoiceItem key={invoice.id}>
            <ChangeLabel active>change</ChangeLabel>
            <FlexerRow>
              <InvoiceName>{formatDate(invoice.date)} invoice: </InvoiceName>
              {existingInvoice ? (
                <Centerer>
                  <InvoicePreviousValue>
                    {formatAmount(existingInvoice.amount, existingInvoice.currency)}
                  </InvoicePreviousValue>
                  <ArrowNarrowRightIcon style={{ marginRight: '0px' }} />
                  <InvoiceNewValue>{formatAmount(invoice.amount, invoice.currency)}</InvoiceNewValue>
                </Centerer>
              ) : (
                formatAmount(invoice.amount, invoice.currency)
              )}
            </FlexerRow>
          </InvoiceItem>
        );
      })}

      {invoicesToDelete?.map((invoice) => (
        <InvoiceItem key={invoice.id}>
          <RemoveLabel active>remove</RemoveLabel>
          <InvoiceName>
            {formatDate(invoice.date)} invoice: {formatAmount(invoice.amount, invoice.currency)}
          </InvoiceName>
        </InvoiceItem>
      ))}

      {invoicesToInsert?.map((invoice) => (
        <InvoiceItem key={invoice.date}>
          <CreateLabel active>create</CreateLabel>
          <InvoiceName>{formatDate(invoice.date)} invoice</InvoiceName>
        </InvoiceItem>
      ))}
    </div>
  );
};

export const InvoiceChangesToApply = ({
  existingInvoices,
  externalUpdate,
  resolveExternalUpdate,
  updateData,
  removeScheduleMode,
  onClose,
  onConfirmUpdate,
  onConfirmNoUpdate,
  customFooter,
}) => {
  return (
    <>
      <FlexerColumn padding="0 36px 28px">
        <ExternalUpdateBody>
          <ExternalUpdateBodyTitle>
            {removeScheduleMode ? 'The following invoices will be removed:' : 'Review Invoice Changes:'}
          </ExternalUpdateBodyTitle>

          <InvoiceChangesList
            changes={updateData}
            existingInvoicesById={existingInvoices.reduce((acc, invoice) => {
              acc[invoice.id] = invoice;
              return acc;
            }, {})}
          />

          {!removeScheduleMode && (
            <Row horizontal="flex-start">
              <DismissButton
                data-cy="external-update-warning__deny"
                onClick={async () =>
                  await resolveExternalUpdate.mutateAsync({
                    id: externalUpdate?.id,
                    data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.dismiss },
                  })
                }
              >
                Dismiss
              </DismissButton>

              <ApplyButton
                data-cy="external-update-warning__apply"
                onClick={async () => {
                  await resolveExternalUpdate.mutateAsync({
                    id: externalUpdate?.id,
                    data: {
                      actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite,
                      objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.INVOICING_SCHEDULE,
                    },
                  });
                  onClose?.();
                }}
              >
                Apply
                <CheckIcon />
              </ApplyButton>
            </Row>
          )}
        </ExternalUpdateBody>
      </FlexerColumn>

      {!!removeScheduleMode && (
        <ModalFooter custom={!!customFooter}>
          {customFooter}
          <FlexerRow>
            <CancelButton
              data-cy="invoice-changes-to-apply-modal__deny-button"
              style={{ fontSize: 12 }}
              onClick={onConfirmNoUpdate}
            >
              {removeScheduleMode ? "Don't remove schedule" : "Don't update invoices"}
            </CancelButton>
            <SubmitButton
              data-cy="invoice-changes-to-apply-modal__confirm-button"
              style={{ fontSize: 12 }}
              onClick={onConfirmUpdate}
            >
              {removeScheduleMode ? 'Yes, remove schedule' : 'Yes, update invoices'}
            </SubmitButton>
          </FlexerRow>
        </ModalFooter>
      )}
    </>
  );
};
