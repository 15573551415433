import { omit } from 'lodash';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';

export const PREPAID_RECOGNITION_METHODS = {
  PER_UNIT: false,
  STRAIGHTLINE: true,
};

export const PREPAID_RECOGNITION_LABELS = {
  [PREPAID_RECOGNITION_METHODS.STRAIGHTLINE]: 'Straightline recognition',
  [PREPAID_RECOGNITION_METHODS.PER_UNIT]: 'Recognize per unit',
};

export const PREPAID_RECOGNITION_SUB_LABELS = {
  [PREPAID_RECOGNITION_METHODS.STRAIGHTLINE]:
    'Evenly distributes the revenue over the billing period, regardless of the actual usage during that time.',
  [PREPAID_RECOGNITION_METHODS.PER_UNIT]: 'Ties revenue recognition directly to the actual usage of the service.',
};

export const USAGE_BASED_BILLING_SCHEMES = {
  FIXED_PRICE: 'fixed_price',
  PER_UNIT: 'per_unit',
  FORMULA: 'formula',
  PREPAID: 'prepaid',
};

export const USAGE_BASED_BILLING_SCHEMES_LABELS = {
  [USAGE_BASED_BILLING_SCHEMES.FIXED_PRICE]: 'Fixed price',
  [USAGE_BASED_BILLING_SCHEMES.PER_UNIT]: 'Per unit',
  [USAGE_BASED_BILLING_SCHEMES.FORMULA]: 'Formula',
  [USAGE_BASED_BILLING_SCHEMES.PREPAID]: 'Prepaid',
};

export const PRICING_PLANS_TABLE_COLUMN_TITLES_BY_ID = {
  name: 'Name / Description',
  product_id: 'Product',
  event_name: 'Event Name',
  tiers: 'Billing Scheme',
  invoicing_frequency: 'Interval',
  currency: 'Currency',
};

export const USAGE_BASED_ENGINE_FREQUENCY = omit(
  { ...INVOICING_FREQUENCIES, SUBSCRIPTION_DURATION: 'Subscription Duration' },
  ['UPFRONT', 'EVENT_BASED', 'CUSTOM_SCHEDULE_METADATA'],
);
