import { useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { RevenueDetailsModal } from 'shared/RevenueDetailsModal';
import { BookingsModal } from 'views/Dashboard/Common/BookingsModal';
import { UpForRenewalModal } from 'views/Dashboard/Common/UpForRenewalModal';
import { ExplainModal } from 'views/Dashboard/Common/ExplainModal';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { useOpenDrilldownsFromUrl } from 'utils/hooks/useOpenDrilldownsFromUrl';
import { Table } from './Table';
import { generateSnapshotColumns } from './columns';

const SnapshotTable = ({ dataForTable, tableRef, selectedRows, isARR, setIsARR }) => {
  const {
    organizations,
    appSettings: { isCommitted },
  } = useContext(AppContext);

  const { dataFilter, openCardChartModal, optimisticAnalytics, setOptimisticAnalytics } = useContext(DashboardContext);

  // used for TransactionDetailsModal
  const [showRevenueDetailsModal, setShowRevenueDetailsModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null); //this state variable is also used for UpForRenewalModal
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedUpsellType, setSelectedUpsellType] = useState(null);

  // used for BookingsModal
  const [showBookingsModal, setShowBookingsModal] = useState(false);
  const [bookingStart, setBookingStart] = useState(null);
  const [bookingEnd, setBookingEnd] = useState(null);
  const [bookingsValue, setBookingsValue] = useState(null);
  const [bookingIsRecurring, setBookingIsRecurring] = useState(true);

  // used for ExplainModal
  const [showExplainModal, setShowExplainModal] = useState(false);
  const [dataForExplainModal, setDataForExplainModal] = useState(null);

  // used for UpForRenewalModal
  const [showUpForRenewalModal, setShowUpForRenewalModal] = useState(false);

  // used in both UpForRenewalModal and RevenueDetailsModal
  const [summaryStartMonth, setSummaryStartMonth] = useState(null);
  const [summaryColumnHeader, setSummaryColumnHeader] = useState(null);

  useOpenDrilldownsFromUrl({
    setSelectedMetric,
    setSelectedMonth,
    setShowRevenueDetailsModal,
    setShowUpForRenewalModal,
    setSummaryColumnHeader,
    openCardChartModal,
  });

  const columns = useMemo(
    () =>
      generateSnapshotColumns({
        dataForTable,
        dataFilter,
        setSelectedMonth,
        setSelectedMetric,
        setSelectedUpsellType,
        setShowRevenueDetailsModal,
        setShowUpForRenewalModal,
        setBookingStart,
        setBookingEnd,
        setBookingsValue,
        setBookingIsRecurring,
        setShowBookingsModal,
        setShowExplainModal,
        setDataForExplainModal,
        setSummaryStartMonth,
        setSummaryColumnHeader,
        openCardChartModal,
      }),
    [
      dataForTable,
      dataFilter,
      setSelectedMonth,
      setSelectedMetric,
      setSelectedUpsellType,
      setShowRevenueDetailsModal,
      setShowUpForRenewalModal,
      setBookingStart,
      setBookingEnd,
      setBookingsValue,
      setBookingIsRecurring,
      setShowBookingsModal,
      setShowExplainModal,
      setDataForExplainModal,
      setSummaryStartMonth,
      setSummaryColumnHeader,
      openCardChartModal,
    ],
  );

  const data = useMemo(
    () => ({
      ...dataForTable,
      rows: dataForTable.rows
        .map((row) => ({
          ...row,
          subRows: row.subRows.filter((subRow) => selectedRows?.includes(subRow.title)),
        }))
        .filter((row) => row.subRows.length > 0),
    }),
    [dataForTable, selectedRows],
  );

  const MemoTable = useMemo(() => <Table columns={columns} tableRef={tableRef} data={data} />, [
    columns,
    data,
    tableRef,
  ]);

  return (
    <>
      {data && MemoTable}

      {showRevenueDetailsModal && (
        <RevenueDetailsModal
          organization={organizations[0]}
          closeModal={() => setShowRevenueDetailsModal(false)}
          selectedMonth={selectedMonth}
          selectedMetric={selectedMetric}
          viewAsARR={isARR}
          setViewAsARR={setIsARR}
          committedRevenue={isCommitted}
          optimisticAnalytics={optimisticAnalytics}
          setOptimisticAnalytics={setOptimisticAnalytics}
          selectedUpsellType={selectedUpsellType}
          summaryStartMonth={summaryStartMonth}
          summaryColumnHeader={summaryColumnHeader}
        />
      )}

      {showBookingsModal && (
        <BookingsModal
          closeModal={() => setShowBookingsModal(false)}
          bookingStartDate={bookingStart}
          bookingEndDate={bookingEnd}
          bookingsValue={bookingsValue}
          bookingIsRecurring={bookingIsRecurring}
          setShowBookingsModal={setShowBookingsModal}
        />
      )}

      {showUpForRenewalModal && (
        <UpForRenewalModal
          organization={organizations[0]}
          closeModal={() => setShowUpForRenewalModal(false)}
          selectedMonth={selectedMonth}
          setShowBookingsModal={setShowUpForRenewalModal}
          isARR={isARR}
          summaryStartMonth={summaryStartMonth}
          summaryColumnHeader={summaryColumnHeader}
        />
      )}

      {showExplainModal && <ExplainModal value={dataForExplainModal} closeModal={() => setShowExplainModal(false)} />}
    </>
  );
};

export { SnapshotTable };
