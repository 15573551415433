import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { useOrganizationCountsAPI } from 'api/organizations';
import { AlertTriangleIcon } from 'components/Icons';
import { TRANSACTIONS_PAGES_ROUTES } from 'views/Transactions';
import { TRANSACTIONS_ALERTS_PAGE_TABS } from 'views/Transactions/TransactionsAlertsPage/consts';
import { PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';
import { PopoverLinkContentsWrapper, Wrapper } from '../SubLinkPopovers/BillingConfigSubLinksPopover';
import { ProblemsCounter } from '../styles';
import { useMVP } from 'utils/hooks';

export const TransactionAlerts = ({ title, togglePortal }) => {
  const { trackEvent } = useAnalytics();
  const { orgId } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const isMVP = useMVP();

  const { data } = useOrganizationCountsAPI({
    orgId,
    scopes: ['unresolvedExternalUpdates', 'unconfirmedTransactions', 'aiTransactionsDescrepencies'],
  });

  const unresolvedExternalUpdatesCount = data?.unresolvedExternalUpdates ?? 0;
  const unconfirmedTransactionsCount = data?.unconfirmedTransactions ?? 0;
  const aiTransactionsDescrepenciesCount = data?.aiTransactionsDescrepencies ?? 0;
  const needReviewCount =
    unresolvedExternalUpdatesCount + unconfirmedTransactionsCount + aiTransactionsDescrepenciesCount;

  if (needReviewCount === 0) {
    return null;
  }

  return (
    <Wrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          titlePrefix: <AlertTriangleIcon fill="#FE5151" />,
          title: 'Transactions Alerts',
          description: 'Confirm new data or external updates',
          titleSuffix: (
            <ProblemsCounter data-cy="transactions-alerts-popover__counter">{needReviewCount}</ProblemsCounter>
          ),
          to: isMVP
            ? {
                pathname: '/transactions/alerts',
              }
            : {
                pathname: '/transactions',
                state: { transactionsConfirmation: 'To be confirmed' },
              },
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_TRANSACTIONS_ALERTS });
        }}
        highlighted
      />

      {isOpen && (
        <PopoverLinkContentsWrapper width="300px" top="-30px" left="246px">
          <PopoverLinkContents title="Transaction alerts" minWidth="100%">
            {unconfirmedTransactionsCount > 0 && (
              <PopoverLinkContentsItem
                title={title}
                item={{
                  title: 'Unconfirmed transactions',
                  titleSuffix: (
                    <ProblemsCounter data-cy="transactions-missing-details-popover__counter">
                      {unconfirmedTransactionsCount}
                    </ProblemsCounter>
                  ),
                  to: {
                    pathname: '/transactions',
                    state: { transactionsConfirmation: 'To be confirmed' },
                  },
                }}
                onClick={togglePortal}
              />
            )}

            {aiTransactionsDescrepenciesCount > 0 && (
              <PopoverLinkContentsItem
                title={title}
                item={{
                  title: 'Differing from their contracts',
                  titleSuffix: (
                    <ProblemsCounter data-cy="transactions-ai-descrepencies-popover__counter">
                      {aiTransactionsDescrepenciesCount}
                    </ProblemsCounter>
                  ),
                  to: `${TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_ALERTS}?tab=${TRANSACTIONS_ALERTS_PAGE_TABS.AI_DESCREPENCIES}`,
                }}
                onClick={togglePortal}
              />
            )}

            {unresolvedExternalUpdatesCount > 0 && (
              <PopoverLinkContentsItem
                title={title}
                item={{
                  title: 'External Updates',
                  titleSuffix: (
                    <ProblemsCounter data-cy="transactions-external-updates-popover__counter">
                      {unresolvedExternalUpdatesCount}
                    </ProblemsCounter>
                  ),
                  to: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_UPDATES,
                }}
              />
            )}
          </PopoverLinkContents>
        </PopoverLinkContentsWrapper>
      )}
    </Wrapper>
  );
};
