import React from 'react';
import { PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';
import { TransactionAlerts } from './TransactionAlerts';

export const TransactionLinksPopover = ({ togglePortal }) => {
  const title = 'Transactions';

  return (
    <PopoverLinkContents title={title} minWidth="258px">
      <TransactionAlerts title={title} togglePortal={togglePortal} />

      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'All transactions',
          description: 'Manage all your transactions',
          to: {
            pathname: '/transactions',
            state: { transactionsConfirmation: 'All transactions' },
          },
        }}
        onClick={togglePortal}
      />

      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'All Contracts',
          description: 'Manage all your contracts',
          to: {
            pathname: '/contracts',
          },
        }}
        onClick={togglePortal}
      />

      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Contract PDFs',
          description: 'Upload contract PDF files',
          to: {
            pathname: '/contracts/pdfs',
          },
        }}
        onClick={togglePortal}
      />
    </PopoverLinkContents>
  );
};
