import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { ORG_CONFIGS } from 'consts/global';
import { capitalize, humanize } from 'utils/stringUtils';
import { BILLING_CUSTOM_FIELDS_VARIABLES_TYPE, BILLING_CUSTOM_FIELD_OPTIONS } from 'consts/billing';
import { useInvoiceCustomFields } from 'api/integrations/hooks';
import { Centerer, FlexBetweenContainer, Flexer } from 'components/Core';
import { BinIcon, EditCircleIcon } from 'components/Icons';
import { SERVICE_WITH_CUSTOM_FIELDS } from 'views/Billing/consts';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { HeaderLine, HeaderName, ItemCount, ItemsHeader } from 'views/Wizard/steps/SubscriptionData/styles';
import { AddButton, ItemName } from '../../styles';
import { METADATA_FIELD_TYPE, metadataToOptions } from '../utils';
import useDeleteFieldModal from './useDeleteFieldModal';
import useUpsertFieldModal from './useUpsertFieldModal';

const BillingCustomFields = ({ values, setFieldValue }) => {
  const {
    orgId,
    orgConfigs: { transactionCustomFields, customerCustomFields, productCustomFields },
  } = useContext(AppContext);

  const { data = [] } = useInvoiceCustomFields({ orgId, integrationId: values.id });
  const glCustomFields = data?.reduce((acc, customField) => {
    acc[customField.id] = `${customField.name} (${humanize(values.display_name)} custom field${
      customField.active ? '' : ' - inactive'
    })`;
    return acc;
  }, {});

  const billingCustomFieldOptions = [
    ...BILLING_CUSTOM_FIELD_OPTIONS,
    ...metadataToOptions({
      metadataFields: Object.keys(transactionCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.TRANSACTION,
    }),
    ...metadataToOptions({
      metadataFields: Object.keys(customerCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.CUSTOMER,
    }),
    // NOTE: transaction_customer means "when billing using parent customer, use this custom field from the transaction customer instead"
    ...metadataToOptions({
      metadataFields: Object.keys(customerCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.TRANSACTION_CUSTOMER,
    }),
    ...metadataToOptions({
      metadataFields: Object.keys(productCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.PRODUCT,
    }),
  ];

  const { Modal: UpsertFieldModal, openModal: openUpsertFieldModal } = useUpsertFieldModal({
    glCustomFields,
    billingCustomFieldOptions,
    onUpsert: ({ glField, subscriptField }) => {
      const updatedFields = { ...values.configs[ORG_CONFIGS.BILLING_CUSTOM_FIELDS] };
      updatedFields[glField] = subscriptField;
      setFieldValue(`configs.${ORG_CONFIGS.BILLING_CUSTOM_FIELDS}`, updatedFields);
    },
  });

  const { Modal: DeleteFieldModal, openModal: openDeleteFieldModal } = useDeleteFieldModal({
    onDelete: ({ glField }) => {
      const updatedFields = { ...values.configs[ORG_CONFIGS.BILLING_CUSTOM_FIELDS] };
      delete updatedFields[glField];
      setFieldValue(`configs.${ORG_CONFIGS.BILLING_CUSTOM_FIELDS}`, updatedFields);
    },
  });

  return (
    <FieldBody>
      <FlexBetweenContainer centerer marginBottom="24px">
        <FieldTitle noMarginBottom>Manage custom fields</FieldTitle>
        <AddButton onClick={openUpsertFieldModal}>Add Custom Field</AddButton>
      </FlexBetweenContainer>

      <ItemsHeader>
        <HeaderLine />
        <FlexBetweenContainer width="100%">
          <HeaderName style={{ marginRight: '42px' }}>Name</HeaderName>
          <HeaderName>Field Name in your GL</HeaderName>
          <HeaderName style={{ marginRight: '120px' }}>Type</HeaderName>
        </FlexBetweenContainer>
      </ItemsHeader>

      {Object.entries(values.configs[ORG_CONFIGS.BILLING_CUSTOM_FIELDS]).map(([glField, subscriptField], index) => {
        const glFieldLabel = glCustomFields?.[glField] ?? glField;
        const subscriptFieldLabel =
          billingCustomFieldOptions.find(({ value }) => value === subscriptField)?.label ?? subscriptField;

        const handleOpenCustomField = () => {
          openUpsertFieldModal({ glField, glFieldLabel, subscriptField, subscriptFieldLabel });
        };
        const handleDeleteCustomField = () => {
          openDeleteFieldModal({ glField, glFieldLabel, subscriptField, subscriptFieldLabel });
        };

        return (
          <Flexer key={subscriptField} width="100%" marginTop="20px" alignItems="center">
            <ItemName
              width="270px"
              fontWeight="700"
              padding="0px 0px 0px 34px"
              cursor="pointer"
              color="var(--primaryBlue)"
              onClick={handleOpenCustomField}
            >
              <Flexer gap="8px" alignItems="center" justifyContent="flex-start">
                <ItemCount>{index + 1}</ItemCount>
                {subscriptFieldLabel}
              </Flexer>
            </ItemName>
            <ItemName flex="1">{glFieldLabel}</ItemName>
            <ItemName width="110px">
              {capitalize(BILLING_CUSTOM_FIELDS_VARIABLES_TYPE[subscriptField] ?? 'text')}
            </ItemName>

            <Centerer gap="8px">
              <EditCircleIcon style={{ cursor: 'pointer' }} onClick={handleOpenCustomField} />
              <BinIcon style={{ cursor: 'pointer' }} onClick={handleDeleteCustomField} />
            </Centerer>
          </Flexer>
        );
      })}

      <UpsertFieldModal />
      <DeleteFieldModal />
    </FieldBody>
  );
};

// With this wrapper we ensure that the component is only rendered for services that support custom fields
const WrappedBillingCustomFields = ({ service, values, setFieldValue }) => {
  return !SERVICE_WITH_CUSTOM_FIELDS.includes(service) ? null : (
    <BillingCustomFields values={values} setFieldValue={setFieldValue} />
  );
};

export { WrappedBillingCustomFields as BillingCustomFields };
