import { useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { Modal, ModalBody, ModalButton, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { ReactComponent as ArrowRightCircleWhiteIcon } from 'images/arrow-right-circle-white.svg';
import { FlexEndContainer, FlexerRow } from 'components/Core';
import { pluralize } from 'utils/stringUtils';
import { groupBy } from 'utils/arrayUtils';
import { useStateWithStorage } from 'utils/hooks';
import {
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY,
  INVOICE_DATE_REFERENCE_OPTIONS,
  INVOICE_DATE_RELATIVE_OPTIONS,
  INVOICING_FREQUENCIES,
} from 'views/Billing/consts';
import { usePastAndFutureInvoiceOptions } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleTabsPanel/PastAndFutureInvoiceOptions';
import { RECOGNITION_TYPES } from 'consts/global';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { BulkCreateSettings } from './BulkCreateSettings';
import { areSelectedTransactionsEligibleForBulkCreation } from './utils';
import { SchedulesCount } from './BulkCreateSettings.styles';

export const CREATE_EMPTY_EVENTS_TYPE = {
  ALWAYS: 'always',
  WITH_USED_EVENTS: 'with_used_events',
};

export const BulkCreateInvoicesModal = ({
  allTransactionsSelected,
  totalTransactionsCount,
  transactionsSelected,
  billingInvoiceDefaults,
  transactionsWithProducts,
  onBulkCreateClick,
  waitingOnBulkCreate,
  pageSize,
  onClose,
  transactionsById,
  setTransactionsSelected,
  handleAddAdditionalTransactions,
}) => {
  const { orgConfigs, integrations, entities } = useContext(AppContext);
  const { billingSenderDefaults } = orgConfigs;

  const glIntegrations = integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL);

  const [defaultMemoTemplateId, setDefaultMemoTemplateId] = useState();
  const [paymentOptions, setPaymentOptions] = useStateWithStorage(
    'bulk-create-invoices-modal__payment-options',
    billingInvoiceDefaults?.payment_options ?? [],
  );
  const [frequency, setFrequency] = useStateWithStorage(
    'bulk-create-invoices-modal__frequency',
    billingInvoiceDefaults?.frequency ?? INVOICING_FREQUENCIES.MONTHLY,
  );
  const [daysToPay, setDaysToPay] = useStateWithStorage(
    'bulk-create-invoices-modal__days-to-pay',
    billingInvoiceDefaults.days_to_pay,
  );
  const [autoSend, setAutoSend] = useStateWithStorage('bulk-create-invoices-modal__auto-send', false);
  const [billInArrears, setBillInArrears] = useStateWithStorage('bulk-create-invoices-modal__bill-in-arrears', false);
  const [invoiceDateRelative, setInvoiceDateRelative] = useStateWithStorage(
    'bulk-create-invoices-modal__invoice-date-relative',
    INVOICE_DATE_RELATIVE_OPTIONS.EQUAL_TO,
  );
  const [invoiceDateReference, setInvoiceDateReference] = useStateWithStorage(
    'bulk-create-invoices-modal__invoice-date-reference',
    INVOICE_DATE_REFERENCE_OPTIONS.TRANSACTION_START_DATE,
  );
  const [daysFromDate, setDaysFromDate] = useStateWithStorage(
    'bulk-create-invoices-modal__days-from-date',
    billingInvoiceDefaults.days_from_date,
  );
  const [invoicingSchedules, setInvoicingSchedules] = useState([]);
  const [memo, setMemo] = useStateWithStorage('bulk-create-invoices-modal__memo', '');
  const [useMappingMemo, setUseMappingMemo] = useStateWithStorage(
    'bulk-create-invoices-modal__use-mapping-memo',
    false,
  );
  const [groupTransactionsBy, setGroupTransactionsBy] = useStateWithStorage(
    'bulk-create-invoices-modal__group-transactions-by',
    billingInvoiceDefaults?.group_transactions_by ??
      GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER],
  );
  const [selectedIntegrationId, setSelectedIntegrationId] = useStateWithStorage(
    'bulk-create-invoices-modal__integration-id',
    glIntegrations?.[0]?.id,
    false,
    {
      setStorageValue: (value) =>
        glIntegrations?.some((integration) => integration.id === value?.toString()) ? value : glIntegrations?.[0]?.id,
    },
  );
  const [entityId, setEntityId] = useStateWithStorage(
    'bulk-create-invoices-modal__entity-id',
    billingSenderDefaults.default_entity_id?.toString() ?? entities[0]?.id,
    {
      setStorageValue: (value) =>
        entities?.some((entity) => entity.id === value?.toString())
          ? value
          : billingSenderDefaults.default_entity_id?.toString() ?? entities[0]?.id,
    },
  );
  const [createEmptyEvents, setCreateEmptyEvents] = useStateWithStorage(
    'bulk-create-invoices-modal__create-empty-events',
    false,
  );
  const [createEmptyEventsType, setCreateEmptyEventsType] = useStateWithStorage(
    'bulk-create-invoices-modal__create-empty-events-type',
    CREATE_EMPTY_EVENTS_TYPE.ALWAYS,
  );
  const [useParentCustomer, setUseParentCustomer] = useState(false);

  const transactionById = groupBy(transactionsWithProducts, 'id', { uniqueness: true });

  const {
    PastAndFutureInvoiceOptions,
    onlyFutureInvoices,
    allowPastInvoices,
    hasInvoicesThatStartInThePast,
    cutOffDate,
  } = usePastAndFutureInvoiceOptions({
    includedTransactions: Array.from(transactionsSelected).reduce((total, id) => {
      if (transactionById[id]) total.push(transactionById[id]);
      return total;
    }, []),
  });

  const [prorateInvoices, setProrateInvoices] = useState(false);

  const canBulkCreate = useMemo(
    () =>
      areSelectedTransactionsEligibleForBulkCreation({
        transactionIds: transactionsSelected,
        transactionsWithProducts,
      }),
    [transactionsSelected, transactionsWithProducts],
  );

  const allTransactionsAreLinearOrImmediate = useMemo(
    () =>
      Array.from(transactionsSelected).every((transactionId) => {
        return [RECOGNITION_TYPES.linear, RECOGNITION_TYPES.linearNotRecurring, RECOGNITION_TYPES.immediate].includes(
          transactionById?.[transactionId]?.recognition,
        );
      }),
    [transactionsSelected, transactionById],
  );

  return (
    <ModalContainer data-cy="bulk-create-invoices-modal">
      <Modal minHeight="570px" padding={4} height="auto" width="960px" minWidth="960px">
        <ModalBody paddingLeft={0} paddingRight={0}>
          <ModalCloseIcon onClose={onClose} data-cy="bulk-create-invoices-modal__close-button" />

          <BulkCreateSettings
            allTransactionsSelected={allTransactionsSelected}
            totalTransactionsCount={totalTransactionsCount}
            transactionsSelected={transactionsSelected}
            setTransactionsSelected={setTransactionsSelected}
            transactionsWithProducts={transactionsWithProducts}
            canBulkCreate={canBulkCreate}
            setDefaultMemoTemplateId={setDefaultMemoTemplateId}
            setMemo={setMemo}
            memo={memo}
            pageSize={pageSize}
            setUseMappingMemo={setUseMappingMemo}
            useMappingMemo={useMappingMemo}
            setPaymentOptions={setPaymentOptions}
            paymentOptions={paymentOptions}
            setDaysToPay={setDaysToPay}
            daysToPay={daysToPay}
            setAutoSend={setAutoSend}
            autoSend={autoSend}
            setFrequency={setFrequency}
            frequency={frequency}
            setBillInArrears={setBillInArrears}
            billInArrears={billInArrears}
            waitingOnBulkCreate={waitingOnBulkCreate}
            setInvoiceDateRelative={setInvoiceDateRelative}
            invoiceDateRelative={invoiceDateRelative}
            setInvoiceDateReference={setInvoiceDateReference}
            invoiceDateReference={invoiceDateReference}
            setGroupTransactionsBy={setGroupTransactionsBy}
            groupTransactionsBy={groupTransactionsBy}
            onlyFutureInvoices={onlyFutureInvoices}
            allowPastInvoices={allowPastInvoices}
            entityId={entityId}
            setEntityId={setEntityId}
            selectedIntegrationId={selectedIntegrationId}
            setSelectedIntegrationId={setSelectedIntegrationId}
            allTransactionsAreLinearOrImmediate={allTransactionsAreLinearOrImmediate}
            daysFromDate={daysFromDate}
            setDaysFromDate={setDaysFromDate}
            hasInvoicesThatStartInThePast={hasInvoicesThatStartInThePast}
            PastAndFutureInvoiceOptions={PastAndFutureInvoiceOptions}
            invoicingSchedules={invoicingSchedules}
            setInvoicingSchedules={setInvoicingSchedules}
            useParentCustomer={useParentCustomer}
            setUseParentCustomer={setUseParentCustomer}
            prorateInvoices={prorateInvoices}
            setProrateInvoices={setProrateInvoices}
            transactionsById={transactionsById}
            handleAddAdditionalTransactions={handleAddAdditionalTransactions}
            createEmptyEvents={createEmptyEvents}
            setCreateEmptyEvents={setCreateEmptyEvents}
            createEmptyEventsType={createEmptyEventsType}
            setCreateEmptyEventsType={setCreateEmptyEventsType}
            cutOffDate={cutOffDate}
          />
        </ModalBody>

        <ModalFooter
          padding="12px 36px"
          noFixedHeight
          style={{ backgroundColor: 'var(--primaryBlack2)', borderTop: '1px solid var(--primaryBlack5)' }}
        >
          <FlexerRow justifyContent="space-between" alignItems="center">
            <SchedulesCount>
              {allTransactionsSelected
                ? `schedules for ${pluralize(totalTransactionsCount, 'transaction')}`
                : pluralize(invoicingSchedules.length, 'New inv. schedule')}

              {}
            </SchedulesCount>
            <FlexEndContainer>
              <ModalButton data-cy="bulk-create-invoices-modal__cancel-button" onClick={onClose}>
                Cancel
              </ModalButton>

              <ModalButton
                fontWeight="700"
                disabled={!canBulkCreate || waitingOnBulkCreate || !invoicingSchedules?.length}
                className="primary"
                onClick={() =>
                  onBulkCreateClick({
                    paymentOptions,
                    daysToPay,
                    autoSend,
                    frequency,
                    billInArrears,
                    invoiceDateRelative:
                      allTransactionsAreLinearOrImmediate && !billInArrears ? invoiceDateRelative : null,
                    invoiceDateReference:
                      allTransactionsAreLinearOrImmediate && !billInArrears ? invoiceDateReference : null,
                    daysFromDate:
                      invoiceDateRelative === INVOICE_DATE_RELATIVE_OPTIONS.EQUAL_TO && !billInArrears
                        ? null
                        : daysFromDate,
                    groupTransactionsBy,
                    allowPastInvoices,
                    onlyFutureInvoices,
                    prorateInvoices,
                    integrationId: selectedIntegrationId,
                    entityId,
                    memo,
                    defaultMemoTemplateId,
                    useParentCustomer,
                    useMappingMemo,
                    includeEmptyEventItems:
                      createEmptyEvents && createEmptyEventsType === CREATE_EMPTY_EVENTS_TYPE.ALWAYS,
                    includeEmptyItemsWithNonzeroEvents:
                      createEmptyEvents && createEmptyEventsType === CREATE_EMPTY_EVENTS_TYPE.WITH_USED_EVENTS,
                    cutOffDate,
                  })
                }
                data-cy="bulk-create-settings__bulk-create-confirm-button"
              >
                <span>{waitingOnBulkCreate ? 'Bulk Creating...' : 'Confirm Creation'}</span>
                <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: '8px' }} />
              </ModalButton>
            </FlexEndContainer>
          </FlexerRow>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
