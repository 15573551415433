import * as Sentry from '@sentry/react';
import { useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MAX_POLLING_COUNT, POLLING_DURATION } from 'consts/global';
import { pollForCompletion } from 'utils/requestUtils';
import { getJobStatus } from 'api/configs/requests';

const JOB_NO_LONGER_EXISTS_ERROR = 'The job no longer exists';

export const useMutationPolling = ({ mutationFn, mutationOptions }) => {
  const { onProgress, onFailed, onMaxAttempts, customMaxAttempts, ...originalMutationOptions } = mutationOptions;

  const pollingMutation = useMutation(async (params) => {
    const { orgId, jobId, ...rest } = await mutationFn(params);
    return jobId
      ? await pollForCompletion({
          pollFn: () => getJobStatus({ orgId, jobId }),
          onProgress,
          onFailed,
          onMaxAttempts,
          customMaxAttempts,
        })
      : { orgId, ...rest };
  }, originalMutationOptions);

  return pollingMutation;
};

// Use this if calling a mutation on an endpoint that delegates to job
export const useDelegatedMutationPolling = ({ mutationFn, mutationOptions }) => {
  const { onProgress, onFailed, onMaxAttempts, customMaxAttempts, ...originalMutationOptions } = mutationOptions;

  const pollingMutation = useMutation(async (args) => {
    const { id } = await mutationFn(args);
    const returnedData = await pollForCompletion({
      pollFn: () => mutationFn({ ...args, params: { ...args.params, jobId: id } }),
      onProgress,
      onFailed,
      onMaxAttempts,
      customMaxAttempts,
    });

    return returnedData;
  }, originalMutationOptions);

  return pollingMutation;
};

// Use this to query from an endpoint that delegates to a background job.
// In the first request, we send the whole request body to the endpoint, so that the endpoint can create a new
//  background job. The endpoint likely returns a job ID.
// In subsequent requests, we send just the job ID to check if it's now completed.
// We keep trying until MAX_POLLING_COUNT is reached.
export const useQueryPolling = ({
  key,
  jobId: initialJobId,
  fetchFn,
  fetchWithJobIdFn,
  queryOptions,
  pollingErrorMessage,
  alwaysReturnData = false,
}) => {
  const pollingCount = useRef(0);
  const queryClient = useQueryClient();
  const jobId = useRef(initialJobId);

  const { data: jobResult, error, isLoading, isFetching, refetch } = useQuery(
    key,
    async () => {
      const cachedResult = queryClient.getQueryData(key);
      const trueJobId = initialJobId ?? jobId.current;

      let result;

      try {
        result = trueJobId && fetchWithJobIdFn ? await fetchWithJobIdFn(trueJobId) : await fetchFn();
      } catch (err) {
        // if the job no longer exists, let's just reset the job id to retry with same params in case the job has finished but the response was lost
        if (err.response?.status === 404 && err.response?.data === JOB_NO_LONGER_EXISTS_ERROR) {
          pollingCount.current = 0;
          jobId.current = null;
          return { state: 'waiting', data: cachedResult?.data };
        } else {
          throw err;
        }
      }

      const { id, state, data } = result ?? {};
      const waitingForJob = ['active', 'waiting'].includes(state);

      // Actually returned data/state depends on the job state
      const returnedData = waitingForJob && !alwaysReturnData ? cachedResult?.data : data;
      const returnedState = waitingForJob && cachedResult?.data ? 'cached' : state;

      if (waitingForJob) {
        pollingCount.current = pollingCount.current + 1;
        jobId.current = id;
      } else {
        pollingCount.current = 0;
        jobId.current = null;
      }

      if (pollingCount >= MAX_POLLING_COUNT && pollingErrorMessage) {
        Sentry.captureMessage(pollingErrorMessage, 'error');
        pollingCount.current = 0;
        jobId.current = null;
      }

      return { id, state: returnedState, data: returnedData };
    },
    {
      enabled: initialJobId === undefined ? true : !!initialJobId,
      refetchInterval: (response, query) =>
        !query.state.error && ['waiting', 'active', 'cached'].includes(response?.state) ? POLLING_DURATION : undefined,
      ...queryOptions,
    },
  );

  return {
    data: alwaysReturnData || !['active', 'waiting'].includes(jobResult?.state) ? jobResult?.data : undefined,
    error,
    isLoading: isLoading || ['active', 'waiting'].includes(jobResult?.state),
    isFetching,
    refetch: () => {
      pollingCount.current = 0;
      jobId.current = null;
      refetch();
    },
  };
};
