import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { TooltipContainer } from 'components/Tooltip';
import { SPREADS_PALLETS } from './utils';

const TableDataCell = styled.div`
  width: 90px;
  border-radius: 3px;
  padding: ${({ padding }) => padding ?? '8px 12px'};
  height: 47px;
  font-size: ${({ primary }) => primary && '12px'};
  display: flex;
  align-items: center;
  color: ${({ whiteText }) => whiteText && 'white'};
  justify-content: ${({ left }) => (left ? 'start' : 'end')};
  font-weight: ${({ bold }) => (bold ? '700' : 'normal')};
  font-family: 'Nunito Sans';
  border-collapse: collapse;
  ${({ primary }) => primary && 'border: solid 1px white;'}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  white-space: nowrap;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'default')};

  ${({ isMoneyColumn }) =>
    isMoneyColumn &&
    `
    text-align: right;
    width: 110px;
    display: flex;
    justify-content: flex-end;
  `}
`;

const TableTile = (props) => {
  const {
    value,
    primary,
    padding,
    left,
    datacyProp,
    heatLevel = -1,
    styles = {},
    bold,
    className,
    fluctuate,
    toolTipText,
    palette = SPREADS_PALLETS.MONOCHROMIC,
    showPointer = false,
    ...rest
  } = props;

  const getBackgroundByHeatLevel = () => {
    const levels =
      palette === SPREADS_PALLETS.COLORFUL
        ? {
            0: '#FF5757',
            1: '#FFD25F',
            2: '#FFE818',
            3: '#00BC40',
          }
        : {
            0: '#EFFBFF',
            1: '#C8F2FF',
            2: '#4BC2F5',
            3: '#0578FF',
          };

    return levels[heatLevel] || '#F5F5F5';
  };

  const getFluctuationIcon = () => {
    return (
      {
        down: <FontAwesomeIcon icon={faSortDown} color="red" />,
        up: <FontAwesomeIcon icon={faSortUp} color="green" />,
        neutral: <FontAwesomeIcon icon={faCircle} style={{ fontSize: 8, color: `rgb(0, 21, 46,0.2)` }} />,
      }[fluctuate] || ''
    );
  };

  return (
    <TableDataCell
      data-cy={datacyProp}
      left={left}
      bold={bold}
      padding={padding}
      primary={primary}
      whiteText={primary && heatLevel > 2}
      backgroundColor={primary && getBackgroundByHeatLevel(heatLevel)}
      className={className ? `table-data ${className}` : 'table-data'}
      styles={styles}
      showPointer={showPointer}
      {...rest}
    >
      <TooltipContainer width={250} fontSize="12px" isVisible={!!toolTipText} toolTipContent={toolTipText}>
        {value}&nbsp;&nbsp;&nbsp;{getFluctuationIcon()}
      </TooltipContainer>
    </TableDataCell>
  );
};

export default TableTile;
