import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStateWithStorage } from 'utils/hooks';
import { Header } from 'shared/Layout';
import { HeaderTitle } from 'shared/Layout';
import { LinkBackButton } from 'components/Buttons';
import { Flexer, FlexerColumn, Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { TRANSACTIONS_ALERTS_PAGE_TABS } from './consts';
import { TransactionsAlertsPageTabs } from './TransactionsAlertsPageTabs';
import { AIDescrepanciesTab } from './AIDecrepanciesTab';
import { UnconfirmedTransactionsTab } from './UnconfirmedTransactionsTab';
import { ExternalUpdatesTab, ExternalUpdatesTabLoader } from './ExternalUpdatesTab';

export const TransactionsAlertsPage = () => {
  const history = useHistory();
  const [isLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingTooltipContent, setFetchingTooltipContent] = useStateWithStorage(
    'external-updates-fetching-tooltip-content',
    null,
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') || TRANSACTIONS_ALERTS_PAGE_TABS.UNCONFIRMED_TRANSACTIONS;

  return (
    <FlexerColumn width="100%" height="100%">
      <Header
        activePage="transactions"
        headerTitle="Transaction Alerts"
        headerLeft={
          <Centerer>
            <LinkBackButton to="" onClick={history.goBack} data-cy="alerts-page__back-button">
              Back
            </LinkBackButton>
            <HeaderTitle>Transaction Alerts</HeaderTitle>
          </Centerer>
        }
        headerRight={
          <ExternalUpdatesTabLoader isFetching={isFetching} fetchingTooltipContent={fetchingTooltipContent} />
        }
      />

      <Flexer width="100%" height="100%">
        {isLoading ? (
          <CircleLoader name="transactions-alerts-page-loading" />
        ) : (
          <FlexerColumn width="100%">
            <TransactionsAlertsPageTabs />

            {currentTab === TRANSACTIONS_ALERTS_PAGE_TABS.UNCONFIRMED_TRANSACTIONS && <UnconfirmedTransactionsTab />}
            {currentTab === TRANSACTIONS_ALERTS_PAGE_TABS.AI_DESCREPENCIES && <AIDescrepanciesTab />}
            {currentTab === TRANSACTIONS_ALERTS_PAGE_TABS.EXTERNAL_UPDATES && (
              <ExternalUpdatesTab setIsFetching={setIsFetching} setFetchingTooltipContent={setFetchingTooltipContent} />
            )}
          </FlexerColumn>
        )}
      </Flexer>
    </FlexerColumn>
  );
};
