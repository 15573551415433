import { ReactComponent as RevenueIcon } from 'images/icon_recurring_revenue_green.svg';
import { ReactComponent as ARPAIcon } from 'images/arpa-icon.svg';
import { ReactComponent as CustomersIcon } from 'images/icon_customers.svg';
import { ReactComponent as RetentionIcon } from 'images/gross-customers-retention-icon.svg';
import { ReactComponent as LTVIcon } from 'images/ltv-icon.svg';
import { ReactComponent as SeatsIcon } from 'images/seats-icon.svg';

const DASHBOARD_CARDS_ICON_NAMES = {
  REVENUE: 'revenue',
  ARPA: 'arpa',
  CUSTOMERS: 'customers',
  RETENTION: 'retention',
  LTV: 'ltv',
  SEATS: 'seats',
};

const DashboardCardIcon = ({ name, ...rest }) => {
  switch (name) {
    case DASHBOARD_CARDS_ICON_NAMES.REVENUE:
      return <RevenueIcon {...rest} />;
    case DASHBOARD_CARDS_ICON_NAMES.ARPA:
      return <ARPAIcon {...rest} />;
    case DASHBOARD_CARDS_ICON_NAMES.CUSTOMERS:
      return <CustomersIcon {...rest} />;
    case DASHBOARD_CARDS_ICON_NAMES.RETENTION:
      return <RetentionIcon {...rest} />;
    case DASHBOARD_CARDS_ICON_NAMES.LTV:
      return <LTVIcon {...rest} />;
    case DASHBOARD_CARDS_ICON_NAMES.SEATS:
      return <SeatsIcon {...rest} />;
    default:
      return null;
  }
};

export { DashboardCardIcon, DASHBOARD_CARDS_ICON_NAMES };
