import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { CentererVertical, Flexer, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { REPORT_OPTIONS_ICONS, REPORT_OPTIONS_SELECTOR } from './consts';
import { InputName, PageSelector } from './styles';

const StyledLabel = styled(CentererVertical)`
  svg {
    width: 14px;
    height: 14px;

    path {
      fill: var(--primaryGreen);
    }
  }
`;

const { SingleValue, Option } = components;

const Label = (props) => {
  const Icon = REPORT_OPTIONS_ICONS[props.data.value];
  return (
    <StyledLabel gap="7px">
      <Icon width={14} height={14} active={true} />
      {props.data.label}
    </StyledLabel>
  );
};

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <Label {...props} />
  </SingleValue>
);

const IconSingleOption = (props) => (
  <Option {...props}>
    <Label {...props} />
  </Option>
);

export const ReportSelector = ({ disabled, showDefaultDashboardOption = false }) => {
  return (
    <Flexer>
      <PageSelector
        name="type"
        label="Page"
        options={REPORT_OPTIONS_SELECTOR}
        components={{ Option: IconSingleOption, SingleValue: IconSingleValue }}
        isDisabled={disabled}
      />
      <Spacer width={!showDefaultDashboardOption ? '30px' : '20px'} />
      <InputName name="title" placeholder="Enter name..." label="Name" />
      {showDefaultDashboardOption && (
        <>
          <Spacer width="20px" />
          <FormikCustomSelector
            name="default"
            label="Default dashboard?"
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
          />
        </>
      )}
    </Flexer>
  );
};
