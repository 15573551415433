import { useCallback, useContext } from 'react';

import { AppContext } from 'AppContext';
import { searchResources } from 'api/billing/requests';
import { FormikCustomSelector } from 'components/Controls';

export const IntegrationProductSelector = ({
  name,
  existingImports = [],
  integrationId,
  productImportName,
  productImportProviderId,
  handleChange,
  label,
  openRemoveImportConfirmModal,
  ...props
}) => {
  const { orgId } = useContext(AppContext);

  const getIntegrationProductsFromSearch = useCallback(
    async ({ searchQuery, integrationId }) => {
      const products = await searchResources({
        params: { searchQuery, resource: 'product', integrationId },
        orgId,
      });
      return (products ?? []).map(({ name, id, ...rest }) => ({
        label: `${name || '<Unknown Name>'} / ${id || ''}`,
        value: { id, name, ...rest },
      }));
    },
    [orgId],
  );
  const loadOptions = (props) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(getIntegrationProductsFromSearch(props));
      }, 250);
    });

  const clearInput = () => {
    handleChange({
      providerId: null,
      name: null,
      code: null,
    });
  };

  const onRemoveProductImport = () => {
    if (existingImports?.length > 0) {
      const existingImport = existingImports[0];
      openRemoveImportConfirmModal({
        integrationDisplayName: existingImport.metadata?.product_name,
        importId: existingImport.id,
        onDelete: clearInput,
      });
    } else {
      clearInput();
    }
  };

  const handleInputChange = (option) => {
    if (option) {
      // user selected a new import
      handleChange({
        providerId: option?.value?.id ?? null,
        name: option?.value?.name ?? null,
        code: option?.value?.code ?? null,
      });
    } else {
      // user clicked the clear button
      onRemoveProductImport();
    }
  };

  return (
    <FormikCustomSelector
      name={name}
      value={
        productImportProviderId
          ? {
              label: `${productImportName || '<No Name>'} / ${productImportProviderId}`,
              value: {
                id: productImportProviderId,
                name: productImportName,
              },
            }
          : null
      }
      options={existingImports
        .filter((existingImport) => existingImport.integration_id === integrationId)
        .map((existingImport) => ({
          label: `${existingImport.metadata?.product_name} / ${existingImport.provider_object_id}`,
          value: { id: existingImport.provider_object_id, name: existingImport.metadata?.product_name },
        }))}
      label={label ?? 'General Ledger Product'}
      handleChange={handleInputChange}
      boldValue
      loadOptions={(searchQuery) => loadOptions({ searchQuery, integrationId })}
      containerWidth="100%"
      isDisabled={!integrationId}
      placeholder="Type to search..."
      isClearable
      {...props}
    />
  );
};
