import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/pencil-new.svg';

export const PencilNewIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};

  path {
    fill: ${({ fill }) => fill};
  }
`;
