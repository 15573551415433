import { WandBoldIcon } from 'components/Icons';
import styled from 'styled-components';

const BoldText = styled.div`
  color: var(--primaryBlack);
  font-size: 13px;
  font-style: italic;
  font-weight: 900;
  line-height: 16px;
`;

const Text = styled.span`
  color: var(--primaryBlack50);
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
`;

const AIButtonWrapper = styled.div`
  display: inline-flex;
  height: 40px;
  padding: 12px 12px 12px 6px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  background: var(--light100);
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    border: 1px solid var(--neutralGray);
    background: var(--light100);
    box-shadow: 0px 4px 20px 0px var(--primaryBlack10);

    ${Text} {
      color: var(--primaryBlack);
    }
  }
`;

const WandWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  background: linear-gradient(180deg, #ffc27a 0%, #ff62dd 48%, #6d48ff 100%);
`;

export const AIButton = ({ onClick, mainText, subText }) => {
  return (
    <AIButtonWrapper onClick={onClick}>
      <WandWrapper>
        <WandBoldIcon width={'20px'} height={'20px'} stroke="white" style={{ flexShrink: 0 }} />
      </WandWrapper>
      <BoldText>
        {mainText}
        <Text>{subText}</Text>
      </BoldText>
    </AIButtonWrapper>
  );
};
