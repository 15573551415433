import { useMemo } from 'react';

const { AllRowsSelectorDropdown } = require('components/Table/AllRowsSelectorDropdown');

export const SelectAllTransactionsForReviewCheckbox = ({
  dataForTable,
  selectAllCheckboxXOffset,
  allSelectableTransactionsCount,
  allTransactionsSelected,
  setAllTransactionsSelected,
  transactionsSelected,
  setTransactionsSelected,
}) => {
  const allPageTransactions = useMemo(() => dataForTable?.flatMap(({ subRows }) => subRows), [dataForTable]);

  const allPageTransactionsSelected = useMemo(
    () => transactionsSelected.size > 0 && allPageTransactions.every(({ id }) => transactionsSelected.has(id)),
    [allPageTransactions, transactionsSelected],
  );

  const handleAllPageTransactionsSelectedChange = ({ target: { checked } }) => {
    if (!checked) {
      setTransactionsSelected(new Set());
    } else {
      setTransactionsSelected(new Set(allPageTransactions?.map(({ id }) => id)));
    }
  };

  const handleAllTransactionsSelectedChange = (props) => {
    handleAllPageTransactionsSelectedChange(props);
    setAllTransactionsSelected(props?.target?.checked);
  };

  return (
    <AllRowsSelectorDropdown
      getToggleAllPageRowsSelectedProps={() => ({
        checked: allPageTransactionsSelected,
        indeterminate: transactionsSelected.size > 0 && !allPageTransactionsSelected,
        onChange: handleAllPageTransactionsSelectedChange,
      })}
      getToggleAllRowsSelectedProps={() => ({
        checked: allTransactionsSelected,
        indeterminate: transactionsSelected.size > 0 && !allTransactionsSelected,
        onChange: handleAllTransactionsSelectedChange,
      })}
      page={allPageTransactions}
      rows={Array.from({ length: allSelectableTransactionsCount }).map((_, index) => ({ id: index }))}
      selectAllCheckboxXOffset={selectAllCheckboxXOffset}
    />
  );
};
