import React, { useState } from 'react';
import { ModalListItem, HeaderSubtext, HeaderLeftContent, BigItemsContainer } from 'components/Core';
import { ModalPopover } from 'components/Modal';
import { useClickOutside } from 'utils/hooks';

const OpenedDropdown = ({ options, activeOption, setActiveOption }) => (
  <ModalPopover isInstallByPopOver={true}>
    <BigItemsContainer soloView={true}>
      {options.map((option) => (
        <ModalListItem isActive={activeOption === option} onClick={() => setActiveOption(option)} key={option}>
          {option}
        </ModalListItem>
      ))}
    </BigItemsContainer>
  </ModalPopover>
);

export const HeaderDropdown = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <>
      <HeaderSubtext active={showDropdown} onClick={() => setShowDropdown(!showDropdown)} ref={dropdownRef}>
        <HeaderLeftContent soloView={true}>
          {props.activeOption} {props.children}
        </HeaderLeftContent>
        {showDropdown && <OpenedDropdown {...props} />}
      </HeaderSubtext>
    </>
  );
};
