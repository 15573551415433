import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { cloneDeep } from 'lodash';
import { NUMBER_FORMATS, SEGMENT_BY_OPTIONS } from 'consts/global';
import { RESOLUTION_OPTIONS } from 'shared/Filters';
import { getEndOfPeriodDayJS, periodKeyToDayJS, reFormatDate } from 'utils/dateUtils';
import { ReactComponent as TableArrowDown } from 'images/table_arrow_down.svg';
import { ReactComponent as TableNeutralCircle } from 'images/table_neutral_circle.svg';
import { ReactComponent as TableArrowUp } from 'images/table_arrow_up.svg';
import { INSTALL_BY_OPTIONS, COHORT_PALLETS, COHORTS_DATA_KEYS } from './consts';

dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear); //for quarterly cohorts

export const getResolutionFormats = ({ resolution, quarters, quartersYearOffset }) => {
  let labelPrefix;
  let legendFormatter;
  let getMaxNumberOfLabels;
  switch (resolution) {
    case RESOLUTION_OPTIONS.monthly:
      labelPrefix = 'M';
      legendFormatter = (cohortKey) => dayjs(cohortKey).format('MMM YYYY');
      getMaxNumberOfLabels = (cohortKey) => dayjs().diff(dayjs(cohortKey), 'month');
      break;
    case RESOLUTION_OPTIONS.quarterly:
      labelPrefix = 'Q';
      legendFormatter = (cohortKey) => cohortKey;
      getMaxNumberOfLabels = (cohortKey) =>
        dayjs().diff(
          periodKeyToDayJS({
            periodKey: cohortKey,
            periodType: 'quarter',
            quarters,
            quartersYearOffset,
          }),
          'month',
        ) / 3;
      break;
    case RESOLUTION_OPTIONS.yearly:
      labelPrefix = 'Y';
      legendFormatter = (cohortKey) => cohortKey;
      getMaxNumberOfLabels = (cohortKey) =>
        dayjs().diff(
          periodKeyToDayJS({
            periodKey: cohortKey,
            periodType: 'year',
            quarters,
            quartersYearOffset,
          }),
          'year',
        );
      break;
    default:
  }

  return {
    labelPrefix,
    legendFormatter,
    getMaxNumberOfLabels,
  };
};

const numberOfColumns = (rowData) => {
  // This goes negative if the start date is in the future
  return Math.max(rowData.length, 0);
};

const COL_HEADERS = ['Period', 'Starting', 'Rev. Ret.'];

export const getColumnHeaders = ({ rowData, resolution }) =>
  COL_HEADERS.concat(
    [...Array(numberOfColumns(rowData)).keys()].map(
      (i) =>
        `${
          resolution === RESOLUTION_OPTIONS.yearly
            ? i
              ? 'Y'
              : 'Year'
            : resolution === RESOLUTION_OPTIONS.quarterly
            ? 'Q '
            : i
            ? 'M'
            : 'Month'
        } ${i + 1}`,
    ),
  );

export const getAllSortedValues = (arrayForMonth, installBy) => {
  return arrayForMonth
    .map((object) =>
      parseFloat(
        installBy === INSTALL_BY_OPTIONS.customerCount || installBy === INSTALL_BY_OPTIONS.customerPercent
          ? object.count
          : object.sum,
      ),
    )
    .sort((a, b) => a - b);
};

export const doesRowHaveNoCustomers = ({ arrayForMonth }) => {
  return arrayForMonth.every((object) => !object.count);
};

export const getLTVs = (cohortData) => {
  const LTVs = {};

  Object.keys(cohortData).forEach((cohortTabName) => {
    if (cohortTabName === 'total') {
      LTVs[cohortTabName] = Object.keys(cohortData.total).reduce((acc, month) => {
        const currMonthArray = cohortData[cohortTabName][month];
        if (currMonthArray.length > 1) {
          const sumOfPriorMonths = currMonthArray.reduce((sum, curr) => sum + parseFloat(curr.sum), 0);
          const terminalChurn =
            parseFloat(currMonthArray[currMonthArray.length - 1]['count']) /
            parseFloat(currMonthArray[currMonthArray.length - 2]['count']);
          const terminalValue = parseFloat(currMonthArray[currMonthArray.length - 1]['sum']) / terminalChurn;
          acc[month] = sumOfPriorMonths + terminalValue;
        } else if (currMonthArray.length === 1) {
          acc[month] = null;
        } else {
          acc[month] = 0;
        }
        return acc;
      }, {});
    } else if ((cohortTabName = 'segmented')) {
      LTVs.segmented = {};

      Object.keys(cohortData.segmented).forEach((month) => {
        const segmentedMonthObject = cohortData.segmented[month];
        LTVs.segmented[month] = Object.keys(segmentedMonthObject).reduce((acc, segmentName) => {
          const currMonthArray = cohortData[cohortTabName][month][segmentName];
          if (currMonthArray.length > 1) {
            const sumOfPriorMonths = currMonthArray.reduce((sum, curr) => sum + parseFloat(curr.sum), 0);
            const terminalChurn =
              parseFloat(currMonthArray[currMonthArray.length - 1]['count']) /
              parseFloat(currMonthArray[currMonthArray.length - 2]['count']);
            const terminalValue = parseFloat(currMonthArray[currMonthArray.length - 1]['sum']) / terminalChurn;
            acc[segmentName] = sumOfPriorMonths + terminalValue;
          } else if (currMonthArray.length === 1) {
            acc[segmentName] = null;
          } else {
            acc[segmentName] = 0;
          }
          return acc;
        }, {});
      });
    }
  });

  return LTVs;
};

export const periodTypeFromCohortResolution = (cohortResolution) => {
  switch (cohortResolution) {
    case RESOLUTION_OPTIONS.yearly:
      return 'year';
    case RESOLUTION_OPTIONS.quarterly:
      return 'quarter';
    default:
      return 'month';
  }
};

export const getRevenueRetention = ({ cohortData, cohortResolution, quarters }) => {
  const revenueRetention = {};
  const periodType = periodTypeFromCohortResolution(cohortResolution);
  const currentPeriod = getEndOfPeriodDayJS({ date: dayjs.utc().startOf('month'), periodType, quarters });

  if (cohortData.total) {
    revenueRetention.total = Object.keys(cohortData.total).reduce((acc, cohortDate) => {
      // The backend returns columns into the future, but we only show columns until the current month
      //  so, to get the last value we'll show customers, we need filter out the future months
      const currMonthArray = cohortData.total[cohortDate].filter((o) =>
        dayjs.utc(o.date).startOf('month').isSameOrBefore(currentPeriod),
      );
      if (currMonthArray.length && currMonthArray[0].sum) {
        acc[cohortDate] = currMonthArray[currMonthArray.length - 1].sum / currMonthArray[0].sum;
      } else {
        acc[cohortDate] = 1;
      }
      return acc;
    }, {});
  }
  if (cohortData.segmented) {
    revenueRetention.segmented = {};

    Object.keys(cohortData.segmented).forEach((month) => {
      // The backend returns columns into the future, but we only show columns until the current month
      //  so, to get the last value we'll show customers, we need filter out the future months
      const segmentedMonthObject = cohortData.segmented[month];

      revenueRetention.segmented[month] = Object.keys(segmentedMonthObject).reduce((acc, segmentName) => {
        const currMonthArray = cohortData.segmented[month][segmentName].filter((o) =>
          dayjs.utc(o.date).startOf('month').isSameOrBefore(currentPeriod),
        );
        if (currMonthArray.length) {
          acc[segmentName] = currMonthArray[currMonthArray.length - 1].sum / currMonthArray[0].sum || 0;
        } else {
          acc[segmentName] = 1;
        }
        return acc;
      }, {});
    });
  }

  return revenueRetention;
};

// modifies `averageRow` and `totalRow` and `averageRowSegment` in place
export const pushMonthsValuesToAverageRow = ({
  averageRow,
  totalRow,
  averageRowSegment,
  totalRowSegment,
  cohortData,
  index,
  installBy,
  resolution,
  quarters,
  quartersYearOffset,
  cohortSegmentBy,
}) => {
  // We want this to be a weighted average. This is a bit more complex than it sounds, because:
  //  for any given column, we need to figure out which of the rows have reached this point.
  //  For the rows that have data for this column, we need to add up all their starting counts to
  //  divide by.
  const periodType = periodTypeFromCohortResolution(resolution);
  const currentPeriod = getEndOfPeriodDayJS({ date: dayjs.utc().startOf('month'), periodType, quarters });

  const indexIsBeforeTodayForCohort = (cohortDate, index) =>
    // This below is index + 1 because index 7 means the 8th column. If we just use index, we'll include a cohort that hasn't reached
    //  the current date, so we'll end up averaging 0s
    periodKeyToDayJS({ periodKey: cohortDate, periodType, quarters, quartersYearOffset })
      .add(index + 1, periodType)
      .isSameOrBefore(currentPeriod);

  // `cohortsForIndex` is the number of cohorts for a specific month number (index)
  let cohortsForIndex = 0;
  let totalCountForIndex = 0;
  const cohortsForSegmentsForIndex = {};
  for (const [cohortDate, cohortArray] of Object.entries(cohortData.total)) {
    const cohortDateDayjs = periodKeyToDayJS({ periodKey: cohortDate, periodType, quarters, quartersYearOffset });
    const rowStartingCount = cohortArray[0]?.count || 0;
    // This below is index + 1 because index 7 means the 8th column. If we just use index, we'll include a cohort that hasn't reached
    //  the current date, so we'll end up averaging 0s
    if (cohortDateDayjs.add(index + 1, periodType).isSameOrBefore(currentPeriod) && rowStartingCount !== 0) {
      cohortsForIndex++;
      totalCountForIndex += rowStartingCount;
    }

    // total counts for segmenting
    if (cohortSegmentBy) {
      for (const [segmentKey, segmentValue] of Object.entries(cohortData?.segmented[cohortDate])) {
        cohortsForSegmentsForIndex[segmentKey] = cohortsForSegmentsForIndex[segmentKey] ?? 0;
        const customerCount = segmentValue[0]?.count ?? 0;
        if (customerCount !== 0) {
          cohortsForSegmentsForIndex[segmentKey]++;
        }
      }
    }
  }

  switch (installBy) {
    case INSTALL_BY_OPTIONS.revenueDollar:
    case INSTALL_BY_OPTIONS.customerCount:
      let totalRowValue = 0;
      const totalOfRowValuesBySegments = {};
      for (const [cohortDate, cohortArray] of Object.entries(cohortData.total)) {
        // Cohort array will have future months too, but we don't want to include those in the average, since we won't display them
        if (cohortArray[index] && indexIsBeforeTodayForCohort(cohortDate, index)) {
          /*
            totalRowValue
          */
          totalRowValue +=
            installBy === INSTALL_BY_OPTIONS.revenueDollar
              ? cohortArray[index]?.sum || 0
              : cohortArray[index]?.count || 0;
        }
        /*
          totalOfRowValuesBySegments
        */
        if (cohortSegmentBy) {
          for (const [segmentKey, segmentValues] of Object.entries(cohortData?.segmented?.[cohortDate] ?? {})) {
            totalOfRowValuesBySegments[segmentKey] = totalOfRowValuesBySegments[segmentKey] ?? 0;

            totalOfRowValuesBySegments[segmentKey] +=
              installBy === INSTALL_BY_OPTIONS.revenueDollar
                ? segmentValues[index]?.sum || 0
                : segmentValues[index]?.count || 0;
          }
        }
      }

      const averageRowValue = totalRowValue / (cohortsForIndex !== 0 ? cohortsForIndex : 1); //prevent divide by 0

      averageRow.push(averageRowValue);
      totalRow.push(totalRowValue);

      if (cohortSegmentBy) {
        for (const [segmentKey, totalValueBySegment] of Object.entries(totalOfRowValuesBySegments)) {
          /*
            averageRowSegment
          */
          averageRowSegment[segmentKey] = averageRowSegment[segmentKey] ?? [];
          averageRowSegment[segmentKey].push(
            totalValueBySegment /
              (cohortsForSegmentsForIndex[segmentKey] !== 0 ? cohortsForSegmentsForIndex[segmentKey] : 1),
          );

          /*
            totalRowSegment
          */
          totalRowSegment[segmentKey] = totalRowSegment[segmentKey] ?? [];
          totalRowSegment[segmentKey].push(totalValueBySegment);
        }
      }
      break;

    case INSTALL_BY_OPTIONS.revenuePercent:
      averageRow.push(
        Object.entries(cohortData.total)
          .map(([cohortDate, cohortArray]) => {
            const rowStartingCount = cohortArray[0]?.count || 0;
            // Note: it's possible that the whole array is 0s (if there's a sole transaction that's 0)
            const startingRevenuePercent = cohortArray.find((a) => a.sum > 0);
            return cohortArray[index] && indexIsBeforeTodayForCohort(cohortDate, index)
              ? (cohortArray[index]?.sum / startingRevenuePercent?.sum) * rowStartingCount || 0
              : 0;
          })
          .reduce((sum, curr) => sum + curr, 0) / totalCountForIndex,
      );
      break;

    case INSTALL_BY_OPTIONS.customerPercent:
      averageRow.push(
        Object.entries(cohortData.total)
          .map(([cohortDate, cohortArray]) => {
            const rowStartingCount = cohortArray[0]?.count || 0;
            const highestCustomerCount = Math.max(...cohortArray.map((a) => a.count));

            return cohortArray[index] && indexIsBeforeTodayForCohort(cohortDate, index) && highestCustomerCount > 0
              ? (cohortArray[index].count / highestCustomerCount) * rowStartingCount
              : 0;
          })
          .reduce((sum, curr) => sum + curr, 0) / totalCountForIndex,
      );
      break;

    default:
  }
};

const DEFAULT_HEAT_LEVELS = [
  { cutOff: 1, level: 1 }, // 100%+
  { cutOff: 0.9, level: 2 }, // 91-100%
  { cutOff: 0.8, level: 3 }, // 81-90%
  { cutOff: 0.7, level: 4 }, // 71-80%
  { cutOff: 0.6, level: 5 }, // 61-70%
  { cutOff: 0.5, level: 6 }, // 51-60%
  { cutOff: 0.4, level: 7 }, // 41-50%
  { cutOff: 0.3, level: 8 }, // 31-40%
  { cutOff: 0.2, level: 9 }, // 21-30%
  { cutOff: 0.1, level: 10 }, // 11-20%
  { cutOff: -1, level: 11 }, // 0-10%
];

const calculateHeatLevelsForInstallBy = ({ values = [], installBy }) => {
  const maxValue = Math.max(...values);

  const heatLevels = cloneDeep(DEFAULT_HEAT_LEVELS);
  if ([INSTALL_BY_OPTIONS.customerPercent, INSTALL_BY_OPTIONS.revenuePercent].includes(installBy)) {
    // for percentages >100%, we have a different calculation. Find the range between the maxValue and 100%
    // and divide that range into 4 diff colors
    const rangeAbove100 = maxValue - 1; // highest % subtract 100%
    heatLevels.unshift(
      { cutOff: 1 + 0.8 * rangeAbove100, level: '0-4' },
      { cutOff: 1 + 0.6 * rangeAbove100, level: '0-3' },
      { cutOff: 1 + 0.4 * rangeAbove100, level: '0-2' },
      { cutOff: 1 + 0.2 * rangeAbove100, level: '0-1' }, // 100% plus 1/5 of the range above 100%
    );
  }

  return {
    maxValue: values.length !== 0 ? maxValue : 0,
    heatLevels,
  };
};

const arrayOfAllTotalValues = ({ cohortsData, installBy, totalAmounts }) => {
  const totalOrAverageKey = totalAmounts ? COHORTS_DATA_KEYS.TOTAL : COHORTS_DATA_KEYS.AVERAGE;

  let allValues = [];
  for (const cohortData of Object.values(cohortsData[installBy].cohortsData)) {
    allValues = allValues.concat(cohortData[totalOrAverageKey]);
  }

  return allValues;
};

const arrayOfAllSegmentedValues = ({ cohortsData, installBy, totalAmounts }) => {
  const totalOrAverageKey = totalAmounts ? COHORTS_DATA_KEYS.TOTAL : COHORTS_DATA_KEYS.AVERAGE;

  let allValues = [];
  for (const cohortData of Object.values(cohortsData[installBy].cohortSegmentData ?? {})) {
    for (const segmentKey of Object.keys(cohortData)) {
      allValues = allValues.concat(cohortData[segmentKey][totalOrAverageKey]);
    }
  }

  return allValues;
};

export const calculateAbsoluteHeatLevels = ({ cohortsData, installBy, totalAmounts, segmentBy }) => {
  const allTotalValues = arrayOfAllTotalValues({
    cohortsData,
    installBy,
    totalAmounts,
  });

  let allSegmentValues = [];
  if (segmentBy) {
    allSegmentValues = arrayOfAllSegmentedValues({
      cohortsData,
      installBy,
      totalAmounts,
    });
  }

  return {
    total: calculateHeatLevelsForInstallBy({
      values: allTotalValues,
      installBy,
    }),
    segmented: calculateHeatLevelsForInstallBy({
      values: allSegmentValues,
      installBy,
    }),
  };
};

export const calculateRowHeatLevels = ({ installBy, rowData, totalAmounts }) => {
  const totalOrAverageKey = totalAmounts ? COHORTS_DATA_KEYS.TOTAL : COHORTS_DATA_KEYS.AVERAGE;

  const values = rowData[totalOrAverageKey];

  return calculateHeatLevelsForInstallBy({
    values,
    installBy,
  });
};

export const getHeatLevel = ({ value, maxValue, installBy, heatLevels = DEFAULT_HEAT_LEVELS }) => {
  const isPercent = [INSTALL_BY_OPTIONS.customerPercent, INSTALL_BY_OPTIONS.revenuePercent].includes(installBy);

  if (isPercent && maxValue === 0) return -1;

  const percentage = isPercent ? value : value / maxValue;

  for (let i = 0; i < heatLevels.length; i++) {
    if (percentage > heatLevels[i].cutOff) {
      return heatLevels[i].level;
    }
  }
};

export const getProductName = (products, productId) =>
  products.find((product) => product.id.toString() === productId.toString())?.name ?? 'Unnamed Product';

export const getCohortRowLabel = ({
  cohortTabName,
  cohortRowName,
  cohortSegmentBy,
  cohortSegmentKey,
  cohortResolution,
  products,
}) => {
  if (cohortTabName === 'segmented') {
    if (cohortSegmentBy === SEGMENT_BY_OPTIONS.PRODUCT) {
      return getProductName(products, cohortSegmentKey);
    } else {
      return cohortSegmentKey;
    }
  } else {
    if (cohortResolution === RESOLUTION_OPTIONS.monthly) {
      return reFormatDate(cohortRowName, 'YYYY-MM', 'MMM YYYY');
    } else {
      return cohortRowName;
    }
  }
};

export const cohortTableData = ({
  cohortData,
  revenueRetentions,
  startingCounts,
  cohortTabName,
  cohortRowName,
  cohortSegmentBy,
  cohortSegmentKey,
  cohortResolution,
  products,
}) => {
  const cohortDisplayRowName =
    cohortTabName === 'segmented'
      ? cohortSegmentBy === SEGMENT_BY_OPTIONS.PRODUCT
        ? getProductName(products, cohortSegmentKey)
        : cohortSegmentKey
      : cohortResolution === RESOLUTION_OPTIONS.monthly
      ? reFormatDate(cohortRowName, 'YYYY-MM', 'MMM YYYY')
      : cohortRowName;

  const arrayForMonth = cohortSegmentKey
    ? cohortData[cohortTabName][cohortRowName][cohortSegmentKey]
    : cohortData[cohortTabName][cohortRowName];

  // Cohort data by Revenue %
  const startingRevenuePercent = arrayForMonth.length ? arrayForMonth.find((a) => a.sum > 0) : 0;
  const arrayForMonthByRevenuePercent = arrayForMonth.map((a) => {
    // Have to do the || 1 because it's possible all the sums are 0
    return { ...a, sum: startingRevenuePercent?.sum ? a.sum / startingRevenuePercent?.sum : 1 };
  });

  // Cohort data by Customer %
  const startingCount = cohortSegmentKey
    ? cohortData[cohortTabName][cohortRowName][cohortSegmentKey]?.[0].count ?? 0
    : startingCounts[cohortRowName];
  const arrayForMonthByCustomerPercent = arrayForMonth.map((a) => ({
    ...a,
    count: startingCount ? a.count / startingCount : 1,
  }));

  const revenueRetentionValue = cohortSegmentKey
    ? revenueRetentions[cohortTabName][cohortRowName][cohortSegmentKey]
    : revenueRetentions[cohortTabName][cohortRowName];

  const revenueRetention = `${Math.round(revenueRetentionValue * 100)}%`;

  const maxRowValues = {
    revenueDollar: Math.max(...arrayForMonth.map((val) => val.sum)),
    revenuePercent: Math.max(...arrayForMonthByRevenuePercent.map((val) => val.sum)),
    customerCount: Math.max(...arrayForMonth.map((val) => val.count)),
    customerPercent: Math.max(...arrayForMonthByCustomerPercent.map((val) => val.count)),
    revenueDollarTotal: Math.max(...arrayForMonth.map((val) => val.total)),
  };

  return {
    cohortDisplayRowName,
    arrayForMonth,
    arrayForMonthByRevenuePercent,
    arrayForMonthByCustomerPercent,
    revenueRetention,
    startingCount,
    maxRowValues,
  };
};

export const getFormatType = (installBy) => {
  switch (installBy) {
    case INSTALL_BY_OPTIONS.revenueDollar:
      return NUMBER_FORMATS.CURRENCY;
    case INSTALL_BY_OPTIONS.revenuePercent:
    case INSTALL_BY_OPTIONS.customerPercent:
      return NUMBER_FORMATS.PERCENT;
    case INSTALL_BY_OPTIONS.customerCount:
    default:
      return NUMBER_FORMATS.NUMBER;
  }
};

export const getTooltipByInstallationType = (installBy, totalAmounts) => {
  return installBy === INSTALL_BY_OPTIONS.customerCount
    ? 'This is the number of customers with active subscriptions this month'
    : installBy === INSTALL_BY_OPTIONS.revenueDollar && !totalAmounts
    ? 'This is the total revenue for this cohort divided by number of starting customers in the cohort'
    : installBy === INSTALL_BY_OPTIONS.revenueDollar && totalAmounts
    ? 'This is the total revenue for this cohort'
    : installBy === INSTALL_BY_OPTIONS.revenuePercent
    ? 'This is the % revenue retention for this cohort'
    : installBy === INSTALL_BY_OPTIONS.customerPercent
    ? 'This is the % customer retention for this cohort'
    : 'Something went wrong...';
};

export const getTableMaximums = (cohortData, cohortSegmentBy) => {
  // get the maximum values across all cohorts -> cohorts by month + cohorts by segment (if segmenting)
  const maxTableValues = {
    revenueDollar: 0,
    customerCount: 0,
    revenuePercent: 0,
    customerPercent: 0,
    revenueDollarTotal: 0,
  };

  const accumulateMaxes = (cohort) => {
    if (!cohort || !cohort.length) return;
    const startingCount = cohort[0]?.count || 0;
    const startingSum = cohort.find((i) => i.sum > 0)?.sum || 0;
    const maxCohortValues = {
      revenueDollar: Math.max(...cohort.map((val) => val.sum)),
      revenuePercent: Math.max(...cohort.map((val) => (startingSum ? val.sum / startingSum : 1))),
      customerCount: Math.max(...cohort.map((val) => val.count)),
      customerPercent: Math.max(...cohort.map((val) => (startingCount ? val.count / startingCount : 1))),
      revenueDollarTotal: Math.max(...cohort.map((val) => val.total)),
    };
    for (let installByOption of Object.keys(INSTALL_BY_OPTIONS)) {
      if (maxCohortValues[installByOption] > maxTableValues[installByOption]) {
        maxTableValues[installByOption] = maxCohortValues[installByOption];
      }
    }
    if (maxCohortValues.revenueDollarTotal > maxTableValues.revenueDollarTotal) {
      maxTableValues.revenueDollarTotal = maxCohortValues.revenueDollarTotal;
    }
  };

  if (cohortData.total) {
    Object.values(cohortData.total).forEach((cohort) => {
      accumulateMaxes(cohort);
    });
  }

  if (cohortSegmentBy && cohortData.segmented) {
    Object.values(cohortData.segmented).forEach((cohort) => {
      Object.values(cohort).forEach((cohortSegment) => {
        accumulateMaxes(cohortSegment);
      });
    });
  }

  return maxTableValues;
};

export const getStartingCountFluctuations = ({ data }) => {
  let previousAmount = null;
  const startingCountFluctuations = {};
  for (const cohortKey of Object.keys(data)) {
    const { startingCustomerCount: currentStartingCount } = data[cohortKey];
    startingCountFluctuations[cohortKey] = {
      icon:
        previousAmount !== null
          ? currentStartingCount > previousAmount
            ? 'up'
            : currentStartingCount < previousAmount
            ? 'down'
            : 'neutral'
          : 'neutral',
      tooltipText:
        previousAmount !== null
          ? currentStartingCount > previousAmount
            ? `${currentStartingCount} customers who started this period is greater than on previous period of ${previousAmount}`
            : currentStartingCount < previousAmount
            ? `${currentStartingCount} customers who started this period is less than on previous period of ${previousAmount}`
            : `${currentStartingCount} customers who started this period is same with on previous period of ${previousAmount}`
          : 'This customers is a first on this period',
    };

    previousAmount = currentStartingCount;
  }
  return startingCountFluctuations;
};

export const getBackgroundByHeatLevel = (cohortPalette, level) => {
  // https://www.figma.com/file/4RrMDAafABcFZ6tmLqClAK/Subscript-%E2%80%94-Revenue-Analytics?node-id=22823%3A187531
  const levels =
    cohortPalette === COHORT_PALLETS.COLORFUL
      ? {
          '0-4': '#005A24',
          '0-3': '#00712D',
          '0-2': '#008435',
          '0-1': '#01A041',
          1: '#00BC40',
          2: '#52D700',
          3: '#97DE00',
          4: '#CEEF05',
          5: '#FFE818',
          6: '#FFD25F',
          7: '#FFB672',
          8: '#FF9696',
          9: '#FD7373',
          10: '#FF5757',
          11: '#FF4343',
        }
      : {
          '0-4': '#0212AC',
          '0-3': '#1A2AC2',
          '0-2': '#1F32E5',
          '0-1': '#3343FF',
          1: '#0058DC',
          2: '#0273F8',
          3: '#3492FF',
          4: '#51ABFF',
          5: '#53C8FB',
          6: '#84E1FF',
          7: '#A9EBFE',
          8: '#C2EFFD',
          9: '#D3F4FE',
          10: '#E0F8FF',
          11: '#EFFBFF',
        };

  return levels[level] || '#F5F5F5';
};

export const getFluctuationIcon = (icon) => {
  return (
    {
      down: <TableArrowDown />,
      up: <TableArrowUp />,
      neutral: <TableNeutralCircle />,
    }[icon] || ''
  );
};

export const isWhiteText = ({ primary, heatLevel, cohortPalette }) => {
  return (
    primary &&
    ((cohortPalette === COHORT_PALLETS.COLORFUL && ![undefined, -1, 4, 5, 6, 7].includes(heatLevel)) ||
      (cohortPalette === COHORT_PALLETS.MONOCHROMIC && ![undefined, -1, 6, 7, 8, 9, 10, 11].includes(heatLevel)))
  );
};

export const getSegmentKeys = ({ cohortSegmentBy, averageSegmentData }) => {
  const segmentKeys = [];
  if (cohortSegmentBy) {
    for (const [segmentKey, segmentValues] of Object.entries(averageSegmentData.rows)) {
      if (segmentValues.some((value) => value !== 0)) segmentKeys.push(segmentKey);
    }
  }
  return segmentKeys;
};

export const calculateAvgCustomersAndRetentionPerSegment = ({ segmentKeys, cohortData, revenueRetentions }) => {
  const cohortKeys = Object.keys(cohortData.segmented);
  const weightedAverageRetentionBySegment = {};
  const customersBySegment = {};
  const cohortsWithSegment = {};
  for (const segmentKey of segmentKeys) {
    for (const cohortKey of cohortKeys) {
      const customersForCohortForSegment = cohortData.segmented[cohortKey]?.[segmentKey]?.[0].count ?? 0;

      if (customersForCohortForSegment !== 0) {
        const retentionForCohortForSegment = revenueRetentions.segmented[cohortKey]?.[segmentKey] ?? 0;

        // sum up the retention for a cohort for a segment times the number of customers in that cohort for that segment
        weightedAverageRetentionBySegment[segmentKey] = weightedAverageRetentionBySegment[segmentKey] ?? 0;
        weightedAverageRetentionBySegment[segmentKey] += retentionForCohortForSegment * customersForCohortForSegment;

        // add up customers for a segment for all cohorts
        customersBySegment[segmentKey] = customersBySegment[segmentKey] ?? 0;
        customersBySegment[segmentKey] += customersForCohortForSegment;

        cohortsWithSegment[segmentKey] = cohortsWithSegment[segmentKey] ?? 0;
        cohortsWithSegment[segmentKey]++;
      }
    }
    // divide by the total number of customers for a segment for all cohorts
    weightedAverageRetentionBySegment[segmentKey] /= customersBySegment[segmentKey];
  }
  return {
    customersBySegment,
    cohortsWithSegment,
    weightedAverageRetentionBySegment,
  };
};
