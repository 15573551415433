import dayjs from 'dayjs';
import { isEmpty, uniqBy } from 'lodash';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { Centerer, FlexerColumn, Row, Spacer } from 'components/Core';
import { useClickOutside } from 'utils/hooks';
import { groupBy } from 'utils/arrayUtils';
import { EVENT_RECOGNITION_TYPES } from 'consts/global';
import { useDebouncedSearchBar } from 'components/Blocks';
import { SwitchWithLabel } from 'components/Controls';
import { useTransactionsAPI } from 'api/transactions';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';
import { CircleLoader } from 'components/Loaders';
import { ModalContainer, Modal } from 'components/Modal';
import { groupTransactionsByExternalId } from '../utils';
import { INVOICE_ADDITION_OPTIONS } from '../consts';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { PreviewScheduleModal } from './PreviewScheduleModal';
import {
  TableHeader,
  Footer,
  TransactionsList,
  Header,
  TogglesDescription,
  Body,
  EmptyState,
  ConfirmButton,
  Description,
  FiltersTogglesWrapper,
} from './styles';
import { SelectTransactionsTable } from './SelectTransactionsTable';

const now = dayjs().toDate();

export const SelectTransactionModal = ({ onClose, customerId, initiallySelectedTransactions }) => {
  const { orgId } = useContext(AppContext);
  const {
    currentInvoicingSchedule,
    setIncludedTransactions,
    setInvoiceUpdateLoading,
    scheduleFormRef,
    invoiceUpdateLoading,
    refetchInvoicingSchedule,
  } = useContext(InvoicingScheduleContext);

  const [showDismissed, setShowDismissed] = useState(false);
  const [showPast, setShowPast] = useState(true);
  const [searchQuery, setSearchQuery] = useState();
  const [previewScheduleData, setPreviewScheduleData] = useState();

  const { data, isLoading } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 1000,
      params: {
        withoutInvoicingSchedule: true,
        includeDismissedInvoicing: showDismissed,
        confirmed: true,
        archived: false,
        includeChildCustomerTransactions: true,
        searchQuery,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy: [{ column: 'start_date', order: 'asc' }],
        customerIds: [customerId],
        endDate: showPast ? null : now,
      },
    },
  });

  const {
    operations: { regenerateInvoicingSchedule },
  } = useInvoicingSchedulesAPI({
    orgId,
    autoFetch: false,
  });

  const isCreateMode = !currentInvoicingSchedule?.id;

  const transactionsToPickFrom = useMemo(
    () => uniqBy([...(data ?? []), ...(isCreateMode ? initiallySelectedTransactions ?? [] : [])], 'id'),
    [data, initiallySelectedTransactions, isCreateMode],
  );

  const groupedTransactions = useMemo(
    () =>
      transactionsToPickFrom
        ? groupTransactionsByExternalId({
            transactions: transactionsToPickFrom,
            searchQuery,
            initiallySelectedTransactions,
          })
        : {},
    [transactionsToPickFrom, searchQuery, initiallySelectedTransactions],
  );

  const intialOptionValues = useRef(scheduleFormRef?.current?.values?.transaction_options ?? {});

  const modalRef = useClickOutside(() => {
    scheduleFormRef.current.setFieldValue('transaction_options', intialOptionValues.current);
    onClose();
  });

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const saveTransactionsForNotCreatedSchedule = useCallback(() => {
    const values = scheduleFormRef?.current?.values;
    if (isEmpty(values.transaction_options)) return;

    const transactionById = groupBy(transactionsToPickFrom, 'id', { uniqueness: true });

    const selectedTransactions = Object.keys(values.transaction_options).map((id) => transactionById[parseInt(id)]);

    setIncludedTransactions(selectedTransactions);

    const includesEventTransaction = selectedTransactions?.some((transaction) =>
      EVENT_RECOGNITION_TYPES.includes(transaction?.recognition),
    );

    if (includesEventTransaction) {
      setFieldValue('invoicing_frequency', INVOICING_FREQUENCIES.EVENT_BASED);
    }

    setFieldValue('invoices', values?.invoices?.filter((i) => i.is_imported) ?? []);

    onClose();
  }, [setIncludedTransactions, onClose, transactionsToPickFrom, setFieldValue, scheduleFormRef]);

  const handleRegenerateSchedule = async ({ previewMode }) => {
    setInvoiceUpdateLoading(true);
    try {
      const values = scheduleFormRef.current.values;

      const updatedSchedule = await regenerateInvoicingSchedule.mutateAsync({
        id: currentInvoicingSchedule?.id,
        data: {
          transactionIdsAndOptions: values.transaction_options,
          dryRun: !!previewMode,
        },
      });

      if (previewMode) {
        setPreviewScheduleData(updatedSchedule);
      } else {
        setPreviewScheduleData(null);
        refetchInvoicingSchedule();
        onClose();
      }
    } catch (err) {
      console.error({ message: err.message, component: 'InvoicingScheduleTransactionsList', stack: err });
    }
    setInvoiceUpdateLoading(false);
  };

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: setSearchQuery,
    initialValue: searchQuery,
    placeholder: 'Search by Name or External ID...',
    placeholderColor: 'var(--primaryBlack30)',
    backgroundColor: 'transparent',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    width: '180px',
  });

  return !previewScheduleData ? (
    <ModalContainer>
      <Modal
        padding="0"
        width="860px"
        height="675px"
        ref={modalRef}
        data-cy="invoicing-schedule__select-transaction-modal"
        overflow="visible"
        compact
      >
        <FlexerColumn>
          <Header>
            <DebouncedSearchBar />

            <Row>
              <TogglesDescription>Showing only trans. not invoiced</TogglesDescription>

              <FiltersTogglesWrapper horizontal="flex-start">
                <SwitchWithLabel
                  onChange={setShowDismissed}
                  checked={showDismissed}
                  label="Dismissed trans."
                  labelSize="12px"
                />

                <SwitchWithLabel onChange={setShowPast} checked={showPast} label="Past trans." labelSize="12px" />
              </FiltersTogglesWrapper>
            </Row>
          </Header>

          <TableHeader>
            <div>transaction</div>
            <div>dates</div>
            <div>amount</div>
          </TableHeader>
          {invoiceUpdateLoading ? (
            <Centerer style={{ marginTop: 40 }}>
              <CircleLoader />
            </Centerer>
          ) : (
            <>
              <Body>
                <TransactionsList>
                  {isLoading ? (
                    <Centerer>
                      <Spacer height="200px" />
                      <CircleLoader width={24} height={24} />
                    </Centerer>
                  ) : isEmpty(groupedTransactions) ? (
                    <EmptyState>Subscript didn’t find any transactions not invoiced by this customer.</EmptyState>
                  ) : (
                    <SelectTransactionsTable
                      showOptions
                      currentInvoicingSchedule={currentInvoicingSchedule}
                      groupedTransactions={groupedTransactions}
                    />
                  )}
                </TransactionsList>
              </Body>

              <Footer>
                <ConfirmButton
                  onClick={() => {
                    const selectedTransactions = Object.entries(groupedTransactions ?? {}).reduce(
                      (acc, [, transactions]) => {
                        transactions.forEach((transaction) => {
                          acc[transaction.id] = {
                            addType: INVOICE_ADDITION_OPTIONS.MERGE,
                            prorateFirstInvoice: false,
                          };
                        });
                        return acc;
                      },
                      {},
                    );

                    setFieldValue('transaction_options', {
                      ...selectedTransactions,
                      ...scheduleFormRef.current.values.transaction_options,
                    });
                  }}
                >
                  Select all transactions
                </ConfirmButton>

                {Object.keys(scheduleFormRef?.current?.values?.transaction_options ?? {}).length ? (
                  <ConfirmButton
                    filled
                    onClick={() =>
                      isCreateMode
                        ? saveTransactionsForNotCreatedSchedule()
                        : handleRegenerateSchedule({ previewMode: true })
                    }
                    data-cy="select-transaction-modal__confirm-button"
                  >
                    <span>
                      Save transaction selection (
                      {Object.keys(scheduleFormRef?.current?.values?.transaction_options).length})
                    </span>
                  </ConfirmButton>
                ) : null}
              </Footer>
            </>
          )}
        </FlexerColumn>

        <Description>Showing only transactions not invoiced</Description>
      </Modal>
    </ModalContainer>
  ) : (
    <PreviewScheduleModal
      onBack={() => setPreviewScheduleData(null)}
      previewScheduleData={previewScheduleData}
      handleRegenerateSchedule={handleRegenerateSchedule}
    />
  );
};
