import { isEqual, omitBy, isNil, isPlainObject } from 'lodash';

// if object is of format:
// {
//   CONST1: {
//     key: key1,
//     label: label1,
//   },
//   CONST2: {
//     key: key2,
//     label: label2,
//   }
// }
//
// use this function to get label for given key
export const getLabelForKey = ({ object, key }) => {
  // eslint-disable-next-line no-unused-vars
  for (const [_constKey, { key: crtKey, label }] of Object.entries(object)) {
    if (crtKey === key) return label;
  }
  return null;
};

export const getValueFromlabel = ({ options, label }) => options.find((option) => option.label === label)?.value;

/**
 * Convert all object properties that passes predicate function using converter function
 * @param object to convert
 * @param predicate function that selects properties to convert
 * @param converter function
 * @param excludeKeys array with keys to exclude from conversion
 */
export const deepConvertValues = ({ object, predicate, converter, excludeKeys }) => {
  const clone = { ...object };
  if (!clone) return;
  Object.entries(clone).forEach(([key, value]) => {
    if (excludeKeys?.includes(key)) {
      return;
    }
    if (predicate(value)) {
      clone[key] = converter(value);
    } else if (isPlainObject(value)) {
      clone[key] = deepConvertValues({ object: value, predicate, converter });
    }
  });
  return clone;
};

/**
 * Compare two objects, excluding null or undefined properties
 * @param a
 * @param b
 * @param options
 * @returns {boolean}
 */
export const deepEqual = (a, b, { excludeNil = false }) =>
  isEqual(
    excludeNil && typeof a !== 'boolean' ? omitBy(a, isNil) : a,
    excludeNil && typeof a !== 'boolean' ? omitBy(b, isNil) : b,
  );

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const getKeyByValue = ({ object = {}, value }) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const _deepAssign = (object, keys, value) => {
  if (keys.length === 0) return object;

  const [currentKey, ...remainingKeys] = keys;

  if (remainingKeys.length > 0) {
    const currentValue = typeof object[currentKey] === 'object' ? object[currentKey] : {};
    object[currentKey] = currentValue ?? {};
    _deepAssign(object[currentKey], remainingKeys, value);
  } else {
    object[currentKey] = value;
  }

  return object;
};

export const dig = (object, path) =>
  path.split('.').reduce((acc, key) => (acc?.[key] === undefined ? undefined : acc?.[key]), object);

export const deepAssign = (object, path, value) => _deepAssign(object, path.split('.'), value);

/**
 * Check if two objects have the same id
 * @param a {number|string}
 * @param b {number|string}
 * @returns {boolean}
 */
export const isSameId = (a, b) => {
  return parseInt(a) === parseInt(b);
};
