import React, { useCallback, useContext, useState } from 'react';
import { Formik } from 'formik';
import { pick, cloneDeep } from 'lodash';

import { AppContext } from 'AppContext';
import { useDiscountsAPI, useUsageSubscriptionGroupsAPI } from 'api/usageBasedEngine';
import { Modal, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';

import { SUBSCRIPTION_DUPLICATE_MODAL_MODE, USAGE_SUBSCRIPTION_METADATA_KEYS } from './consts';
import { DuplicateForm } from './DuplicateForm/DuplicateForm';

export const SubscriptionDuplicateModal = ({ groupedSubscriptions, onClose }) => {
  const { orgId, orgCurrency } = useContext(AppContext);

  const {
    operations: { bulkUpload: bulkCreateUsageSubscriptionGroups },
  } = useUsageSubscriptionGroupsAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: { createDiscount },
  } = useDiscountsAPI({
    orgId,
    autoFetch: false,
  });

  const handleSubmit = async ({ subscriptions: newSubscriptions }) => {
    const newSubscriptionsData = [];

    for (const [subscriptionIndex, subscription] of newSubscriptions.entries()) {
      const originalSubscription = groupedSubscriptions[subscriptionIndex];
      subscription.usage_subscriptions = originalSubscription.usage_subscriptions;

      await Promise.all(
        subscription.usage_subscriptions.map(async (subscription) => {
          const originalDiscount = originalSubscription.discount;

          if (originalDiscount) {
            const discount = await createDiscount.mutateAsync({
              data: {
                type: originalDiscount.type,
                value: originalDiscount.value,
              },
            });
            subscription.discount_id = discount.id;
          }
        }),
      );

      const metadata = {
        [USAGE_SUBSCRIPTION_METADATA_KEYS.SKIP_PREPAID_TRANSACTION_SYNC]: !!originalSubscription?.metadata
          ?.skip_prepaid_transaction_sync,
      };

      const data = {
        ...pick(subscription, [
          'start_date',
          'end_date',
          'customer_id',
          'trial_units',
          'previous_group_id',
          'usage_subscriptions',
          'is_prorated',
          'aggregate_usage_on_parent',
          'child_customer_ids',
          'prorate_by_amount',
        ]),
        prorate_by_amount: subscription.is_prorated ? subscription.prorate_by_amount : false,
        metadata,
      };

      data.usage_subscriptions = data.usage_subscriptions.map((subscription) =>
        pick(subscription, [
          'pricing_plan_id',
          'overage_transaction_name',
          'overage_transaction_metadata',
          'prepaid_transaction_name',
          'prepaid_transaction_metadata',
          'discount_id',
        ]),
      );

      newSubscriptionsData.push(data);
    }

    await bulkCreateUsageSubscriptionGroups.mutateAsync({ data: newSubscriptionsData });

    onClose();
  };

  const initialValues = {
    subscriptions: groupedSubscriptions.map((groupedSubscription) => {
      const firstSubscription = groupedSubscription?.usage_subscriptions?.[0] ?? {};
      const customer = groupedSubscription?.customer || firstSubscription?.customer;

      return {
        ...pick(firstSubscription, [
          'aggregate_usage_on_parent',
          'customer_name',
          'end_date',
          'is_prorated',
          'prorate_by_amount',
          'start_date',
          'trial_units',
          'selected_child_customers',
        ]),
        ...groupedSubscription,
        customer: customer,
        customer_name: customer?.name || groupedSubscription?.customer_name || firstSubscription?.customer_name,
        customer_id: customer?.id || groupedSubscription?.customer_id || firstSubscription?.customer_id,
        has_discount: !!groupedSubscription?.usage_subscriptions?.some((subscription) => !!subscription.discount),
        currency: firstSubscription?.pricing_plan?.currency ?? orgCurrency,
        skip_prepaid_transaction_sync: firstSubscription?.metadata?.skip_prepaid_transaction_sync,
        child_customer_ids: (firstSubscription?.selected_child_customers ?? []).map(({ id }) => id),
        pricing_plan: firstSubscription?.pricing_plan,
        metadata: firstSubscription?.metadata || groupedSubscription?.metadata,
        usage_subscriptions: (groupedSubscription?.usage_subscriptions ?? []).map((subscription) => {
          return {
            ...subscription,
            pricing_plan_id: subscription?.pricing_plan?.id,
            overage_transaction_name: subscription?.overageTransaction?.name,
            overage_transaction_metadata: subscription?.overageTransaction?.metadata,
            prepaid_transaction_name: subscription?.prepaidTransactions?.[0]?.name,
            prepaid_transaction_metadata: subscription?.prepaidTransactions?.[0]?.metadata,
            pricing_plan_frequency: subscription?.pricing_plan?.invoicing_frequency,
          };
        }),
      };
    }),
  };

  return (
    <ModalContainer>
      <Modal overflow="visible" width="1400px" data-cy="duplicate-subscriptions-modal" height="auto" maxHeight="94vh">
        <ModalCloseIcon onClose={onClose} />
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <DuplicateForm onClose={onClose} originalSubscriptions={cloneDeep(initialValues?.subscriptions)} />
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useSubscriptionDuplicateModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_USAGE_SUBSCRIPTION_DUPLICATE_MODAL,
        properties: { mode: SUBSCRIPTION_DUPLICATE_MODAL_MODE.DUPLICATE },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const Modal = useCallback(
    () => (showModal ? <SubscriptionDuplicateModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    SubscriptionDuplicateModal: Modal,
  };
};
