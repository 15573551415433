import React, { useContext, useState } from 'react';
import { cssVar } from 'polished';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { HEADER_TITLES } from 'shared/Layout';
import { ShareButton } from 'shared/ShareButton';
import { SaveReportButton } from 'shared/Reports/SaveReportButton';
import { Centerer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { TooltipRowText12 } from 'components/Tooltip/styles';
import { TruncateStringWithTooltip, TooltipContainer } from 'components/Tooltip';
import { IconButton } from 'components/Buttons';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { useReportsAPI } from 'api/reports';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import { ReactComponent as ToolsIcon } from 'images/tools.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { EditReportModal } from 'shared/Reports/EditReportModal';
import { DeleteConfirmationModal } from 'shared/DeleteConfirmationModal';
import { useClickOutside, useToasts } from 'utils/hooks';
import styled from 'styled-components';
import { COMMON_METRICS_DASHBOARD_TITLE } from '../consts';
import { Header, ReportName, Title } from '../styles';
import { ReportBuilderContext } from '../ReportBuilderContext';

const ListItemIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  opacity: 0.5;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ReportBuilderHeader = () => {
  const history = useHistory();
  const { pushError } = useToasts();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const { organizations } = useContext(AppContext);
  const {
    operations: { removeReport },
  } = useReportsAPI({
    orgId: organizations[0].id,
    autoFetch: false,
  });

  const { report, setReport, pageReportState, ExportButton, openEditReportNameModal } = useContext(
    ReportBuilderContext,
  );

  const handleSaveReport = ({ updatedReport }) => {
    if (updatedReport) {
      setReport(updatedReport);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleEditSettings = () => {
    setShowEditModal(true);
    setShowDropdown(false);
  };

  const handleEditModalClose = ({ updatedReport } = {}) => {
    if (updatedReport) {
      setReport(updatedReport);
    }
    setShowEditModal(false);
  };

  const handleDeleteReport = async () => {
    setShowDropdown(false);
    setShowDeleteModal(false);
    try {
      history.push('/dashboard/common-metrics');
      await removeReport({
        orgId: organizations[0].id,
        reportId: report.id,
      });
      window.location.reload(true);
    } catch (e) {
      pushError(e, 'Could not not remove the report');
      console.error({ message: e.message, component: 'ReportBuilderHeader.js', stack: e });
    }
  };

  // So we disable editing the default dashboard
  const isEditable = [COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title);

  return (
    <Header>
      <Centerer gap="12px">
        <Title>Reports: </Title>
        <TooltipContainer
          width={70}
          hideArrow
          fontSize="12px"
          toolTipContent={<TooltipRowText12>Edit name</TooltipRowText12>}
          isVisible={!isEditable && !report?.title?.length > 40}
          tooltipWrapperStyles={{ height: '40px', marginTop: '-3px' }}
        >
          <ReportName
            data-cy="header-reports__title"
            editable={!isEditable}
            onClick={!isEditable ? openEditReportNameModal : null}
          >
            <TruncateStringWithTooltip children={report?.title} length={40} />
          </ReportName>
        </TooltipContainer>
      </Centerer>

      <Centerer gap="12px">
        {report && ![COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title) && (
          <>
            <PopoverWrapper ref={dropdownRef}>
              <IconButton
                icon={<DotsIcon />}
                iconFillColor={cssVar('--primaryGreen')}
                onClick={toggleDropdown}
                data-cy="more-options-button"
              >
                More
              </IconButton>
              {showDropdown && (
                <Popover data-cy="more-options-dropdown" width="160px">
                  <PopoverItemsContainer>
                    <PopoverListItem onClick={handleEditSettings} data-cy="edit-settings-option">
                      <ListItemIcon>
                        <ToolsIcon />
                      </ListItemIcon>
                      Edit Settings
                    </PopoverListItem>
                    <PopoverListItem onClick={() => setShowDeleteModal(true)} data-cy="delete-report-option">
                      <ListItemIcon>
                        <TrashIcon />
                      </ListItemIcon>
                      Delete Report
                    </PopoverListItem>
                  </PopoverItemsContainer>
                </Popover>
              )}
            </PopoverWrapper>
            <SaveReportButton report={report} pageReportState={pageReportState} onSubmitted={handleSaveReport} />
            <GreyDot size="6px" spacing="0" />
          </>
        )}
        <ShareButton
          headerTitle={HEADER_TITLES.dashboard}
          report={{
            ...report,
            // The user could have changed the filters without saving the report, this will make sure it share whatever the user is seeing
            options: pageReportState,
          }}
          pageToShare={HEADER_TITLES.dashboard}
        />
        <ExportButton />
      </Centerer>
      {showEditModal && (
        <EditReportModal
          report={report}
          onClose={handleEditModalClose}
          showDefaultDashboardOption={true}
          hideRemoveButton={true}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          modalName="delete-report-modal"
          resourceType={'report'}
          resourceName={report?.title}
          onDeleteConfirm={handleDeleteReport}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </Header>
  );
};
