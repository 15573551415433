import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { Spacer, Centerer, FlexerRow } from 'components/Core';
import { FlexerColumn } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { TextBox } from 'components/Core/Text';
import { AllDoneCheckmark } from 'components/Icons/AllDoneCheckmark';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { numberFormatter } from 'utils/formatters';
import { MAGIC_METADATA, NUMBER_FORMATS } from 'consts/global';
import { Accordion, AccordionHeader } from 'components/Accordion';
import { InlineButton } from 'components/Buttons';
import { AccordionItem, AccordionTitle } from 'components/Accordion/Accordion';
import { displayBillingScheme } from 'views/Billing/UsageBasedEngine/PricingPlans/columns';
import { useEditPricingPlanModal } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/EditPricingPlanModal';
import { PRICING_PLAN_MODAL_MODE } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/consts';
import { useSubscriptionEditModal, SUBSCRIPTION_EDIT_MODAL_MODE } from 'views/Billing/UsageBasedEngine/Subscriptions';
import { useProductActionsModal } from 'shared/ProductActionsModal';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';

const AllDoneCheckmarkContainer = styled(Centerer)`
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;

  background: var(--primaryBlack10);
  box-shadow: 0px 4px 10px var(--primaryBlack10);

  border: 1px solid var(--primaryBlack10);
  border-radius: 100px;
`;

const RowWrapper = styled(FlexerRow)`
  cursor: pointer;
  padding: 20px;
  &:hover svg g {
    opacity: 0.8;
  }
`;

const NameWrapper = styled(FlexerRow)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);
`;

const TypeBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid var(--dark5);
  background: var(--dark2);
  font-size: 9px;
  line-height: 12px;
  font-weight: 900;
  color: #afb5be;
`;

const BlueArrowRight = styled(ArrowRight)`
  g {
    opacity: 1;
  }
  path {
    fill: var(--primaryBlue);
  }
`;

const CreateInvoiceButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d0d0d0;
  padding: 2px 10px;
`;

const getDefaultFrequencyFromTransactions = ({ transactions = [] }) => {
  return transactions.some((transaction) => transaction.metadata?.[MAGIC_METADATA.CUSTOM_SCHEDULE]?.length > 0)
    ? INVOICING_FREQUENCIES.CUSTOM_SCHEDULE_METADATA
    : undefined;
};

export const ContractTransactionsCreatedModal = ({ data, onClose }) => {
  const { openModal: openInvoicingScheduleModal, Modal: InvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose,
  });

  const { openModal: openPricingPlanModal, EditPricingPlanModal } = useEditPricingPlanModal();
  const { openModal: openSubscriptionEditModal, SubscriptionEditModal } = useSubscriptionEditModal();
  const { openModal: openProductModal, ProductActionsModal } = useProductActionsModal();

  const openPricingPlan = ({ pricingPlan }) => {
    openPricingPlanModal({
      pricingPlan,
      mode: PRICING_PLAN_MODAL_MODE.EDIT,
    });
  };

  const openSubscriptionModal = ({ group }) => {
    openSubscriptionEditModal({
      selectedGroup: group,
      mode: SUBSCRIPTION_EDIT_MODAL_MODE.EDIT,
    });
  };

  const openProduct = ({ product }) => {
    openProductModal({
      product,
      action: 'edit',
    });
  };

  const { customer, products, transactions, pricingPlans, subscriptions: subscriptionGroups } = data;

  const contacts = customer.invoicingDetails?.contacts || customer.invoicing_details?.contacts || [];

  return (
    <>
      <ModalContainer data-cy="contract-files-modal">
        <Modal width="90vw" maxWidth="1000px" height="60vh" overflow="visible" padding="0px">
          <ModalHeader addPadding>
            <ModalCloseIcon onClose={onClose} />
          </ModalHeader>

          <ModalBody>
            <FlexerColumn height="100%" alignItems="center" justifyContent="flex-start">
              <AllDoneCheckmarkContainer>
                <AllDoneCheckmark />
              </AllDoneCheckmarkContainer>
              <FlexerRow alignItems="center" gap="0 4px" marginTop="20px">
                <TextBox fontSize="18px" bold>
                  Subscript AI has successfully created
                </TextBox>
                <TextBox fontSize="18px" bold italic opacity="0.5">
                  new data
                </TextBox>
              </FlexerRow>
              <FlexerColumn width="100%" alignItems="stretch" marginTop="20px" gap="8px 0">
                {!!customer && (
                  <Accordion key="customer" isExpandable={false}>
                    <AccordionHeader>
                      <FlexerRow width="100%" justifyContent="space-between">
                        <TextBox bold>Customer</TextBox>
                        <div data-cy="ai-modal-success-customer-name">
                          <InlineButton onClick={() => window.open(`/customers/${customer.id}`, '_blank')} isSecondary>
                            {customer.name}
                          </InlineButton>
                          {contacts?.length > 0 && (
                            <TextBox style={{ marginLeft: '5px' }}>({contacts?.join(', ')})</TextBox>
                          )}
                        </div>
                      </FlexerRow>
                    </AccordionHeader>
                  </Accordion>
                )}

                {products?.length > 0 && (
                  <Accordion key="products" entityName="product">
                    <AccordionHeader>
                      <AccordionTitle>Products</AccordionTitle>
                    </AccordionHeader>
                    {products.map((product) => (
                      <AccordionItem key={product.id}>
                        <RowWrapper width="100%" onClick={() => openProduct({ product })}>
                          <FlexerRow width="30%" alignItems="center" gap="0 8px">
                            <TypeBadge>PR</TypeBadge>
                            <NameWrapper>{product.name}</NameWrapper>
                          </FlexerRow>
                          <FlexerRow width="40%" style={{ textTransform: 'capitalize' }}>
                            {product.recognition}
                          </FlexerRow>
                          <FlexerRow width="20%">
                            {numberFormatter({
                              type: NUMBER_FORMATS.CURRENCY,
                              rawValue: product.standalone_selling_price,
                            })}
                          </FlexerRow>
                          <FlexerRow flexGrow="1" justifyContent="flex-end">
                            <ArrowRight />
                          </FlexerRow>
                        </RowWrapper>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
                {transactions?.length > 0 && (
                  <Accordion key="transactions" entityName="transaction" open>
                    <AccordionHeader>
                      <FlexerRow width="100%" justifyContent="space-between">
                        <AccordionTitle>Transactions</AccordionTitle>
                        <CreateInvoiceButton
                          onClick={() => {
                            openInvoicingScheduleModal({
                              includedTransactions: data?.transactions,
                              options: {
                                defaultFrequency: getDefaultFrequencyFromTransactions({
                                  transactions: data?.transactions,
                                }),
                              },
                            });
                          }}
                        >
                          <TextBox fontWeight="600" fontSize="12px">
                            Create Invoices
                          </TextBox>
                          <Spacer width="2px" />
                          <BlueArrowRight />
                        </CreateInvoiceButton>
                      </FlexerRow>
                    </AccordionHeader>
                    {transactions.map((transaction) => (
                      <AccordionItem key={transaction.id}>
                        <RowWrapper
                          width="100%"
                          onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}
                        >
                          <FlexerRow width="30%" alignItems="center" gap="0 8px">
                            <TypeBadge>TR</TypeBadge>
                            <NameWrapper>{transaction.name}</NameWrapper>
                          </FlexerRow>
                          <FlexerRow width="40%">
                            {dayjs.utc(transaction.start_date ?? transaction.date).format('DD/MM/YYYY')}
                          </FlexerRow>
                          <FlexerRow width="20%">
                            {numberFormatter({
                              type: NUMBER_FORMATS.CURRENCY,
                              currency: transaction.currency,
                              rawValue: transaction.amount,
                            })}
                          </FlexerRow>
                          <FlexerRow flexGrow="1" justifyContent="flex-end">
                            <ArrowRight />
                          </FlexerRow>
                        </RowWrapper>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
                {pricingPlans?.length > 0 && (
                  <Accordion key="pricingPlans" entityName="pricingPlan" open>
                    <AccordionHeader>
                      <FlexerRow width="100%" justifyContent="space-between">
                        <AccordionTitle>Usage-Based Pricing Plans</AccordionTitle>
                      </FlexerRow>
                    </AccordionHeader>
                    {pricingPlans.map((pricingPlan) => (
                      <AccordionItem key={pricingPlan.id}>
                        <RowWrapper width="100%" onClick={() => openPricingPlan({ pricingPlan })}>
                          <FlexerRow width="30%" alignItems="center" gap="0 8px">
                            <TypeBadge>PL</TypeBadge>
                            <NameWrapper>{pricingPlan.name}</NameWrapper>
                          </FlexerRow>
                          <FlexerRow width="40%">
                            {displayBillingScheme({
                              tiers: pricingPlan.tiers,
                              currency: pricingPlan.currency,
                              numberFormatter,
                            })}
                          </FlexerRow>
                          <FlexerRow flexGrow="1" justifyContent="flex-end">
                            <ArrowRight />
                          </FlexerRow>
                        </RowWrapper>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
                {subscriptionGroups?.length > 0 && (
                  <Accordion key="subscriptions" entityName="subscription" open>
                    <AccordionHeader>
                      <FlexerRow width="100%" justifyContent="space-between">
                        <AccordionTitle>Usage-Based Subscriptions</AccordionTitle>
                      </FlexerRow>
                    </AccordionHeader>
                    {subscriptionGroups.map((group) => (
                      <AccordionItem key={group.id}>
                        <RowWrapper width="100%" onClick={() => openSubscriptionModal({ group })}>
                          <FlexerRow width="30%" alignItems="center" gap="0 8px">
                            <TypeBadge>SB</TypeBadge>
                            <NameWrapper>{group.usage_subscriptions?.[0].customer?.name}</NameWrapper>
                          </FlexerRow>
                          <FlexerRow width="40%">
                            {dayjs.utc(group.start_date).format('DD/MM/YYYY')} –{' '}
                            {dayjs.utc(group.end_date).format('DD/MM/YYYY')}
                          </FlexerRow>
                          <FlexerRow flexGrow="1" justifyContent="flex-end">
                            <ArrowRight />
                          </FlexerRow>
                        </RowWrapper>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </FlexerColumn>
            </FlexerColumn>
          </ModalBody>
          <Spacer height="32px" />
        </Modal>
      </ModalContainer>

      <InvoicingScheduleModal />
      <EditPricingPlanModal />
      <SubscriptionEditModal />
      <ProductActionsModal />
    </>
  );
};
