import dayjs from 'dayjs';

const formatDate = (date) => dayjs.utc(date).format('MMM D');

export const displayPreviousGroup = (group) => {
  const firstSubscription = group.usage_subscriptions?.[0];
  return `${formatDate(firstSubscription?.start_date)} - ${formatDate(firstSubscription?.end_date)} | ${
    firstSubscription?.pricing_plan?.name
  }`;
};

export const convertSubscriptionToTransaction = (subscription, i) => {
  if (!subscription.pricing_plan_id) return [];

  const existingOverageTransaction = subscription?.overageTransaction ?? {};
  const existingPrepaidTransaction = subscription?.prepaidTransactions?.[0] ?? {};
  return [
    {
      ...existingOverageTransaction,
      pricing_plan_id: subscription?.pricing_plan_id,
      _id: `${subscription.pricing_plan_id}-overage`,
      label: `${subscription?.pricing_plan?.name}: Main Transaction`,
      metadataKey: `usage_subscriptions.${i}.overage_transaction_metadata`,
      nameKey: `usage_subscriptions.${i}.overage_transaction_name`,
      metadata: subscription?.overage_transaction_metadata ?? {},
      name: subscription?.overage_transaction_name,
    },
    ...(subscription?.prepaid_transaction_metadata
      ? [
          {
            ...existingPrepaidTransaction,
            _id: `${subscription.pricing_plan_id}-prepaid`,
            pricing_plan_id: subscription.pricing_plan_id,
            label: `${subscription?.pricing_plan?.name}: Prepaid Transaction`,
            metadataKey: `usage_subscriptions.${i}.prepaid_transaction_metadata`,
            nameKey: `usage_subscriptions.${i}.prepaid_transaction_name`,
            metadata: subscription?.prepaid_transaction_metadata,
            name: subscription?.prepaid_transaction_name,
          },
        ]
      : []),
  ];
};

export const checkDisableAggregate = (subscription) => {
  return (subscription?.pricing_plan?.product_ids && subscription?.pricing_plan?.product_ids?.length > 1) ?? false;
};
