import React from 'react';
import _ from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { Centerer } from 'components/Core';
import { AllRowsSelectorDropdown } from 'components/Table/AllRowsSelectorDropdown';
import { ArrowContainer } from 'views/ExternalUpdates/styles';
import { TableCell } from 'views/ExternalUpdates/Components/TableCell';
import { compareDates, formatDate, sortingFunction } from 'views/ExternalUpdates/utils';

const { AI_DESCREPANCIES_TABLE_COLUMNS } = require('./consts');

const makeTableCell = ({ row, key, formatter = _.identity, onClick, isLink }) => {
  return (
    <TableCell
      isLink={isLink}
      onClick={onClick}
      isChanged={row.original.changedFields[key]}
      original={formatter(row.original.targetObject?.[key])}
      updated={formatter(row.original.aiDiscrepancy?.update_data?.[key])}
      field={key}
    />
  );
};

export const generateColumns = ({ onAction }) => {
  const columns = [
    {
      Header: (props) => (
        <AllRowsSelectorDropdown
          getToggleAllPageRowsSelectedProps={props?.getToggleAllPageRowsSelectedProps}
          getToggleAllRowsSelectedProps={props?.getToggleAllRowsSelectedProps}
          {...props}
        />
      ),
      accessor: 'checked',
      width: 32,
      Cell: ({ row }) => (
        <Checkbox data-cy="ai-discrepancies__checkbox" checked={row.isSelected} onClick={row.toggleRowSelected} />
      ),
      noPaddingRight: true,
      noInitialSortBy: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Created At',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.created_at.key,
      sortType: (rowA, rowB) =>
        compareDates(rowA.original.aiDiscrepancy.created_at, rowB.original.aiDiscrepancy.created_at) ? 1 : -1,
      width: 160,
      Cell: ({ row }) => (
        <Centerer
          onClick={() => {
            if (row.original.metadata.keys.length > 0) row.toggleRowExpanded();
          }}
        >
          <TableCell original={formatDate(row.original.aiDiscrepancy.created_at)} />
          {row.original.metadata.keys.length > 0 && (
            <ArrowContainer active={row.isExpanded} highlight={row.original.changedFields.metadata ?? false}>
              <ArrowUp />
            </ArrowContainer>
          )}
        </Centerer>
      ),
    },
    {
      Header: 'Name',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.name.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.name.key }),
      width: 250,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.name.key,
          onClick: async () => {
            await onAction({ data: row.original });
          },
          isLink: true,
        }),
    },
    {
      Header: 'Customer',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.customer_name.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.customer_name.key }),
      width: 200,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.customer_name.key,
          onClick: () =>
            window
              .open(`${window.location.origin}/customers/${row.original.targetObject?.customer_id}`, '_blank')
              .focus(),
          isLink: true,
        }),
    },
    {
      Header: 'Date',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.date.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'Total',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.amount.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.amount.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.amount.key,
        }),
    },
    {
      Header: 'Recurring',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.recurring_amount.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.recurring_amount.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.recurring_amount.key,
        }),
    },
    {
      Header: 'Seats',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.seats.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.seats.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.seats.key,
        }),
    },
    {
      Header: 'Type',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.recognition.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.recognition.key }),
      width: 100,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.recognition.key,
        }),
    },
    {
      Header: 'Product',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.product_name.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.product_name.key }),
      width: 130,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.product_name.key,
        }),
    },
    {
      Header: 'Start',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.start_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.start_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.start_date.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'End',
      accessor: AI_DESCREPANCIES_TABLE_COLUMNS.end_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: AI_DESCREPANCIES_TABLE_COLUMNS.end_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: AI_DESCREPANCIES_TABLE_COLUMNS.end_date.key,
          formatter: formatDate,
        }),
    },
  ];

  return columns;
};
