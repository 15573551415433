import styled from 'styled-components';
import { CentererVertical } from 'components/Core';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { ModalFooter } from 'components/Modal';
import { ReactComponent as PencilIcon } from 'images/pencil-new.svg'; // TODO: move this to a component

export const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 17px;
  line-height: 26px;

  span {
    color: var(--dark50);
    font-style: italic;
  }

  svg {
    vertical-align: text-top;
  }
`;

export const AggregatedUsageText = styled.div`
  font-weight: 600;
`;

export const FormRow = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

export const FlexBasis = styled.div`
  flex-basis: 33%;
`;

export const CustomFlexBasis = styled.div`
  flex-basis: ${({ width }) => width ?? '33%'};
`;

export const FullWidthFlexBasis = styled.div`
  flex-basis: 100%;
`;

export const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

export const FormFooter = styled(ModalFooter)`
  width: 100%;
`;

export const InfoTextWrapper = styled(Flexer)`
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background-color: var(--accentGrayFourth);
  margin-bottom: 16px;
`;

export const InfoText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack);
`;

export const Seperator = styled.div`
  height: ${({ columnDivider }) => (columnDivider ? '100%' : '2px')};
  align-self: stretch;
  background: var(--accentGraySecond);
  margin-bottom: 20px;
`;

export const SubscriptionSubtext = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--primaryBlack);
  opacity: 0.4;
`;

export const TransactionText = styled.div`
  color: var(--primaryBlack);
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-right: 16px;
  margin-left: 8px;
  white-space: nowrap;
  display: flex;
  gap: 6px;
`;

export const TransactionLinkWrapper = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

export const MetadataBlockWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--primaryBlack2);
`;

export const MetadataBadge = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--primaryBlack5);
  font-size: 12px;
`;

export const AddMetadataButton = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  cursor: pointer;
`;

export const ExternalLinkIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  cursor: pointer;
`;

export const StyledPencilIcon = styled(PencilIcon)`
  margin-bottom: 2px;
  width: 20px;
  height: 20px;

  path {
    fill: var(--primaryBlack);
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: #fff;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
`;

export const Tab = styled.div`
  padding: 4px 8px;
  background: ${(props) => (props.active ? 'var(--primaryBlue10)' : 'initial')};
  color: ${(props) => (props.active ? '#0075FF' : 'initial')};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${(props) => (props.active ? '700' : '600')};
  border-radius: 4px;
  cursor: pointer;
`;

export const OverflowDiv = styled.div`
  overflow: auto;
`;

export const StyledLink = styled.a`
  color: var(--primaryBlue);
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const FadedText = styled.span`
  color: var(--primaryBlack30);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CustomerField = styled(CentererVertical)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NewSubscriptionButton = styled.div`
  position: relative;
  width: 100%;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--primaryBlue);
  text-transform: uppercase;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};

  svg path {
    fill: var(--primaryBlue);
  }

  &:hover {
    cursor: pointer;
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const TrialUnitsText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
