import React from 'react';
import { useFormikContext } from 'formik';

import { FormikCustomCheckbox, SwitchWithLabel } from 'components/Controls';
import { Flexer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';

import { FormRow } from '../styles';

export const ProrateSection = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <FormRow>
      <Flexer gap="12px">
        <TooltipContainer
          toolTipContent={`We will prorate invoices and related spreads based on usage within a period. 
      Prepaid amounts will be prorated, whereas fixed amounts will remain the same.`}
          fontSize="12px"
        >
          <FormikCustomCheckbox name="is_prorated" label="Prorate subscription" />
        </TooltipContainer>

        {values?.is_prorated && (
          <TooltipContainer
            toolTipContent={`We will prorate by total period amount instead of usage date`}
            fontSize="12px"
            width={200}
          >
            <SwitchWithLabel
              name="prorate_by_amount"
              label="Prorate by amount"
              labelSize="12px"
              onChange={(value) => setFieldValue('prorate_by_amount', value)}
              checked={values?.prorate_by_amount}
            />
          </TooltipContainer>
        )}
      </Flexer>
    </FormRow>
  );
};
