// TODO: Refactor to use Portal and usePortal

import React from 'react';
import ReactDOM from 'react-dom';
import { StyledTooltipContainer } from './styles';
import { DIRECTIONS } from './consts';

export class MyPortal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

class Tooltip extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.width = props.width || 400;
    this.height = props.height || 36;
    this.space = props.space || 5;

    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);

    const self = this;

    this.onMouseMove = function (event) {
      if (self.el?.contains(event.target) && !self.state.visible) {
        self.showTooltip();
      } else if (!self.el?.contains(event.target) && !self.portalEl?.contains(event.target) && self.state.visible) {
        self.hideTooltip();
      }
    };

    document.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  showTooltip() {
    // some maths to align the tooltip with whatever you just hovered over (the 'target')
    // or maybe it's 'math' in your weird country
    const style = { zIndex: '999', width: this.width, ...this.props.tooltipStyles }; // this style object will be passed as the tooltip's 'style' prop
    const dimensions = this.el.getBoundingClientRect(); // where on the screen is the target

    // center align the tooltip by taking both the target and tooltip widths into account
    style.left = dimensions.left + dimensions.width / 2 - this.width / 2;
    style.left = Math.max(this.space, style.left); // make sure it doesn't poke off the left side of the page
    style.left = Math.min(style.left, document.body.clientWidth - this.width - this.space) + this.props.xOffset; // or off the right

    if (this.props.direction === DIRECTIONS.TOP) {
      style.top = dimensions.top - this.height - this.props.yOffset;
    } else {
      // Default: bottom placement
      style.top = dimensions.top + dimensions.height + this.space + this.props.yOffset;
    }

    this.setState({
      visible: true,
      style,
    });
  }

  hideTooltip() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <span // a span so it's valid HTML no matter where it's used
        onMouseOver={this.showTooltip}
        style={{
          borderBottom: this.props.underline ? '1px dashed grey' : 'none',
          color: 'inherit',
          ...this.props.tooltipWrapperStyles,
        }}
        ref={(el) => (this.el = el)}
      >
        {this.props.children}
        {this.state.visible && !this.props.hidePortal && (
          <MyPortal>
            <StyledTooltipContainer // this <Styles.TooltipContainer> isn't actually a child of the <span> above. Magic portal.
              style={this.state.style}
              isVisible={this.props.isVisible}
              fontSize={this.props.fontSize}
              backgroundColor={this.props.backgroundColor}
              hideArrow={this.props.hideArrow}
              leftAlign={this.props.leftAlign}
              data-cy="tooltip"
              ref={(el) => (this.portalEl = el)}
              zIndex={this.props.zIndex}
              arrowDirection={this.props.arrowDirection}
            >
              {this.props.toolTipContent}
            </StyledTooltipContainer>
          </MyPortal>
        )}
      </span>
    );
  }
}

const TooltipContainer = ({
  children,
  isVisible = true,
  fontSize = '14px',
  toolTipContent = '',
  backgroundColor = 'var(--dark7)',
  underline = false,
  xOffset = 0,
  yOffset = 0,
  hideArrow,
  direction = DIRECTIONS.BOTTOM,
  ...rest
}) =>
  isVisible ? (
    <Tooltip
      underline={underline}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      isVisible={isVisible}
      toolTipContent={toolTipContent}
      xOffset={xOffset}
      yOffset={yOffset}
      hideArrow={hideArrow}
      direction={direction}
      {...rest}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

export default TooltipContainer;
