import React, { useContext } from 'react';
import { AppContext } from 'AppContext';

import { TextBox } from 'components/Core';
import { CustomerSelector } from './CustomerSelector';
import CustomDateInput from './CustomDateInput';
import BooleanSelector from './BooleanSelector';
import { ACCESSORS } from './consts';

const ComponentAcessor = ({ column: { accessor, width }, data, subscriptionIndex }) => {
  const { formatDateWithLocale } = useContext(AppContext);

  switch (accessor) {
    case ACCESSORS.CUSTOMER:
      return <TextBox data-cy="duplicate-subscription-modal__customer">{data.customer.name}</TextBox>;
    case ACCESSORS.START_DATE:
      return (
        <TextBox data-cy="duplicate-subscription-modal__start-date">
          {data?.start_date ? formatDateWithLocale(data?.start_date) : '-'}
        </TextBox>
      );
    case ACCESSORS.END_DATE:
      return (
        <TextBox data-cy="duplicate-subscription-modal__end-date">
          {data?.end_date ? formatDateWithLocale(data?.end_date) : '-'}
        </TextBox>
      );
    case ACCESSORS.PRICING_PLAN:
      return <TextBox data-cy="duplicate-subscription-modal__pricing-plan">{data.pricing_plan.name}</TextBox>;
    case ACCESSORS.TRIAL_UNITS:
      return <TextBox data-cy="duplicate-subscription-modal__trial-units">{data.trial_units ?? 0}</TextBox>;
    case ACCESSORS.NEW_CUSTOMER:
      return <CustomerSelector name={`subscriptions.${subscriptionIndex}`} />;
    case ACCESSORS.NEW_START_DATE:
      return <CustomDateInput name={`subscriptions.${subscriptionIndex}.start_date`} />;
    case ACCESSORS.NEW_END_DATE:
      return <CustomDateInput name={`subscriptions.${subscriptionIndex}.end_date`} />;
    case ACCESSORS.AGGREGATE_USAGE:
      return <BooleanSelector name={`subscriptions.${subscriptionIndex}.aggregate_usage_on_parent`} width={width} />;
    case ACCESSORS.PRORATE:
      return <BooleanSelector name={`subscriptions.${subscriptionIndex}.is_prorated`} width={width} />;
    default:
      return <TextBox>-</TextBox>;
  }
};

export default ComponentAcessor;
