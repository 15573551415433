import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { FormikCustomSelector } from 'components/Controls';
import { getCustomerDisplayName } from 'models/customer';
import { getCustomersFromSearch } from 'shared/TransactionContent';

export const CustomerSelector = ({ isDisabled, name }) => {
  const { orgId } = useContext(AppContext);
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const customer = getFieldMeta(`${name}.customer`)?.value;

  const value = customer
    ? {
        label: getCustomerDisplayName({
          customerName: customer?.name,
          customerId: customer?.id,
        }),
        value: customer?.id,
      }
    : null;

  return (
    <FormikCustomSelector
      isDisabled={isDisabled}
      placeholder="Select customer"
      value={value}
      name={`${name}.selected_customer`}
      handleChange={(option) => {
        if (option) {
          setFieldValue(`${name}.customer`, option);
          setFieldValue(`${name}.customer_id`, option.value);
          setFieldValue(`${name}.customer_name`, option.label);
        } else {
          setFieldValue(`${name}.customer`, null);
          setFieldValue(`${name}.customer_id`, null);
          setFieldValue(`${name}.customer_name`, null);
        }
      }}
      loadOptions={(searchQuery, prevOptions, additional) =>
        getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
      }
      onCreateOption={async (newCustomerName) => {
        const newCustomer = await createCustomer({
          orgId,
          customerName: newCustomerName,
        });
        setFieldValue(`${name}.customer`, newCustomer);
        setFieldValue(`${name}.customer_id`, newCustomer.id);
        setFieldValue(`${name}.customer_name`, newCustomer.name);
      }}
      creatable
      isPaginateable
      isClearable
      noBorders
      containerWidth="100%"
    />
  );
};
