import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useQueryPolling } from 'api/jobHooks.helper';
import { useToasts } from 'components/Toasts';
import { getMonthlyBreakdown, getLLMReportSettings } from './requests';

export const CACHE_KEY = 'monthlyBreakdown';

export const useMonthlyBreakdownAPI = ({
  orgId,
  startMonth,
  startYear,
  endMonth,
  endYear,
  metadataFilter,
  rollup,
  plugins,
  optimisticAnalytics,
  autoFetch = true,
  isCommitted,
  countInfluxAsRenewed,
  growthType,
  segmentBy,
  includeLastTransactionUpdatedAt,
  hasCostAttribution,
  referral,
  usesMetricsEngine,
}) => {
  const params = autoFetch
    ? {
        startmo: startMonth + 1,
        startyear: startYear,
        endmo: endMonth + 1,
        endyear: endYear,
        rollup,
        plugins,
        isCommitted,
        optimisticAnalytics,
        countInfluxAsRenewed,
        growthType,
        segmentBy,
        includeLastTransactionUpdatedAt,
        hasCostAttribution,
        referral,
        usesMetricsEngine,
      }
    : {};

  const { data, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: [CACHE_KEY, orgId, metadataFilter, params],
    fetchFn: () => getMonthlyBreakdown({ orgId, body: metadataFilter, params }),
    fetchWithJobIdFn: (jobId) => getMonthlyBreakdown({ orgId, params: { jobId } }),
    queryOptions: {
      enabled:
        autoFetch &&
        !!orgId &&
        plugins !== null &&
        plugins !== undefined &&
        dayjs(new Date(startYear, startMonth))
          .startOf('month')
          .diff(dayjs(new Date(endYear, endMonth)).endOf('month'), 'month') < 0,
    },
    pollingErrorMessage: `Dashboard is taking too long to load for org ${orgId}`,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetchMonthlyBreakdown: refetch,
  };
};

const LLM_CACHE_KEY = 'llmReportSettings';

export const useLLMReportSettingsAPI = ({ orgId, query, enabled = false }) => {
  const { pushError } = useToasts();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [LLM_CACHE_KEY, orgId, query],
    () => getLLMReportSettings({ orgId, query }),
    {
      enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      onError: (err) => {
        pushError(err, 'Failed to process your query');
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
  };
};
