import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as ArrowUp } from 'images/arrow-up.svg';
import { getPreviousDateKey } from 'utils/dateUtils';
import { GoalsContext } from 'shared/Goals';
import { StyledTableName, TableSubRowsCountArrow, TableRowTag, TableRowTitle } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import DataTableRowValue, { METRICS_WITH_EXPLAIN, RETENTION_METRICS } from 'views/Dashboard/Common/DataTableRowValue';
import { RowTitleDisplay } from 'views/Dashboard/Common/RowTitleDisplay';
import { DashboardCellInner, DashboardHeadInner } from './styles';

export const generateSnapshotColumns = ({
  dataForTable,
  dataFilter,
  setSelectedMonth,
  setSelectedMetric,
  setSelectedUpsellType,
  setShowRevenueDetailsModal,
  setShowUpForRenewalModal,
  setBookingStart,
  setBookingEnd,
  setBookingsValue,
  setBookingIsRecurring,
  setShowBookingsModal,
  setShowExplainModal,
  setDataForExplainModal,
  setSummaryStartMonth,
  setSummaryColumnHeader,
  openCardChartModal,
}) => {
  const dateColumns = dataForTable?.columnHeaders.map((date, index) => ({
    Header: () => {
      if (date === 'CUMULATIVE') {
        return (
          <DashboardHeadInner>
            <TooltipContainer
              width={180}
              toolTipContent={`${dayjs.utc(dataFilter.startMonth).format('MMM YYYY')} to ${dayjs
                .utc(dataFilter.endMonth)
                .format('MMM YYYY')}`}
            >
              {date}
            </TooltipContainer>
          </DashboardHeadInner>
        );
      } else {
        return <DashboardHeadInner>{date}</DashboardHeadInner>;
      }
    },
    accessor: date,
    Cell: ({ row }) => {
      const { goals, selectedGoals } = useContext(GoalsContext);
      const title = row.original.title;
      const activeGoals = useMemo(() => selectedGoals[title]?.map((key) => goals?.[title]?.[key]) ?? [], [
        selectedGoals,
        goals,
        title,
      ]);

      return (
        <DashboardCellInner>
          {/* In Business Snapshot Table 0 depth level (tab toggler row) is always empty */}
          {!!row.depth && (
            <DataTableRowValue
              title={row.original.title}
              columnHeaders={dataForTable.columnHeaders}
              dataType={row.original.dataType}
              monthDataKey={row.original.monthDataKey}
              decimalPlaces={row.original.decimalPlaces || null}
              setSelectedMonth={setSelectedMonth}
              setSelectedMetric={setSelectedMetric}
              setSelectedUpsellType={setSelectedUpsellType}
              setShowRevenueDetailsModal={setShowRevenueDetailsModal}
              setShowUpForRenewalModal={setShowUpForRenewalModal}
              setBookingStart={setBookingStart}
              setBookingEnd={setBookingEnd}
              setShowBookingsModal={setShowBookingsModal}
              setBookingsValue={setBookingsValue}
              setBookingIsRecurring={setBookingIsRecurring}
              setShowExplainModal={setShowExplainModal}
              setDataForExplainModal={setDataForExplainModal}
              setSummaryStartMonth={setSummaryStartMonth}
              setSummaryColumnHeader={setSummaryColumnHeader}
              index={index}
              value={row.original.data[date]}
              date={date}
              previousValue={
                METRICS_WITH_EXPLAIN.has(row.original.title) || RETENTION_METRICS.has(row.original.title)
                  ? row.original.data[getPreviousDateKey(date)]?.value
                  : row.original.data[getPreviousDateKey(date)]
              }
              goalData={activeGoals}
              dataFilter={dataFilter}
              openCardChartModal={openCardChartModal}
            />
          )}
        </DashboardCellInner>
      );
    },
  }));

  const columns = [
    {
      Header: () => <DashboardHeadInner>Metric</DashboardHeadInner>,
      accessor: 'title',
      Cell: ({ row, cell: { value } }) =>
        !row.depth ? (
          <StyledTableName
            style={{ paddingTop: 4, paddingBottom: (!row.isExpanded || !row.canExpand) && 4 }}
            {...row.getToggleRowExpandedProps()}
          >
            <TableRowTag>{value}</TableRowTag>
            {row.canExpand && (
              <TableSubRowsCountArrow dashboardTable={true} isHidden={!row.isExpanded}>
                <ArrowUp />
              </TableSubRowsCountArrow>
            )}
          </StyledTableName>
        ) : (
          <TableRowTitle>
            <RowTitleDisplay element={row.original} />
          </TableRowTitle>
        ),
    },
    ...dateColumns,
  ];

  return columns;
};
