import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useOrganizationCountsAPI } from 'api/organizations';
import { ReactComponent as ProblemsCollapsedIcon } from 'images/problem-transactions-collapsed.svg';
import { PopoverLink } from './PopoverLink';

export const TransactionsLink = ({ section }) => {
  const { orgId } = useContext(AppContext);
  const { data } = useOrganizationCountsAPI({
    orgId,
    scopes: ['unresolvedExternalUpdates', 'unconfirmedTransactions', 'aiTransactionsDescrepencies'],
  });
  const needReview =
    (data?.unresolvedExternalUpdates ?? 0) +
      (data?.unconfirmedTransactions ?? 0) +
      (data?.aiTransactionsDescrepencies ?? 0) >
    0;

  return (
    <PopoverLink
      key={section.title}
      active={section.active}
      title={section.title}
      icon={section.icon}
      explainTooltipText={section.explainTooltipText}
      PopoverContents={section.PopoverContents}
    >
      {needReview ? <ProblemsCollapsedIcon style={{ position: 'absolute', right: 34, top: 16 }} /> : <></>}
    </PopoverLink>
  );
};
