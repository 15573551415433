import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'AppContext';
import { getMetadataOptions } from 'api/metadata';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import {
  AUTO_CHARGE_DATE_COMPARISON,
  AUTO_CHARGE_DATE_COMPARISON_TO_LABEL,
  AUTO_CHARGE_DATE_REFERENCE,
  AUTO_CHARGE_DATE_REFERENCE_TO_LABEL,
} from 'views/Billing/consts';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { CentererVertical } from 'components/Core';
import { templateToHTMLString } from 'utils/htmlUtils';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { DEFAULT_AVAILABLE_TAGS, DESCRIPTION_TAGS } from 'consts/billing';

import { FieldDescription, FormRow, PaddingWrapper } from '../styles';
import { ItemsRow } from '../BillingSettings/styles';

export const AutoChargeInvoiceSendingSettings = ({ values }) => {
  const { orgId } = useContext(AppContext);

  const [transactionMetadataTags, setTransactionMetadataTags] = useState([]);
  const [customerMetadataTags, setCustomerMetadataTags] = useState([]);

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...DESCRIPTION_TAGS,
  ];

  useEffect(() => {
    const getAvailableTags = async () => {
      // TODO: Rewrite to use useMetadataOptionsAPI hook
      const metadataOptions =
        (await getMetadataOptions({
          orgId,
          types: [METADATA_FILTER_TYPES.TRANSACTIONS, METADATA_FILTER_TYPES.CUSTOMERS],
        })) || {};

      setTransactionMetadataTags(
        metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]
          ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]).map(
              (tag) => `{transaction_metadata_${tag}}`,
            )
          : [],
      );

      setCustomerMetadataTags(
        metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]
          ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]).map((tag) => `{customer_metadata_${tag}}`)
          : [],
      );
    };

    if (orgId) {
      getAvailableTags();
    }
  }, [orgId]);

  return (
    <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
      <PaddingWrapper>
        <FieldTitle>Auto-charging</FieldTitle>
        <FormikCustomCheckbox
          name="autoChargeInvoiceSendingDefaults.auto_send"
          label="Auto send invoices to customers on auto charge before charging"
          data-cy="auto-charge-invoice-sending-setting__auto-send--checkbox"
        />

        {values.autoChargeInvoiceSendingDefaults?.auto_send && (
          <>
            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.days_before"
                label={`Send invoice to be auto charged how many business days before invoice date?`}
                placeholder="e.g. 2"
                value={values?.autoChargeInvoiceSendingDefaults?.days_before ?? 2}
                data-cy="auto-charge-invoice-sending-settings__days-before"
              />
            </FormRow>

            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.email_subject"
                label="Invoice Email Subject"
                HTMLString={templateToHTMLString({
                  text: values?.autoChargeInvoiceSendingDefaults?.email_subject,
                  availableTags,
                })}
                availableTags={availableTags}
                TopRightHeader={
                  <CentererVertical gap="8px">
                    <BillingDynamicFieldsTooltip
                      tags={{
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                      }}
                      XOffset={100}
                    />
                  </CentererVertical>
                }
                type="HTMLEdit"
                placeholder="Enter Invoice Email Subject..."
                data-cy="auto-charge-invoice-sending-settings__email-subject--input"
              />
            </FormRow>

            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.email_body"
                minHeight="186px"
                label="Invoice Email Body"
                HTMLString={templateToHTMLString({
                  text: values?.autoChargeInvoiceSendingDefaults?.email_body,
                  availableTags,
                })}
                availableTags={availableTags}
                TopRightHeader={
                  <CentererVertical gap="8px">
                    <BillingDynamicFieldsTooltip
                      tags={{
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                      }}
                      XOffset={100}
                    />
                  </CentererVertical>
                }
                type="HTMLEdit"
                placeholder="Enter Invoice Email Body..."
                data-cy="auto-charge-invoice-sending-settings__email-body--input"
              />
            </FormRow>
          </>
        )}

        <FieldDescription style={{ marginTop: '16px' }}>
          If auto-charging is enabled, then by default, set invoice auto-charge date to be
        </FieldDescription>

        <ItemsRow style={{ marginTop: '8px', marginBottom: 0 }}>
          {values.autoChargeInvoiceSendingDefaults.charging_date_comparison !== AUTO_CHARGE_DATE_COMPARISON.EQUAL && (
            <FormikCustomInput
              suffixStyle={{ fontSize: '13px', fontWeight: 400 }}
              name="autoChargeInvoiceSendingDefaults.charging_date_offset"
              type="number"
              suffix="days"
              data-cy="auto-charge-invoice-sending-settings__days-before"
            />
          )}
          <FormikCustomSelector
            name="autoChargeInvoiceSendingDefaults.charging_date_comparison"
            options={Object.values(AUTO_CHARGE_DATE_COMPARISON).map((comparison) => ({
              value: comparison,
              label: AUTO_CHARGE_DATE_COMPARISON_TO_LABEL[comparison],
            }))}
            noSort
          />
          <FormikCustomSelector
            name="autoChargeInvoiceSendingDefaults.charging_date_reference"
            options={Object.values(AUTO_CHARGE_DATE_REFERENCE).map((reference) => ({
              value: reference,
              label: AUTO_CHARGE_DATE_REFERENCE_TO_LABEL[reference],
            }))}
          />
        </ItemsRow>
      </PaddingWrapper>
    </FieldBody>
  );
};
