import { CARD_SIZES_COLUMN_SIZES, CARD_SIZES_ROW_SIZES } from '../Common/Card/consts';

const getMetricWithPosition = ({ rowIndex, columnIndex, lastMetricSize, metricData }) => {
  const columSize = CARD_SIZES_COLUMN_SIZES[metricData?.size];
  let newRowIndex = rowIndex;
  let newColumnIndex = columnIndex;

  if (newColumnIndex + columSize - 1 > 12) {
    // We need to go to a new line
    newColumnIndex = 1;
    newRowIndex = newRowIndex + CARD_SIZES_ROW_SIZES[lastMetricSize];
  }

  const metricDataWithPosition = {
    ...metricData,
    rowStart: newRowIndex,
    columnStart: newColumnIndex,
    active: true,
  };

  // We set the new newColumnIndex
  newColumnIndex += columSize;

  return {
    newRowIndex,
    newColumnIndex,
    metricDataWithPosition,
  };
};

const addMetricToReport = ({
  report,
  metricData,
  currentRowIndex,
  currentColumnIndex,
  lastMetricSize,
  addInRowIndex,
}) => {
  let rowIndex = currentRowIndex;
  let columnIndex = currentColumnIndex;
  let updatedLastMetricSize = lastMetricSize;
  const updatedReport = global.structuredClone(report);

  // if we are trying to add to a previous row
  if (addInRowIndex && addInRowIndex < rowIndex) {
    const lastMetricInRowIndex =
      (updatedReport.options.metricsSetting?.findIndex((metric) => metric.rowStart > addInRowIndex) ?? 1) - 1;
    const lastMetricInRow = updatedReport.options.metricsSetting[lastMetricInRowIndex];
    // reseting indexes
    rowIndex = addInRowIndex;
    columnIndex = lastMetricInRow.columnStart + CARD_SIZES_COLUMN_SIZES[lastMetricInRow.size];
    updatedLastMetricSize = lastMetricInRow.size;
    // Metrics we will keep in position
    const metricsSetting = updatedReport.options.metricsSetting.slice(0, lastMetricInRowIndex + 1);
    // All the metrics we will update position, including the new metric
    const metricsSettingToUpdate = updatedReport.options.metricsSetting.slice(lastMetricInRowIndex + 1);
    metricsSettingToUpdate.unshift(metricData);

    // Update positions
    const updatedMetricsSetting = metricsSettingToUpdate.reduce((acc, metric) => {
      const { newRowIndex, newColumnIndex, metricDataWithPosition } = getMetricWithPosition({
        rowIndex,
        columnIndex,
        lastMetricSize: updatedLastMetricSize,
        metricData: metric,
      });
      rowIndex = newRowIndex;
      columnIndex = newColumnIndex;
      updatedLastMetricSize = metricDataWithPosition.size;
      acc.push(metricDataWithPosition);
      return acc;
    }, metricsSetting);
    updatedReport.options.metricsSetting = updatedMetricsSetting;
  } else {
    // else we just add metric to the last row
    const { newRowIndex, newColumnIndex, metricDataWithPosition } = getMetricWithPosition({
      rowIndex,
      columnIndex,
      lastMetricSize: updatedLastMetricSize,
      metricData,
    });
    rowIndex = newRowIndex;
    columnIndex = newColumnIndex;
    updatedLastMetricSize = metricDataWithPosition.size;
    updatedReport.options.metricsSetting = [...report.options.metricsSetting, metricDataWithPosition];
  }

  return {
    updatedReport,
    rowIndex,
    columnIndex,
    updatedLastMetricSize,
  };
};

const updateMetricInReport = ({
  report,
  metricIndex,
  metricData,
  currentRowIndex,
  currentColumnIndex,
  lastMetricSize,
}) => {
  let rowIndex = currentRowIndex;
  let columnIndex = currentColumnIndex;
  let updatedLastMetricSize = lastMetricSize;
  const updatedReport = global.structuredClone(report);

  // Let's update metric
  updatedReport.options.metricsSetting[metricIndex] = metricData;

  // Does the metric change the size, if so we need to update all following widgets
  if (report.options.metricsSetting[metricIndex].size !== metricData.size) {
    // reseting indexes
    if (metricIndex === 0) {
      rowIndex = 1;
      columnIndex = 1;
    } else {
      const lastMetric = report.options.metricsSetting[metricIndex - 1];
      rowIndex = lastMetric.rowStart;
      columnIndex = lastMetric.columnStart + CARD_SIZES_COLUMN_SIZES[lastMetric.size];
      updatedLastMetricSize = lastMetric.size;
    }
    // Metrics we will keep in position
    const metricsSetting = updatedReport.options.metricsSetting.slice(0, metricIndex);
    // All the metrics we will update position
    const metricsSettingToUpdate = updatedReport.options.metricsSetting.slice(metricIndex + 1);
    metricsSettingToUpdate.unshift(metricData);

    // Update positions
    const updatedMetricsSetting = metricsSettingToUpdate.reduce((acc, metric) => {
      const { newRowIndex, newColumnIndex, metricDataWithPosition } = getMetricWithPosition({
        rowIndex,
        columnIndex,
        lastMetricSize: updatedLastMetricSize,
        metricData: metric,
      });
      rowIndex = newRowIndex;
      columnIndex = newColumnIndex;
      updatedLastMetricSize = metricDataWithPosition.size;
      acc.push(metricDataWithPosition);
      return acc;
    }, metricsSetting);
    updatedReport.options.metricsSetting = updatedMetricsSetting;
  }

  return {
    updatedReport,
    rowIndex,
    columnIndex,
    updatedLastMetricSize,
  };
};

const removeMetricFromReport = ({ report, metricIndex, currentRowIndex, currentColumnIndex, lastMetricSize }) => {
  let rowIndex = currentRowIndex;
  let columnIndex = currentColumnIndex;
  let updatedLastMetricSize = lastMetricSize;
  const updatedReport = global.structuredClone(report);

  // reseting indexes
  if (metricIndex === 0) {
    rowIndex = 1;
    columnIndex = 1;
  } else {
    const lastMetric = report.options.metricsSetting[metricIndex - 1];
    rowIndex = lastMetric.rowStart;
    columnIndex = lastMetric.columnStart + CARD_SIZES_COLUMN_SIZES[lastMetric.size];
    updatedLastMetricSize = lastMetric.size;
  }
  // Metrics we will keep in position
  const metricsSetting = updatedReport.options.metricsSetting.slice(0, metricIndex);
  const metricsSettingToUpdate = updatedReport.options.metricsSetting.slice(metricIndex + 1);

  // Update positions
  const updatedMetricsSetting = metricsSettingToUpdate.reduce((acc, metric) => {
    const { newRowIndex, newColumnIndex, metricDataWithPosition } = getMetricWithPosition({
      rowIndex,
      columnIndex,
      lastMetricSize: updatedLastMetricSize,
      metricData: metric,
    });
    rowIndex = newRowIndex;
    columnIndex = newColumnIndex;
    updatedLastMetricSize = metricDataWithPosition.size;
    acc.push(metricDataWithPosition);
    return acc;
  }, metricsSetting);
  updatedReport.options.metricsSetting = updatedMetricsSetting;

  return {
    updatedReport,
    rowIndex,
    columnIndex,
    updatedLastMetricSize,
  };
};

export { addMetricToReport, updateMetricInReport, removeMetricFromReport };
