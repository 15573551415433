import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, externalUpdateId, suffix }) => {
  let url = `${API_URL}/organizations/${orgId}/external-updates`;
  if (externalUpdateId) url = `${url}/${externalUpdateId}`;
  if (suffix) url = `${url}/${suffix}`;

  return url;
};

export const getExternalUpdates = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId }), { params });
  return response.data;
};

export const resolveExternalUpdate = async ({ orgId, externalUpdateId, body }) => {
  const response = await axios.post(buildUrl({ orgId, externalUpdateId, suffix: 'resolve' }), body);
  return response.data;
};

export const bulkApproveUpdates = async ({ orgId }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'bulk-overwrite' }));
  return response.data;
};

export const bulkDismissUpdates = async ({ orgId }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'bulk-dismiss' }));
  return response.data;
};

export const bulkResolveUpdates = async ({ orgId, externalUpdateIds, actionType, params }) => {
  const response = await axios.post(
    buildUrl({ orgId, suffix: 'bulk-resolve' }),
    { externalUpdateIds, actionType },
    { params },
  );
  return response.data;
};

export const undoUpdate = async ({ orgId, externalUpdateId }) => {
  const response = await axios.post(buildUrl({ orgId, externalUpdateId, suffix: 'undo' }));
  return response.data;
};

export const bulkUndoUpdates = async ({ orgId, externalUpdateIds }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'bulk-undo' }), { externalUpdateIds });
  return response.data;
};
