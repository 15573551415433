import React from 'react';
import styled from 'styled-components';
import { Row, Flexer } from 'components/Core';
import { SwitchWithLabel } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { usePortal } from 'components/Portal';
import { BUSINESS_SNAPSHOT_COLUMNS } from 'consts/global';
import { changeTableSummaryColumns } from '../Dashboard/Common/utils';

const ModalPopover = styled.div`
  width: 400px;
  background-color: white;
  text-transform: none;
  border: 1px solid var(--accentGray);
  border-radius: 12px;
  box-shadow: 15px 15px 60px rgba(0, 21, 46, 0.2);
  padding: 16px;
`;

const SectionTitle = styled.div`
  color: var(--primaryBlack);
  opacity: 0.5;
  font-weight: 900;
  font-size: 10px;
  margin-bottom: 8px;
`;

const SwitchLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

const SummarySwitchContainer = styled.div`
  padding: 8px;
  width: 30%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
`;

const SummarySwitchLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

const SwitchContainer = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin: 0;
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 16px 0;
  background-color: var(--accentGraySecond);
`;

const PopoverContainer = styled.span`
  span {
    cursor: pointer;
  }
`;

const TogglePopover = ({ popoverText, ...props }) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: -38, YOffset: -5 });

  return (
    <PopoverContainer ref={triggerRef}>
      <Row horizontal="start" onClick={() => togglePortal()}>
        <span>{popoverText}</span>
      </Row>

      {isPortalVisible && (
        <Portal>
          <OpenedDropdown {...props} />
        </Portal>
      )}
    </PopoverContainer>
  );
};

const OpenedDropdown = ({
  isIncreases,
  setIsIncreases,
  showWaterfallExistingRow,
  setShowWaterFallExistingRow,
  rollup,
  setRollup,
  snapshotSelectedColumns,
  setSnapshotSelectedColumns,
  showInfluxRow,
  setShowInfluxRow,
  showInfluxMovementRow,
  setShowInfluxMovementRow,
  countInfluxAsRenewed,
}) => {
  const summaryColumns = [
    {
      key: BUSINESS_SNAPSHOT_COLUMNS.MONTHS,
      label: 'Months',
      backgroundColor: 'var(--primaryBlack1)',
    },
    {
      key: BUSINESS_SNAPSHOT_COLUMNS.QUARTERS,
      label: 'Quarters',
      backgroundColor: 'var(--primaryYellow10)',
    },
    {
      key: BUSINESS_SNAPSHOT_COLUMNS.YEARS,
      label: 'Years',
      backgroundColor: 'var(--primaryPurple10)',
    },
    {
      key: BUSINESS_SNAPSHOT_COLUMNS.CUMULATIVE,
      label: 'Cumulative',
      backgroundColor: 'var(--primaryBlack2)',
    },
  ];

  return (
    <ModalPopover>
      <SectionTitle>COLUMNS</SectionTitle>

      <Flexer width="100%" justifyContent="space-between" style={{ marginBottom: 16 }} gap="8px">
        {summaryColumns.map(({ key, label, backgroundColor }) => (
          <SummarySwitchContainer key={key} backgroundColor={backgroundColor}>
            <SwitchWithLabel
              onChange={() =>
                setSnapshotSelectedColumns(
                  changeTableSummaryColumns({
                    currentColumns: snapshotSelectedColumns,
                    changeColumn: key,
                  }),
                )
              }
              checked={snapshotSelectedColumns[key]}
              name={`waterfall-summary-column-${key}`}
            />
            <SummarySwitchLabel>{label}</SummarySwitchLabel>
          </SummarySwitchContainer>
        ))}
      </Flexer>

      <SectionTitle>ROWS</SectionTitle>

      {setShowWaterFallExistingRow && (
        <SwitchContainer>
          <SwitchWithLabel
            onChange={setShowWaterFallExistingRow}
            checked={showWaterfallExistingRow}
            label={<SwitchLabel>Show existing row</SwitchLabel>}
            name="showsExisting"
          />
        </SwitchContainer>
      )}

      {countInfluxAsRenewed && setShowInfluxRow && (
        <SwitchContainer>
          <SwitchWithLabel
            onChange={setShowInfluxRow}
            checked={showInfluxRow}
            disabled={!showWaterfallExistingRow}
            label={
              <TooltipContainer
                toolTipContent="First enable Existing row"
                isVisible={!showWaterfallExistingRow}
                width={110}
                underline={false}
              >
                <SwitchLabel>Show In Flux row</SwitchLabel>
              </TooltipContainer>
            }
            name="showInflux"
          />
        </SwitchContainer>
      )}

      {countInfluxAsRenewed && setShowInfluxMovementRow && (
        <SwitchContainer>
          <SwitchWithLabel
            onChange={setShowInfluxMovementRow}
            checked={showInfluxMovementRow}
            disabled={!showWaterfallExistingRow || !showInfluxRow}
            label={
              <TooltipContainer
                toolTipContent="First enable In Flux row"
                isVisible={!showInfluxRow}
                width={110}
                underline={false}
              >
                <SwitchLabel>Show In Flux movement row</SwitchLabel>
              </TooltipContainer>
            }
            name="showInfluxMovement"
          />
        </SwitchContainer>
      )}

      <Divider />

      <SectionTitle>COMMON SETTINGS</SectionTitle>

      {setIsIncreases && (
        <SwitchContainer>
          <SwitchWithLabel
            onChange={setIsIncreases}
            checked={isIncreases}
            label={<SwitchLabel>Increases first</SwitchLabel>}
            name="isIncreases"
          />
        </SwitchContainer>
      )}

      {setRollup && (
        <SwitchContainer>
          <SwitchWithLabel
            onChange={setRollup}
            checked={rollup}
            label={<SwitchLabel>Roll up by parent customer</SwitchLabel>}
            name="rollup"
          />
        </SwitchContainer>
      )}
    </ModalPopover>
  );
};

export default TogglePopover;
