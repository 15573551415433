import { columnGenerator } from './columnGenerator';
import { ProductsTableCell } from 'views/Configuration/Tabs/ProductsTab/styles';
import { TextBox } from 'components/Core';
import { PRODUCT_IMPORT_METADATA_KEYS } from 'views/Billing/consts';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import { displayProductGlCell } from 'views/Configuration/Tabs/ProductsTab/ProductsTable';
import { getIntegrationDisplayName } from 'models/integration';
import styled from 'styled-components';

const ProductNameColumn = styled(TextBox)`
  cursor: pointer;
  font-weight: 700;
  color: var(--blueNormal100);
`;

const column = columnGenerator({ entityName: 'products' });

export const generateColumns = ({
  glIntegrations,
  crmIntegrations,
  incomeAccountOptionsByIntegrationId,
  incomeAccountRefIdByIntegrationId,
  onEditClick,
}) => {
  const columns = [
    column.header(),
    {
      Header: 'Product',
      accessor: 'product',
      width: 265,
      Cell: ({ row }) => {
        return <ProductNameColumn onClick={() => onEditClick(row)}>{row.original.name}</ProductNameColumn>;
      },
    },
    {
      Header: 'Recognition',
      accessor: 'recognition',
      width: 200,
      Cell: ({ row }) => <ProductsTableCell>{row.original.recognition ?? ''}</ProductsTableCell>,
    },
    {
      Header: 'Source',
      accessor: 'source',
      width: 200,
      Cell: ({ row }) => {
        const integratedServices = [...glIntegrations, ...crmIntegrations]
          .filter((integration) =>
            row.original?.imports?.some(
              (productImport) =>
                productImport.integration_id &&
                productImport.provider_object_id &&
                productImport.integration_id === integration.id,
            ),
          )
          .map((integration) => getIntegrationDisplayName(integration));
        const serviceNames = ['Contract PDF', ...integratedServices];
        return (
          <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__source-cell`}>
            {serviceNames.join(', ')}
          </ProductsTableCell>
        );
      },
    },
    {
      Header: 'GL Link & Income Account',
      accessor: `${PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT}`,
      width: 265,
      Cell: ({ row }) => {
        return displayProductGlCell({
          imports: row.original?.glImports,
          canRowExpand: row.canExpand,
          productName: row.original?.name,
          glIntegrations,
          incomeAccountOptionsByIntegrationId,
          incomeAccountRefIdByIntegrationId,
        });
      },
    },
    column.actions({ onEditClick: onEditClick }),
  ];

  return columns;
};
