import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { usePortal } from 'components/Portal';
import { InlineButton } from 'components/Buttons';
import { FlexEndContainer, FlexerColumn } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';

const Wrapper = styled(FlexerColumn)`
  align-items: flex-start;
  background: white;
  border: 1px solid var(--accentGray);
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 8px;
  min-width: 320px;
  padding: 12px;
  white-space: nowrap;
  z-index: 5;
`;

export const OverrideTotalTaxesButton = ({ dataCy, currency }) => {
  const { triggerRef, togglePortal, hidePortal, isPortalVisible, Portal } = usePortal();
  const { setFieldValue } = useFormikContext();

  const handleDisable = async () => {
    setFieldValue('total_tax_override', null);
    hidePortal();
  };

  const handleApply = async () => {
    hidePortal();
  };

  return (
    <>
      <InlineButton
        lineHeight="15px"
        fontSize="12px"
        isSecondary
        withBackground
        type="button"
        ref={triggerRef}
        onClick={togglePortal}
        data-cy={dataCy}
      >
        Override total taxes
      </InlineButton>
      {isPortalVisible && (
        <Portal>
          <Wrapper gap="12px">
            <FormikCustomInput
              placeholder="Enter the manual total taxes amount"
              width="100%"
              minWidth={160}
              type="number"
              suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
              name="total_tax_override"
            />
            <FlexEndContainer gap="12px" width="100%">
              <InlineButton withBackground isSecondary onClick={handleDisable}>
                Disable override
              </InlineButton>
              <InlineButton withBackground isSecondary onClick={handleApply}>
                Apply override
              </InlineButton>
            </FlexEndContainer>
          </Wrapper>
        </Portal>
      )}
    </>
  );
};
