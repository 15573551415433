import { FlexerColumn } from 'components/Core';
import { ConfirmModal } from 'shared/ConfirmModal';

export const InvoiceResyncNotification = ({ onClose }) => (
  <ConfirmModal
    title="Review changes to invoicing schedule?"
    content={
      <FlexerColumn gap="12px">
        You've made some changes that may affect invoices related to this transaction. <br />
        Review and confirm these changes now?
      </FlexerColumn>
    }
    closeButtonText="No, I can review later"
    confirmButtonText="Yes, take me there"
    onConfirm={() => (window.location.href = '/billing/external-updates')}
    onClose={onClose}
    contentPadding="70px"
    width="600px"
  />
);
