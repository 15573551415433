import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { getIntegrationsObjectFields, getPipelinesAndStages } from 'api/integrations';
import { getConfigs } from 'api/configs';
import { Deals, DEAL_OR_LINE_ITEM_CONSTS } from 'views/Wizard/steps/Deals';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { getKeyByValue } from 'utils/objectUtils';
import { transformConfigKeysToCamelCase } from 'models/appSettings/base';
import { RECOGNITION_TYPES } from 'consts/global';
import { CustomersAndProducts } from 'views/Wizard/steps/CustomersAndProducts';
import { FieldTitle } from 'views/Wizard/styles';
import { Loader } from 'components/Loaders';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { BodyContainer, IntegrationAdditionalDetails, Divider } from '../styles';
import { ConfigurationHeader } from 'views/Configuration/ConfigurationHeader';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';

export const ExternalSourcesTab = ({ configSelectedIntegration = {}, selectedIntegration }) => {
  const { organizations, orgConfigs } = useContext(AppContext);
  const orgId = organizations?.[0].id;

  const orgConfig = useRef(orgConfigs);

  const { data: integrationTypes } = useIntegrationsAPI({ orgId });

  // integrations
  const [integrationObjectFields, setIntegrationObjectFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pipelinesAndStages, setPipelinesAndStages] = useState({});

  // page 2
  const [defaultTransactionRecognition, setDefaultTransactionRecognition] = useState(); // string

  // only used if organization has hubspot integration
  const [closedWonStages, setClosedWonStages] = useState([]); // array

  // determines if to show deal or line item fields or both
  const [dealsOrLineItems, setDealsOrLineItems] = useState(DEAL_OR_LINE_ITEM_CONSTS.DEALS);

  // deal and line item mappings
  const [totalValueFieldDeal, setTotalValueFieldDeal] = useState();
  const [totalValueFieldLineItem, setTotalValueFieldLineItem] = useState();
  const [startDateFieldDeal, setStartDateFieldDeal] = useState();
  const [startDateFieldLineItem, setStartDateFieldLineItem] = useState();
  const [endDateFieldDeal, setEndDateFieldDeal] = useState();
  const [endDateFieldLineItem, setEndDateFieldLineItem] = useState();
  const [seatsFieldDeal, setSeatsFieldDeal] = useState();
  const [seatsFieldLineItem, setSeatsFieldLineItem] = useState();
  const [isTillCanceledMrr, setIsTillCanceledMrr] = useState();
  const [currencyISOField, setCurrencyISOField] = useState();

  const [transactionMetadataOptions, setTransactionMetadataOptions] = useState([]); // array
  const [internalId, setInternald] = useState(); // this also includes "internal database id"

  const [skipDailySync, setSkipDailySync] = useState(true);

  // page 3
  const [customerMetadataOptions, setCustomerMetadataOptions] = useState([]); // array

  const user = {
    orgId,
    company: organizations[0].name,
  };

  const data = {
    haveError: null,
    user,
    integrationTypes,
    integrationObjectFields,
    setReachedPage: () => {},
  };

  const hasHubspotIntegration = configSelectedIntegration?.service === INTEGRATION_SERVICES.HUBSPOT;

  // Get all configs for the selected integration and set initial values
  useEffect(() => {
    const setInitialValues = async () => {
      const params = {
        orgId: organizations[0].id,
        includeAllAppSettings: false,
      };

      if (configSelectedIntegration.service) {
        params.service = configSelectedIntegration.service;
      }

      const integrationConfigs = await getConfigs(params);

      const {
        transactionMappings,
        lineItemMappings,
        closedWonStages: appClosedWonStages,
        transactionMetadata,
        customerMetadata,
      } = transformConfigKeysToCamelCase({ config: integrationConfigs });

      const { defaultTransactionsRecognition } = orgConfig?.current;

      // recognition
      setDefaultTransactionRecognition(defaultTransactionsRecognition);

      // deal or line item fields (or both)
      if (transactionMappings && lineItemMappings) {
        setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.BOTH);
      } else if (transactionMappings) {
        setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.DEALS);
      } else if (lineItemMappings) {
        setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS);
      }

      // stages
      if (appClosedWonStages) {
        setClosedWonStages(appClosedWonStages);
      }

      // total
      const reccuringTotalValueDeal = getKeyByValue({
        object: transactionMappings,
        value: 'recurring_amount',
      });

      const reccuringTotalValueLine = getKeyByValue({
        object: lineItemMappings,
        value: 'recurring_amount',
      });

      const totalValueDeal = getKeyByValue({
        object: transactionMappings,
        value: 'amount',
      });

      const totalValueLine = getKeyByValue({
        object: lineItemMappings,
        value: 'amount',
      });
      setTotalValueFieldDeal(reccuringTotalValueDeal ?? totalValueDeal);
      setTotalValueFieldLineItem(reccuringTotalValueLine ?? totalValueLine);

      if (
        defaultTransactionsRecognition === RECOGNITION_TYPES.tillCanceled &&
        (reccuringTotalValueDeal || reccuringTotalValueLine)
      ) {
        setIsTillCanceledMrr(
          reccuringTotalValueDeal
            ? !integrationConfigs['transaction_mappings_isARR']
            : reccuringTotalValueLine
            ? !integrationConfigs['line_item_mappings_isARR']
            : true,
        );
      }

      // start date
      const startDateDeal = getKeyByValue({
        object: transactionMappings,
        value: 'start_date',
      });
      const startDateLine = getKeyByValue({
        object: lineItemMappings,
        value: 'start_date',
      });
      setStartDateFieldDeal(startDateDeal ?? null);
      setStartDateFieldLineItem(startDateLine ?? null);

      // end date
      const endDateDeal = getKeyByValue({
        object: transactionMappings,
        value: 'end_date',
      });

      const endDateLine = getKeyByValue({
        object: lineItemMappings,
        value: 'end_date',
      });
      setEndDateFieldDeal(endDateDeal ?? null);
      setEndDateFieldLineItem(endDateLine ?? null);

      // seats
      const seatsDeal = getKeyByValue({
        object: transactionMappings,
        value: 'seats',
      });
      const seatsLine = getKeyByValue({
        object: lineItemMappings,
        value: 'seats',
      });
      setSeatsFieldDeal(seatsDeal ?? null);
      setSeatsFieldLineItem(seatsLine ?? null);

      // Currency
      const currency = getKeyByValue({
        object: transactionMappings,
        value: 'currency',
      });
      setCurrencyISOField(currency ?? null);

      setTransactionMetadataOptions(Object.keys(transactionMetadata ?? {}));
      setCustomerMetadataOptions(Object.keys(customerMetadata ?? {}));

      setSkipDailySync(configSelectedIntegration?.metadata?.skip_daily_sync ?? false);
    };

    if (organizations && configSelectedIntegration.service && orgConfig?.current) {
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [organizations, configSelectedIntegration, orgConfig]);

  // Get external object fields and pipelines/stages
  useEffect(() => {
    const getHubspotPipelinesAndStages = async () => {
      const hubspotPipelinesAndStages = await getPipelinesAndStages(user.orgId, configSelectedIntegration.id);
      setPipelinesAndStages(hubspotPipelinesAndStages);
    };

    const getExternalObjectFields = async () => {
      const integrationExternalObjectFields = await getIntegrationsObjectFields({
        orgId: user.orgId,
        service: configSelectedIntegration.service,
      });
      setIntegrationObjectFields(integrationExternalObjectFields);
      setIsLoading(false);
    };

    if (integrationTypes.length === 0) {
      setIsLoading(false);
      return;
    }

    if (configSelectedIntegration.service) getExternalObjectFields();

    if (hasHubspotIntegration) getHubspotPipelinesAndStages();
  }, [integrationTypes, hasHubspotIntegration, user.orgId, configSelectedIntegration]);

  const rawData = {
    // integration settings
    defaultTransactionRecognition:
      defaultTransactionRecognition === 'linear:2' ? RECOGNITION_TYPES.linear : defaultTransactionRecognition,
    closedWonStages, // only add if organization has hubspot integration
    totalValueFieldDeal,
    totalValueFieldLineItem,
    startDateFieldDeal,
    startDateFieldLineItem,
    endDateFieldDeal,
    endDateFieldLineItem,
    seatsFieldDeal,
    seatsFieldLineItem,
    recurringRevenueFieldDeal:
      defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled ? totalValueFieldDeal : undefined,
    recurringRevenueFieldLineItem:
      defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled ? totalValueFieldLineItem : undefined,
    isTillCanceledMrr,
    transactionMetadataOptions,
    internalId,
    customerMetadataOptions,
    skipDailySync,
    currencyISOField,
  };

  if (internalId) {
    rawData.transactionMetadataOptions = [...rawData.transactionMetadataOptions, internalId];
  }

  return isLoading ? (
    <div className="w-100 flexer" style={{ marginTop: 20 }}>
      <Loader containerStyles={{ width: 40 }} />
    </div>
  ) : (
    <>
      <ConfigurationHeader activeTab={CONFIGURATION_TABS.SOURCES} selectedIntegration={selectedIntegration} />

      {configSelectedIntegration.service.includes(INTEGRATION_SERVICES.SALESFORCE) && (
        <IntegrationAdditionalDetails>
          <p>Additional details:</p>
          <p>- {configSelectedIntegration.metadata.instance_url}</p>
        </IntegrationAdditionalDetails>
      )}

      <Divider />

      <BodyContainer>
        {[INTEGRATION_SERVICES.HUBSPOT, INTEGRATION_SERVICES.SALESFORCE].includes(configSelectedIntegration.service) ? (
          <>
            <Deals
              configPageView
              configSelectedIntegration={configSelectedIntegration}
              defaultTransactionRecognition={defaultTransactionRecognition}
              setDefaultTransactionRecognition={setDefaultTransactionRecognition}
              closedWonStages={closedWonStages}
              setClosedWonStages={setClosedWonStages}
              transactionMetadataOptions={transactionMetadataOptions}
              setTransactionMetadataOptions={setTransactionMetadataOptions}
              internalId={internalId}
              setInternald={setInternald}
              totalValueFieldDeal={totalValueFieldDeal}
              setTotalValueFieldDeal={setTotalValueFieldDeal}
              totalValueFieldLineItem={totalValueFieldLineItem}
              setTotalValueFieldLineItem={setTotalValueFieldLineItem}
              startDateFieldDeal={startDateFieldDeal}
              setStartDateFieldDeal={setStartDateFieldDeal}
              startDateFieldLineItem={startDateFieldLineItem}
              setStartDateFieldLineItem={setStartDateFieldLineItem}
              endDateFieldDeal={endDateFieldDeal}
              setEndDateFieldDeal={setEndDateFieldDeal}
              endDateFieldLineItem={endDateFieldLineItem}
              setEndDateFieldLineItem={setEndDateFieldLineItem}
              seatsFieldDeal={seatsFieldDeal}
              setSeatsFieldDeal={setSeatsFieldDeal}
              seatsFieldLineItem={seatsFieldLineItem}
              setSeatsFieldLineItem={setSeatsFieldLineItem}
              isTillCanceledMrr={isTillCanceledMrr}
              setIsTillCanceledMrr={setIsTillCanceledMrr}
              pipelinesAndStages={pipelinesAndStages}
              dealsOrLineItems={dealsOrLineItems}
              setDealsOrLineItems={setDealsOrLineItems}
              skipDailySync={skipDailySync}
              setSkipDailySync={setSkipDailySync}
              currencyISOField={currencyISOField}
              setCurrencyISOField={setCurrencyISOField}
              {...data}
            />

            <div>
              <CustomersAndProducts
                configPageView
                customerMetadataOptions={customerMetadataOptions}
                setCustomerMetadataOptions={setCustomerMetadataOptions}
                {...data}
              />
            </div>
          </>
        ) : (
          <FieldTitle>
            <span>
              Configuration from the UI for {configSelectedIntegration?.service} is not available yet. Please reach out
              to us directly for custom configurations.
            </span>
          </FieldTitle>
        )}
      </BodyContainer>
    </>
  );
};
