// Sorted alphabetically
export const EVENTS = {
  ADD_SLACK_REPORTS_CONNECTION_FROM_BANNER: 'Add slack reports connection from banner',
  ADD_EMAIL_REPORTS_CONNECTION_FROM_BANNER: 'Add email reports connection from banner',
  APPLY_METADATA_FILTERS: 'Apply metadata filters',
  CHART_VISUALIZER_SELECT_METRICS: 'Chart Visualizer - Metrics selected',
  CHART_VISUALIZER_VISIT: 'Chart Visualizer visited',
  CLICK_CONNECT_SLACK: 'Click connect Slack',
  CLICK_SHARE_BUTTON: 'Click Share button',
  CLICK_COPY_LINK_TO_PAGE: 'Click Copy link to page',
  EXPORT_PNG: 'Export page as PNG',
  LOG_OUT: 'Log out',
  METRIC_ADDED_TO_DASHBOARD: 'Metric added to Dashboard',
  OPEN_BULK_PROCESS_TRANSACTIONS_MODAL: 'Open Bulk Process Transactions Modal',
  OPEN_CHANGE_RECURRING_UPDATES_METRICS: 'Open change Recurring Updates metrics',
  OPEN_CREATE_FORECAST_SCENARIO: 'Open Create Forecast Scenario',
  OPEN_DELETE_FORECAST_SCENARIO: 'Open Delete Forecast Scenario',
  OPEN_EDIT_FORECAST_SCENARIO: 'Open Edit Forecast Scenario',
  OPEN_DASHBOARD_CARD_CHART_DETAILS: 'Open Dashboard Card Chart Details',
  OPEN_DASHBOARD_MODAL_FROM_EMAIL_REPORT: 'Open Dashboard Modal from Email Report',
  OPEN_EDIT_METRIC_CARD_MODAL: 'Open Edit Metric Card Modal',
  OPEN_LOCK_OLD_TRANSACTIONS: 'Open Lock Old transactions Modal',
  OPEN_LOCK_ACCOUNTING_SPREADS: 'Open Lock Accounting Spreads Modal',
  OPEN_EXPLAIN_MODAL: 'Open Explain Modal',
  OPEN_GLOBAL_TOGGLES: 'Open Global Toggles',
  OPEN_GLOBAL_TOGGLES_MODAL: 'Open Global Toggles Modal',
  OPEN_LINK_SHARE_MODAL: 'Open Link Share Modal',
  OPEN_NEW_REPORT_MODAL: 'Open New Report Modal',
  OPEN_METADATA_ACTIONS_MODAL: 'Open Global Toggles',
  OPEN_REPORT_SHARE_MODAL: 'Open Report Share Modal',
  OPEN_REPORT_ONE_TIME_SHARE_MODAL: 'Open Report One Time Share Modal',
  OPEN_REVENUE_DEATILS_MODAL: 'Open Revenue Details Modal',
  OPEN_SYNCED_EXPORT_MODAL: 'Open Synced Export Modal',
  OPEN_UP_FOR_RENEWAL_MODAL: 'Open Up For Renewal Modal',
  OPEN_GOAL_NEW: 'Open New Goal Modal',
  OPEN_GOAL_EDIT: 'Open Edit Goal Modal',
  OPEN_GOAL_REMOVE: 'Open Remove Goal Modal',
  OPEN_GOAL_METRIC_MODAL: 'Open Goal Card Chart Details',
  SELECT_TRANSACTION_AUDIT: 'Select Transaction Audit',
  SELECT_DISPLAYED_GOAL_ROW: 'Select Displayed Goal Row',
  START_BULK_CONFIRMING_TRANSACTIONS: 'Start Bulk Confirming Transactions',
  TRY_ADDING_SECOND_FILTER: 'Try to add second filter',
  USER_COLLAPSED_NAVBAR: 'User collapsed Navbar',
  USER_EXPANDED_NAVBAR: 'User expanded Navbar',
  VIEW_ACCOUNTING_SPREADS: 'View Accounting Spreads',
  VIEW_ACCOUNTING_DRILLDOWN: 'View Accounting Drilldown',
  VIEW_SAAS_SPREADS: 'View SaaS Spreads',
  VIEW_TRANSACTION_HISTORY: 'View Transaction History',
  VISIT_AUTO_CONFIRM_PAGE: 'Autoconfirm page visit',
  VISIT_EXTERNAL_UPDATES: 'View Transaction History',
  VISIT_BILLING_PAGE: 'Visit Billing Page',
  VISIT_BILLING_REVIEW_TRANSACTIONS_PAGE: 'Visit Billing Review Transactions Page',
  VISIT_BILLING_INCONSISTENCIES_REPORT: 'Billing Inconsistencies Report',
  VISIT_REV_REC_EXCEPTIONS_REPORT: 'Rev Rec Exceptions Report',
  VISIT_INVOICE_CHECKOUT_PAGE: 'Visit Invoice Checkout Page',
  VIEW_SEGMENT_WITH_INSIGHTS: 'View Segment with Insights',
  CLICK_INSIGHTS_BANNER: 'Click Insights Banner',
  CLICK_BILLING_CREATE_NEW_INVOICES: 'Click Billing Create New Invoices',
  CLICK_BILLING_SCHEDULES_EXTERNAL_UPDATES: 'Click Billing Review Schedules External Updates',
  CLICK_BILLING_TO_DOS: 'Click Billing To-Dos',
  CLICK_BILLING_DASHBOARD: 'Click Billing Dashboard',
  CLICK_BILLING_USAGE_BASED_ENGINE: 'Click Billing Usage Based Engine',
  CLICK_BILLING_OVERDUE_INVOICES: 'Click Billing Overdue Invoices',
  CLICK_BILLING_FAILED_INVOICES: 'Click Billing Failed Invoices',
  CLICK_BILLING_PAYMENT_FAILED_INVOICES: 'Click Billing Payment Failed Invoices',
  CLICK_BILLING_FAILED_TO_SAVE_INVOICES: 'Click Billing Failed to Save Invoices',
  CLICK_BILLING_FAILED_TO_SEND_INVOICES: 'Click Billing Failed to Send Invoices',
  CLICK_BILLING_QUEUED_UP_INVOICES: 'Click Billing Queued Up Invoices',
  CLICK_BILLING_PROCESSING_INVOICES: 'Click Billing Processing Invoices',
  CLICK_BILLING_NEW_INVOICES: 'Click Billing New Invoices',
  CLICK_BILLING_AUTO_CHARGE_INVOICES: 'Click Billing Auto Charge Invoices',
  CLICK_BILLING_REMIND_INVOICES: 'Click Billing Reminders',
  CLICK_BILLING_AWAITING_PAYMENT_INVOICES: 'Click Billing Awaiting Payment Invoices',
  CLICK_BILLING_PAID_INVOICES: 'Click Billing Paid Invoices',
  CLICK_BILLING_VOID_INVOICES: 'Click Billing Void Invoices',
  CLICK_BILLING_AR_AGING_REPORT: 'Click Billing AR Aging Report',
  CLICK_TRANSACTIONS_ALERTS: 'Click Transactions Alerts',
  VIEW_TRANSACTIONS_ALERTS_PAGE_TAB: 'View Transactions Alerts Page Tab',
  OPEN_INVOICE_MODAL: 'Open Invoice Modal',
  OPEN_INVOICE_PREVIEW_MODAL: 'Open Invoice Preview Modal',
  OPEN_INVOICE_PDF_PREVIEW_MODAL: 'Open Invoice PDF Preview Modal',
  OPEN_INVOICING_SCHEDULE_MODAL: 'Open Invoicing Schedule Modal',
  OPEN_SELECT_TRANSACTION_FOR_INVOICING_SCHEDULE_MODAL: 'Open Select Transaction For Invoicing Schedule Modal',
  OPEN_SELECT_INVOICES_TO_IMPORT: 'Open Select Invoices to Import',
  OPEN_REPLACE_TRANSACTION_FOR_INVOICING_SCHEDULE_MODAL: 'Open Replace Transaction For Invoicing Schedule Modal',
  SET_VISIBLE_COLUMNS_ON_CUSTOMERS_PAGE: 'Set Visible Columns on Customers Page',
  SEE_HOW_SCHEDULE_WAS_GENERATED_CLICKED: 'See how schedule was generated" button was clicked',
  VISIT_USAGE_BASED_ENGINE_PAGE: 'Visit Usage Based Engine Page',
  VIEW_USAGE_BASED_ENGINE_TAB: 'View Usage Based Engine Tab',
  OPEN_USAGE_SUBSCRIPTION_MODAL: 'Open Usage Subscription Modal',
  OPEN_USAGE_SUBSCRIPTION_DUPLICATE_MODAL: 'Open Usage Subscription Duplicate Modal',
  OPEN_PRICING_PLAN_MODAL: 'Open Pricing Plan Modal',
  OPEN_CREATE_USAGE_EVENT_MODAL: 'Open Create Usage Event Modal',
  OPEN_DELETE_USAGE_SUBSCRIPTION_MODAL: 'Open Delete Usage Subscription Modal',
  OPEN_DELETE_PRICING_PLAN_MODAL: 'Open Delete Pricing Plan Modal',
  OPEN_DELETE_USAGE_EVENT_MODAL: 'Open Delete Usage Event Modal',
  OPEN_INVOICE_HISTORY: 'Open Invoice History Panel',
  SHOW_ALL_INVOICES_HOSTORY: 'Show All Invoice History in a Schedule',
};
