import { AUTO_CHARGE_DATE_COMPARISON, AUTO_CHARGE_DATE_REFERENCE } from 'views/Billing/consts';

export const DEFAULT_AUTO_CHARGE_INVOICE_SENDING_SETTINGS = {
  auto_send: false,
  days_before: 2,
  email_body: 'Your upcoming invoice is ready to view, and will be charged on invoice date.',
  email_subject: 'Your upcoming invoice is ready to view',
  charging_date_offset: 0,
  charging_date_reference: AUTO_CHARGE_DATE_REFERENCE.INVOICE_DATE,
  charging_date_comparison: AUTO_CHARGE_DATE_COMPARISON.EQUAL,
};
