import styled from 'styled-components';

export const CustomSelect = styled.div`
  display: flex;
  flex-direction: ${({ isFlexRow }) => (isFlexRow ? 'row' : 'column')};
  align-items: ${({ isFlexRow }) => (isFlexRow ? 'center' : 'initial')};
  gap: ${({ isFlexRow }) => (isFlexRow ? '0 5px' : '0')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'inherit' : 'pointer')};
  width: ${({ width }) => width};
`;

export const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.isError ? 'var(--primaryRed)' : ' var(--primaryBlack)')};
  opacity: ${(props) => (props.isError ? '1' : props.opacity ?? '0.4')};
  cursor: pointer;
  width: ${({ width }) => width};
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const InputSuffix = styled.div`
  color: green;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
`;
