import React from 'react';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { getHeatLevel, formatCurrency, getSegmentTitle } from './utils';
import { DATA_TYPES } from 'shared/Filters/utils';

import TableTile from './TableTile';
import { TableBodyRow, LeftTitlesGroup } from './SpreadsTable.styles';

export const SegmentRow = ({
  segmentBreakdowns,
  customerId,
  products,
  productCategories,
  segmentBy,
  quartiles,
  isARR,
  currency,
  setTransactionToShow,
  dataTypeToShow,
}) => {
  const onClickSegmentTitle = (segmentTitle) => () => {
    switch (segmentBy) {
      case 'transaction':
        const substringIndex = segmentTitle.lastIndexOf('(');
        if (substringIndex === -1) return; // if there is no transaction id
        const transactionId = segmentTitle.substring(substringIndex + 1, segmentTitle.length - 1); // removes the parenthesis around the transactionId
        setTransactionToShow?.({ id: transactionId, customer_id: customerId });
        break;
      default:
    }
  };

  const allSegmentKeys = new Set();
  for (const monthData of Object.values(segmentBreakdowns[customerId])) {
    for (const segmentKey of Object.keys(monthData)) {
      allSegmentKeys.add(segmentKey);
    }
  }

  return (
    <>
      {Array.from(allSegmentKeys).map((segmentKey, index, arr) => {
        const segmentTitle = getSegmentTitle({
          segmentKey,
          segmentBy,
          products,
          productCategories,
        });
        return (
          <TableBodyRow rowType="segmented" key={segmentKey}>
            <LeftTitlesGroup
              isSpreadSegmented={segmentBy}
              isFirstRow={!index}
              segmentCell
              isLastRow={index === arr.length - 1}
            >
              <TableTile
                left={true}
                value={segmentTitle.length < 35 ? segmentTitle : `${segmentTitle.substring(0, 35)}...`}
                toolTipText={segmentTitle.length >= 35 ? segmentTitle : null}
                style={{
                  width: 250,
                  cursor: segmentBy === 'transaction' ? 'pointer' : undefined,
                  color: segmentBy === 'transaction' ? 'var(--primaryBlue)' : undefined,
                }}
                onClick={segmentBy === 'transaction' ? onClickSegmentTitle(segmentTitle) : null}
              />
            </LeftTitlesGroup>

            {/* months cells */}
            {Object.values(segmentBreakdowns[customerId]).map((monthBreakdowns, monthIndex) => {
              const value =
                (dataTypeToShow === DATA_TYPES.revenue
                  ? monthBreakdowns?.[segmentKey]?.amount
                  : monthBreakdowns?.[segmentKey]?.seats) ?? 0;

              const formattedValue =
                dataTypeToShow === DATA_TYPES.revenue
                  ? formatCurrency({ rawValue: value, isARR, currency, shouldAbbreviate: true })
                  : numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: value });
              return (
                <TableTile
                  key={monthIndex}
                  primary
                  style={{ fontSize: '10px' }}
                  index={monthIndex}
                  heatLevel={getHeatLevel({ quartiles, value })}
                  value={formattedValue}
                  isMoneyColumn={true}
                />
              );
            })}
          </TableBodyRow>
        );
      })}
    </>
  );
};
