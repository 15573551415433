import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from 'AppContext';
import { TooltipContainer } from 'components/Tooltip';
import { formatCustomerName } from 'utils/stringUtils';
import { StyledUserInfo, HeaderCompanyName, NavCompanyName, HeaderUserName, NavUserName } from './styles';

export const UserInfo = ({ isHeaderInfo = false }) => {
  const { user = {} } = useAuth0();
  const { organizations } = useContext(AppContext);
  const myOrganization = organizations?.[0];

  const getUserIdentity = () => {
    // People who sign up via email-password instead of socials
    if (user?.name === user?.email) return user?.nickname;

    // for people that sign up via socials like google
    return formatCustomerName(user?.name);
  };

  const tooltipStyles = {
    color: 'var(--primaryBlack)',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: '14px',
    width: '179px',
    padding: '6px 4px',
    marginLeft: '16px',
    border: '1px solid var(--accentGray)',
    boxShadow: '0px 4px 20px var(--primaryBlack10)',
  };

  return isHeaderInfo ? (
    <StyledUserInfo isHeaderInfo={isHeaderInfo}>
      <HeaderCompanyName>{myOrganization?.name}</HeaderCompanyName>
      <HeaderUserName>{getUserIdentity()}</HeaderUserName>
    </StyledUserInfo>
  ) : (
    <TooltipContainer toolTipContent={myOrganization?.name} tooltipStyles={tooltipStyles} backgroundColor="white">
      <StyledUserInfo isHeaderInfo={isHeaderInfo}>
        <NavCompanyName>{myOrganization?.name}</NavCompanyName>
        <NavUserName>{getUserIdentity()}</NavUserName>
      </StyledUserInfo>
    </TooltipContainer>
  );
};
