import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FieldArray, Formik, useFormikContext } from 'formik';
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg';
import { ReactComponent as RightCircle } from 'images/arrow-right-circle.svg';
import { ReactComponent as AddIcon } from 'images/transaction_spread_add.svg';
import { CustomDatePicker, FormikCustomInput, FormikOnFormChangeEffect } from 'components/Controls';
import { ExternalLinkIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import {
  SpreadRows,
  SpreadRow,
  LeftToRow,
  LeftToTitle,
  LeftToContent,
  SpreadHeader,
  HeaderItem,
  RowItem,
  ActionIcon,
  UsageDetailsButton,
  InvoiceButton,
} from './styles';
import { RECOGNITION_TYPES, NUMBER_FORMATS, ISO_CODE_TO_SYMBOL } from 'consts/global';
import { INITIAL_EVENT } from 'shared/TransactionContent/TransactionBody/TransactionSpread/consts';
import { TOTAL_AMOUNT_METHOD } from 'shared/TransactionContent/consts';
import { calculateRecognizePercent, calculateRecognizeAmount, mergeRecognitionEvents } from './utils';
import { FlexerColumnGrower, RecognitionBody, RecognitionTable } from '../TransactionRecognitionsSchedule/styles';
import { TRANSACTION_MODAL_MODE } from '../../consts';
import { TransactionRecalculateUsageButton } from './TransactionRecalculateUsageButton';
import { CHANGE_TYPES } from './consts';

export const TransactionSpread = ({
  onChange,
  transactionAmount,
  currentTransactionSpread,
  recognitionType,
  isARR,
  currency,
  usagePricingBreakdown = {},
  usageEventName,
  customerId,
  disabled,
}) => {
  const {
    currentTransaction,
    mode,
    changedData,
    invoicingSchedule,
    spreadsFormRef,
    fetchTransactionRef,
    openInvoicingScheduleModal,
    transactionFormValues,
  } = useContext(TransactionContext);
  const numberFormatter = useCurrencyNumberFormatter({ currency });
  const { setFieldValue: setMainFieldValue } = useFormikContext();

  const [leftToRecognize, setLeftToRecognize] = useState({
    moneyLeft: 0,
    percentLeft: 0,
  });

  const [initialValues, setInitialValues] = useState(null);
  const [spreadsWithInvoices, setSpreadsWithInvoices] = useState(null);

  const externalUpdateMode = mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE;

  const isTotalAmountAutoCalculated =
    RECOGNITION_TYPES.eventNotRecurring === transactionFormValues?.recognition &&
    TOTAL_AMOUNT_METHOD.AUTO_CALCULATED === transactionFormValues?.total_amount_method;

  useEffect(() => {
    // if in external_updates mode, then set the change_type for each spread
    if (externalUpdateMode && changedData.spread_updates?.length > 0) {
      // merge events together by their external_id and spread_id
      setInitialValues({
        recognitionEvents: mergeRecognitionEvents({
          existingEvents: currentTransactionSpread.recognitionEvents,
          externalUpdateEvents: changedData.spread_updates,
        }),
      });
    } else {
      setInitialValues({ ...currentTransactionSpread });
    }
  }, [currentTransactionSpread, changedData, externalUpdateMode]);

  useEffect(() => {
    if (
      initialValues &&
      invoicingSchedule &&
      initialValues?.recognitionEvents?.some((event) => !!event?.invoice_item_ids?.length)
    ) {
      const spreadsWithInvoices = initialValues?.recognitionEvents?.reduce((acc, event) => {
        if (event?.invoice_item_ids?.length) {
          const invoices = invoicingSchedule?.invoices?.filter((invoice) =>
            invoice?.invoice_items?.some((item) => event?.invoice_item_ids?.includes(item.id)),
          );
          if (invoices?.length) {
            acc[event.spread_id] = invoices?.[0]?.id;
          }
        }
        return acc;
      }, {});

      setSpreadsWithInvoices(spreadsWithInvoices);
    }
  }, [initialValues, invoicingSchedule]);

  const calculateLeftToRecognize = (formValues) => {
    const totalValuesAmount = formValues.recognitionEvents.reduce((sum, value) => {
      return Number(sum) + Number(value.amount);
    }, 0);

    const moneyLeft =
      Number(transactionAmount) - totalValuesAmount > 0 ? Number(transactionAmount) - totalValuesAmount : 0;
    const percentLeft =
      Math.floor(100 - (totalValuesAmount / Number(transactionAmount)) * 100) > 0
        ? Math.floor(100 - (totalValuesAmount / Number(transactionAmount)) * 100)
        : 0;

    setLeftToRecognize({
      moneyLeft,
      percentLeft,
    });
  };

  const handleFormChange = (values) => {
    calculateLeftToRecognize(values);
    onChange(values);
  };

  const viewUsageDetails = ({ eventSpread }) => {
    const searchParams = new URLSearchParams({
      tab: 'usage-events',
      eventName: usageEventName,
      customerId,
      startDate: dayjs.utc(eventSpread.date).startOf('month'),
      endDate: dayjs.utc(eventSpread.date).endOf('month'),
    });
    window.open(`${window.location.origin}/billing/usage-based-engine?${searchParams}`, '_blank');
  };

  const isRecurring = recognitionType === RECOGNITION_TYPES.eventRecurring;
  const generatedSpreads = Object.values(usagePricingBreakdown ?? {}).reduce((acc, curr) => {
    for (const spreadId of Array.isArray(curr.spreadIds) ? curr.spreadIds : [curr.spreadId]) {
      acc[spreadId] = curr;
    }
    return acc;
  }, {});

  const isUsageBased = !!transactionFormValues?.usage_subscription_id;

  if (!initialValues) return null;

  return (
    <>
      <SpreadHeader>
        <HeaderItem width="22%">Month</HeaderItem>
        {!isRecurring && (
          <HeaderItem width="18%" style={{ textAlign: 'right' }}>
            % to recognize
          </HeaderItem>
        )}
        <HeaderItem width="18%" textAlign="right" paddingRight="10px">
          Amount
        </HeaderItem>
        <HeaderItem width="18%" textAlign="right" paddingRight="10px">
          Seats
        </HeaderItem>
        {isRecurring ? (
          <HeaderItem width="18%" textAlign="center">
            {isARR ? 'ARR' : 'MRR'}
          </HeaderItem>
        ) : (
          <HeaderItem width="18%" textAlign="center">
            One-time
          </HeaderItem>
        )}
        <HeaderItem width="23%" textAlign="right">
          {currentTransaction.usage_subscription_id ? (
            <TransactionRecalculateUsageButton
              orgId={currentTransaction.organization_id}
              transactionId={currentTransaction.id}
              usageSubscriptionId={currentTransaction.usage_subscription_id}
              fetchTransactionRef={fetchTransactionRef}
            />
          ) : (
            'Actions'
          )}
        </HeaderItem>
      </SpreadHeader>
      <FlexerColumnGrower>
        {/*TODO [TC 2022-03-02]: Can we refactor to avoid a separate Formik context here?
      We should use the main formik context instead and have the spread values as part
      of recognitionEvents. This would allow us to use setFieldValue directly instead of
      having to pass setMainFieldValue as parameter (since that one is for the main context)
      */}
        <Formik
          initialValues={initialValues}
          innerRef={spreadsFormRef}
          enableReinitialize={externalUpdateMode ? false : true}
        >
          {({ values, setFieldValue }) => {
            const handleChangeAmountValue = (val, index) => {
              const totalValuesAmount = values.recognitionEvents.reduce(
                (sum, value, arrIndex) =>
                  arrIndex === index ? Number(sum) + Number(val) : Number(sum) + Number(value.amount),
                0,
              );

              if (isRecurring) {
                setFieldValue(`recognitionEvents.${index}.amount`, +val);
              } else if (isTotalAmountAutoCalculated) {
                setFieldValue(`recognitionEvents.${index}.amount`, +val);
                setMainFieldValue('amount', totalValuesAmount);
                for (const [i, recognitionEvent] of values.recognitionEvents.entries()) {
                  setFieldValue(
                    `recognitionEvents.${i}.percent`,
                    Math.round(((i === index ? +val : recognitionEvent.amount) / totalValuesAmount) * 10000) / 100,
                  );
                }
              } else if (typeof transactionAmount === 'string' && !transactionAmount.length) {
                setFieldValue(`recognitionEvents.${index}.amount`, +val);
              } else {
                if (totalValuesAmount < transactionAmount) {
                  setFieldValue(`recognitionEvents.${index}.amount`, +val);
                  setFieldValue(
                    `recognitionEvents.${index}.percent`,
                    calculateRecognizePercent(+val, transactionAmount),
                  );
                } else {
                  setFieldValue(`recognitionEvents.${index}.amount`, +val - (totalValuesAmount - transactionAmount));
                  setFieldValue(
                    `recognitionEvents.${index}.percent`,
                    calculateRecognizePercent(+val - (totalValuesAmount - transactionAmount), transactionAmount),
                  );
                }
              }
            };

            const handleChangeSeatsValue = (val, index) => setFieldValue(`recognitionEvents.${index}.seats`, +val);

            return (
              <RecognitionBody>
                <RecognitionTable>
                  <FieldArray name="recognitionEvents">
                    {({ remove, push }) => (
                      <SpreadRows data-cy={'transaction-modal__spread-rows'}>
                        {values.recognitionEvents.length > 0 &&
                          values.recognitionEvents.map((event, index) => {
                            const usageDetails = generatedSpreads[event.spread_id];
                            const isGenerated = !!usageDetails;

                            return (
                              <TooltipContainer
                                width={150}
                                toolTipContent={`${event.change_type} spread`}
                                isVisible={[CHANGE_TYPES.DELETED, CHANGE_TYPES.NEW, CHANGE_TYPES.CHANGED].includes(
                                  event.change_type,
                                )}
                              >
                                <SpreadRow
                                  key={index}
                                  changeType={event.change_type}
                                  data-cy={`spread-row-${index}`}
                                  disabled={disabled}
                                >
                                  <RowItem width="22%">
                                    <CustomDatePicker
                                      formik
                                      onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                                      selected={
                                        event.date && dayjs(event.date).isValid()
                                          ? formatDateForDatepicker(event.date)
                                          : null
                                      }
                                      name={`recognitionEvents.${index}.date`}
                                      disabled={isGenerated || externalUpdateMode}
                                      backgroundColor={
                                        event?.changed_fields?.date ? 'var(--primaryYellow20)' : undefined
                                      }
                                      toolTipWidth={150}
                                      tooltipInputDisplay={
                                        event?.changed_fields?.date ? (
                                          <div>
                                            Previous value:{' '}
                                            {event?.changed_fields?.date.original
                                              ? dayjs.utc(event?.changed_fields?.date.original).format('MM/YYYY')
                                              : ''}
                                          </div>
                                        ) : undefined
                                      }
                                    />
                                  </RowItem>

                                  {!isRecurring && (
                                    <RowItem width="18%">
                                      <FormikCustomInput
                                        data-cy={`recognitionEvents.${index}.percent`}
                                        name={`recognitionEvents.${index}.percent`}
                                        suffix="%"
                                        handleChange={(val) =>
                                          handleChangeAmountValue(
                                            calculateRecognizeAmount(+val, transactionAmount),
                                            index,
                                          )
                                        }
                                        style={{ direction: 'ltr', height: 42 }}
                                        type="number"
                                        max="100"
                                        isDisabled={isTotalAmountAutoCalculated || isUsageBased}
                                      />
                                    </RowItem>
                                  )}

                                  <RowItem width="18%">
                                    <FormikCustomInput
                                      data-cy={`recognitionEvents.${index}.amount`}
                                      data-disabled={isGenerated || externalUpdateMode}
                                      name={`recognitionEvents.${index}.amount`}
                                      suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
                                      placeholder="Enter Amount"
                                      style={{ direction: 'ltr', height: 42 }}
                                      type="number"
                                      handleChange={(val) => handleChangeAmountValue(val, index)}
                                      disabled={isGenerated || externalUpdateMode}
                                      backgroundColor={
                                        event?.changed_fields?.amount ? 'var(--primaryYellow20)' : undefined
                                      }
                                      toolTipWidth={150}
                                      tooltipInputDisplay={
                                        event?.changed_fields?.amount ? (
                                          <div>Previous value: {event?.changed_fields?.amount.original}</div>
                                        ) : undefined
                                      }
                                    />
                                  </RowItem>

                                  <RowItem width="18%">
                                    <FormikCustomInput
                                      data-cy={`recognitionEvents.${index}.seats`}
                                      name={`recognitionEvents.${index}.seats`}
                                      placeholder="Enter Seats"
                                      style={{ direction: 'ltr', height: 42 }}
                                      type="number"
                                      handleChange={(val) => handleChangeSeatsValue(val, index)}
                                      disabled={isGenerated || externalUpdateMode}
                                      backgroundColor={
                                        event?.changed_fields?.seats ? 'var(--primaryYellow20)' : undefined
                                      }
                                      toolTipWidth={150}
                                      tooltipInputDisplay={
                                        event?.changed_fields?.seats ? (
                                          <div>Previous value: {event?.changed_fields?.seats.original}</div>
                                        ) : undefined
                                      }
                                    />
                                  </RowItem>

                                  {isRecurring ? (
                                    <RowItem width="18%" isOpacityRow={!isRecurring} style={{ textAlign: 'center' }}>
                                      <span>
                                        {numberFormatter({
                                          type: NUMBER_FORMATS.CURRENCY,
                                          rawValue: isARR
                                            ? +values.recognitionEvents[index].amount * 12
                                            : +values.recognitionEvents[index].amount,
                                          decimalPlaces: 2,
                                        })}
                                      </span>
                                    </RowItem>
                                  ) : (
                                    <RowItem width="18%" isOpacityRow={isRecurring} style={{ textAlign: 'center' }}>
                                      <span>
                                        {numberFormatter({
                                          type: NUMBER_FORMATS.CURRENCY,
                                          rawValue: +values.recognitionEvents[index].amount,
                                        })}
                                      </span>
                                    </RowItem>
                                  )}

                                  <RowItem width="23%" style={{ justifyContent: 'flex-end' }}>
                                    {(!!index || values.recognitionEvents.length > 1) &&
                                      !isGenerated &&
                                      !externalUpdateMode && (
                                        <ActionIcon
                                          data-cy={`transaction-modal__spread-rows--delete-icon-${index}`}
                                          onClick={() => {
                                            handleChangeAmountValue(0, index);
                                            remove(index);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </ActionIcon>
                                      )}

                                    {isGenerated && (
                                      <TooltipContainer
                                        width={150}
                                        toolTipContent={'View all usage events in this month'}
                                      >
                                        <UsageDetailsButton
                                          disabled={!usageDetails?.startDate}
                                          onClick={() => viewUsageDetails({ usageDetails, eventSpread: event })}
                                          data-cy={`spread-row-${index}__usage-details-button`}
                                          isLast={values.recognitionEvents.length === index + 1 && !externalUpdateMode}
                                        >
                                          <ExternalLinkIcon size="15px" />
                                          Usage Details
                                        </UsageDetailsButton>
                                      </TooltipContainer>
                                    )}

                                    {spreadsWithInvoices?.[event.spread_id] && (
                                      <InvoiceButton
                                        data-cy={`spread-row-${index}__invoice-button`}
                                        onClick={() =>
                                          openInvoicingScheduleModal({
                                            invoicingSchedule: { id: invoicingSchedule?.id },
                                            invoice: { id: spreadsWithInvoices[event.spread_id] },
                                          })
                                        }
                                      >
                                        <RightCircle width={15} height={15} />
                                        Open Invoice
                                      </InvoiceButton>
                                    )}

                                    {values.recognitionEvents.length === index + 1 && !externalUpdateMode && (
                                      <ActionIcon
                                        data-cy={`transaction-modal__spread-rows--add-icon-${index}`}
                                        onClick={() => push({ ...INITIAL_EVENT })}
                                      >
                                        <AddIcon />
                                      </ActionIcon>
                                    )}
                                  </RowItem>
                                </SpreadRow>
                              </TooltipContainer>
                            );
                          })}
                        {!isTotalAmountAutoCalculated && (
                          <LeftToRow>
                            <LeftToTitle>Left to recognize:</LeftToTitle>
                            <LeftToContent>
                              {numberFormatter({
                                type: NUMBER_FORMATS.CURRENCY,
                                rawValue: leftToRecognize.moneyLeft,
                                decimalPlaces: 2,
                              })}{' '}
                              / <span>{leftToRecognize.percentLeft}%</span>
                            </LeftToContent>
                          </LeftToRow>
                        )}
                      </SpreadRows>
                    )}
                  </FieldArray>
                  <FormikOnFormChangeEffect onChange={() => handleFormChange(values)} />
                </RecognitionTable>
              </RecognitionBody>
            );
          }}
        </Formik>
      </FlexerColumnGrower>
    </>
  );
};
