import React, { useState } from 'react';
import { BlueText, Spacer } from 'components/Core';
import { SelectDropdownButton } from 'components/Buttons';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { RollupExplainModal } from './RollupExplainModal';
import { CustomOptionTitle, CustomOptionText, CustomOptionItem, RollUpExplain } from './styles';
import {
  ROLL_UP_BOOLEAN_TO_SELECTOR,
  ROLL_UP_SELECTOR_TO_BOOLEAN,
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_DESCRIPTIONS,
  TOGGLES_SELECTORS_KEYS,
} from './consts';

export const GlobalToggleIsRollup = ({ globalTogglesState, setGlobalTogglesState }) => {
  const [showExplainRollupModal, setShowExplainRollupModal] = useState();

  const closeDropdown = () => {
    document.dispatchEvent(
      new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  return (
    <>
      <SelectDropdownButton
        showSelectedDirectly
        noOptionsStyles
        name={STORAGE_TOGGLES_KEYS.IS_ROLLUP}
        options={{
          [TOGGLES_SELECTORS_KEYS.NO_ROLL_UP]: (
            <CustomOptionItem>
              <CustomOptionTitle>No Roll up by parent</CustomOptionTitle>
              <CustomOptionText noMarginBottom>
                {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.NO_ROLL_UP]}
              </CustomOptionText>
            </CustomOptionItem>
          ),

          [TOGGLES_SELECTORS_KEYS.ROLL_UP]: (
            <CustomOptionItem>
              <CustomOptionTitle>Roll up by parent</CustomOptionTitle>
              <CustomOptionText>{TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.ROLL_UP]}</CustomOptionText>

              <RollUpExplain
                onClick={(event) => {
                  event.stopPropagation();
                  closeDropdown();
                  setShowExplainRollupModal(true);
                }}
              >
                <RelatedHelpIcon />
                See how
                <Spacer width="4px" />
                <BlueText>this mode works</BlueText>
              </RollUpExplain>
            </CustomOptionItem>
          ),
        }}
        selected={ROLL_UP_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_ROLLUP]]}
        onSelect={(key) =>
          setGlobalTogglesState({ name: STORAGE_TOGGLES_KEYS.IS_ROLLUP, value: ROLL_UP_SELECTOR_TO_BOOLEAN[key] })
        }
      />
      {showExplainRollupModal && <RollupExplainModal onClose={() => setShowExplainRollupModal(false)} />}
    </>
  );
};
