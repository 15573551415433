import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyFilesListIllustration } from 'images/empty-credit-notes-illustration.svg';
import { Centerer, FlexerColumn } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { FileUploadIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { EmptyStateText } from './styles';

const EmptyStateWrapper = styled(FlexerColumn)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px 12px;
  border-radius: 12px;
  border: 1px solid var(--accentGraySecond);
`;

const IllustrationWrapper = styled(Centerer)`
  align-items: flex-end;
  width: 60px;
  height: 60px;
  border-radius: 16px;
  background: rgba(25, 44, 66, 0.05);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack4);

  svg {
    height: 40px;
    margin-bottom: 2px;
  }
`;

export const SidebarEmptyState = ({ onUploadFile, uploadFileDisabled }) => {
  return (
    <EmptyStateWrapper>
      <IllustrationWrapper>
        <EmptyFilesListIllustration />
      </IllustrationWrapper>

      <FlexerColumn alignItems="center" gap="12px">
        <EmptyStateText>Add the first file associated with the contract</EmptyStateText>
        <TooltipContainer
          toolTipContent="Please add the contract file directly in the transaction page"
          isVisible={uploadFileDisabled}
        >
          <IconButton
            icon={<FileUploadIcon />}
            iconFillColor="var(--darkGreen)"
            data-cy="contracts-files-modal__upload-button"
            onClick={onUploadFile}
            disabled={uploadFileDisabled}
          >
            Upload File
          </IconButton>
        </TooltipContainer>
      </FlexerColumn>
    </EmptyStateWrapper>
  );
};
