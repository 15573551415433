import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Loader } from 'components/Loaders';
import { FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import { RemoveButton } from 'components/Buttons';
import { useToasts } from 'components/Toasts';
import { ModalBodyWrapper } from '../styles';
import { ReportSelector } from '../ReportSelector';
import { DateText, ExplainerText, ReportDataSection } from './styles';

const DEFAULT_REPORT_CREATOR_NAME = 'Subscript';

// TODO: Add missing fields to report editing modal https://github.com/ChifferCo/chiffer/issues/1821
export const EditReportModal = ({ report, onClose, showDefaultDashboardOption = false, hideRemoveButton = false }) => {
  const history = useHistory();

  const { organizations } = useContext(AppContext);
  const {
    operations: { updateReport, removeReport },
  } = useReportsAPI({
    orgId: organizations[0].id,
    autoFetch: false,
  });
  const { pushError } = useToasts();
  const [loading, setLoading] = useState(false);

  if (report.default === undefined) {
    report.default = false;
  }

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const updatedReport = await updateReport({
        orgId: organizations[0].id,
        reportId: report.id,
        report: {
          title: data.title,
          default: data.default,
        },
      });
      onClose({
        updatedReport,
      });
    } catch (e) {
      pushError(e, 'Could not not update the report');
      console.error({ message: e.message, component: 'EditReportModal.js', stack: e });
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      onClose();
      history.push('/dashboard/common-metrics');
      await removeReport({
        orgId: organizations[0].id,
        reportId: report.id,
      });
    } catch (e) {
      pushError(e, 'Could not not remove the report');
      console.error({ message: e.message, component: 'EditReportModal.js', stack: e });
      setLoading(false);
    }
  };

  return (
    <ModalContainer data-cy="edit-report-modal">
      <Modal height="auto" width={!showDefaultDashboardOption ? '640px' : '740px'}>
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle>
            <ModalTitleText>
              <b>Edit Saved Report</b>
            </ModalTitleText>
            <ModalSubtitleText>
              Created by{' '}
              {report?.creator_name !== ''
                ? report?.creator_name
                : report?.creator_email ?? DEFAULT_REPORT_CREATOR_NAME}
            </ModalSubtitleText>
          </ModalTitle>
        </ModalHeader>
        {loading ? (
          <ModalBody>
            <ModalBodyWrapper>
              <div className="w-100 flexer">
                <Loader containerStyles={{ width: 40 }} />
              </div>
            </ModalBodyWrapper>
          </ModalBody>
        ) : (
          <Formik
            initialValues={{
              ...report,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              title: Yup.string().required('Please, enter name'),
            })}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <>
                <ModalBody>
                  <ModalBodyWrapper>
                    <ReportSelector disabled showDefaultDashboardOption />
                  </ModalBodyWrapper>
                </ModalBody>

                <ReportDataSection>
                  <FlexBetweenContainer centerer>
                    <ExplainerText>To change your filter settings, go to the page of this report</ExplainerText>
                    <DateText>Updated at {dayjs(report.updated_at).format('DD MMM YYYY')}</DateText>
                  </FlexBetweenContainer>
                </ReportDataSection>

                <ModalFooter>
                  <FlexBetweenContainer centerer>
                    {!hideRemoveButton ? <RemoveButton onClick={handleDelete}>Remove</RemoveButton> : <div></div>}
                    <Flexer>
                      <ModalButton default onClick={onClose}>
                        Cancel
                      </ModalButton>
                      <Spacer width="14px" />
                      <ModalButton primary onClick={handleSubmit} data-cy="edit-report-modal__save-button">
                        Save
                      </ModalButton>
                    </Flexer>
                  </FlexBetweenContainer>
                </ModalFooter>
              </>
            )}
          </Formik>
        )}
      </Modal>
    </ModalContainer>
  );
};
