import React, { useEffect, useMemo, useContext, useState, useCallback } from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { Row } from 'antd';
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters } from 'react-table';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { AppContext } from 'AppContext';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  RowsCount,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  SortIconWrapper,
  TableHeaderActions,
  TablePageSeparator,
} from 'components/Table';
import { EyeIcon, GreyDot } from 'components/Icons';
import { SearchBar } from 'components/Blocks';
import { PaginationButton } from 'components/Buttons';
import { dateGlobalFilter } from 'components/Table/utils';
import { CentererVertical, Spacer } from 'components/Core';
import { CustomDatePicker } from 'components/Controls';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { getColumns } from './columns';

const GLSelector = styled(CentererVertical)`
  padding: 12px 20px;
  border: 1px solid var(--neutralGray);
  border-radius: 16px;
  font-size: 14px;
`;

const GLOption = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 12px;
  border: 1px solid var(--primaryBlack3);
  border-radius: 100px;
  background-color: var(--primaryBlack2);

  ${({ active }) =>
    active &&
    `
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
    background-color: var(--primaryBlue10);
  `}
`;

const StyledReactTableRow = styled(ReactTableRow)`
  border-bottom: 0;
  height: 42px;
  padding: 8px 14px;

  &:first-child {
    margin-top: 6px;
  }

  &:last-child {
    margin-bottom: 6px;
  }
`;

export const ExchangeRatesTable = ({
  formRef,
  onRowRemove,
  storageSortBy,
  homeCurrency,
  setStorageSortBy,
  currenciesKeys,
  currentPageIndex,
  setCurrentPageIndex,
  setConvertToCurrency,
  selectedIntegration,
  setSelectedIntegration,
}) => {
  const {
    orgId,
    orgConfigs,
    dateFormat,
    glIntegrations,
    orgConfigs: { accountingCurrencies },
  } = useContext(AppContext);
  const { accountingSpreadsLockBeforeDate } = orgConfigs;
  const { values } = useFormikContext();

  const hasMultipleGLs = glIntegrations.length > 1;

  const data = useMemo(() => values?.rates, [values]);

  const columns = useMemo(
    () =>
      getColumns({
        formRef,
        onRowRemove,
        currenciesKeys,
        homeCurrency,
        orgId,
        accountingSpreadsLockBeforeDate,
        dateFormat,
      }),
    [formRef, onRowRemove, currenciesKeys, homeCurrency, orgId, accountingSpreadsLockBeforeDate, dateFormat],
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');

  const dateRangeFilter = useCallback((rows, _, filterValue) => {
    const { startDate, endDate } = filterValue || {};

    if (!startDate && !endDate) return rows;

    return rows.filter((row) => {
      const rowDate = row.values.date;

      const dateToCheck = dayjs(rowDate).startOf('day');
      const start = startDate ? dayjs(startDate).startOf('day') : null;
      const end = endDate ? dayjs(endDate).startOf('day') : null;

      if (start && end) {
        return dateToCheck.isSameOrAfter(start) && dateToCheck.isSameOrBefore(end);
      }
      if (start) {
        return dateToCheck.isSameOrAfter(start);
      }
      if (end) {
        return dateToCheck.isSameOrBefore(end);
      }
      return true;
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    setFilter,
    pageCount,
    nextPage,
    previousPage,
    state: { sortBy, pageIndex },
  } = useTable(
    {
      columns,
      data,
      globalFilter: (rows, ids, filterValue) => {
        if (typeof filterValue === 'string') {
          return dateGlobalFilter(rows, ids, filterValue);
        }
        return rows;
      },
      initialState: {
        sortBy: storageSortBy,
        pageIndex: currentPageIndex,
        pageSize: 40,
      },
      filterTypes: {
        date: dateRangeFilter,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    setStorageSortBy(sortBy);
  }, [sortBy, setStorageSortBy]);

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [pageIndex, setCurrentPageIndex]);

  return (
    <>
      {hasMultipleGLs && (
        <GLSelector gap="16px">
          <EyeIcon />
          <span>General Ledger:</span>
          {Object.values(accountingCurrencies).map(({ service, value: currency, integration_id }) => {
            const label = isNil(integration_id) ? 'No GL' : service;
            const onClick = () => {
              setConvertToCurrency(currency);
              setSelectedIntegration(integration_id);
            };
            return (
              <GLOption onClick={onClick} active={integration_id === selectedIntegration} key={integration_id}>
                {`${label} (${currency})`}
              </GLOption>
            );
          })}
        </GLSelector>
      )}
      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <SearchBar
            value={searchText}
            placeholder="Search..."
            onChange={({ target }) => {
              setSearchText(target.value);
              setGlobalFilter(target.value);
            }}
            onBlur={({ target }) => {
              setSearchText(target.value);
              setGlobalFilter(target.value);
            }}
            onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
            name="product"
          />
          <Spacer width="8px" />
          <CustomDatePicker
            name="start_date"
            placeholderText="From date"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setFilter('date', { startDate: date, endDate });
            }}
            dateFormat="MMM dd, yyyy"
            isClearable
            maxDate={endDate}
          />
          <Spacer width="4px" />
          <CustomDatePicker
            name="end_date"
            placeholderText="To date"
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              setFilter('date', { startDate, endDate: date });
            }}
            dateFormat="MMM dd, yyyy"
            isClearable
            minDate={startDate}
          />
        </SearchContainer>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RowsCount>{page?.length} rows found</RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </Row>
      </TableHeaderActions>

      <TablePageSeparator />
      <ReactTable pageView {...getTableProps()}>
        <ReactTableHeader pageView>
          {headerGroups?.map((headerGroup) => (
            <ReactTableHeaderRow pageView {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <ReactTableHeaderColumn
                  customWidth={column.width}
                  {...column.getHeaderProps(
                    column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  {column.render('Header')}

                  {column.isSorted && (
                    <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                      <TotalIcon />
                    </SortIconWrapper>
                  )}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody pageView {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);

            return (
              <StyledReactTableRow {...row.getRowProps()} data-cy="exchange-rates-table--rate-row">
                {row.cells?.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </StyledReactTableRow>
            );
          })}
        </ReactTableBody>
      </ReactTable>
    </>
  );
};
