import { Column, Row } from 'components/Core';
import styled from 'styled-components';

export const CollectionsSubtitle = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--primaryBlack);
`;

export const FieldDescription = styled.div`
  font-size: 12px;
  padding-left: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.4;
`;

export const CollectionsCheckboxRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
`;

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;

  &:hover {
    svg g {
      opacity: 1;
    }
  }
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const CollectionItemCount = styled.div`
  width: 51px;
  padding: 6px 0;
  font-weight: 900;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  color: var(--primaryBlack50);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  text-transform: uppercase;
`;

export const ItemTemplate = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 24px;
  color: var(--primaryBlack);
`;

export const DynamicVarInline = styled.b`
  font-weight: 700;
`;

export const ItemTemplateEvent = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 30px;
  width: 210px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  line-height: 20px;
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const ModalSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;
  margin-right: auto;
  margin-left: 36px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const HeaderActions = styled.div`
  display: flex;
`;

export const BillingConfigWrapper = styled.div`
  padding: 0 100px;
  position: relative;
  padding-bottom: 20px;
`;

export const AddEmailButton = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  cursor: pointer;
  line-height: 16px;
  border-bottom: 1px dashed var(--primaryBlue);
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const FormRow = styled(Row)`
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? '0px'};

  > * {
    width: ${({ childrenWidth }) => childrenWidth ?? 'auto'};
  }
`;

export const RowSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack40);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 16px;
`;

export const AddEmailText = styled.span`
  color: var(--primaryBlack);
  font-style: italic;
`;

export const TooltipRow = styled(Row)`
  &:hover {
    svg g {
      opacity: 1;
    }
  }

  svg {
    margin-left: 4px;
  }
`;

export const FileCard = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--accentGraySecond);

  svg {
    cursor: pointer;
  }

  &:hover {
    background: #fff;
    svg {
      path {
        fill: ${({ disableIconHover }) => (disableIconHover ? 'auto' : 'var(--tertiaryRed)')};
      }
    }
  }
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: var(--accentGraySecond);
`;

export const Explainer = styled.p`
  font-size: 12px;
  color: var(--primaryBlack50);
  text-wrap: wrap;
`;

export const CollectionSequenceByAINote = styled.div`
  font-size: 12px;
  font-style: normal;
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  position: relative;
  margin-top: 24px;
  line-height: 16px;
  padding: 12px;
  border-radius: 12px;
  background-color: var(--primaryBlack3);
`;

export const ConfirmModalContainer = styled(Column)`
  gap: 24px;
  margin-top: 10px;
  font-size: 14px;
`;

export const ReconnectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--accentGrayFourth);
  border-radius: 12px;
  margin: 0 16px;
  padding: 16px;
  font-size: 14px;
  color: var(--primaryBlack);

  p {
    font-size: 12px;
    font-style: italic;
    color: var(--primaryBlack50);
  }
`;

export const PaddingWrapper = styled.div`
  padding: 28px;
`;

export const AiHelpButton = styled.span`
  width: fit-content;
  white-space: nowrap;
  color: var(--primaryBlue);
  background: var(--primaryBlue10);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  margin-left: auto;
  font-weight: 900;
  line-height: 12px; /* 120% */
  text-transform: uppercase;

  &:hover {
    color: var(--secondaryBlue);
    background: var(--primaryBlue20);
  }

  &:focus {
    color: var(--secondaryBlue);
    background: var(--primaryBlue20);
  }
`;
