import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, discrepancyId, suffix }) => {
  let url = `${API_URL}/organizations/${orgId}/ai-discrepancies`;
  if (discrepancyId) url = `${url}/${discrepancyId}`;
  if (suffix) url = `${url}/${suffix}`;

  return url;
};

export const getAiDiscrepancies = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId }), { params });
  return response.data;
};
