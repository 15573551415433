import { useEffect, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import {
  ReactTable,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  HeaderCellWrapper,
  ReactTableRow,
  ReactTableCell,
} from 'components/Table';
import styled from 'styled-components';
import { Spacer } from 'components/Core';

const Container = styled.div`
  min-width: max-content;
  background: var(--light100);
  border: 1px solid var(--naturalAdditional3);
  border-radius: 16px;
`;

export const PreviewTable = ({ columns, tableData, setDataHook, entityName, children, title }) => {
  const [selectedRowIds, setSelectedRowIds] = useState({});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds: internalSelectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useRowSelect,
  );

  useEffect(() => {
    setSelectedRowIds(internalSelectedRowIds);
  }, [internalSelectedRowIds]);

  useEffect(() => {
    setTimeout(() => {
      Object.keys(selectedRowIds).forEach((id) => {
        const row = rows.find((r) => r.id === id);
        if (row) row.toggleRowSelected(true);
      });
    }, 0);
  }, [tableData, rows, selectedRowIds]);

  useEffect(() => {
    // Filter tableData to include only the selected rows
    const filteredData = tableData
      .map((data, index) => ({ ...data, index }))
      .filter((_, index) => selectedRowIds[index]);
    setDataHook(filteredData);
  }, [setDataHook, tableData, selectedRowIds]);

  return (
    <>
      {title}
      <div data-cy={`${entityName}-table`}>
        {tableData?.length ? (
          <ReactTable pageView={true} data-cy={`${entityName}-table`} {...getTableProps()}>
            <Container>
              {headerGroups.map((headerGroup) => {
                return (
                  <ReactTableHeaderRow
                    height="56px"
                    padding="20px 12px 20px 12px"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <ReactTableHeaderColumn
                        color="var(--dark75)"
                        alignRight={column?.alignRight}
                        customWidth={column.width}
                        {...column.getHeaderProps()}
                      >
                        <HeaderCellWrapper>
                          {column.id === 'actions' ? <></> : column.render('Header')}
                        </HeaderCellWrapper>
                      </ReactTableHeaderColumn>
                    ))}
                  </ReactTableHeaderRow>
                );
              })}
            </Container>
            <Spacer height="8px" />
            <Container {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <>
                    <ReactTableRow
                      data-cy={'transaction-row'}
                      borderColor="var(--naturalAdditional3)"
                      padding="20px 12px 20px 12px"
                      minHeight={index === rows.length - 1 ? '57px' : '56px'}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <ReactTableCell
                          fontSize="12px"
                          isAbsolute={row.canExpand}
                          customWidth={cell.column.width}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </ReactTableCell>
                      ))}
                    </ReactTableRow>
                  </>
                );
              })}
            </Container>
          </ReactTable>
        ) : (
          <div>
            Subscript AI was unable to parse any {entityName} out of this contract. Please try again or reach out to us
            on Slack!
          </div>
        )}
      </div>
      {children}
    </>
  );
};
