import React from 'react';
import styled from 'styled-components';
import { truncate } from 'lodash';
import dayjs from 'dayjs';
import { Checkbox as _Checkbox } from 'antd';
import { numberFormatter } from 'utils/formatters';
import { pluralize } from 'utils/stringUtils';
import { NUMBER_FORMATS } from 'consts/global';
import TooltipContainer from 'components/Tooltip/Tooltip';
import { TooltipRowText, TooltipRowText12 } from 'components/Tooltip/styles';
import { TableCell } from 'views/ExternalUpdates/Components/TableCell';
import { ReactComponent as Link2Icon } from 'images/link2.svg';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { InlineButton } from 'components/Buttons';
import { ErrorCircleIcon, MailSendIcon } from 'components/Icons';
import { Column, FlexBetweenContainer, Row, Spacer } from 'components/Core';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { INVOICE_MAGIC_METADATA, INVOICE_STATUSES, TITLE_BY_STATUS } from '../consts';
import { ExpandableRow } from '../Common/ExpandableRow';
import { addRemindersInfoFieldsToInvoice, getInvoiceStatusForTableTag } from './utils';
import { InvoiceTableActions } from './InvoiceTableActions';
import { INVOICES_TABLE_COLUMN_KEY, INVOICES_TABLE_COLUMN_TITLES_BY_ID, INVOICE_SYNC_STATUSES } from './consts';
import { RemindersBanner } from './RemindersTableBanner';
import { INVOICES_FILTER_GROUP_BY } from '../BillingProvider';

const INVOICE_STATUS_TAG_TO_FIELDS = {
  [INVOICE_STATUSES.PAID]: {
    backgroundColor: 'var(--primaryGreen10)',
    borderColor: 'var(--primaryGreen10)',
    tagColor: 'var(--secondaryGreen80)',
  },
  [INVOICE_STATUSES.PARTIALLY_PAID]: {
    backgroundColor: 'var(--primaryYellow10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--secondaryYellow80)',
  },
  [INVOICE_STATUSES.UNSENT]: {
    backgroundColor: 'white',
    borderColor: 'var(--neutralGray)',
    tagColor: 'var(--primaryBlack50)',
  },
  [INVOICE_STATUSES.SENT]: {
    backgroundColor: 'var(--primaryYellow10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--secondaryYellow80)',
  },
  [INVOICE_STATUSES.SAVE_FAILED]: {
    backgroundColor: 'var(--primaryRed10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--primaryRed80)',
  },
  [INVOICE_STATUSES.SENT_FAILED]: {
    backgroundColor: 'var(--primaryRed10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--primaryRed80)',
  },
  [INVOICE_STATUSES.PAYMENT_FAILED]: {
    backgroundColor: 'var(--primaryRed10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--primaryRed80)',
  },
  OVERDUE: {
    backgroundColor: 'var(--primaryRed10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--primaryRed80)',
  },
  [INVOICE_STATUSES.AUTO_CHARGE]: {
    backgroundColor: 'var(--primaryYellow10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--secondaryYellow80)',
  },
  [INVOICE_STATUSES.VOID]: {
    backgroundColor: 'var(--primaryBlack5)',
    borderColor: 'var(--primaryBlack5)',
    tagColor: 'var(--primaryBlack50)',
  },
  [INVOICE_STATUSES.QUEUED_UP]: {
    backgroundColor: 'var(--primaryPurple10)',
    borderColor: 'var(--primaryPurple10)',
    tagColor: 'var(--additionalPurple)',
  },
  [INVOICE_STATUSES.PROCESSING]: {
    backgroundColor: 'white',
    borderColor: 'white',
    tagColor: 'var(--primaryBlack50)',
  },
  [INVOICE_STATUSES.TAXES_PROCESSING]: {
    backgroundColor: 'white',
    borderColor: 'white',
    tagColor: 'var(--primaryBlack50)',
  },
};

// Keep in sync with AVAILABLE_STATUSES_FOR_BULK_ACTIONS in backend/src/components/invoices/service/bulkActions.utils.js
export const AVAILABLE_STATUSES_FOR_BULK_ACTIONS = [
  INVOICE_STATUSES.UNSENT,
  INVOICE_STATUSES.SENT,
  INVOICE_STATUSES.SAVE_FAILED,
  INVOICE_STATUSES.SENT_FAILED,
];

export const INVOICE_STATUS_TAG_TO_LABEL = {
  [INVOICE_STATUSES.SAVE_FAILED]: TITLE_BY_STATUS[INVOICE_STATUSES.SAVE_FAILED],
  [INVOICE_STATUSES.SENT_FAILED]: TITLE_BY_STATUS[INVOICE_STATUSES.SENT_FAILED],
  [INVOICE_STATUSES.VOID]: 'Voided',
  [INVOICE_STATUSES.QUEUED_UP]: 'Queued up',
  [INVOICE_STATUSES.TAXES_PROCESSING]: 'Processing',
};

const SYNC_STATUS_TAG_TO_FIELDS = {
  [INVOICE_SYNC_STATUSES.SYNCED]: {
    backgroundColor: 'var(--primaryGreen10)',
    borderColor: 'var(--primaryGreen10)',
    tagColor: 'var(--secondaryGreen80)',
  },
  [INVOICE_SYNC_STATUSES.UNSYNCED]: {
    backgroundColor: 'var(--primaryBlack5)',
    borderColor: 'var(--primaryBlack5)',
    tagColor: 'var(--primaryBlack50)',
  },
  [INVOICE_SYNC_STATUSES.NO_SYNC]: {
    backgroundColor: 'white',
    borderColor: 'white',
    tagColor: 'var(--primaryBlack50)',
  },
};

const StyledErrorCircleIcon = styled(ErrorCircleIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--tertiaryRed);
  }
`;

export const OpenCustomerButton = styled(InlineButton)`
  text-align: left;
  white-space: nowrap;
`;

export const LinkIcon = styled(Link2Icon)`
  margin-left: 16px;
  cursor: pointer;
`;

const Checkbox = styled(_Checkbox)`
  margin-right: 4px;
`;

export const InvoiceStatusTag = styled.div`
  font-weight: 900;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 12px;
  padding: 4px 8px;
  position: relative;
  left: -5px;
  border-radius: 100px;
  background-color: ${({ status }) => INVOICE_STATUS_TAG_TO_FIELDS[status].backgroundColor};
  color: ${({ status }) => INVOICE_STATUS_TAG_TO_FIELDS[status].tagColor};
  border: ${({ status }) => `1px solid ${INVOICE_STATUS_TAG_TO_FIELDS[status].borderColor}`};
`;

export const SyncStatusTag = styled.div`
  font-weight: 900;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 12px;
  padding: 4px 8px;
  position: relative;
  left: -5px;
  border-radius: 100px;
  background-color: ${({ status }) => SYNC_STATUS_TAG_TO_FIELDS[status].backgroundColor};
  color: ${({ status }) => SYNC_STATUS_TAG_TO_FIELDS[status].tagColor};
  border: ${({ status }) => `1px solid ${SYNC_STATUS_TAG_TO_FIELDS[status].borderColor}`};
`;

export const generateColumns = ({
  allInvoicesSelected,
  invoicesSelected,
  setInvoicesSelected,
  currency,
  onInvoiceClick,
  updatedFields,
  autoChargeJobRunsAt,
  invoicesGroupBy,
  dateFormat,
}) => [
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.CUSTOMER],
    accessor: INVOICES_TABLE_COLUMN_KEY.CUSTOMER,
    id: INVOICES_TABLE_COLUMN_KEY.CUSTOMER,
    width: '300px',
    Cell: ({ row }) => {
      if (row.canExpand) {
        let totalAmount = 0;
        for (const subRow of row.originalSubRows) {
          totalAmount += subRow.amount;
        }

        const expandableRowChecked = row.originalSubRows.every(({ id }) =>
          invoicesSelected.find((invoice) => invoice.id === id),
        );

        const onCheckboxClick = () => {
          if (expandableRowChecked) {
            // if all are selected, then clear out all selected
            const idsToRemove = row.originalSubRows.map((subRow) => subRow.id);
            const newArray = [...invoicesSelected].filter((invoice) => !idsToRemove.includes(invoice.id));
            setInvoicesSelected(newArray);
          } else {
            // if not all are selected, then make all selected
            const newArray = [...invoicesSelected];
            row.originalSubRows.forEach((subRow) => {
              newArray.push(subRow);
            });
            setInvoicesSelected(newArray);
          }
        };

        const label =
          invoicesGroupBy === INVOICES_FILTER_GROUP_BY.INVOICING_SCHEDULE
            ? row.original.customerName
            : dayjs(`${row.original.month}-15`).format('MMMM YYYY');

        return (
          <ExpandableRow
            label={label}
            totalAmount={totalAmount}
            currency={currency}
            unitCount={row.originalSubRows.length}
            unitName="invoice"
            internalNotes={row.original.internalNotes}
            isExpanded={row.isExpanded}
            showCheckbox={true}
            disabledWithChecked={allInvoicesSelected}
            onCheckboxClick={onCheckboxClick}
            checked={expandableRowChecked}
          />
        );
      } else {
        const invoiceChecked = invoicesSelected.find((invoice) => invoice.id === row.original.id);
        const onCheckboxClick = () => {
          if (invoiceChecked) {
            // remove the invoice matching id
            const newArray = [...invoicesSelected].filter((invoice) => invoice.id !== row.original.id);
            setInvoicesSelected(newArray);
          } else {
            // add the invoice
            const newArray = [...invoicesSelected];
            newArray.push(row.original);
            setInvoicesSelected(newArray);
          }
        };
        return (
          <Column>
            <Row horizontal="start" style={{ width: '100%' }} gap="8px">
              <Checkbox
                disabled={allInvoicesSelected}
                checked={invoiceChecked}
                onClick={onCheckboxClick}
                data-cy="invoices-table__row__checkbox"
              />

              {[INVOICE_STATUSES.SAVE_FAILED, INVOICE_STATUSES.SENT_FAILED].includes(row.original?.invoice_status) && (
                <StyledErrorCircleIcon />
              )}
              <OpenCustomerButton
                data-cy={`invoices-table--customer-row-${row.original.customer_name}`}
                onClick={() => onInvoiceClick(row.original)}
                isSecondary
              >
                {truncate(row.original.customer_name)}
              </OpenCustomerButton>
              <TooltipContainer width={125} toolTipContent="Open Customer">
                <LinkIcon onClick={() => window.open(`/customers/${row.original.customer_id}`, '_blank')} />
              </TooltipContainer>
            </Row>

            {row?.original?.has_unsent_reminders && row?.original?.sent_at && !row?.original?.auto_charge && (
              <RemindersBanner
                onInvoiceClick={onInvoiceClick}
                invoice={addRemindersInfoFieldsToInvoice({ invoice: row?.original })}
              />
            )}
          </Column>
        );
      }
    },
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.INVOICE_NUMBER],
    accessor: INVOICES_TABLE_COLUMN_KEY.INVOICE_NUMBER,
    width: '150px',
    Cell: ({ row }) => (row.canExpand ? null : row?.original?.invoice_number || '-'),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.INVOICE_STATUS],
    accessor: INVOICES_TABLE_COLUMN_KEY.INVOICE_STATUS,
    width: '100px',
    Cell: ({ row }) => {
      if (row.canExpand) {
        return null;
      }
      const status = getInvoiceStatusForTableTag({ invoice: row.original });
      const chargingToday =
        status === INVOICE_STATUSES.AUTO_CHARGE &&
        dayjs.utc(row.original.auto_charge_date ?? row.original.date).isSameOrBefore(dayjs(), 'day') &&
        dayjs().isBefore(autoChargeJobRunsAt);

      const toolTipContent =
        status === INVOICE_STATUSES.SAVE_FAILED ? (
          <>
            <TooltipRowText>
              Failed at{' '}
              {dayjs
                .utc(row.original.metadata?.[INVOICE_MAGIC_METADATA.LAST_SAVE_INVOICE_FAILED_AT])
                .format('MMM DD, YYYY')}
            </TooltipRowText>
            <TooltipRowText12>
              {row.original.metadata?.[INVOICE_MAGIC_METADATA.LAST_SAVE_INVOICE_ERROR]}
            </TooltipRowText12>
          </>
        ) : status === INVOICE_STATUSES.SENT_FAILED ? (
          <>
            <TooltipRowText12>
              Last attempt:{' '}
              {dayjs
                .utc(row.original.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT])
                .format('MMM DD, YYYY')}
            </TooltipRowText12>
            <TooltipRowText>
              {pluralize(row.original.metadata?.[INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT] ?? 0, 'attempt')}
            </TooltipRowText>
          </>
        ) : status === INVOICE_STATUSES.AUTO_CHARGE ? (
          `This invoice will be charged ${
            chargingToday
              ? 'today'
              : dayjs.utc(row.original.auto_charge_date ?? row.original.date).format('MMM DD, YYYY')
          } at ${autoChargeJobRunsAt.format('ha (z)')}`
        ) : status === INVOICE_STATUSES.PAYMENT_FAILED ? (
          <>
            <TooltipRowText>
              Failed at{' '}
              {dayjs.utc(row.original.metadata?.[INVOICE_MAGIC_METADATA.LAST_PAYMENT_FAILED_AT]).format('MMM DD, YYYY')}
            </TooltipRowText>
            <TooltipRowText12>{row.original.metadata?.[INVOICE_MAGIC_METADATA.LAST_PAYMENT_ERROR]}</TooltipRowText12>
          </>
        ) : null;

      const statusLabel = INVOICE_STATUS_TAG_TO_LABEL[status] ?? status;

      return (
        <TooltipContainer isVisible={!!toolTipContent} width={190} toolTipContent={toolTipContent}>
          <InvoiceStatusTag
            data-cy={`invoices-table__customer-${row.original.customer_name}__status-tag`}
            status={status}
          >
            {statusLabel}
          </InvoiceStatusTag>
        </TooltipContainer>
      );
    },
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.DATE,
    width: '100px',
    Cell: ({ row }) => (row.canExpand ? null : dayjs.utc(row.original.date).format(dateFormat)),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.SENT_DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.SENT_DATE,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand ? null : row.original.sent_at ? dayjs.utc(row.original.sent_at).format(dateFormat) : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.DUE_DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.DUE_DATE,
    width: '100px',
    Cell: ({ row }) => (row.canExpand ? null : dayjs.utc(row.original.due_date).format(dateFormat)),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand
        ? null
        : row.original.auto_charge_date ?? row.original.date
        ? dayjs.utc(row.original.auto_charge_date ?? row.original.date).format(dateFormat)
        : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.PAID_DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.PAID_DATE,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand ? null : row.original.paid_at ? dayjs.utc(row.original.paid_at).format(dateFormat) : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE],
    accessor: INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand ? null : row.original.voided_at ? dayjs.utc(row.original.voided_at).format(dateFormat) : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.SERVICE_START],
    accessor: INVOICES_TABLE_COLUMN_KEY.SERVICE_START,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand
        ? null
        : row.original.service_start
        ? dayjs.utc(row.original.service_start).format(dateFormat)
        : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.SERVICE_END],
    accessor: INVOICES_TABLE_COLUMN_KEY.SERVICE_END,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand ? null : row.original.service_end ? dayjs.utc(row.original.service_end).format(dateFormat) : '-',
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.AUTO_SEND],
    accessor: INVOICES_TABLE_COLUMN_KEY.AUTO_SEND,
    id: INVOICES_TABLE_COLUMN_KEY.AUTO_SEND,
    width: '120px',
    Cell: ({ row }) =>
      row.canExpand ? null : row.original.auto_send &&
        row.original.send_date &&
        row.original?.invoice_status === INVOICE_STATUSES.UNSENT ? (
        <FlexBetweenContainer gap="4px">
          <MailSendIcon />
          <span>
            <b>{`${dayjs.utc(row.original.send_date).format('MMM DD, YYYY')} at 12:00 PM`}</b>
          </span>
        </FlexBetweenContainer>
      ) : (
        '-'
      ),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.PRODUCT_NAMES],
    accessor: INVOICES_TABLE_COLUMN_KEY.PRODUCT_NAMES,
    width: '150px',
    Cell: ({ row }) =>
      row.canExpand ? null : (
        <TruncateStringWithTooltip length={30} tooltipWidth={360}>
          {row.original.product_names?.join(', ')}
        </TruncateStringWithTooltip>
      ),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.EMAILS],
    accessor: INVOICES_TABLE_COLUMN_KEY.EMAILS,
    width: '240px',
    Cell: ({ row }) => {
      const noNewEmails =
        !updatedFields?.[INVOICES_TABLE_COLUMN_KEY.EMAILS] ||
        updatedFields?.[INVOICES_TABLE_COLUMN_KEY.EMAILS]?.length === 0;

      return row.canExpand ? null : (
        <TableCell
          isChanged={Object.keys(updatedFields || {}).includes(INVOICES_TABLE_COLUMN_KEY.EMAILS)}
          original={
            row?.original?.email_addresses && row?.original?.email_addresses?.length > 0
              ? row?.original?.email_addresses?.join(', ')
              : 'No emails'
          }
          newValueTooltip={
            noNewEmails
              ? 'No emails (Will use emails from email source if set)'
              : updatedFields?.[INVOICES_TABLE_COLUMN_KEY.EMAILS]?.join(', ')
          }
          updated={noNewEmails ? 'No emails' : updatedFields?.[INVOICES_TABLE_COLUMN_KEY.EMAILS]?.join(', ')}
          field={INVOICES_TABLE_COLUMN_KEY.EMAILS}
          overrideOriginalDisplay={
            <TruncateStringWithTooltip length={30} tooltipWidth={360}>
              {row?.original?.email_addresses?.join(', ')}
            </TruncateStringWithTooltip>
          }
        />
      );
    },
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.TRANSACTION_NAMES],
    accessor: INVOICES_TABLE_COLUMN_KEY.TRANSACTION_NAMES,
    width: '200px',
    Cell: ({ row }) => {
      const uniqueTransactionNames = new Set();
      for (const [index, transactionName] of (row.original?.transaction_names || []).entries()) {
        const transactionDetails = {
          name: transactionName,
          start_date: row.original?.transaction_start_dates?.[index],
          end_date: row.original?.transaction_end_dates?.[index],
          customer_name: row.original?.customer_name,
        };
        uniqueTransactionNames.add(transactionName || transactionDisplayNameForBilling(transactionDetails));
      }
      return row.canExpand ? null : (
        <TruncateStringWithTooltip length={30} tooltipWidth={360}>
          {Array.from(uniqueTransactionNames).join(', ')}
        </TruncateStringWithTooltip>
      );
    },
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.AMOUNT],
    accessor: INVOICES_TABLE_COLUMN_KEY.AMOUNT,
    id: INVOICES_TABLE_COLUMN_KEY.AMOUNT,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand
        ? ''
        : numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.amount,
            currency: row.original.currency ?? currency,
            decimalPlaces: 2,
          }),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.AMOUNT_PAID],
    accessor: INVOICES_TABLE_COLUMN_KEY.AMOUNT_PAID,
    id: INVOICES_TABLE_COLUMN_KEY.AMOUNT_PAID,
    width: '100px',
    Cell: ({ row }) =>
      row.canExpand
        ? ''
        : numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.amount_paid,
            currency: row.original.currency ?? currency,
            decimalPlaces: 2,
          }),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.TERMS],
    id: INVOICES_TABLE_COLUMN_KEY.TERMS,
    width: '100px',
    Cell: ({ row }) => (row.canExpand ? '' : `Net ${row.original.days_to_pay ?? 0}`),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.NOTES],
    id: INVOICES_TABLE_COLUMN_KEY.NOTES,
    accessor: INVOICES_TABLE_COLUMN_KEY.NOTES,
    width: '200px',
    Cell: ({ row }) => (row.canExpand ? '' : row?.original?.internal_notes),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY],
    accessor: INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY,
    id: INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY,
    width: '100px',
    Cell: ({ row }) => (row.canExpand ? '' : row.original.invoicing_frequency),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.PAYMENT_OPTIONS],
    id: INVOICES_TABLE_COLUMN_KEY.PAYMENT_OPTIONS,
    width: '150px',
    Cell: ({ row }) => (row.canExpand ? null : (row.original.payment_options ?? []).join(', ')),
  },
  {
    Header: INVOICES_TABLE_COLUMN_TITLES_BY_ID[INVOICES_TABLE_COLUMN_KEY.SYNC_STATUS],
    id: INVOICES_TABLE_COLUMN_KEY.SYNC_STATUS,
    accessor: INVOICES_TABLE_COLUMN_KEY.SYNC_STATUS,
    width: '100px',
    Cell: ({ row }) => {
      if (row.canExpand) return null;

      const syncStatus = row.original[INVOICES_TABLE_COLUMN_KEY.SYNC_STATUS] ?? INVOICE_SYNC_STATUSES.NA;

      return <SyncStatusTag status={syncStatus}>{syncStatus}</SyncStatusTag>;
    },
  },
  {
    Header: '',
    id: 'actions',
    width: '50px',
    alignRight: true,
    Cell: ({ row }) => (
      <>
        {row.canExpand ? null : <InvoiceTableActions onInvoiceClick={onInvoiceClick} invoice={row?.original} />}
        <Spacer width="12px" />
      </>
    ),
  },
];
