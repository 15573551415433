import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { AppContext } from 'AppContext';
import { CsvUploadV2 } from 'shared/CsvUploadV2';
import { CSV_MODES } from 'shared/CsvUploadV2/CsvUpload';
import { useCSVImportsAPI } from 'api/csvImports';
import {
  NAME_MATCHING_SOURCE,
  UPLOADABLE_OBJECTS,
  INTERNAL_CUSTOMER_ID_SOURCE,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import { afterUploadFn } from 'shared/CsvUploadV2/utils';

import { getColumns } from './columns';
import { DEFAULT_INVOICES_CREATE_MAPPER, DEFAULT_INVOICES_MAPPER, DEFAULT_INVOICES_TRANSFORMATIONS } from './consts';
import { getFormFinalInvoices, getInitialIdSource, getOrgIdSources } from './utils';

export const InvoicesCsvUploadV2 = () => {
  const { organizations, orgId, integrations } = useContext(AppContext);

  const history = useHistory();

  const [customerIdSourceAndType, setCustomerIdSourceAndType] = useState({});
  const [fuzzyErrors, setFuzzyErrors] = useState();
  const [submittingForm, setSubmittingForm] = useState(false);

  const customersIdSources = useMemo(() => {
    return {
      ...getOrgIdSources({
        integrations,
        internalSource: INTERNAL_CUSTOMER_ID_SOURCE,
        suffix: 'customer ID',
      }),
      [INTERNAL_CUSTOMER_ID_SOURCE]: INTERNAL_CUSTOMER_ID_SOURCE,
      [NAME_MATCHING_SOURCE]: 'Customer name',
    };
  }, [integrations]);

  useEffect(() => {
    if (organizations) {
      setCustomerIdSourceAndType(getInitialIdSource());
    }
  }, [organizations]);

  const defaultMapperWithCreateOrUpdateMode = useCallback(({ createOrUpdateMode }) => {
    return createOrUpdateMode === CSV_MODES.UPDATE
      ? DEFAULT_INVOICES_MAPPER
      : createOrUpdateMode === CSV_MODES.CREATE
      ? DEFAULT_INVOICES_CREATE_MAPPER
      : undefined;
  }, []);

  const getColumnsWithCsvUploadState = useCallback(
    ({ mappedData, formRef, csvColumnsMapper, fuzzyErrors, setCsvColumnsMapper, csvColumns, createOrUpdateMode }) =>
      getColumns({
        organization: organizations[0],
        mappedData,
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,
        customerIdSourceAndType,
        setCustomerIdSourceAndType,
        customersIdSources,
        createOrUpdateMode,
        fuzzyErrors,
      }),
    [customerIdSourceAndType, organizations, setCustomerIdSourceAndType, customersIdSources],
  );

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const handleSubmit = async ({ values, extraData, formRef }) => {
    try {
      setSubmittingForm(true);
      const result = await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: getFormFinalInvoices({ formInvoices: values, organizations: organizations }),
        settings: {
          orgId,
          entity: extraData.entity,
          createOrUpdateMode: extraData.createOrUpdateMode,
          customerIdSourceAndType,
          useBackendValidation: true,
        },
      });

      afterUploadFn({ result, setFuzzyErrors, formRef, history, setSubmittingForm, backLink: '/billing/invoices' });
    } catch {
      setSubmittingForm(false);
    }
  };

  const isLoading = submittingForm;

  return (
    <CsvUploadV2
      entityName={UPLOADABLE_OBJECTS.INVOICES}
      isLoading={isLoading}
      fuzzyErrors={fuzzyErrors}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={defaultMapperWithCreateOrUpdateMode}
      csvColumnsTransformations={DEFAULT_INVOICES_TRANSFORMATIONS}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      backLink="/billing/invoices"
    />
  );
};
