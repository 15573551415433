import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/new-copy-icon.svg';

export const CopyIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};

  path {
    fill: ${({ fill }) => fill};
  }
`;
