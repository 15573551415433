import { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { Modal, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { Flexer, FlexerColumn, Spacer } from 'components/Core';
import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { SubscriptionInfo } from './SubscriptionInfo';
import { usePricingPlansAPI } from 'api/usageBasedEngine';
import { useUniqueUsageEventNamesAPI } from 'api/usageBasedEngine/hooks';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { useAnalytics, useToasts } from 'utils/hooks';

import { PRICING_PLAN_MODAL_MODE, editPricingModalValidationSchema, initialPricingPlanValue } from './consts';
import { BillingSchemeSection } from './BillingSchemeSection';
import { ProductEventSection } from './ProductEventSection';

import {
  Wrapper,
  Header,
  ActionItem,
  FlexGrow,
  FormFooter,
  FormButtonsRow,
  CancelButton,
  SaveButton,
  CheckIconWrapper,
  StyledCheckIcon,
} from './styles';
import { USAGE_BASED_BILLING_SCHEMES, USAGE_BASED_ENGINE_FREQUENCY } from '../consts';

const FREQUENCY_OPTIONS = Object.values(USAGE_BASED_ENGINE_FREQUENCY).map((freq) => ({
  label: freq,
  value: freq,
}));

const printCurrencyLabel = (currency) => {
  const symbol = ISO_CODE_TO_SYMBOL[currency] ?? '$';
  return `${symbol} (${currency})`;
};

export const EditPricingPlanModal = ({
  onClose,
  onPricingPlanCreated,
  mode = PRICING_PLAN_MODAL_MODE.EDIT,
  pricingPlan = initialPricingPlanValue,
  savePricingPlan = true,
}) => {
  const {
    orgId,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const { pushError } = useToasts();

  const {
    operations: { editPricingPlan, createPricingPlan },
  } = usePricingPlansAPI({ autoFetch: false, orgId });

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { currencies: allOrgNonHomeCurrencies } = organizationMetadata ?? {};

  const {
    data: eventNameData,
    isLoading: isLoadingEvents,
    operations: { refetch: refetchEventNames },
  } = useUniqueUsageEventNamesAPI({ orgId });

  const isCreate = [PRICING_PLAN_MODAL_MODE.CREATE, PRICING_PLAN_MODAL_MODE.DUPLICATE].includes(mode);

  const eventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);

  const handleOnSubmit = async (values) => {
    const adjustedValues = omit(
      {
        ...values,
        tiers: values?.tiers?.map((tier) => ({
          ...tier,
          carry_over_unused_units:
            tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID && values.shouldCarryOverUnusedUnits,
        })),
      },
      'shouldCarryOverUnusedUnits',
    );

    if (adjustedValues?.tiers) {
      const firstNonPrepaidTierIndex = adjustedValues.tiers.findIndex(
        (tier) => tier.billing_scheme !== USAGE_BASED_BILLING_SCHEMES.PREPAID,
      );
      const lastPrepaidTierIndex = adjustedValues.tiers.findLastIndex(
        (tier) => tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID,
      );
      if (
        firstNonPrepaidTierIndex !== -1 &&
        lastPrepaidTierIndex !== -1 &&
        firstNonPrepaidTierIndex < lastPrepaidTierIndex
      ) {
        pushError('Prepaid tiers must before other tier schemes');
        return;
      }
    }

    if (isCreate) {
      if (savePricingPlan) {
        const newPricingPlan = await createPricingPlan.mutateAsync({ data: adjustedValues });
        onPricingPlanCreated?.(newPricingPlan);
      } else {
        onPricingPlanCreated?.(adjustedValues);
      }
    } else {
      await editPricingPlan.mutateAsync({
        data: adjustedValues,
        id: pricingPlan.id,
      });
    }

    if (!eventNames.includes(adjustedValues.event_name)) refetchEventNames();

    onClose();
  };

  return (
    <ModalContainer>
      <Flexer>
        <Modal
          overflow="visible"
          width="1140px"
          minHeight="500px"
          data-cy="edit-pricing-plan-modal"
          height="auto"
          maxHeight="auto"
        >
          <ModalCloseIcon onClose={onClose} />
          <Formik
            initialValues={{
              currency: currencyISOCode,
              bill_in_advance: false,
              ...pricingPlan,
              shouldCarryOverUnusedUnits: pricingPlan?.tiers?.some((tier) => tier.carry_over_unused_units),
            }}
            validationSchema={editPricingModalValidationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form>
                <Wrapper>
                  <Header>{`${isCreate ? 'Create' : 'Edit'} Pricing Plan`}</Header>
                  <ActionItem>
                    <FlexGrow>
                      <FormikCustomInput
                        formik
                        data-cy="pricing-plan-modal__name"
                        name="name"
                        label="Name"
                        placeholder="Enter pricing plan name"
                        width="250px"
                      />
                    </FlexGrow>
                    <FormikCustomSelector
                      label="Currency"
                      placeholder="Select currency"
                      name="currency"
                      value={
                        values?.currency
                          ? {
                              label: printCurrencyLabel(values.currency),
                              value: values.currency,
                            }
                          : null
                      }
                      options={[
                        ...(allOrgNonHomeCurrencies?.map((currency) => ({
                          label: printCurrencyLabel(currency),
                          value: currency,
                        })) ?? []),
                        { label: printCurrencyLabel(currencyISOCode), value: currencyISOCode },
                      ]}
                      handleChange={(option) => setFieldValue('currency', option ? option.value : null)}
                      width="160px"
                      isClearable
                    />
                    <FormikCustomSelector
                      label="Interval"
                      placeholder="Select interval"
                      name="invoicing_frequency"
                      data-cy="pricing-plan-modal__invoicing_frequency"
                      value={
                        values?.invoicing_frequency
                          ? { label: values.invoicing_frequency, value: values.invoicing_frequency }
                          : null
                      }
                      options={
                        values.use_single_rate_tier
                          ? FREQUENCY_OPTIONS.filter((freq) => freq.value === USAGE_BASED_ENGINE_FREQUENCY.MONTHLY)
                          : FREQUENCY_OPTIONS
                      }
                      handleChange={(option) => setFieldValue('invoicing_frequency', option ? option.value : null)}
                      width="160px"
                    />
                    <FormikCustomInput
                      formik
                      textarea
                      data-cy="pricing-plan-modal__description"
                      name="description"
                      label="Description"
                      placeholder="Enter description"
                      width="450px"
                      height="38px"
                    />
                  </ActionItem>
                  <ActionItem>
                    <ProductEventSection
                      isCreate={isCreate}
                      eventNameData={eventNameData}
                      isLoadingEvents={isLoadingEvents}
                    />
                  </ActionItem>
                </Wrapper>

                <BillingSchemeSection
                  name="tiers"
                  currency={values.currency}
                  productIdsFieldName="aggregated_pricing_plan_info.product_ids"
                />

                <FormFooter>
                  <FlexerColumn>
                    <TooltipContainer
                      toolTipContent={
                        <span>
                          By default, we create invoices at the end of each billing period. Check this box if you want
                          to instead create invoices <em>at start of each billing period</em>.
                        </span>
                      }
                    >
                      <FormikCustomCheckbox name="bill_in_advance" label="Bill in advance" />
                    </TooltipContainer>
                  </FlexerColumn>
                  <FormButtonsRow>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                    <SaveButton
                      onClick={handleSubmit}
                      disabled={createPricingPlan.isLoading || editPricingPlan.isLoading}
                      data-cy="edit-pricing-plan-modal__save-button"
                    >
                      {createPricingPlan.isLoading || editPricingPlan.isLoading ? 'Saving' : 'Save'}
                      <Spacer width="10px" />
                      <CheckIconWrapper>
                        <StyledCheckIcon height="20px" />
                      </CheckIconWrapper>
                    </SaveButton>
                  </FormButtonsRow>
                </FormFooter>
              </Form>
            )}
          </Formik>
        </Modal>

        <SubscriptionInfo pricingPlan={pricingPlan} onClose={onClose} />
      </Flexer>
    </ModalContainer>
  );
};

export const useEditPricingPlanModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_PRICING_PLAN_MODAL,
        properties: { mode: openParams?.mode ?? PRICING_PLAN_MODAL_MODE.EDIT },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => (showModal ? <EditPricingPlanModal {...openParams} onClose={closeModal} /> : <></>), [
    closeModal,
    openParams,
    showModal,
  ]);

  return {
    openModal,
    EditPricingPlanModal: Modal,
  };
};
