import styled from 'styled-components';
import { FlexerRow } from 'components/Core';

const SummaryContainer = styled.div`
  width: 386px;
`;

const SummaryText = styled.div`
  color: var(--primaryBlack);
  padding: 2px 0px;
`;

const ActionText = styled.span`
  font-weight: 700;
`;

export const InvoiceChangesSummaryCell = ({ row }) => {
  const changedInvoices = row.original.externalUpdate?.update_data?.changedInvoices ?? [];
  const invoicesToDelete = row.original.externalUpdate?.update_data?.invoicesToDelete ?? [];
  const invoicesToInsert = row.original.externalUpdate?.update_data?.invoicesToInsert ?? [];

  return (
    <FlexerRow alignItems="flex-start">
      <SummaryContainer>
        {changedInvoices.length > 0 && (
          <SummaryText>
            {changedInvoices.length} invoice{changedInvoices.length === 1 ? '' : 's'} to <ActionText>update</ActionText>
          </SummaryText>
        )}
        {invoicesToInsert.length > 0 && (
          <SummaryText>
            {invoicesToInsert.length} invoice{invoicesToInsert.length === 1 ? '' : 's'} to{' '}
            <ActionText>create</ActionText>
          </SummaryText>
        )}
        {invoicesToDelete.length > 0 && (
          <SummaryText>
            {invoicesToDelete.length} invoice{invoicesToDelete.length === 1 ? '' : 's'} to{' '}
            <ActionText>remove</ActionText>
          </SummaryText>
        )}
      </SummaryContainer>
    </FlexerRow>
  );
};
