import React, { useState } from 'react';
import _ from 'lodash';

import { TooltipContainer } from 'components/Tooltip';
import { Centerer, Spacer } from 'components/Core';
import { InfoIcon } from 'components/Icons';
import { LinkBackButton } from 'components/Buttons';
import { Header } from 'shared/Layout';
import { useStateWithStorage } from 'utils/hooks';

import { TableCoordinator } from './TableCoordinator';
import { ExternalUpdatesLoader } from './ExternalUpdatesLoader';

export const ExternalUpdatesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingTooltipContent, setFetchingTooltipContent] = useStateWithStorage(
    'external-updates-fetching-tooltip-content',
    null,
  );

  return (
    <>
      <Header
        activePage="transactions"
        headerTitle="Transaction Updates"
        headerLeft={
          <Centerer>
            <LinkBackButton to="/transactions" data-cy="external-updates__back-button">
              All Transactions
            </LinkBackButton>
            <b>Transaction Updates </b>
            <Spacer width="10px" />
            <TooltipContainer
              width={400}
              toolTipContent="You can confirm or dismiss updates using the list or all at once.
                The yellow highlighting of the fields shows what has changed in the new version,
                hover the mouse cursor to find out what the value was before the update."
            >
              <Centerer>
                <InfoIcon size="20px" fill="var(--primaryGreen)" />
              </Centerer>
            </TooltipContainer>
          </Centerer>
        }
        headerRight={<ExternalUpdatesLoader isFetching={isFetching} fetchingTooltipContent={fetchingTooltipContent} />}
      />

      <TableCoordinator setIsFetching={setIsFetching} setFetchingTooltipContent={setFetchingTooltipContent} />
    </>
  );
};
