import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { getDiscountsFromSearch } from 'api/usageBasedEngine';
import { InlineButton as _InlineButton } from 'components/Buttons';
import { FormikCustomRadio, FormikCustomSelector } from 'components/Controls';
import { Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { CirclePlusIcon, TrashIcon } from 'components/Icons';
import { DISCOUNT_TYPE } from 'views/Billing/consts';
import { ReactComponent as DiscountIcon } from 'images/discount-icon.svg';

import { DiscountEditModal, DiscountTypeSelector, DiscountValueInput } from './DiscountEditModal';

const EmptyDiscountOption = styled(Flexer)`
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--primaryBlack30);
    }
  }
`;

const DiscountText = styled.div`
  color: var(--primaryBlack);
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-right: 16px;
  margin-left: 8px;
`;

const TableWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--light100);
  border: 1px solid var(--naturalAdditional3);
  border-radius: 16px;
  height: 56px;
  padding: 0px 12px;
  margin-top: 16px;
  font-size: 10px;
  color: var(--dark75);
  gap: 12px;

  & > :last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 0px 13px;

  & > :last-child {
    margin-left: auto;
  }
`;

const ColumnWrapper = styled.div`
  flex-basis: ${({ width }) => `${width}px`};
`;

const IndexHeader = styled.div`
  text-align: center;
`;

const IndexCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  background-color: var(--dark5);
  color: var(--dark75);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-family: Nunito Sans;
  font-weight: 900;
`;

const ActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const InlineButton = styled(_InlineButton)`
  padding: 4px 8px;
  font-size: 13px;
  width: ${({ width }) => width};
`;

const TrashIconWrapper = styled(FlexerColumn)`
  cursor: pointer;
  width: 16px;

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.3;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const createNewDiscountCodeOption = {
  label: (
    <EmptyDiscountOption>
      <CirclePlusIcon />
      Create new
    </EmptyDiscountOption>
  ),
  value: 0,
  data: {
    id: null,
    type: DISCOUNT_TYPE.PERCENTAGE,
    value: 0,
  },
};

export const DiscountSection = () => {
  const { orgId } = useContext(AppContext);
  const { setFieldValue, values } = useFormikContext();
  const [discountToEdit, setDiscountToEdit] = useState(null);

  const hasDiscount = values.has_discount;

  const updateDiscountById = (id, data) => {
    values.usage_subscriptions.forEach((subscription, index) => {
      if (subscription.discount?.id === id) {
        setFieldValue(`usage_subscriptions.${index}.discount`, data);
      }
    });
  };

  return (
    <>
      <FlexerRow alignItems="center">
        <DiscountIcon style={{ marginBottom: '2px' }} />
        <DiscountText>Discount:</DiscountText>
        <FormikCustomRadio
          name="has_discount"
          options={[
            { value: false, label: 'No discount' },
            { value: true, label: 'Apply discounts' },
          ]}
        />
      </FlexerRow>
      {hasDiscount && (
        <TableWrapper>
          <Header>
            <ColumnWrapper width={20}>
              <IndexHeader>#</IndexHeader>
            </ColumnWrapper>
            <ColumnWrapper width={240}>DISCOUNT CODE</ColumnWrapper>
            <ColumnWrapper width={300}>APPLY TO</ColumnWrapper>
            <ColumnWrapper width={120}>DISCOUNT SCHEME</ColumnWrapper>
            <ColumnWrapper width={100}>DISCOUNT</ColumnWrapper>
            <ColumnWrapper width={96}>
              <InlineButton
                width="96px"
                isSecondary
                withBackground
                data-cy="usage-subscription-modal__add-discount"
                onClick={() => {
                  const firstEmptyDiscountIndex = values.usage_subscriptions.findIndex(
                    (subscription) => !subscription.discount,
                  );
                  if (firstEmptyDiscountIndex !== -1) {
                    setFieldValue(
                      `usage_subscriptions.${firstEmptyDiscountIndex}.discount`,
                      createNewDiscountCodeOption.data,
                    );
                  }
                }}
                type="button"
                disabled={values.usage_subscriptions.every((subscription) => !!subscription.discount)}
              >
                Add discount
              </InlineButton>
            </ColumnWrapper>
          </Header>

          {values.usage_subscriptions.map((subscription, index) => (
            <Row key={subscription.pricing_plan?.id} hidden={!subscription.discount}>
              {!!subscription.discount && (
                <>
                  <ColumnWrapper width={20}>
                    <IndexCell>{index + 1}</IndexCell>
                  </ColumnWrapper>
                  <ColumnWrapper width={240}>
                    <FormikCustomSelector
                      isPaginateable
                      labelOpacity={1}
                      name={`usage_subscriptions.${index}.discount.id`}
                      value={
                        subscription?.discount?.id
                          ? {
                              label: subscription?.discount?.code,
                              value: subscription?.discount?.id,
                            }
                          : createNewDiscountCodeOption
                      }
                      loadOptions={async (searchQuery) => {
                        const result = await getDiscountsFromSearch({ searchQuery, orgId });
                        return {
                          ...result,
                          options: [createNewDiscountCodeOption, ...result.options],
                        };
                      }}
                      handleChange={(option) => {
                        if (option?.data) {
                          setFieldValue(`usage_subscriptions.${index}.discount`, option.data);
                        }
                      }}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper width={300}>
                    <FormikCustomSelector
                      labelOpacity={1}
                      name={`usage_subscriptions.${index}.discount.pricing_plan.id`}
                      value={
                        subscription?.pricing_plan?.id
                          ? {
                              label: subscription?.pricing_plan?.name,
                              value: subscription?.pricing_plan?.id,
                            }
                          : createNewDiscountCodeOption
                      }
                      options={[
                        values.usage_subscriptions[index],
                        ...values.usage_subscriptions.filter((subscription) => !subscription.discount),
                      ].map((subscription) => ({
                        label: subscription?.pricing_plan?.name,
                        value: subscription?.pricing_plan?.id,
                      }))}
                      handleChange={(option) => {
                        if (option) {
                          const replacedIndex = values.usage_subscriptions.findIndex(
                            (subscription) => subscription.pricing_plan?.id === option.value,
                          );

                          if (replacedIndex === -1 || replacedIndex === index) {
                            return;
                          }

                          // swap the discount
                          setFieldValue(
                            `usage_subscriptions.${replacedIndex}.discount`,
                            values.usage_subscriptions[index].discount,
                          );
                          setFieldValue(`usage_subscriptions.${index}.discount`, null);
                        }
                      }}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper width={120}>
                    <DiscountTypeSelector
                      name={`usage_subscriptions.${index}.discount.type`}
                      disabled={!!subscription.discount?.id}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper width={100}>
                    <DiscountValueInput
                      name={`usage_subscriptions.${index}.discount.value`}
                      disabled={!!subscription.discount?.id}
                      discountType={subscription?.discount?.type}
                      currency={values?.currency}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper width={96}>
                    <ActionCell>
                      {subscription.discount?.id && (
                        <InlineButton
                          data-cy={`usage-subscription-modal__edit-discount-button`}
                          isSecondary
                          withBackground
                          width="40px"
                          onClick={() => setDiscountToEdit(subscription.discount)}
                          type="button"
                        >
                          Edit
                        </InlineButton>
                      )}
                      <TrashIconWrapper
                        onClick={() => {
                          setFieldValue(`usage_subscriptions.${index}.discount`, null);
                        }}
                      >
                        <TrashIcon />
                      </TrashIconWrapper>
                    </ActionCell>
                  </ColumnWrapper>
                </>
              )}
            </Row>
          ))}
        </TableWrapper>
      )}

      {discountToEdit && (
        <DiscountEditModal
          discount={discountToEdit}
          onClose={() => setDiscountToEdit(null)}
          onUpdated={(newDiscount) => {
            updateDiscountById(newDiscount?.id, newDiscount);
          }}
          onDeleted={(id) => {
            updateDiscountById(id, null);
          }}
        />
      )}
    </>
  );
};
