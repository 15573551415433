import React from 'react';

import { FormikCustomCheckbox } from 'components/Controls';

import { AggregatedUsageText, FormRow, FullWidthFlexBasis, SubscriptionSubtext } from '../styles';

export const AdditionalSettingsSection = ({ shouldDisableAggregate }) => {
  return (
    <FormRow>
      <FullWidthFlexBasis>
        <SubscriptionSubtext>Additional Settings</SubscriptionSubtext>
        <FormikCustomCheckbox
          data-cy="subscription-create-modal__aggregate-usage-on-parent"
          name="aggregate_usage_on_parent"
          label={
            <AggregatedUsageText>
              Aggregate usage on parent customer (usage of child customers you select will be summed before comparing to
              plan tiers, then the results will be divided among the children)
            </AggregatedUsageText>
          }
          disabled={shouldDisableAggregate}
        />
      </FullWidthFlexBasis>
    </FormRow>
  );
};
