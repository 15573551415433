import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { UPLOADABLE_OBJECTS } from 'views/Transactions/TransactionsCsvUpload/consts';
import { afterUploadFn } from 'shared/CsvUploadV2/utils';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';
import { CsvUploadV2 } from 'shared/CsvUploadV2';
import { useCSVImportsAPI } from 'api/csvImports';

import { getColumns } from './columns';
import {
  DEFAULT_INVOICE_ITEMS_CREATE_MAPPER,
  DEFAULT_INVOICE_ITEMS_MAPPER,
  DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS,
} from './consts';

export const InvoiceItemsCsvUpload = () => {
  const history = useHistory();
  const { organizations } = useContext(AppContext);

  const [submittingForm, setSubmittingForm] = useState(false);
  const [fuzzyErrors, setFuzzyErrors] = useState();

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId: organizations?.[0]?.id, autoFetch: false });

  const defaultMapperWithCreateOrUpdateMode = useCallback(({ createOrUpdateMode }) => {
    return createOrUpdateMode === CSV_MODES.UPDATE
      ? DEFAULT_INVOICE_ITEMS_MAPPER
      : createOrUpdateMode === CSV_MODES.CREATE
      ? DEFAULT_INVOICE_ITEMS_CREATE_MAPPER
      : undefined;
  }, []);

  const getColumnsWithCsvUploadState = useCallback(
    ({ fuzzyErrors, formRef, csvColumnsMapper, createOrUpdateMode, setCsvColumnsMapper, csvColumns }) =>
      getColumns({
        organization: organizations[0],

        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,

        createOrUpdateMode,

        fuzzyErrors,
      }),
    [organizations],
  );

  const handleSubmit = async ({ values, extraData, formRef }) => {
    try {
      setSubmittingForm(true);

      const result = await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: values,
        settings: {
          orgId: organizations[0]?.id,
          entity: extraData.entity,
          createOrUpdateMode: extraData.createOrUpdateMode,
          useBackendValidation: true,
        },
      });

      afterUploadFn({ result, setFuzzyErrors, formRef, history, setSubmittingForm, backLink: '/billing/invoices' });
    } catch {
      setSubmittingForm(false);
    }
  };

  const isLoading = submittingForm;

  return (
    <CsvUploadV2
      entityName={UPLOADABLE_OBJECTS.INVOICE_ITEMS}
      isLoading={isLoading}
      fuzzyErrors={fuzzyErrors}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={defaultMapperWithCreateOrUpdateMode}
      csvColumnsTransformations={DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      backLink="/billing/invoices"
    />
  );
};
