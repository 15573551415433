import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { ReportIcon } from 'components/Icons';
import { usePortal } from 'components/Portal';
import { Tooltip } from 'components/Tooltip_NEW';
import { ReportsPopoverStyledButton } from './styles';
import { ReportsPopover } from './ReportsPopover';

export const ReportsPopoverButton = () => {
  const { state } = useLocation();
  const { organizations } = useContext(AppContext);
  const [showWarn, setShowWarn] = useState(!(organizations[0]?.reportsCount > 0) && state?.report);
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    XOffset: 310,
    YOffset: 20,
    fixed: true,
  });

  const onSavedBtnClick = () => {
    setShowWarn(false);
    togglePortal();
  };

  return (
    <>
      <Tooltip
        secondary
        overlay={<>Customize and save all page settings for quick access to your reports</>}
        overlayInnerStyle={{
          width: '120px',
        }}
        placement="bottom"
        visible={showWarn}
      >
        <ReportsPopoverStyledButton onClick={onSavedBtnClick} ref={triggerRef} active={isPortalVisible} warn={showWarn}>
          <div>Saved</div>
          <ReportIcon width={16} height={16} />
        </ReportsPopoverStyledButton>
      </Tooltip>
      {isPortalVisible && (
        <Portal>
          <ReportsPopover onClose={togglePortal} />
        </Portal>
      )}
    </>
  );
};
