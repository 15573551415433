import React from 'react';
import { ReactComponent as DonutIcon } from 'images/chart-donut-2.svg';
import { ReactComponent as ChartBarIcon } from 'images/chart-bar.svg';
import { ReactComponent as WaterfallIcon } from 'images/bx-water.svg';
import { ReactComponent as TableIcon } from 'images/table.svg';

export const COMMON_METRICS_DASHBOARD_ID = 'common-metrics';
export const COMMON_METRICS_DASHBOARD_TITLE = 'Common SaaS Metrics';

export const WIDGET_TYPES = {
  METRIC: 'metric',
  CHART_VISUALIZER: 'chart_visualizer',
  REVENUE_WATERFALL: 'revenue_waterfall',
  DATA_TABLE: 'data_table',
  TEXT_SECTION: 'text_section',
};

export const WIDGET_TYPES_TITLES = {
  [WIDGET_TYPES.METRIC]: 'Metric Widget',
  [WIDGET_TYPES.CHART_VISUALIZER]: 'Chart Visualizer',
  [WIDGET_TYPES.REVENUE_WATERFALL]: 'Revenue Waterfall',
  [WIDGET_TYPES.DATA_TABLE]: 'Data Table',
  [WIDGET_TYPES.TEXT_SECTION]: 'Text Section',
};

export const POPOVER_OPTIONS = [
  {
    key: WIDGET_TYPES.METRIC,
    title: 'Metric Widget',
    description: 'Chart with one metric',
    icon: <DonutIcon />,
  },
  {
    key: WIDGET_TYPES.CHART_VISUALIZER,
    title: 'Chart Visualizer',
    description: 'Chart with two metrics',
    icon: <ChartBarIcon />,
  },
  {
    key: WIDGET_TYPES.REVENUE_WATERFALL,
    title: 'Revenue Waterfall',
    description: 'Breakdown of revenue by New, Upsell, Downsell, etc.',
    icon: <WaterfallIcon />,
  },
  {
    key: WIDGET_TYPES.DATA_TABLE,
    title: 'Data Table',
    description: 'Table with finance metrics',
    icon: <TableIcon />,
  },
  {
    key: WIDGET_TYPES.TEXT_SECTION,
    title: 'Text Section',
    description: 'Rich text section for titles, descriptions, etc.',
    icon: <TableIcon />, // TODO: Change to text
  },
];
