import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { FormikCustomSelector } from 'components/Controls';
import { TrashIcon } from 'components/Icons';
import { getCustomersFromSearch } from 'shared/TransactionContent';

const getInitialChildCustomer = (customer) => {
  return {
    label: `All child customers${customer?.name ? ` of ${customer?.name}` : ''}`,
    manual: false,
    value: -1,
    id: -1,
  };
};

export const ChildCustomersSelector = ({ customValues, customKeyBase = '', hideLabel = false }) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const { orgId } = useContext(AppContext);

  const values = customValues ?? formikValues;

  const [selectedChildCustomers, setSelectedChildCustomers] = useState(
    values?.selected_child_customers?.reduce((obj, child_customer) => {
      obj[child_customer.id] = child_customer.label;
      return obj;
    }, {}),
  );

  const _value = values.child_customer_ids?.map((id) => ({
    id: id,
    value: id,
    label: selectedChildCustomers[id],
  }));

  const initialCustomer = getInitialChildCustomer(values?.customer ?? {});

  const value = _value?.length ? _value : [initialCustomer];

  return (
    <FormikCustomSelector
      isDisabled={!values?.customer_id}
      label={hideLabel ? '' : 'Child customer(s)'}
      placeholder="+ Customer"
      name="child_customer_ids"
      key={`parent_customers_${values?.customer_id}_child_num_${values?.child_customer_ids?.length}`}
      value={value}
      handleChange={(selectedItems) => {
        const manualAllChild = selectedItems.filter((item) => item.manual);
        const excludingInitialCustomer = selectedItems.filter((item) => item.value !== -1);
        if (excludingInitialCustomer.length === 0 || manualAllChild?.length) {
          return setFieldValue(`${customKeyBase}child_customer_ids`, []);
        }

        const newSelectedChildCustomers = {};
        excludingInitialCustomer.forEach((customer) => {
          newSelectedChildCustomers[customer.id] = customer.label || customer.name;
        });

        setSelectedChildCustomers(newSelectedChildCustomers);
        setFieldValue(
          `${customKeyBase}child_customer_ids`,
          excludingInitialCustomer.map((item) => item.id),
        );
      }}
      loadOptions={(searchQuery, prevOptions, additional) =>
        getCustomersFromSearch({
          searchQuery,
          orgId,
          parentCustomerId: values?.customer_id,
          prevOptions,
          additional,
          customFilter: (customer) => !values?.child_customer_ids?.includes(customer.id.toString()),
        })
      }
      defaultOptions={[{ ...initialCustomer, manual: true }]}
      onCreateOption={async (newCustomerName) => {
        const newChildCustomer = await createCustomer({
          orgId,
          customerName: newCustomerName,
          customerParent: values?.customer_id,
        });

        const currentChildCustomerIds = values?.child_customer_ids ?? [];
        const excludingInitialCustomer = currentChildCustomerIds.filter((item) => item.id !== -1);
        setFieldValue(`${customKeyBase}child_customer_ids`, [...excludingInitialCustomer, newChildCustomer.id]);
        setSelectedChildCustomers({
          ...selectedChildCustomers,
          [newChildCustomer.id]: newChildCustomer.name,
        });
      }}
      creatable
      isPaginateable
      isMulti
      boldValue
      blueVer
      forceShowBorder
      customBackgroundColor="#FFFFFF"
      customBorderRadius={4}
      CustomDeleteIcon={TrashIcon}
    />
  );
};
