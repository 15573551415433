import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { Graph } from 'components/Graph';
import { TooltipContainer } from 'components/Tooltip';
import { TooltipRowText12 } from 'components/Tooltip/styles';
import { SmallCardBarChart } from 'shared/SmallCardBarChart';
import { GoalsContext } from 'shared/Goals';

import { DashboardContext } from '../../DashboardContext';
import { CARD_SIZES } from './consts';
import { CardContainer, ChartContainer, SettingsButtonContainer } from './styled';
import { CardHeader } from './CardHeader';
import { FlexBetweenContainer, Flexer } from 'components/Core';

const CHART_TICKS = {
  [CARD_SIZES.MEDIUM]: 3,
  [CARD_SIZES.LARGE]: 6,
  [CARD_SIZES.BIG]: 6,
};

const Card = ({
  title: titleOverride,
  metricKey,
  currentValue,
  startingValue,
  graphData,
  graphLabels,
  rowStart,
  columnStart,
  size,
  ActionButton,
  SettingsButton,
  onTTMClick,
  children,
  clickable = true,
  version,
  AddGridCardButton,
}) => {
  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const { isARR, growthType, hasConfirmedTransactions, openCardChartModal } = useContext(DashboardContext);
  const { goals } = useContext(GoalsContext);

  const { label, description: titleTooltip, monthDataKey, dataType, isARRMetric } =
    SNAPSHOT_METRIC_BY_KEY[metricKey] ?? {};
  const title = titleOverride ?? label?.replace(' TTM', '');
  const isTTM = metricKey?.includes('ttm');
  const isARRValue = !!isARRMetric && isARR;

  const latestGoal = useMemo(() => {
    const metricGoals = goals?.[title];
    if (metricGoals) {
      const goal = metricGoals[Object.keys(metricGoals)[Object.keys(metricGoals).length - 1]];
      // For now let's not show if periods are not monthly
      return goal.periods[0].type === 'month' ? goal : null;
    }
  }, [goals, title]);

  const goalData = useMemo(() => {
    if (latestGoal) {
      const monthlyGoals = latestGoal.periods.reduce((acc, period) => {
        acc[dayjs(period.start_month).format('YYYY-MM')] = period.value;
        return acc;
      }, {});

      return graphLabels.reduce((acc, month) => {
        const monthKey = dayjs(month).format('YYYY-MM');
        acc.push(monthlyGoals[monthKey]);
        return acc;
      }, []);
    }
    return [];
  }, [latestGoal, graphLabels]);

  const handleViewMoreClick = () => {
    if (clickable && openCardChartModal) {
      openCardChartModal({
        metricKey,
        selectedGoalId: latestGoal?.id,
      });
    }
  };

  return (
    <CardContainer
      onClick={handleViewMoreClick}
      clickable={clickable && openCardChartModal}
      rowStart={rowStart}
      columnStart={columnStart}
      size={size}
      data-cy={`dashboard-card--${title}`}
      version={version}
    >
      <TooltipContainer
        width={77}
        height={24}
        hideArrow
        tooltipWrapperStyles={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
        tooltipStyles={{ borderRadius: '4px' }}
        fontSize="12px"
        backgroundColor={'var(--opaquePrimaryBlack50)'}
        yOffset={-20}
        isVisible={clickable && openCardChartModal}
        toolTipContent={<TooltipRowText12>Learn More</TooltipRowText12>}
      >
        <FlexBetweenContainer
          width="100%"
          height="100%"
          direction={size === CARD_SIZES.SMALL && version !== 2 ? 'row' : 'column'}
        >
          {![CARD_SIZES.HUGE].includes(size) && (
            <>
              <CardHeader
                isTTM={isTTM}
                title={title}
                titleTooltip={titleTooltip && titleTooltip({ isARR, growthType })}
                currentValue={currentValue}
                startingValue={startingValue}
                valueType={dataType}
                graphLabels={graphLabels}
                hasConfirmedTransactions={hasConfirmedTransactions}
                size={size}
                ActionButton={ActionButton}
                onTTMClick={onTTMClick}
                version={version}
              />
              {hasConfirmedTransactions && (
                <>
                  {[CARD_SIZES.SMALL].includes(size) && (
                    <Flexer alignItems="flex-end" justifyContent="flex-end" width="100%" height="100%">
                      <ChartContainer size={size} version={version}>
                        <SmallCardBarChart
                          graphLabels={graphLabels}
                          valueType={dataType}
                          data={graphData}
                          areaKey={monthDataKey}
                          goalData={goalData}
                          showTicks={version === 2}
                        />
                      </ChartContainer>
                    </Flexer>
                  )}
                  {[CARD_SIZES.MEDIUM, CARD_SIZES.BIG, CARD_SIZES.LARGE].includes(size) && (
                    <ChartContainer size={size} version={version}>
                      <Graph
                        isARR={isARRValue}
                        data={graphData}
                        dataKey={monthDataKey}
                        labels={graphLabels}
                        dataType={dataType}
                        goalData={goalData}
                        currency={currencyISOCode}
                        ticks={CHART_TICKS[size]}
                      />
                    </ChartContainer>
                  )}
                </>
              )}
            </>
          )}

          {hasConfirmedTransactions && children}
        </FlexBetweenContainer>
      </TooltipContainer>
      <SettingsButtonContainer>{SettingsButton}</SettingsButtonContainer>
      {AddGridCardButton}
    </CardContainer>
  );
};

export { Card };
