import React, { useCallback, useContext, useState } from 'react';
import { Formik } from 'formik';
import { pick } from 'lodash';

import { AppContext } from 'AppContext';
import { useDiscountsAPI, useUsageSubscriptionGroupsAPI } from 'api/usageBasedEngine';
import { Modal, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';

import {
  SUBSCRIPTION_EDIT_MODAL_MODE,
  subscriptionGroupValidationSchema,
  USAGE_SUBSCRIPTION_METADATA_KEYS,
} from './consts';
import { SubscriptionForm } from './SubscriptionForm';

const INITIAL_EMPTY_VALUES = {
  customer_id: '',
  start_date: null,
  end_date: null,
  trial_units: 0,
  has_discount: false,
  previous_group_id: null,
  usage_subscriptions: [],
};

export const SubscriptionEditModal = ({
  onClose,
  selectedGroup = INITIAL_EMPTY_VALUES,
  mode = SUBSCRIPTION_EDIT_MODAL_MODE.CREATE,
  saveSubscription = true,
  onSubscriptionCreated,
  openDuplicateModal,
}) => {
  const { orgId, currencyISOCode: orgCurrency } = useContext(AppContext);

  const {
    operations: { createUsageSubscriptionGroup, createTransactions, editUsageSubscriptionGroup },
  } = useUsageSubscriptionGroupsAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: { createDiscount },
  } = useDiscountsAPI({
    orgId,
    autoFetch: false,
  });

  const handleSubmit = async (values) => {
    await Promise.all(
      values.usage_subscriptions.map(async (subscription) => {
        if (!values.has_discount || !subscription.discount) {
          subscription.discount_id = null;
          return;
        }
        if (subscription.discount?.id) {
          subscription.discount_id = subscription.discount?.id ?? null;
          return;
        }

        const discount = await createDiscount.mutateAsync({
          data: {
            type: subscription.discount.type,
            value: subscription.discount.value,
          },
        });
        subscription.discount_id = discount.id;
      }),
    );

    const metadata = {
      [USAGE_SUBSCRIPTION_METADATA_KEYS.SKIP_PREPAID_TRANSACTION_SYNC]: !!values.skip_prepaid_transaction_sync,
    };

    const data = {
      ...pick(values, [
        'start_date',
        'end_date',
        'customer_id',
        'trial_units',
        'previous_group_id',
        'usage_subscriptions',
        'is_prorated',
        'aggregate_usage_on_parent',
        'child_customer_ids',
        'prorate_by_amount',
      ]),
      metadata,
    };

    data.usage_subscriptions = data.usage_subscriptions.map((subscription) =>
      pick(subscription, [
        'pricing_plan_id',
        'overage_transaction_name',
        'overage_transaction_metadata',
        'prepaid_transaction_name',
        'prepaid_transaction_metadata',
        'discount_id',
      ]),
    );

    if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.CREATE) {
      if (saveSubscription) {
        const newSubscription = await createUsageSubscriptionGroup.mutateAsync({ data });
        onSubscriptionCreated?.(newSubscription);
      } else {
        onSubscriptionCreated?.(values);
      }
    } else if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT) {
      const shouldCreateTransactions = selectedGroup.usage_subscriptions.every((sub) => sub.transactions?.length === 0);
      if (shouldCreateTransactions) {
        await createTransactions.mutateAsync({ id: selectedGroup.id });
      }
      await editUsageSubscriptionGroup.mutateAsync({ id: selectedGroup.id, data });
    }

    onClose();
  };

  const firstSubscription = selectedGroup?.usage_subscriptions?.[0] ?? {};

  const customer = selectedGroup?.customer || firstSubscription?.customer;

  const onDuplicate = () => {
    openDuplicateModal?.({ groupedSubscriptions: [selectedGroup] });
    onClose();
  };

  return (
    <ModalContainer>
      <Modal overflow="visible" width="90%" data-cy="edit-subscription-modal" height="auto" maxHeight="94vh">
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={{
            ...pick(firstSubscription, [
              'aggregate_usage_on_parent',
              'customer_name',
              'end_date',
              'is_prorated',
              'prorate_by_amount',
              'start_date',
              'trial_units',
              'selected_child_customers',
              'metadata',
            ]),
            ...selectedGroup,
            customer_name: customer?.name || selectedGroup?.customer_name || firstSubscription?.customer_name,
            customer_id: customer?.id || selectedGroup?.customer_id || firstSubscription?.customer_id,
            has_discount: !!selectedGroup?.usage_subscriptions?.some((subscription) => !!subscription.discount),
            currency: firstSubscription?.pricing_plan?.currency ?? orgCurrency,
            skip_prepaid_transaction_sync: firstSubscription?.metadata?.skip_prepaid_transaction_sync,
            child_customer_ids:
              firstSubscription?.child_customer_ids ??
              (firstSubscription?.selected_child_customers ?? []).map(({ id }) => id),
            usage_subscriptions: (selectedGroup?.usage_subscriptions ?? []).map((subscription) => {
              return {
                ...subscription,
                pricing_plan_id: subscription?.pricing_plan?.id,
                overage_transaction_name: subscription?.overageTransaction?.name,
                overage_transaction_metadata: subscription?.overageTransaction?.metadata,
                prepaid_transaction_name: subscription?.prepaidTransactions?.[0]?.name,
                prepaid_transaction_metadata: subscription?.prepaidTransactions?.[0]?.metadata,
                pricing_plan_frequency: subscription?.pricing_plan?.invoicing_frequency,
              };
            }),
          }}
          validationSchema={subscriptionGroupValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <SubscriptionForm
              isLoading={createUsageSubscriptionGroup.isLoading || editUsageSubscriptionGroup.isLoading}
              mode={mode}
              onClose={onClose}
              onDuplicate={onDuplicate}
            />
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useSubscriptionEditModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_USAGE_SUBSCRIPTION_MODAL,
        properties: { mode: openParams?.mode ?? SUBSCRIPTION_EDIT_MODAL_MODE.EDIT },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const Modal = useCallback(
    () => (showModal ? <SubscriptionEditModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    SubscriptionEditModal: Modal,
  };
};
