import styled from 'styled-components';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { ArrowNarrowRightIcon } from 'components/Icons';

export const Table = styled(ReactTable)`
  width: 100%;
  padding: 1px;
  margin-top: 10px;
`;

export const TableHeader = styled(ReactTableHeader)`
  width: 100%;
  background-color: var(--light100);
  color: var(--dark75);
  border-radius: 16px 16px 0 0;
  border: 2px solid var(--naturalAdditional3);
`;

export const TableHeaderRow = styled(ReactTableHeaderRow)`
  border-radius: 16px 16px 0 0;
  justify-content: flex-start;
`;

export const TableHeaderColumn = styled(ReactTableHeaderColumn)`
  color: var(--dark75);
`;

export const TableBody = styled(ReactTableBody)`
  border-bottom: 2px solid var(--naturalAdditional3);
  border-left: 2px solid var(--naturalAdditional3);
  border-right: 2px solid var(--naturalAdditional3);
  border-top: ${({ isFullBorder }) => (isFullBorder ? '2px solid var(--naturalAdditional3)' : 'none')};
  border-radius: ${({ isFullBorder }) => (isFullBorder ? '16px 16px ' : '0 0')} 16px 16px;
  width: 100%;
  margin-left: -1px;
`;

export const TableRow = styled(ReactTableRow)`
  background-color: var(--dark2);
  font-weight: ${({ bolder }) => (bolder ? '700' : 'normal')};
  font-size: 14px;
  line-height: 16px;
  padding: 0 14px;
`;

export const ArrowIcon = styled(ArrowNarrowRightIcon)`
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: transparent;
`;

export const TableCell = styled(ReactTableCell)`
  color: ${({ primary }) => (primary ? 'var(--dark100)' : 'var(--dark75)')};
  font-style: ${({ primary }) => (primary ? 'italic' : 'normal')};
  padding-left: ${({ extraPadding }) => (extraPadding ? '10px' : '0')};
  background-color: ${({ isWhite }) => (isWhite ? 'var(--light100)' : 'transparent')};
  border-radius: ${({ isCustomRadius }) => (isCustomRadius ? '0 16px 16px 0' : '0')};
  border-right: ${({ isFullBorders }) => (isFullBorders ? '2px solid var(--naturalAdditional3)' : 'none')};
  position: relative;
  ${({ noPadding }) => noPadding && 'padding: 0'};

  &:has(${ArrowIcon}) {
    &::before {
      content: '';
      position: absolute;
      right: 8px;
      top: 0;
      width: 2px;
      background-color: var(--naturalAdditional3);
      height: 14px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 8px;
      bottom: 0;
      width: 2px;
      background-color: var(--naturalAdditional3);
      height: 14px;
    }
  }
`;

export const InlineDate = styled.span`
  width: 100%;
  border: none;
  background-image: none;
  padding: 18px 20px;

  &:hover {
    border: none;
  }
`;
