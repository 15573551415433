import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { useUsageSubscriptionGroupsAPI } from 'api/usageBasedEngine';
import { FormikCustomSelector } from 'components/Controls';
import { isSameId } from 'utils/objectUtils';

import { displayPreviousGroup } from './utils';

export const PreviousGroupSelector = () => {
  const { values, setFieldValue } = useFormikContext();
  const { orgId } = useContext(AppContext);

  const customerId = values?.customer_id;

  const { data: previousGroupData } = useUsageSubscriptionGroupsAPI({
    orgId,
    autoFetch: !!customerId,
    params: {
      filters: {
        customerIds: [customerId],
      },
      pagination: {
        limit: 100,
      },
      scopes: ['usage_subscriptions', 'pricing_plans'],
    },
  });

  const previousGroupOptions = useMemo(
    () =>
      (previousGroupData?.data ?? [])
        .filter(({ id, renewal_id, usage_subscriptions: previousSubscriptions }) => {
          if (isSameId(id, values?.id)) return false;
          if (isSameId(id, values?.renewal_id)) return false;
          if (!previousSubscriptions[0]?.end_date) return false;
          return !renewal_id || isSameId(renewal_id, values?.id);
        })
        .map((group) => ({
          value: group.id,
          renewal_id: group.renewal_id,
          label: displayPreviousGroup(group),
        })),
    [previousGroupData, values?.renewal_id, values?.id],
  );

  const handlePreviousSubscriptionChange = (option) => {
    const previousGroup = (previousGroupData?.data ?? []).find(({ id }) => id === option?.value);
    setFieldValue('previous_group_id', previousGroup?.id ?? null);
    if (previousGroup) {
      const previousFirstSubscription = previousGroup.usage_subscriptions[0];
      setFieldValue(
        'usage_subscriptions',
        previousGroup.usage_subscriptions.map(({ pricing_plan }) => ({
          pricing_plan,
          pricing_plan_id: pricing_plan.id,
        })),
      );
      setFieldValue('start_date', previousFirstSubscription.end_date);

      const previousLength =
        dayjs(previousFirstSubscription.end_date).diff(previousFirstSubscription.start_date, 'month') || 1;

      setFieldValue('end_date', dayjs(previousFirstSubscription.end_date).add(previousLength, 'month'));
    }
  };

  return (
    <FormikCustomSelector
      isDisabled={(previousGroupOptions ?? []).length === 0}
      label="Previous subscription"
      placeholder="Select subscription"
      name="previous_group_id"
      value={previousGroupOptions.find((previousGroup) => isSameId(previousGroup?.renewal_id, values?.id))}
      options={previousGroupOptions}
      handleChange={handlePreviousSubscriptionChange}
      isClearable
    />
  );
};
