import React, { useEffect, Fragment } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy, useExpanded } from 'react-table';

import { useStateWithStorage } from 'utils/hooks';
import { formatValueForDisplay } from 'utils/formatters';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  ReactTableCell,
  RowsCount,
  TableHeaderActions,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { Row, Spacer, Flexer, FlexerColumn } from 'components/Core';
import { PaginationButton } from 'components/Buttons';
import { TableColumnSettings } from 'shared/Common/TableColumnSettings';
import { ReactComponent as GreyDot } from 'images/grey-dot.svg';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

import { MetadataSection, MetadataLabelText, MetadataWrapper } from 'views/ExternalUpdates/styles';
import { ComparatorDisplay } from 'views/ExternalUpdates/Components/ComparatorDisplay';
import { AI_DESCREPANCIES_TABLE_COLUMN_TITLES_BY_ID } from './consts';

const Table = ({ data, columns, onSelectedRowsChange }) => {
  const [localStoragePageSize, setLocalStoragePageSize] = useStateWithStorage('ai-discrepancies-page-size', 50);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    rows,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      paginateExpandedRows: false,
      initialState: {
        pageSize: localStoragePageSize,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setLocalStoragePageSize(pageSize);
  }, [pageSize, setLocalStoragePageSize]);

  useEffect(() => {
    onSelectedRowsChange(selectedRowIds);
  }, [selectedRowIds, onSelectedRowsChange]);

  return (
    <FlexerColumn data-cy="ai-descrepancies-table">
      <TableHeaderActions horizontal="space-between">
        <Flexer></Flexer>
        <Row>
          <RowsCount>
            {rows.length} rows found ({data.length} total)
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <TableColumnSettings
            numberValue={pageSize}
            handleShowResultsChange={(number) => {
              setPageSize(number?.value ?? 10);
            }}
            allColumns={allColumns}
            columnsTitles={AI_DESCREPANCIES_TABLE_COLUMN_TITLES_BY_ID}
            resultsLabel="Rows on Page:"
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
            ]}
          />
        </Row>
      </TableHeaderActions>

      <ReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => {
            return (
              <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableHeaderColumn
                    alignRight={column?.alignRight}
                    customWidth={column.width}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                      {column.render('Header')}
                      {column.isSorted && (
                        <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                          <TotalIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </ReactTableHeaderColumn>
                ))}
              </ReactTableHeaderRow>
            );
          })}
        </ReactTableHeader>
        <ReactTableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row?.id}>
                <ReactTableRow data-cy="ai-discrepancies__row">
                  {row.cells.map((cell) => (
                    <ReactTableCell
                      noPaddingRight={cell.column.noPaddingRight}
                      customWidth={cell.column.width}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ))}
                </ReactTableRow>

                {row.isExpanded ? (
                  <MetadataSection data-cy="ai-discrepancies__metadata">
                    <MetadataLabelText> Metadata: </MetadataLabelText>
                    <Spacer width="10px" />
                    <Flexer wrapRow>
                      {row.original.metadata.keys.map((key) => (
                        <ComparatorDisplay
                          key={key}
                          original={row.original.metadata.original[key]}
                          updated={row.original.metadata.updated[key]}
                          isChanged={row.original.metadata.original[key] !== row.original.metadata.updated[key]}
                          displayOriginal={
                            <MetadataWrapper>
                              {`${key}: `} <b> {formatValueForDisplay(row.original.metadata.original[key])} </b>
                            </MetadataWrapper>
                          }
                          displayUpdated={
                            <MetadataWrapper highlight>
                              {`${key}: `} <b> {formatValueForDisplay(row.original.metadata.updated[key])} </b>
                            </MetadataWrapper>
                          }
                        />
                      ))}
                    </Flexer>
                  </MetadataSection>
                ) : null}
              </Fragment>
            );
          })}
        </ReactTableBody>
      </ReactTable>
    </FlexerColumn>
  );
};

export { Table };
