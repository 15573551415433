import React from 'react';
import { CardContainer } from '../Common/Card/styled';
import { DashboardTables } from '../DashboardTables';
import { DataTableEditWidgetDropdown } from '../ReportBuilder/DataTableEditWidgetDropdown';

export const DashboardDataTableCard = ({
  metric,
  metricIndex,
  rowStart,
  columnStart,
  size,
  version,
  name,
  selectedMetrics,
  selectedColumns,
  AddGridCardButton,
}) => {
  return (
    <CardContainer
      rowStart={rowStart}
      columnStart={columnStart}
      size={size}
      direction="column"
      version={version}
      data-cy="dashboard-data-table-card"
      invisible
    >
      <DashboardTables
        name={name}
        selectedMetrics={selectedMetrics}
        selectedColumns={selectedColumns}
        SettingsButton={(props) => <DataTableEditWidgetDropdown metricIndex={metricIndex} metric={metric} {...props} />}
      />
      {AddGridCardButton}
    </CardContainer>
  );
};
