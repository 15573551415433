import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useClickOutside } from 'utils/hooks';
import { SwitchWithLabel } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';

const StyledSettingsPopover = styled.div`
  width: 220px;
  max-height: 500px;
  overflow-y: scroll;
  right: 8px;
  padding: 18px 18px 10px;
  background-color: white;
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
  z-index: 50;
  position: absolute;
`;

const ColumnLabel = styled.h4`
  font-size: 12px;
  color: rgba(0, 21, 46, 0.5);
  margin-bottom: 8px;
`;

const ColumnItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
`;

const ColumnItemText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 10px;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 4px 0;
  background-color: var(--primaryBlack5);
`;

const SettingsButton = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  cursor: pointer;
  color: var(--primaryBlue);
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const MAX_NUMBER_OF_ACTIVE_SEGMENTS = 5;

export const BarChartSettings = ({
  hasSegmenting,
  showingSegments,
  onChangeShowingSegments,
  options,
  showLabels,
  setShowLabels,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));
  return (
    <div ref={dropdownRef}>
      <SettingsButton onClick={() => setShowDropdown(!showDropdown)}>Chart Settings</SettingsButton>
      {showDropdown && (
        <StyledSettingsPopover>
          <div>
            <ColumnLabel>Chart Settings:</ColumnLabel>
            {!hasSegmenting && (
              <SwitchWithLabel onChange={() => setShowLabels(!showLabels)} checked={showLabels} label={'Show labels'} />
            )}
            {hasSegmenting && (
              <>
                <Divider />
                <ColumnLabel>
                  {`Shows only ${MAX_NUMBER_OF_ACTIVE_SEGMENTS} segments, the remaining segments will be combined into others:`}
                </ColumnLabel>
                {options?.map(({ key, label }) => {
                  const selected = showingSegments.has(key);
                  return (
                    <TooltipContainer
                      key={key}
                      width={200}
                      toolTipContent={`You have already selected ${MAX_NUMBER_OF_ACTIVE_SEGMENTS} segments, remove one to select this one`}
                      isVisible={showingSegments.size === MAX_NUMBER_OF_ACTIVE_SEGMENTS && !selected}
                    >
                      <ColumnItems>
                        <Checkbox
                          style={{ height: 20, width: 20 }}
                          checked={selected}
                          onChange={() => onChangeShowingSegments(key)}
                        />
                        &nbsp;
                        <ColumnItemText>{label}</ColumnItemText>
                      </ColumnItems>
                    </TooltipContainer>
                  );
                })}
              </>
            )}
          </div>
        </StyledSettingsPopover>
      )}
    </div>
  );
};
