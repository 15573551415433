import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Centerer, Flexer } from 'components/Core';
import { SnapshotTable } from '../DashboardTables/SnapshotTable/styles';

export const StyledTooltip = styled(Tooltip)`
  display: flex;
`;

export const DataHead = styled(Flexer)`
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #ffffff;
  border: 1px solid var(--neutralGray);
  border-radius: 20px;
  margin-bottom: 8px;
`;

export const DashboardData = styled.div`
  &:hover {
    ${DataHead} {
      box-shadow: 8px 8px 60px var(--primaryBlack2);
    }

    ${SnapshotTable} {
      box-shadow: 8px 8px 60px var(--primaryBlack2);
    }
  }
`;

export const DataPill = styled(Centerer)`
  padding: 4px;
  width: 20px;
  height: 20px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;

  background: var(--primaryBlack3);
  border: 1px solid var(--neutralGray);
  border-radius: 8px;
`;

export const DataInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const Snapshot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 190px;
  height: 43px;
  max-width: 190px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 10px;
  color: var(--primaryBlack);
  border-right: 1px solid var(--primaryBlack10);
  border-bottom: 1px solid var(--primaryBlack10);

  .title {
    height: 43px;
    line-height: 38px;
  }
`;

export const TabSubtitle = styled.div`
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primaryBlack);
  border-bottom: 1px solid var(--primaryBlack10);
  border-right: 1px solid var(--primaryBlack10);
  background-color: rgba(57, 161, 220, 0.1);
`;

export const TabColumn = styled.div`
  width: 190px;
  padding: 10px;
  border-right: 1px solid var(--primaryBlack10);
  border-bottom: 1px solid var(--primaryBlack10);
  border-left: none;
  ${(props) => props.height && `height: ${props.height}px`};
`;

export const TagWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const RowDisplayContainer = styled.h6`
  margin-top: 10px;
  font-size: ${({ accent }) => (accent ? 14 : 12)}px;
  font-weight: ${({ accent }) => (accent ? 600 : 400)};
`;

export const DashboardTables = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  overflow-y: hidden;
  position: relative;
`;

export const TabHead = styled.div`
  display: flex;
  border-bottom: 1px solid var(--primaryBlack10);
  justify-content: space-between;
`;

export const TabData = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 14px 0;
  color: var(--primaryBlack);
  height: 42px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 20px;
  border-radius: 10px 10px 0 0;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}

  &:after {
    position: absolute;
    content: '';
    width: 100px;
    height: 2000px;
    z-index: -1;
    left: 0;
    ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  }
`;

export const TabHeading = styled.div`
  border-bottom: 1px solid var(--primaryBlack10);
  height: ${(props) => props.height}px;
`;

export const TabStats = styled.div`
  display: flex;

  img {
    margin-left: 8px;
  }
`;

export const Bigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  font-size: ${(props) => (props.isRegular ? '12px' : '14px !important')};
  font-weight: ${(props) => props.propFontWeight};
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : 'initial')};
  padding-right: 20px;
  padding-top: ${(props) => (props.inTotalTab ? '14' : '0')}px;
  padding-bottom: ${(props) => (props.inTotalTab ? '14' : '10')}px;
  color: ${(props) => (props.isRegular ? 'rgba(0, 21, 46,0.7)' : 'var(--primaryBlack)')};
  position: relative;
`;

// Remove this and use components/Core instead
export const FlexerStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RowTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleTextContainer = styled(Centerer)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
