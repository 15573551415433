import { useFormikContext } from 'formik';
import { FormikCustomSelector } from 'components/Controls';

const FAIL_SAFE_WIDTH = 10;

const BooleanSelector = ({ name, width }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <FormikCustomSelector
      name={name}
      width={width - FAIL_SAFE_WIDTH}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
      handleChange={(option) => {
        setFieldValue(name, option.value);
      }}
      noBorders
    />
  );
};

export default BooleanSelector;
