import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import { ReportIcon, SearchAltIcon } from 'components/Icons';
import { CentererVertical } from 'components/Core';
import { InlineButton, SelectDropdownButton } from 'components/Buttons';
import { clearCachedMetrics } from 'utils/cachedMetricsUtils';
import { DEFAULT_REPORTS_OPTION, REPORT_OPTIONS_ROUTE } from '../consts';
import { useNewReportModal } from '../NewReportModal';
import {
  ReportsPopoverContents,
  ReportsPopoverHeader,
  ReportsPopoverHeaderLeft,
  ReportsPopoverHeaderSearch,
  ReportsPopoverHeaderTitle,
} from './styles';
import { getFilteredReports, getReportsOptionsWithCount } from './utils';
import { ReportsList } from './ReportsList';
import { SearchBar } from 'components/Blocks';

export const ReportsPopover = ({ onClose }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { organizations } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState();

  const handleNavigateToReportPage = useCallback(
    ({ report, isNewReport = false }) => {
      clearCachedMetrics();
      onClose();
      let route = REPORT_OPTIONS_ROUTE[report.type];
      // We need this for when report options is already the same as page state
      // Otherwise the url will be empty
      if (REPORT_OPTIONS_ROUTE[report.type] === pathname) {
        route += `?${new URLSearchParams(window.location.search).toString()}`;
      }
      history.push(route, { report, isNewReport });
    },
    [history, pathname, onClose],
  );

  const { data, error, isLoading, isFetching } = useReportsAPI({
    orgId: organizations[0].id,
    filters: {
      searchQuery: searchTerm,
    },
  });

  const { openNewReportModal, NewReportModal } = useNewReportModal({
    onSubmitted: handleNavigateToReportPage,
  });

  const reportsOptions = useMemo(() => getReportsOptionsWithCount(data), [data]);

  const [selectedReportType, setSelectedReportType] = useState(DEFAULT_REPORTS_OPTION);

  const reportList = useMemo(
    () =>
      getFilteredReports({
        data: data ?? [],
        selectedReportType,
      }),
    [data, selectedReportType],
  );

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleClickCreateNewReport = () => {
    openNewReportModal();
  };

  return (
    <ReportsPopoverContents>
      <ReportsPopoverHeader>
        <ReportsPopoverHeaderLeft>
          <CentererVertical>
            <ReportIcon />
            <ReportsPopoverHeaderTitle>Saved Reports:</ReportsPopoverHeaderTitle>
            <SelectDropdownButton
              name="reports-popover__report-type"
              options={reportsOptions}
              selected={selectedReportType}
              onSelect={setSelectedReportType}
            />
          </CentererVertical>
          <InlineButton withBackground fontSize="14px" onClick={handleClickCreateNewReport}>
            Create new
          </InlineButton>
        </ReportsPopoverHeaderLeft>
        <ReportsPopoverHeaderSearch>
          <SearchAltIcon />
          <SearchBar
            value={searchTerm}
            onChange={({ target }) => {
              setSearchTerm(target.value);
            }}
            onBlur={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
            placeholder={'Search...'}
          />
        </ReportsPopoverHeaderSearch>
      </ReportsPopoverHeader>
      <ReportsList
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        reportList={reportList}
        searchTerm={searchTerm}
        handleClearSearch={handleClearSearch}
        handleClickCreateNewReport={handleClickCreateNewReport}
        handleNavigateToReportPage={handleNavigateToReportPage}
      />
      <NewReportModal />
    </ReportsPopoverContents>
  );
};
