import { useContext, useMemo } from 'react';

import { EVENT_RECOGNITION_TYPES } from 'consts/global';
import { FormikCustomSelector, SwitchContainer, SwitchWithLabel } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { FlexerColumn } from 'components/Core';

import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';

export const IncludeEmptyEventsOption = () => {
  const { includedTransactions, invoicingScheduleFormValues, scheduleFormRef } = useContext(InvoicingScheduleContext);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const hasEventTransactions = useMemo(
    () => includedTransactions?.some((transaction) => EVENT_RECOGNITION_TYPES.includes(transaction.recognition)),
    [includedTransactions],
  );

  const includeEmptyItems =
    invoicingScheduleFormValues?.include_empty_event_items ||
    invoicingScheduleFormValues?.include_empty_items_with_nonzero_events;

  const options = [
    {
      value: 'include_empty_event_items',
      label: 'Always create $0 items',
    },
    {
      value: 'include_empty_items_with_nonzero_events',
      label: 'Only for transactions with >0 units',
    },
  ];

  const handleIncludeEmptyEventItemsClick = () => {
    if (includeEmptyItems) {
      setFieldValue('include_empty_event_items', false);
      setFieldValue('include_empty_items_with_nonzero_events', false);
    } else {
      setFieldValue('include_empty_event_items', true);
    }
  };

  const handleSelectOption = (selectedOption) => {
    options.forEach((option) => {
      setFieldValue(option.value, selectedOption.value === option.value);
    });
  };

  return (
    <FlexerColumn gap="5px">
      <SwitchContainer>
        <TooltipContainer
          toolTipContent="No event transactions included"
          fontSize="12px"
          isVisible={!hasEventTransactions}
          width={200}
        >
          <SwitchWithLabel
            onChange={handleIncludeEmptyEventItemsClick}
            checked={includeEmptyItems}
            name="include_empty_items"
            label={<>Create $0 items for event transactions</>}
            labelSize="12px"
            disabled={!hasEventTransactions}
          />
        </TooltipContainer>
      </SwitchContainer>

      {hasEventTransactions && includeEmptyItems && (
        <FormikCustomSelector
          name="include_empty_items"
          options={options}
          handleChange={handleSelectOption}
          value={options.find((option) => invoicingScheduleFormValues?.[option.value])}
        />
      )}
    </FlexerColumn>
  );
};
