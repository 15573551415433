import React, { useState, useContext, useMemo, Fragment } from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { cloneDeep } from 'lodash';
import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { ReactComponent as CircleCheck } from 'images/transaction_check.svg';
import { ReactComponent as InfoIcon } from 'images/black-info-circle.svg';
import { ReactComponent as ToolsIcon } from 'images/tools-snapshot.svg';
import { ReactComponent as PillIcon } from 'images/pill-check.svg';
import { StyledInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { CancelButton, GreenButton } from 'components/Buttons';
import { Row, Spacer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { TooltipContainer } from 'components/Tooltip';
import { BUSINESS_SNAPSHOT_COLUMNS } from 'consts/global';
import { useClickOutside, useAnalytics } from 'utils/hooks';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { getAppSetting } from 'models/appSettings';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { SNAPSHOT_TABLE_METRICS } from '../consts';
import { getMetricRowBoxColor, METRIC_FILTER_GROUPS, METRIC_GROUPS_BY_TITLE } from './utils';

const CheckboxesColumn = styled.div`
  height: 100%;
  background: white;
  box-shadow: inset -10px 0px 80px rgba(0, 0, 0, 0.06);
  padding: 32px;
  width: 300px;
`;

const CheckboxSectionTitle = styled.div`
  opacity: 0.3;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 10px;
`;

const ModalSnapshotTitle = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const BodyColumns = styled.div`
  display: flex;
  height: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;
  height: 100%;
  white-space: initial;
`;

const CheckboxSectionRow = styled.div`
  justify-content: flex-start;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
`;

const PillContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  padding-right: 36px;
  padding-left: 22px;
  height: 100%;
  min-width: 198px;
  border-left: 1px solid var(--primaryBlack5);
`;

const Pill = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: ${(props) => (props.active ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'initial')};
  background: ${(props) => (props.active ? 'white' : 'var(--primaryBlack5)')};
  border: ${(props) => (props.active ? '1px solid var(--primaryBlack5)' : '1px solid var(--primaryBlack5)')};

  &:hover {
    background: white;
    box-shadow: 4px 4px 28px ${transparentize(0.95, cssVar('--primaryBlack'))};
    border: 1px solid var(--primaryBlack5);
  }
`;

const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 20px;
`;

const CheckIcon = styled(CircleCheck)`
  margin-left: 10px;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 36px;
  width: 100%;
`;

const MetricsScroller = styled.div`
  overflow: auto;
  max-height: 483px;
  padding-top: 10px;
`;

const MetricRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 18px;
  margin-bottom: 20px;
`;

const MetricRowBox = styled.div`
  width: 40px;
  height: 40px;
  padding: 9px 14px;
  border-radius: 10px;

  ${getMetricRowBoxColor}

  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
`;

const MetricRowTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
`;

const MetricSubTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: var(--primaryBlack10);
  border-radius: 50%;
  margin: 0 8px;
  display: inline-block;
`;

const MetricType = styled.div`
  font-size: 14px;
  line-height: 18px;
  opacity: 0.3;
`;

const MAP_DATA_TYPE = {
  currency: 'Money',
  percent: 'Percentage',
  number: 'Number',
};

const MetricRowDataContainer = styled.div`
  flex: 1;
  margin: 0 14px;
`;

const FooterInfo = styled.div`
  font-size: 14px;
  line-height: 20px;

  b {
    margin-left: 6px;
  }
`;

const MetricLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.3);
  position: absolute;
  top: -8px;
  padding-right: 10px;
  left: 0;
  z-index: 5;
  background-color: white;
`;

const RowSection = styled.div`
  border-top: 1px solid var(--primaryBlack5);
  position: relative;
  padding-top: 27px;
  margin-bottom: 27px;
`;

const TopLine = styled.div`
  background-color: var(--primaryBlack5);
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const PillTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.3;
  margin-bottom: 12px;
`;

const MetricRowTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const generateSubRow = ({ metrics, isARR, growthType, hasCostAttribution, winbackMonths }) => {
  const subRows = metrics.map((metric) => ({
    ...metric,
    title: metric.label,
    tooltipText: metric.description({ growthType, isARR, hasCostAttribution, winbackMonths }),
  }));

  return subRows;
};

const MetricRow = ({ index, title, tooltipText, dataType, checked, setChecked }) => {
  return (
    <>
      <MetricRowContainer data-cy={`business-snapshot-settings-modal__metric-row__${title}`}>
        <MetricRowBox index={index}>
          <p>{title?.substring(0, 1)}</p>
        </MetricRowBox>
        <MetricRowDataContainer>
          <MetricRowTitleContainer>
            <MetricRowTitle>{title}</MetricRowTitle>
            {MAP_DATA_TYPE[dataType] && (
              <>
                <Dot />
                <MetricType>{MAP_DATA_TYPE[dataType]}</MetricType>
              </>
            )}
          </MetricRowTitleContainer>
          {/* TODO: Change to subTitle on Dashboard/utils.js reshapeSnapshotDataForTable function?*/}
          <MetricSubTitle>{tooltipText}</MetricSubTitle>
        </MetricRowDataContainer>
        <Checkbox checked={checked} onClick={setChecked} data-cy="business-snapshot-settings-modal__checkbox" />
      </MetricRowContainer>
    </>
  );
};

export const BusinessSnapshotSettingsModal = ({
  name = 'Metrics',
  selectedMetrics = [],
  selectedColumns = {
    months: true,
    quarters: true,
    years: true,
    cumulative: true,
  },
  fixedHeader = false,
  setSelected,
  setFixedHeader,
  toggleModal,
}) => {
  const { trackEvent } = useAnalytics();

  const { organizations, isARR } = useContext(AppContext);
  const { growthType } = useContext(DashboardContext);
  const winbackMonths = getAppSetting('winbackMonths');
  const hasCostAttribution = organizations[0]?.hasCostAttribution;

  const rows = useMemo(
    () => [
      {
        title: SNAPSHOT_METRICS.REVENUE.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.REVENUE_ROW_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.SAAS.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.SAAS_ROW_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.UNIT_ECONOMICS.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.UNIT_ECONOMICS_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.EFFICIENCY.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.EFFICIENCY_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
    ],
    [growthType, hasCostAttribution, isARR, winbackMonths],
  );

  const [localName, setLocalName] = useState(name);
  const [localSelectedColumns, setLocalSelectedColumns] = useState(selectedColumns);
  const [localSelectedMetrics, setLocalSelectedMetrics] = useState(new Set(selectedMetrics));

  const isColumnChecked = (key) => localSelectedColumns[key];
  const toggleColumnChecked = (key) => {
    const columns = { ...localSelectedColumns };
    columns[key] = !columns[key];
    setLocalSelectedColumns(columns);
  };

  const [localFixedHeader, setLocalFixedHeader] = useState(fixedHeader);
  const isFixedHeaderChecked = () => localFixedHeader;
  const toggleFixedHeaderChecked = () => {
    setFixedHeader(!localFixedHeader);
    setLocalFixedHeader(!localFixedHeader);
  };

  const modalRef = useClickOutside(toggleModal);

  const metricIsSelected = (title) => localSelectedMetrics.has(title);
  const selectMetric = (title) => {
    const metrics = new Set(localSelectedMetrics);
    if (metrics.has(title)) {
      metrics.delete(title);
    } else {
      metrics.add(title);
    }

    setLocalSelectedMetrics(metrics);
  };

  const [activeTab, setActiveTab] = useState('All');

  const saveAndCloseModal = () => {
    // track metrics that are added to the dashboard
    const newMetrics = Array.from(localSelectedMetrics);
    const oldMetricsSet = new Set(selectedMetrics);
    const difference = newMetrics.filter((metric) => !oldMetricsSet.has(metric));
    for (const metric of difference) {
      trackEvent({
        name: EVENTS.METRIC_ADDED_TO_DASHBOARD,
        properties: {
          metric,
        },
      });
    }

    setSelected({
      name: localName,
      selectedMetrics: newMetrics,
      selectedColumns: localSelectedColumns,
    });
  };

  const getFilteredRows = ({ rows }) => {
    if (rows) {
      return rows
        .map((row) => ({
          ...row,
          subRows: row.subRows.filter(
            (subRow) => activeTab === 'All' || METRIC_GROUPS_BY_TITLE[activeTab].includes(subRow.title),
          ),
        }))
        .filter((row) => !!row.subRows.length);
    }
  };

  const metricsGroupsToShow = cloneDeep(METRIC_FILTER_GROUPS);

  const CheckboxLabelWithTooltip = ({ children, toolTipContent }) => (
    <TooltipContainer width={208} toolTipContent={toolTipContent}>
      <Row>
        {children}
        <InfoIcon style={{ marginLeft: 4, verticalAlign: 'text-top' }} width={14} height={14} fill="#00152E" />
      </Row>
    </TooltipContainer>
  );

  return (
    <ModalContainer data-cy="business-snapshot-settings-modal">
      <Modal ref={modalRef} padding="0px" height="597px" width="955px">
        <ModalCloseIcon style={{ width: 36, height: 36 }} onClose={toggleModal} />

        <BodyWrapper>
          <CheckboxesColumn>
            <ToolsIcon />
            <ModalSnapshotTitle>
              <b>Data Table</b>
            </ModalSnapshotTitle>

            <CheckboxSectionTitle style={{ paddingTop: 4 }}>Name</CheckboxSectionTitle>
            <StyledInput
              value={localName}
              onChange={(e) => {
                setLocalName(e.target.value);
              }}
              data-cy="business-snapshot-settings-modal__name-input"
            />
            <Spacer height="24px" />

            <CheckboxSectionTitle style={{ paddingTop: 4 }}>Columns</CheckboxSectionTitle>
            <CheckboxSectionRow>
              <CheckboxContainer>
                <Checkbox
                  checked={isColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.MONTHS)}
                  onClick={() => toggleColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.MONTHS)}
                  data-cy="business-snapshot-settings-modal__checkbox--months"
                />
                <CheckboxLabel>Months</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  checked={isColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.QUARTERS)}
                  onClick={() => toggleColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.QUARTERS)}
                  data-cy="business-snapshot-settings-modal__checkbox--quarters"
                />
                <CheckboxLabel>Quarters</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  checked={isColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.YEARS)}
                  onClick={() => toggleColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.YEARS)}
                  data-cy="business-snapshot-settings-modal__checkbox--years"
                />
                <CheckboxLabel>Years</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  checked={isColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.CUMULATIVE)}
                  onClick={() => toggleColumnChecked(BUSINESS_SNAPSHOT_COLUMNS.CUMULATIVE)}
                  data-cy="business-snapshot-settings-modal__checkbox--cumulative"
                />
                <CheckboxLabel>
                  <CheckboxLabelWithTooltip
                    toolTipContent={'This column will display the cumulative for each metric for your specified period'}
                  >
                    Cumulative
                  </CheckboxLabelWithTooltip>
                </CheckboxLabel>
              </CheckboxContainer>
            </CheckboxSectionRow>

            <CheckboxSectionTitle style={{ paddingTop: 4 }}>Table Configuration</CheckboxSectionTitle>
            <CheckboxSectionRow>
              <CheckboxContainer>
                <Checkbox
                  checked={isFixedHeaderChecked()}
                  onClick={() => toggleFixedHeaderChecked()}
                  data-cy="business-snapshot-settings-modal__checkbox--fixed-header"
                />
                <CheckboxLabel>
                  <CheckboxLabelWithTooltip
                    toolTipContent={"This will keep the current table's header fixed when scrolling"}
                  >
                    Fixed Header
                  </CheckboxLabelWithTooltip>
                </CheckboxLabel>
              </CheckboxContainer>
            </CheckboxSectionRow>
          </CheckboxesColumn>
          <div style={{ width: '100%' }}>
            <ModalBody height="calc(100% - 65px)" style={{ overflow: 'hidden' }} paddingLeft={0} paddingRight={0}>
              <BodyColumns>
                <MetricsContainer>
                  <MetricsScroller>
                    {getFilteredRows({ rows }).map((row) => (
                      <Fragment key={row?.title}>
                        <RowSection>
                          <TopLine />
                          <MetricLabel>
                            {row?.title} (
                            {row?.subRows.filter((subRow) => localSelectedMetrics.has(subRow.title)).length} /{' '}
                            {row?.subRows?.length})
                          </MetricLabel>
                          {row?.subRows?.map((subRow, i) => {
                            const checked = metricIsSelected(subRow.title);
                            const setChecked = () => selectMetric(subRow.title);

                            return (
                              <MetricRow
                                key={subRow.title}
                                index={i}
                                checked={checked}
                                setChecked={setChecked}
                                {...subRow}
                              />
                            );
                          })}
                        </RowSection>
                      </Fragment>
                    ))}
                  </MetricsScroller>
                </MetricsContainer>

                <PillContainer>
                  <PillTitle>Filter metrics</PillTitle>
                  <Pill active={activeTab === 'All'} onClick={() => setActiveTab('All')}>
                    All metrics
                    {activeTab === 'All' && <PillIcon />}
                  </Pill>
                  {Object.values(metricsGroupsToShow).map((group) => (
                    <Pill key={group} active={activeTab === group} onClick={() => setActiveTab(group)}>
                      {group}
                      {activeTab === group && <PillIcon />}
                    </Pill>
                  ))}
                </PillContainer>
              </BodyColumns>
            </ModalBody>
            <ModalFooter padding="12px 36px" height={65} style={{ borderBottomLeftRadius: 0 }}>
              <Row horizontal="space-between">
                <FooterInfo>
                  Active Metrics:
                  <b>{localSelectedMetrics.size}</b>
                </FooterInfo>
                <FooterButtons>
                  <CancelButton onClick={toggleModal} data-cy="business-snapshot-settings-modal__cancel-button">
                    Cancel
                  </CancelButton>
                  <GreenButton
                    onClick={saveAndCloseModal}
                    buttonLabel={
                      <Row>
                        Save
                        <CheckIcon display="inline-block" width={24} height={24} />
                      </Row>
                    }
                    data-cy="business-snapshot-settings-modal__save-button"
                  ></GreenButton>
                </FooterButtons>
              </Row>
            </ModalFooter>
          </div>
        </BodyWrapper>
      </Modal>
    </ModalContainer>
  );
};
