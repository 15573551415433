import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';

import { CustomDatePicker } from 'components/Controls';
import { TextBox } from 'components/Core';
import { formatDateForDatepicker } from 'utils/dateUtils';
import { InlineDate } from './styles';

const CustomDateInput = ({ name }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const date = getFieldMeta(name)?.value;

  return (
    <CustomDatePicker
      name={name}
      selected={date ? formatDateForDatepicker(date) : null}
      onChange={(date) => setFieldValue(name, date)}
      renderCustomInput={({ inputRef }) => (
        <InlineDate ref={inputRef}>
          <TextBox>{date ? formatDateWithLocale(date) : ''}</TextBox>
        </InlineDate>
      )}
    />
  );
};

export default CustomDateInput;
