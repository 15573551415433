import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { ReactComponent as DividerLine } from 'images/explain-modal-divider.svg';
import { ReactComponent as ClockIcon } from 'images/bxs-time.svg';
import { SNAPSHOT_METRIC_BY_LABEL } from 'consts/snapshotMetrics';
import { InlineButton } from 'components/Buttons';
import { getAppSetting } from 'models/appSettings';
import { CentererVertical, Spacer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { numberFormatter } from 'utils/formatters';
import { useClickOutside } from 'utils/hooks';
import {
  DividerText,
  ExplainModalFooter,
  ExplainModalTitle,
  ExplainSubtitle,
  HeaderIconWrapper,
  MetricRow,
  ModalDivider,
  StyledCalculatorIcon,
} from './ExplainModal.styles';
import {
  METRIC_DATA_TYPE_BY_KEY,
  METRIC_ROWS,
  METRIC_ROW_TO_EXPENSE_TYPE,
  METRIC_SHOULD_CONVERT_TO_ARR_BY_KEY,
  METRIC_VALUE_SHOULD_CONVERT_TO_ARR_BY_TITLE,
  HEADER_ICONS_BY_TITLE,
} from './consts';
import { ExplainModalFormula } from './ExplainModalFormula';
import { EditExpenseModal } from './EditExpenseModal';
import { getExplainModalMetricTitleByKey } from './utils';
import { EditNetProfitModal } from './EditNetProfitModal';

export const ExplainModal = ({ value, closeModal }) => {
  const {
    title,
    date,
    metricValue,
    dataType,
    isQuarterMetric,
    isYearMetric,
    isCumulativeMetric,
    isTTMMetric,
    tooltip,
  } = value;

  const {
    dataFilter,
    orgId,
    organizations,
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);
  const { growthType } = useContext(DashboardContext);
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [showEditNetProfitModal, setShowEditNetProfitModal] = useState(false);
  const modalRef = useClickOutside(() => !showEditExpenseModal && !showEditNetProfitModal && closeModal());

  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');

  const convertedMetricValueObject = isARR
    ? Object.entries(metricValue).reduce((acc, [metricTitle, metricValue]) => {
        acc[metricTitle] = METRIC_SHOULD_CONVERT_TO_ARR_BY_KEY[metricTitle] ? metricValue * 12 : metricValue;
        return acc;
      }, {})
    : metricValue;

  const convertedMetricValue =
    isARR && METRIC_VALUE_SHOULD_CONVERT_TO_ARR_BY_TITLE[title] ? metricValue.value * 12 : metricValue.value;

  const allowsManualExpenses = organizations?.[0]?.allowsManualExpenses ?? [];

  const isExpensesEditable = (row) =>
    !isQuarterMetric &&
    !isYearMetric &&
    !isCumulativeMetric &&
    allowsManualExpenses.includes(METRIC_ROW_TO_EXPENSE_TYPE[row]);

  const isNetProfitEditable = (row) => !isQuarterMetric && !isYearMetric && !isCumulativeMetric && row === 'netProfit';

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="540px" height="auto" ref={modalRef} data-cy="explain-modal">
          <ModalHeader style={{ flexDirection: 'column', padding: '0 50px' }}>
            <ModalCloseIcon onClose={closeModal} data-cy="explain-modal__close-button" />
            <HeaderIconWrapper>{HEADER_ICONS_BY_TITLE[title]}</HeaderIconWrapper>
            <ExplainModalTitle>
              <b>{title}</b>
            </ExplainModalTitle>

            <ExplainSubtitle>
              {SNAPSHOT_METRIC_BY_LABEL[title].description({
                growthType,
                isARR,
                hasCostAttribution: organizations[0]?.hasCostAttribution,
              })}
            </ExplainSubtitle>
          </ModalHeader>
          <ModalDivider>
            <DividerText>
              <ClockIcon />
              Calculations for {date}
            </DividerText>
            <DividerLine className="divider" />
          </ModalDivider>
          <ModalBody>
            {convertedMetricValueObject &&
              METRIC_ROWS.map((row) =>
                convertedMetricValueObject[row] !== undefined || row === 'value' ? (
                  <MetricRow isExplainMetric={row === 'value'} key={row}>
                    {row === 'value'
                      ? title
                      : getExplainModalMetricTitleByKey({
                          row,
                          date,
                          isQuarterMetric,
                          isYearMetric,
                          isCumulativeMetric,
                          isTTMMetric,
                          quarters,
                          quartersYearOffset,
                          usesMetricsEngine: organizations[0]?.usesMetricsEngine,

                          //Used for Cumulative column
                          dataFilterStartMonth: dataFilter.startMonth,
                          dataFilterEndMonth: dataFilter.endMonth,
                        })}
                    <CentererVertical>
                      <span data-cy={`explain-modal__metric-value--${row}`}>
                        {row === 'value'
                          ? numberFormatter({
                              type: dataType,
                              rawValue: convertedMetricValueObject[row],
                              decimalPlaces: 2,
                              currency: currencyISOCode,
                            })
                          : numberFormatter({
                              type: METRIC_DATA_TYPE_BY_KEY[row],
                              rawValue: convertedMetricValueObject[row],
                              decimalPlaces: 2,
                              currency: currencyISOCode,
                            })}
                      </span>
                      {isExpensesEditable(row) && (
                        <>
                          <Spacer width="8px" />
                          <InlineButton
                            isSecondary
                            withBackground
                            fontSize="11px"
                            onClick={() => setShowEditExpenseModal(true)}
                            data-cy={`explain-modal__edit-expense-button--${row}`}
                          >
                            {convertedMetricValueObject[row] ? 'Change' : 'Add Cost Data'}
                          </InlineButton>
                        </>
                      )}
                      {isNetProfitEditable(row) && (
                        <>
                          <Spacer width="8px" />
                          <InlineButton
                            isSecondary
                            withBackground
                            fontSize="11px"
                            onClick={() => setShowEditNetProfitModal(true)}
                            data-cy={`explain-modal__edit-net-profit-button--${row}`}
                          >
                            {convertedMetricValueObject[row] ? 'Change' : 'Add Net Profit'}
                          </InlineButton>
                        </>
                      )}
                    </CentererVertical>
                  </MetricRow>
                ) : null,
              )}
          </ModalBody>
          <ExplainModalFooter>
            <StyledCalculatorIcon />
            {convertedMetricValueObject && (
              <ExplainModalFormula
                value={value}
                convertedMetricValue={convertedMetricValue}
                convertedMetricValueObject={convertedMetricValueObject}
                tooltip={tooltip}
              />
            )}
          </ExplainModalFooter>
        </Modal>
      </ModalContainer>
      {showEditExpenseModal && (
        <EditExpenseModal
          orgId={orgId}
          initialMonth={date}
          allowsManualExpenses={allowsManualExpenses}
          onClose={({ hasDataChanged }) => {
            setShowEditExpenseModal(false);
            if (hasDataChanged) {
              closeModal();
            }
          }}
        />
      )}
      {showEditNetProfitModal && (
        <EditNetProfitModal
          orgId={orgId}
          initialMonth={date}
          onClose={({ hasDataChanged }) => {
            setShowEditNetProfitModal(false);
            if (hasDataChanged) {
              closeModal();
            }
          }}
        />
      )}
    </>
  );
};
