import dayjs from 'dayjs';
import { RECOGNITION_TYPES, RECURRING_RECOGNITION_TYPES } from 'consts/global';
import { searchCustomers } from 'api';
import { calculateRecurringAmount, MAPPING_FUNCTIONS } from 'models/transaction';
import { identity, isEqual } from 'lodash';

// TODO: Move this shared function elsewhere, maybe we just do conversion at the API layer?
export const getCustomersFromSearch = async ({
  searchQuery,
  orgId,
  parentCustomerId,
  additional,
  withoutProspects = false,
  scopes = [],
  paginated = true,
  customFilter,
}) => {
  const currentPage = additional?.currentPage ?? 0;
  const searchResult = await searchCustomers({
    orgId,
    parentCustomerId,
    searchQuery,
    withoutProspects,
    scopes,
    page: currentPage + 1,
  });
  let customers = searchResult.data.map((customer) => ({
    label: `${customer.name || ''} / ${customer.id || ''}`,
    id: customer.id,
    value: customer.id,
    name: customer.name,
    parentName: customer.parent_customer_name,
    metadata: customer.metadata,
    ...(scopes.includes('invoicing_details') ? { invoicingDetails: customer.invoicing_details } : {}),
    ...(scopes.includes('imports') ? { imports: customer.imports } : {}),
  }));

  if (customFilter) {
    customers = customers.filter(customFilter);
  }

  return paginated
    ? {
        options: customers,
        hasMore: !!searchResult.metadata?.hasMore,
        additional: searchResult.metadata,
      }
    : customers;
};

// We need a symbol here, since we can't return "null" or "false" or any other value, since those might
// be the ACTUAL old value
export const SYMBOL_getOriginalField = Symbol();
// Returns original field if the field accessed by the key is different. Otherwise, returns symbol
export const getOriginalField = ({ key, data }) => {
  const mapFunc = MAPPING_FUNCTIONS[key] ?? identity;
  const mappedOriginalField = mapFunc(data[key]?.original);
  const mappedUpdatedField = mapFunc(data[key]?.updated);
  let compareFunc = isEqual;
  if (data[key]?.updated !== undefined && !compareFunc(mappedOriginalField, mappedUpdatedField))
    return data[key]?.original;

  return SYMBOL_getOriginalField;
};

export const getTransactionMRR = (transaction) => {
  if (RECURRING_RECOGNITION_TYPES.includes(transaction.recognition)) {
    return (
      transaction.latest_spread_amount ??
      transaction.recurring_amount ??
      (transaction.recognition === RECOGNITION_TYPES.linear &&
        calculateRecurringAmount({
          startDate: transaction.start_date,
          endDate: transaction.end_date,
          totalAmount: transaction.amount,
          includeEndMonth: transaction.include_end_month,
        })) ??
      0
    );
  }

  return 0;
};

//TODO [TC 2022-05-02]: If we need more complex warnings, we can recreate
// the code structure of the backend analyzer on the frontend.
export const getWarnings = ({ data }) => {
  if (!data.amount) return [];
  if (!data.start_date) return [];
  if (!dayjs(data.start_date).isBefore(dayjs().startOf('month'))) return [];

  const message = data.confirmed
    ? 'You are modifying a confirmed transaction with revenue events in the past, which will affect historical data'
    : 'This transaction will create revenue events in the past';

  return [message];
};

export const transformRecognitionEventsToSpreads = (recognitionEvents) =>
  recognitionEvents
    .filter((event) => event.date)
    .map((event) => ({
      spread_id: event.spread_id,
      date: event.date,
      amount: event.amount,
      seats: event.seats,
      external_id: event.external_id,
    }));

export const accountingSpreadsChanged = ({ originalAccountingSpreads, currentAccountingSpreads }) => {
  originalAccountingSpreads = originalAccountingSpreads ?? [];
  currentAccountingSpreads = currentAccountingSpreads ?? [];

  if (originalAccountingSpreads.length !== currentAccountingSpreads.length) return true;

  for (const currentAccountingSpread of currentAccountingSpreads) {
    const { id, date, amount } = currentAccountingSpread;
    // find respective original accounting_spread
    const originalAccountingSpread = originalAccountingSpreads.find(
      (s) =>
        (!id || s.id === id) &&
        new Date(s.date).getTime() === new Date(date).getTime() &&
        parseFloat(s.amount).toFixed(2) === parseFloat(amount).toFixed(2),
    );
    if (!originalAccountingSpread) return true;
  }

  // all the accounting_spreads match up
  return false;
};

export const checkOnlyEndDateChanged = ({ transactionFormValues, currentTransaction }) => {
  if (!transactionFormValues || !currentTransaction) return false;

  const formKeys = Object.keys(transactionFormValues);
  const currentKeys = Object.keys(currentTransaction);

  if (formKeys.length !== currentKeys.length) return false;

  for (const key of formKeys) {
    if (key === 'end_date' || key === 'amount' || key === 'include_end_month') continue; // for a till canceled, when an end date is set, the amount might change too
    if (!isEqual(transactionFormValues[key], currentTransaction[key])) {
      return false;
    }
  }

  if (!transactionFormValues.end_date && !currentTransaction.end_date) return false;
  return (
    transactionFormValues.end_date !== currentTransaction.end_date &&
    transactionFormValues.recurring_amount === currentTransaction.recurring_amount
  );
};
