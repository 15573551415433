import { useContext, useState } from 'react';
import { useStateWithStorage, usePageContext, PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { RESOLUTION_OPTIONS } from 'shared/Filters';
import { InsightsProvider } from 'shared/Insights/InsightsProvider';
import { CohortsContext } from './CohortsContext';
import { CohortsPage } from './CohortsPage';
import { COHORT_PALLETS } from './consts';
import { AppContext } from 'AppContext';

export const CohortsContainer = () => {
  const { orgConfigs } = useContext(AppContext);
  const appIsARR = orgConfigs.custom_settings.isARR.value;
  const [cohortsIsARR, setCohortsIsARR] = useStateWithStorage('cohortsIsARR', appIsARR);

  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.cohort.key,
    replace: {
      isARR: [cohortsIsARR, setCohortsIsARR],
    },
  });

  // We might want to add totalAmounts, absoluteColouring, and cohortPalette to URL
  const [totalAmounts, setTotalAmounts] = useStateWithStorage('cohorts-page-total-amounts', false); // show total amounts in table
  const [absoluteColoring, setAbsoluteColouring] = useStateWithStorage('cohorts-page-absolute-coloring', false);
  const [cohortPalette, setCohortPalette] = useStateWithStorage('cohorts-page-palette', COHORT_PALLETS.COLORFUL);
  const [cohortsData, setCohortsData] = useState({});
  const [cohortResolution, setCohortResolution] = useState(RESOLUTION_OPTIONS.monthly);

  const [showSpreadsModal, setShowSpreadsModal] = useState(false);
  const [cohortStartDate, setCohortStartDate] = useState(null); // date used for spreadsModal
  const [cohortEndDate, setCohortEndDate] = useState(null); // date used for spreadsModal
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [cohortCustomerIds, setCohortCustomerIds] = useState(null); // used for spreadsModal
  const [segmentDescriptor, setSegmentDescriptor] = useState(null); // used for spreadsModal

  return (
    <CohortsContext.Provider
      value={{
        ...urlState,

        totalAmounts,
        setTotalAmounts,
        absoluteColoring,
        setAbsoluteColouring,
        cohortsData,
        setCohortsData,
        cohortPalette,
        setCohortPalette,
        cohortResolution,
        setCohortResolution,

        showSpreadsModal,
        setShowSpreadsModal,
        cohortStartDate,
        setCohortStartDate,
        cohortEndDate,
        setCohortEndDate,
        selectedMonth,
        setSelectedMonth,
        cohortCustomerIds,
        setCohortCustomerIds,
        segmentDescriptor,
        setSegmentDescriptor,
      }}
    >
      <InsightsProvider>
        <CohortsPage />
      </InsightsProvider>
    </CohortsContext.Provider>
  );
};
