import React from 'react';
import { useFormikContext } from 'formik';
import ComponentAcessor from './ComponentAcessor';
import { columns } from './consts';
import { Table, TableBody, TableCell, TableRow } from './styles';
import { ChildCustomersSelector } from '../SubscriptionForm/ChildCustomersSelector';
import { FullWidthFlexBasis } from '../styles';

const SubscriptionRow = ({ originalSubscription, subscriptionIndex }) => {
  const { getFieldMeta } = useFormikContext();
  const isAggregated = getFieldMeta(`subscriptions.${subscriptionIndex}.aggregate_usage_on_parent`)?.value;
  const values = getFieldMeta(`subscriptions.${subscriptionIndex}`)?.value;
  const firstFiveColumnsWidth = columns.slice(0, 5).reduce((acc, column) => acc + column.width, 0);

  return (
    <>
      <TableRow>
        {columns.map((column, index) => {
          const isLastColumn = index === columns.length - 1;
          const isEditMode = index >= 5;
          const isExistingInfo = index >= 1 && index <= 4;
          const key = `${column.accessor}-${index}-${subscriptionIndex}`;

          return (
            <TableCell
              key={key}
              customWidth={column.width}
              isWhite={isEditMode}
              isCustomRadius={isLastColumn}
              isFullBorders={!isLastColumn}
              extraPadding={isExistingInfo}
              noPadding={isEditMode}
            >
              <ComponentAcessor column={column} data={originalSubscription} subscriptionIndex={subscriptionIndex} />
            </TableCell>
          );
        })}
      </TableRow>
      {isAggregated && (
        <TableRow>
          <TableCell customWidth={firstFiveColumnsWidth} />
          <TableCell isWhite>
            <FullWidthFlexBasis>
              <ChildCustomersSelector
                hideLabel
                customValues={values}
                customKeyBase={`subscriptions.${subscriptionIndex}.`}
              />
            </FullWidthFlexBasis>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const SubscriptionsTable = ({ originalSubscriptions }) => {
  return (
    <Table>
      <TableBody isFullBorder>
        {originalSubscriptions.map((originalSubscription, index) => (
          <SubscriptionRow
            key={originalSubscription.id}
            originalSubscription={originalSubscription}
            subscriptionIndex={index}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SubscriptionsTable;
