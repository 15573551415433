import { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { transactionDisplayName } from 'models/transaction';
import { useTransactionsAPI } from 'api/transactions';
import { DeleteConfirmationModal } from 'shared/DeleteConfirmationModal';
import { TransactionContext } from './TransactionContext';

export const TransactionDeleteModal = ({ organizationId, transaction, onClose, onDeleted }) => {
  const {
    operations: { removeTransaction },
  } = useTransactionsAPI({ orgId: organizationId, autoFetch: false });

  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);

  const context = useContext(TransactionContext);
  const { setShowInvoiceResyncNotification } = context ?? {};

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeTransaction.mutateAsync({ id: transaction.id });
      onDeleted?.({ deletedTransaction: transaction });
      if (transaction?.invoicing_schedule_id) {
        setShowInvoiceResyncNotification(true);
      } else {
        onClose?.();
      }
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionDeleteModal.js', stack: err });
    }
    setLoading(false);
  };

  return (
    <DeleteConfirmationModal
      modalName="transaction-delete-modal"
      resourceType="transaction"
      resourceName={transactionDisplayName({ transaction, currency: currencyISOCode })}
      isLoading={loading}
      onClose={onClose}
      onDeleteConfirm={handleDelete}
    />
  );
};
