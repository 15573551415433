export const CARD_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  BIG: 'big',
  HUGE: 'huge',
  FULL_ROW: 'full-row',
};

export const CARD_SIZES_COLUMN_SIZES = {
  [CARD_SIZES.HUGE]: 12,
  [CARD_SIZES.BIG]: 12,
  [CARD_SIZES.LARGE]: 6,
  [CARD_SIZES.MEDIUM]: 4,
  [CARD_SIZES.SMALL]: 2,
  [CARD_SIZES.FULL_ROW]: 12,
};

export const CARD_SIZES_ROW_SIZES = {
  [CARD_SIZES.HUGE]: 3,
  [CARD_SIZES.BIG]: 2,
  [CARD_SIZES.LARGE]: 2,
  [CARD_SIZES.MEDIUM]: 2,
  [CARD_SIZES.SMALL]: 2,
  [CARD_SIZES.FULL_ROW]: 1,
};
