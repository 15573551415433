import styled, { css } from 'styled-components';
import { Centerer } from 'components/Core';

import { AddNewButton } from './AddNewButton';
import { BackButton } from './BackButton';
import { BinButton } from './BinButton';
import { Button } from './Button';
import { ButtonText } from './common';
import { CircleButton } from './CircleButton';
import { DropdownButton, DropdownButtonItem } from './DropdownButton';
import { ExportButton } from './ExportButton';
import { GreenButton } from './GreenButton';
import { IconButton } from './IconButton';
import { IconGreenButton } from './IconGreenButton';
import { InlineButton } from './InlineButton';
import { LinkBackButton } from './LinkBackButton';
import { PaginationButton } from './PaginationButton';
import { TabButton } from './TabButton';
import { TableActionButton } from './TableActionButton';
import { WhiteButton } from './WhiteButton';
import { SelectDropdownButton } from './SelectDropdownButton';
import { RemoveButton } from './RemoveButton';
import { OutlineGreenButton } from './OutlineGreenButton';
import { DuplicateButton } from './DuplicateButton';
import { ConfirmButton } from './ConfirmButton';

const CancelButton = styled.div`
  padding: 10px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 20px;
  background: var(--primaryBlack3);
  border: 1px solid var(--neutralGray);
  border-radius: 100px;
  margin-right: 8px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  padding: 8px;
  padding-left: 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.isRemove ? '#FF5D5D' : '#52b578')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    `}
`;

const TooltipButton = styled(Centerer)`
  width: 100%;
  height: 28px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  cursor: pointer;

  margin-top: 8px;
  background: var(--dark15);
  border-radius: 4px;
`;

export const SplitButton = styled(Centerer)`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  margin-left: 8px;
  padding: 0px;
  color: white;
`;

export const SplitButtonPart = styled(Centerer)`
  padding: ${({ hasTooltip }) => (hasTooltip ? 0 : '8px 14px')};
  background-color: var(--primaryGreen);
  position: relative;
  min-width: fit-content;

  &:hover {
    background-color: var(--secondaryGreen);
    cursor: pointer;
  }

  &:first-child {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }

  &:last-child {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }

  svg {
    path: {
      fill: var(--primaryBlack20);
    }
  }

  svg {
    path {
      fill: ${({ disabled }) => disabled && 'var(--primaryBlack20)'};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: var(--primaryBlack5);
      color: var(--primaryBlack20);
      &:hover {
        cursor: auto;
        background-color: var(--primaryBlack5);
      }
    `}
`;

export const SplitButtonSeparator = styled.div`
  height: 100%;
  width: 1px;
  background-color: var(--primaryBlack10);
  position: absolute;
  right: 0;
  top: 0;
`;

export {
  AddNewButton,
  BackButton,
  BinButton,
  RemoveButton,
  Button,
  ButtonText,
  CancelButton,
  CircleButton,
  DropdownButton,
  DropdownButtonItem,
  ExportButton,
  GreenButton,
  IconButton,
  IconGreenButton,
  InlineButton,
  LinkBackButton,
  PaginationButton,
  SubmitButton,
  TabButton,
  TableActionButton,
  WhiteButton,
  SelectDropdownButton,
  OutlineGreenButton,
  TooltipButton,
  DuplicateButton,
  ConfirmButton,
};

export { SaveButton } from './SaveButton';
