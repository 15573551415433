import { RECOGNITION_TYPES } from 'consts/global';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME } from 'consts/integrations';

export const INVOICE_STATUSES = {
  UNSENT: 'unsent',
  SENT: 'sent',
  ALL_FAILED: 'all-failed', //only frontend status
  SAVE_FAILED: 'save-failed',
  SENT_FAILED: 'sent-failed',
  AUTO_CHARGE: 'auto-charge',
  PAYMENT_FAILED: 'payment-failed',
  PAID: 'paid',
  PARTIALLY_PAID: 'part. paid', // only frontend status
  REMIND: 'remind', //only frontend status
  VOID: 'void',
  ALL: 'all',
  OVERDUE: 'overdue', //only frontend status
  UNPAID: 'unpaid', //only frontend status
  ALL_PROCESSING: 'all-processing', //only frontend status
  QUEUED_UP: 'queued-up',
  PROCESSING: 'processing',
  TAXES_PROCESSING: 'taxes-processing',
  TRANSACTIONS_THAT_NEED_SCHEDULES: 'transactions-that-need-schedules', //only frontend count status
  INVOICING_SCHEDULES_EXTERNAL_UPDATES: 'invoicing-schedules-external-updates', //only frontend count status
};

export const INVOICE_FAILED_STATUS = [
  INVOICE_STATUSES.SAVE_FAILED,
  INVOICE_STATUSES.SENT_FAILED,
  INVOICE_STATUSES.PAYMENT_FAILED,
];

export const REMINDERS_STATUSES = {
  UNSENT: 'to send',
  SENT: 'sent',
};

export const TITLE_BY_STATUS = {
  [INVOICE_STATUSES.REMIND]: 'Reminder to send',
  [INVOICE_STATUSES.UNSENT]: 'Invoice to send',
  [INVOICE_STATUSES.SENT]: 'Awaiting payment',
  [INVOICE_STATUSES.SAVE_FAILED]: 'Failed to save',
  [INVOICE_STATUSES.SENT_FAILED]: 'Failed to send',
  [INVOICE_STATUSES.PAYMENT_FAILED]: 'Failed payment',
  [INVOICE_STATUSES.UNPAID]: 'Unpaid',
  [INVOICE_STATUSES.PAID]: 'Paid',
  [INVOICE_STATUSES.VOID]: 'Voided',
  [INVOICE_STATUSES.AUTO_CHARGE]: 'Will be auto-charged',
  [INVOICE_STATUSES.QUEUED_UP]: 'Queued up',
  [INVOICE_STATUSES.PROCESSING]: 'In processing',
  [INVOICE_STATUSES.TAXES_PROCESSING]: 'In processing',
  [INVOICE_STATUSES.PARTIALLY_PAID]: 'Partially paid',
};

export const INVOICING_FREQUENCIES = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
  SEMI_ANNUALLY: 'Semi-Annually',
  UPFRONT: 'Upfront',
  EVENT_BASED: 'Based on new events',
  CUSTOM_SCHEDULE_METADATA: 'Custom Schedule (Metadata)',
};

export const AUTO_CHARGE_DATE_COMPARISON = {
  EQUAL: 'equal',
  BEFORE: 'before',
  AFTER: 'after',
};

export const AUTO_CHARGE_DATE_COMPARISON_TO_LABEL = {
  [AUTO_CHARGE_DATE_COMPARISON.EQUAL]: 'Equal to',
  [AUTO_CHARGE_DATE_COMPARISON.BEFORE]: 'Before',
  [AUTO_CHARGE_DATE_COMPARISON.AFTER]: 'After',
};

export const AUTO_CHARGE_DATE_REFERENCE = {
  INVOICE_DATE: 'date',
  DUE_DATE: 'due_date',
};

export const AUTO_CHARGE_DATE_REFERENCE_TO_LABEL = {
  [AUTO_CHARGE_DATE_REFERENCE.INVOICE_DATE]: 'Invoice date',
  [AUTO_CHARGE_DATE_REFERENCE.DUE_DATE]: 'Due date',
};

export const INVOICING_FREQUENCY_TO_MONTH_LENGTH = {
  [INVOICING_FREQUENCIES.MONTHLY]: 1,
  [INVOICING_FREQUENCIES.QUARTERLY]: 3,
  [INVOICING_FREQUENCIES.ANNUALLY]: 12,
  [INVOICING_FREQUENCIES.SEMI_ANNUALLY]: 6,
};

export const PAYMENT_OPTIONS = {
  ACH: 'ACH',
  CREDIT_CARD: 'Credit-Card',
  BACS_DEBIT: 'BACS Debit (UK)',
  SEPA_DEBIT: 'SEPA Debit (EU)',
  AU_BECS_DEBIT: 'BECS Debit (AU)',
  PAYPAL: 'PayPal',
  GIROPAY: 'Giropay (DE)',
  ACSS_DEBIT: 'Pre-authorized Debit (CA)',
  LINK: 'Link',
};

export const PAYMENT_OPTIONS_LABELS = {
  [PAYMENT_OPTIONS.ACH]: 'ACH',
  [PAYMENT_OPTIONS.CREDIT_CARD]: 'Credit Card',
  [PAYMENT_OPTIONS.BACS_DEBIT]: 'BACS Debit (UK)',
  [PAYMENT_OPTIONS.SEPA_DEBIT]: 'SEPA Debit (EU)',
  [PAYMENT_OPTIONS.AU_BECS_DEBIT]: 'BECS Debit (AU)',
  [PAYMENT_OPTIONS.PAYPAL]: 'PayPal',
  [PAYMENT_OPTIONS.GIROPAY]: 'Giropay (DE)',
  [PAYMENT_OPTIONS.ACSS_DEBIT]: 'Pre-authorized Debit (CA)',
  [PAYMENT_OPTIONS.LINK]: 'Link',
};

export const SELECTOR_PAYMENT_OPTIONS = Object.entries(PAYMENT_OPTIONS_LABELS).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const SUPPORTED_RECOGNITIONS = [
  RECOGNITION_TYPES.linear,
  RECOGNITION_TYPES.linearNotRecurring,
  RECOGNITION_TYPES.immediate,
  RECOGNITION_TYPES.eventRecurring,
  RECOGNITION_TYPES.eventNotRecurring,
  RECOGNITION_TYPES.tillCanceled,
];

// TODO: When all organizations use Subscript to send invoice emails, we can remove all the following consts
// SERVICE_WITHOUT_EMAIL_MODIFICATIONS, SERVICE_WITHOUT_EMAIL_CC, SERVICE_WITH_TEST_SEND_EMAIL, SERVICE_WITHOUT_INVOICE_SEND_EMAIL
export const SERVICE_WITHOUT_EMAIL_MODIFICATIONS = [INTEGRATION_SERVICES.XERO, INTEGRATION_SERVICES.NETSUITE];
export const SERVICE_WITHOUT_EMAIL_CC = [INTEGRATION_SERVICES.XERO, INTEGRATION_SERVICES.NETSUITE];
export const SERVICE_WITHOUT_EMAIL_BCC = [INTEGRATION_SERVICES.XERO, INTEGRATION_SERVICES.NETSUITE];
export const SERVICE_WITHOUT_INVOICE_SEND_EMAIL = [INTEGRATION_SERVICES.NETSUITE];
export const SERVICE_WITH_TEST_SEND_EMAIL = [INTEGRATION_SERVICES.QUICKBOOKS];
export const SERVICE_WITH_DELETE_INVOICE = [
  INTEGRATION_SERVICES.QUICKBOOKS,
  INTEGRATION_SERVICES.XERO,
  INTEGRATION_SERVICES.NETSUITE,
];
export const SERVICE_WITHOUT_PAID_INVOICE_CHANGE_SUPPORT = [INTEGRATION_SERVICES.XERO, INTEGRATION_SERVICES.NETSUITE];

// [JB 2023-05-19] In Netsuite voiding an invoice involves creating a credit memo
// while we can do it using the API, it's not a good idea to do it automatically
// because there are customizations that customers do (like setting a custom memo or location)
// let's leave it to the user to void the invoice manually in Netsuite and listen to feedback
// In any case to create a credit memo we need to call POST {{REST_SERVICES}}/record/v1/invoice/:id/!transform/creditMemo
export const SERVICE_WITHOUT_INVOICE_VOID = [INTEGRATION_SERVICES.NETSUITE];

export const SERVICE_WITH_INCOME_ACCOUNT_REF = [INTEGRATION_SERVICES.QUICKBOOKS, INTEGRATION_SERVICES.XERO]; // can add more to this list as we implement fetch in backend
export const SERVICE_WITH_DISCONNECT_ROUTE = [INTEGRATION_SERVICES.XERO];
export const SERVICE_WITH_CUSTOM_FIELDS = [INTEGRATION_SERVICES.QUICKBOOKS, INTEGRATION_SERVICES.NETSUITE];
export const SERVICE_WITH_TRACKING_CATEGORIES = [INTEGRATION_SERVICES.XERO];
export const SERVICE_WITH_IS_TAXABLE = [
  INTEGRATION_SERVICES.QUICKBOOKS,
  INTEGRATION_SERVICES.XERO,
  INTEGRATION_SERVICES.NETSUITE,
];
export const HAS_TAX_SETTINGS = [
  INTEGRATION_SERVICES.QUICKBOOKS,
  INTEGRATION_SERVICES.XERO,
  INTEGRATION_SERVICES.NETSUITE,
];

// can add more after we implement external invoice templates in other services
export const SERVICE_WITH_INVOICE_TEMPLATES = [
  INTEGRATION_SERVICES.XERO,
  INTEGRATION_SERVICES.NETSUITE,
  ...(process.env.REACT_APP_RUN_ENV === 'e2e-test' ? [INTEGRATION_SERVICES.QUICKBOOKS] : []),
];

export const BILLING_TODO_PERIODS = {
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  THIS_WEEK: 'This week',
  NEXT_WEEKS: 'Next weeks',
};

export const BILLING_TODO_TYPES = {
  REVIEW: 'review',
  INVOICES_TO_SEND: 'new invoices to send',
  REMINDERS: 'reminders to send',
};

export const REVIEW_TYPES = {
  TRANSACTIONS_NEED_INVOICING_SCHEDULE: 'transactions_need_invoicing_schedule',
};

export const INVOICE_ITEM_TYPES = {
  FEE: 'FEE',
  TAXES: 'TAXES',
  SHIPPING: 'SHIPPING',
  DISCOUNT: 'DISCOUNT',
};

export const INVOICE_ITEM_TYPE_TO_KEY = {
  [INVOICE_ITEM_TYPES.TAXES]: 'invoice_taxes',
  [INVOICE_ITEM_TYPES.FEE]: 'invoice_fee',
  [INVOICE_ITEM_TYPES.SHIPPING]: 'invoice_shipping',
  [INVOICE_ITEM_TYPES.DISCOUNT]: 'invoice_discount',
};

export const BILLING_PAGES_ROUTES = {
  ROOT: '/billing',
  REVIEW_TRANSACTIONS: '/billing/review-transactions',
  DASHBOARD: '/billing/dashboard',
  USAGE_BASED_ENGINE: '/billing/usage-based-engine',
  INVOICES: '/billing/invoices',
  SCHEDULES_EXTERNAL_UPDATES: '/billing/external-updates',
  AR_AGING_REPORT: '/billing/ar-aging-report',
};

export const COMMON_INVOICE_FIELDS = [
  'days_to_pay',
  'payment_options',
  'memo',
  'invoice_fee',
  'invoice_taxes',
  'email_addresses',
  'email_body',
  'email_subject',
];

export const INVOICE_DATE_RELATIVE_OPTIONS = {
  EQUAL_TO: 'equal to',
  N_DAYS_BEFORE: 'N days before',
  N_DAYS_AFTER: 'N days after',
};

export const INVOICE_DATE_REFERENCE_OPTIONS = {
  TRANSACTION_START_DATE: 'Transaction Start Date',
};

export const PRODUCT_METADATA_KEYS = {
  IS_TAXABLE: 'isTaxable',
};

export const PRODUCT_IMPORT_METADATA_KEYS = {
  INCOME_ACCOUNT: 'external_income_account_id',
  DISPLAY_ORDER: 'display_order',
};

export const INVOICE_MAGIC_METADATA = {
  LAST_SAVE_INVOICE_FAILED_AT: 'last_save_invoice_failed_at',
  LAST_SAVE_INVOICE_ERROR: 'last_save_invoice_error',
  LAST_SEND_EMAIL_FAILED_AT: 'last_send_email_failed_at',
  LAST_SEND_EMAIL_ERROR: 'last_send_email_error',
  SEND_EMAIL_ERROR_COUNT: 'send_email_error_count',
  DISABLE_AUTO_CHARGE: 'disable_auto_charge',
  LAST_PAYMENT_FAILED_AT: 'last_payment_failed_at',
  LAST_PAYMENT_ERROR: 'last_payment_error',
  JOB_STATUS: 'job_status',
  JOB_ID: 'job_id',
  JOB_ERROR_MESSAGE: 'job_error_message',
  SHOULD_RETRY_FOR_TAXES: 'should_retry_for_taxes',
  PAYMENT_STATUS: 'payment_status',
  SHOW_SHIPPING_ADDRESS: 'show_shipping_address',
  POLLING_TAXES_LAST_ATTEMPT_AT: 'polling_taxes_last_attempt_at',
};

export const INVOICE_ITEM_MAGIC_METADATA = {
  MANUAL_TAX_OVERRIDE: 'manual_tax_override',
  ORIGINAL_TAX_AMOUNT: 'original_tax_amount',
  ORIGINAL_TAX_PERCENT: 'original_tax_percent',
};

export const REMINDER_ERROR_METADATA_FIELDS = {
  LAST_SEND_EMAIL_FAILED_AT: 'last_send_email_failed_at',
  LAST_SEND_EMAIL_ERROR: 'last_send_email_error',
  SEND_EMAIL_ERROR_COUNT: 'send_email_error_count',
};

export const GROUP_TRANSACTIONS_FOR_SCHEDULES_BY = {
  CUSTOMER: 'Customer',
  CRM_ID: 'CRM ID',
  PARENT_CUSTOMER: 'Parent Customer',
  TRANSACTION_ID: 'No Grouping',
};

export const GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY = {
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER]: 'customer_id',
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CRM_ID]: 'crm_id',
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.PARENT_CUSTOMER]: 'parent_customer_id',
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.TRANSACTION_ID]: 'id',
};

export const GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL = {
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER]]:
    GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER,
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.PARENT_CUSTOMER]]:
    GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.PARENT_CUSTOMER,
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CRM_ID]]:
    GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CRM_ID,
  [GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.TRANSACTION_ID]]:
    GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.TRANSACTION_ID,
};

export const DEFAULT_INVOICE_LANGUAGE = 'en';

export const SUPPORTED_INVOICE_LANGUAGES = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  da: 'Danish',
  nb: 'Norwegian',
  sv: 'Swedish',
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 'percentage',
  FLAT_AMOUNT: 'flat_amount',
};

export const CREDIT_NOTE_METADATA = {
  EXCLUDE_PDF: 'exclude_pdf',
  AUTO_SEND_INVOICING_SCHEDULE_ID: 'auto_send_invoicing_schedule_id',
  JOB_STATUS: 'job_status',
  JOB_ID: 'job_id',
  JOB_FAILED_MESSAGE: 'job_failed_message',
  JOB_FAILED_AT: 'job_failed_at',
};

export const CREDIT_NOTE_JOB_STATUS = {
  QUEUED_UP: 'queued_up',
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCESS: 'success',
};

export const ACCOUNTING_CURRENCIES_NO_GL = 'No GL';

export const CONTRACT_ALLOCATION_TYPES = {
  FIRST_MONTH: 'FIRST_MONTH',
  OVER_TIME: 'OVER_TIME',
};

export const CONTRACT_ALLOCATION_TYPES_TO_LABEL = {
  [CONTRACT_ALLOCATION_TYPES.FIRST_MONTH]: 'First Month',
  [CONTRACT_ALLOCATION_TYPES.OVER_TIME]: 'Over Time',
};

export const SSP_CADENCES = {
  ANNUALLY: 'ANNUALLY',
  QUARTERLY: 'QUARTERLY',
  SEMI_ANNUALLY: 'SEMI_ANNUALLY',
  MONTHLY: 'MONTHLY',
  PER_UNIT: 'PER_UNIT',
};

export const SSP_CADENCES_TO_LABEL = {
  [SSP_CADENCES.ANNUALLY]: 'Annually',
  [SSP_CADENCES.QUARTERLY]: 'Quarterly',
  [SSP_CADENCES.SEMI_ANNUALLY]: 'Semi-Annually',
  [SSP_CADENCES.MONTHLY]: 'Monthly',
  [SSP_CADENCES.PER_UNIT]: 'Per Unit',
};

// Matches backend job names
export const INVOICE_JOBS_OPERATIONS = {
  'invoices.sendInvoiceJob': 'send',
  'invoices.chargeInvoice': 'charge',
  'invoices.pollTaxes': 'save',
};

export { MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME };
