import React from 'react';
import { uniqBy } from 'lodash';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';
import { MultiselectRibbon } from 'components/Blocks';
import { TrashIcon } from 'components/Icons';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  EmptyTableContent,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

export const SubscriptionsTable = ({ tableProps, openInvoicingScheduleModal, bulkDelete, openDuplicateModal }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = tableProps;

  const selectedRows = rows.filter((row) => selectedRowIds[row.id]);

  const subscriptions = selectedRows.flatMap((row) => row.original?.usage_subscriptions ?? []);
  const transactions = subscriptions.flatMap((subscription) => subscription.transactions ?? []);
  const groupedSubscriptions = selectedRows.flatMap((row) => row.original ?? []);

  return (
    <div {...getTableProps()} data-cy="billing__subscriptions-table">
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps(column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }))}
                customWidth={column.width}
              >
                <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                  {column.id === 'actions' ? <></> : column.render('Header')}

                  {column.isSorted && (
                    <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                      <TotalIcon />
                    </SortIconWrapper>
                  )}
                </HeaderCellWrapper>
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>

      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {Object.keys(selectedRowIds).length > 0 && (
            <MultiselectRibbon
              label={`${Object.keys(selectedRowIds).length} selected rows:`}
              actions={[
                {
                  role: 'primary',
                  label: 'Create invoices',
                  disabled:
                    subscriptions.some((subscription) => !!subscription?.transactions?.[0]?.invoicing_schedule_id) ||
                    transactions.length === 0 ||
                    new Set(subscriptions.map((subscription) => subscription.customer_id)).size > 1,
                  onClick: () => {
                    const currentFrequency = subscriptions?.[0]?.pricing_plan?.invoicing_frequency;
                    const defaultFrequency = Object.values(INVOICING_FREQUENCIES).includes(currentFrequency)
                      ? currentFrequency
                      : undefined;
                    openInvoicingScheduleModal({
                      includedTransactions: uniqBy(transactions, 'id'),
                      options: {
                        defaultFrequency,
                        autoSetAutoCharge: true,
                      },
                    });

                    toggleAllRowsSelected(false);
                  },
                },
                {
                  role: 'primary',
                  label: 'Duplicate',
                  onClick: () => {
                    openDuplicateModal({
                      groupedSubscriptions,
                    });
                  },
                },
                {
                  label: <TrashIcon size="16px" fill="white" />,
                  isRound: true,
                  onClick: () => bulkDelete.mutateAsync({ ids: selectedRows.map((row) => row.original.id) }),
                },
              ]}
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow {...row.getRowProps()} noRowHover>
                {row.cells.map((cell) => (
                  <ReactTableCell {...cell.getCellProps()} customWidth={cell.column.width}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : (
        <EmptyTableContent>No Usage-based Subscriptions Found</EmptyTableContent>
      )}
    </div>
  );
};
