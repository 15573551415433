import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { FormikCustomSelector } from 'components/Controls';
import { getCustomerDisplayName } from 'models/customer';
import { getCustomersFromSearch } from 'shared/TransactionContent';

import { CustomFlexBasis } from '../styles';

export const CustomerSelector = ({ isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const { orgId } = useContext(AppContext);

  const value = values?.customer_id
    ? {
        label: getCustomerDisplayName({
          customerName: values?.customer_name,
          customerId: values?.customer_id,
        }),
        value: values?.customer_id,
      }
    : null;

  return (
    <CustomFlexBasis width={values?.aggregate_usage_on_parent ? '25%' : '100%'}>
      <FormikCustomSelector
        isDisabled={isDisabled}
        label="Customer"
        placeholder="Select customer"
        value={value}
        name="customer_id"
        handleChange={(option) => {
          if (option) {
            setFieldValue('customer', option);
            setFieldValue('customer_id', option.value);
            setFieldValue('customer_name', option.label);
          } else {
            setFieldValue('customer_id', null);
            setFieldValue('customer_name', null);
          }
        }}
        loadOptions={(searchQuery, prevOptions, additional) =>
          getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
        }
        onCreateOption={async (newCustomerName) => {
          const newCustomer = await createCustomer({
            orgId,
            customerName: newCustomerName,
          });
          setFieldValue('customer', newCustomer);
          setFieldValue('customer_id', newCustomer.id);
          setFieldValue('customer_name', newCustomer.name);
        }}
        creatable
        isPaginateable
        isClearable
      />
    </CustomFlexBasis>
  );
};
