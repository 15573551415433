import { useContext } from 'react';
import { cssVar } from 'polished';

import { ReactComponent as GoalsIcon } from 'images/presentation-active.svg';
import { IconButton } from 'components/Buttons';
import { GoalsContext } from './GoalsContext';

export const GoalsButton = () => {
  const { openGoalsModal } = useContext(GoalsContext);

  return (
    <IconButton icon={<GoalsIcon />} iconFillColor={cssVar('--primaryGreen')} onClick={openGoalsModal}>
      Goals
    </IconButton>
  );
};
