import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  ModalBodyMargin,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Centerer, Spacer } from 'components/Core';
import { InfoIcon } from 'components/Icons';
import { WIDGET_TYPES, WIDGET_TYPES_TITLES } from '../consts';
import { WidgetMetricsForm } from './WidgetMetricsForm';
import { WidgetChartVisualizerForm } from './WidgetChartVisualizerForm';
import { WidgetTextSectionForm } from './WidgetTextSectionForm';
import { BusinessSnapshotSettingsModal } from '../../DashboardTables/BusinessSnapshotSettingsModal';

const ErrorMessage = styled.div`
  color: var(--primaryRed);
`;

const StyledModalBodyMargin = styled(ModalBodyMargin)`
  padding: 0 32px;

  overflow: scroll;
`;

const StyledModalTitle = styled(ModalTitle)`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
`;

const schemas = {
  [WIDGET_TYPES.TEXT_SECTION]: Yup.object({
    markdown: Yup.string().required('Please, enter text'),
  }),
  [WIDGET_TYPES.DATA_TABLE]: Yup.object({
    name: Yup.string(),
    selectedMetrics: Yup.array(),
    selectedColumns: Yup.object(),
    fixedHeader: Yup.bool(),
  }),
  default: Yup.object({
    id: Yup.string().required('Please, select a metric'),
    isTTM: Yup.bool(),
    size: Yup.string().required('Please, select a width'),
    selectedMetrics: Yup.array().when('id', {
      is: WIDGET_TYPES.CHART_VISUALIZER,
      then: Yup.array().required('Please, select a metric').min(1, 'Please, select a metric'),
      otherwise: Yup.array().nullable(),
    }),
  }),
};

export const WidgetModal = ({ widgetType, onClose, onSubmitted, metric, metricOptions, editMode }) => {
  return (
    <Formik
      initialValues={{ ...metric }}
      validationSchema={schemas[widgetType] ?? schemas.default}
      onSubmit={onSubmitted}
    >
      {({ values, setFieldValue, handleSubmit, errors, submitCount }) => {
        return widgetType === WIDGET_TYPES.DATA_TABLE ? (
          <BusinessSnapshotSettingsModal
            name={values?.name}
            selectedMetrics={values?.selectedMetrics}
            selectedColumns={values?.selectedColumns}
            fixedHeader={values?.fixedHeader}
            setSelected={({ name, selectedMetrics, selectedColumns }) => {
              setFieldValue('name', name);
              setFieldValue('selectedMetrics', selectedMetrics);
              setFieldValue('selectedColumns', selectedColumns);

              handleSubmit();
            }}
            setFixedHeader={(fixedHeader) => setFieldValue('fixedHeader', fixedHeader)}
            toggleModal={onClose}
          />
        ) : (
          <ModalContainer data-cy={`${widgetType}-widget-modal`}>
            <Modal
              minHeight="400px"
              maxHeight="800px"
              height="auto"
              width={widgetType === WIDGET_TYPES.TEXT_SECTION ? '800px' : '440px'}
            >
              <ModalHeader>
                <ModalCloseIcon onClose={onClose} />
                <StyledModalTitle>
                  <ModalTitleText>
                    <b>
                      {editMode ? 'Edit' : 'Add'} {WIDGET_TYPES_TITLES[widgetType]}
                    </b>
                  </ModalTitleText>
                  {widgetType === WIDGET_TYPES.TEXT_SECTION && (
                    <>
                      <Spacer width="8px" />
                      <StyledInfoIcon
                        size="30px"
                        onClick={() => window.open('https://www.markdownguide.org/basic-syntax/', '_blank')}
                      />
                    </>
                  )}
                </StyledModalTitle>
              </ModalHeader>
              <>
                <StyledModalBodyMargin>
                  {widgetType === WIDGET_TYPES.METRIC ? (
                    <WidgetMetricsForm metricOptions={metricOptions} values={values} setFieldValue={setFieldValue} />
                  ) : widgetType === WIDGET_TYPES.TEXT_SECTION ? (
                    <WidgetTextSectionForm
                      markdown={values?.markdown}
                      setMarkdown={(val) => setFieldValue('markdown', val)}
                    />
                  ) : (
                    <WidgetChartVisualizerForm
                      metricOptions={metricOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}

                  {submitCount > 0 && errors && (
                    <Centerer direction="column">
                      {Object.entries(errors).map(([key, error]) => (
                        <ErrorMessage key={key}>{error}</ErrorMessage>
                      ))}
                    </Centerer>
                  )}
                </StyledModalBodyMargin>

                <ModalFooter flexEnd>
                  <ModalButton default onClick={onClose}>
                    Cancel
                  </ModalButton>
                  <Spacer width="14px" />
                  <ModalButton primary onClick={handleSubmit} data-cy={`${widgetType}-widget-modal__save-button`}>
                    Save
                  </ModalButton>
                </ModalFooter>
              </>
            </Modal>
          </ModalContainer>
        );
      }}
    </Formik>
  );
};
