import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { AIWandIcon, ArrowNarrowLeftIcon } from 'components/Icons';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalContainer, ModalButton, ModalFooter, ModalCloseIcon } from 'components/Modal';
import { Spacer } from 'components/Core';

const WandWrapper = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  border: 1px solid var(--Dark-3, rgba(0, 21, 46, 0.03));
  background: linear-gradient(
    180deg,
    rgba(255, 194, 122, 0.1) 0%,
    rgba(255, 98, 221, 0.1) 48%,
    rgba(109, 72, 255, 0.1) 100%
  );
  box-shadow: 0px 10px 40px 0px rgba(152, 80, 245, 0.1);
`;

const ModalHeader = styled.div`
  padding: 4px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 17px;
  line-height: 26px;
`;

const DescriptionText = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  gap: 12px;
  max-width: 400px;
`;

const CenteredModal = styled(Modal)`
  justify-content: center;
  align-items: center;
`;

const StyledModalFooter = styled(ModalFooter)`
  width: 100%;
`;

const GradientText = styled.span`
  background: linear-gradient(180deg, #ffc27a 0%, #ff62dd 48%, #6d48ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AIProcessingModal = ({ onClose, onBack, fromCustomerPage = false }) => {
  const modal = useClickOutside(onClose);
  const history = useHistory();

  const redirectToContractPdfs = () => {
    history.push({
      pathname: '/contracts/pdfs',
    });
  };

  const onBackHandler = () => {
    onBack?.();
    onClose({ clearProcessingContractIds: false });
  };

  return (
    <ModalContainer data-cy="ai-processing-modal">
      <CenteredModal width="700px" height="auto" overflow="visible" compact ref={modal}>
        <ModalHeader>
          <WandWrapper>
            <AIWandIcon width="26px" height="26px" />
          </WandWrapper>
          <ModalCloseIcon data-cy="ai-processing-modal__close" onClose={onClose} />
        </ModalHeader>
        <ModalBody centered paddingLeft="36px" paddingRight="36px" paddingBottom="36px">
          <Title>
            Subscript <GradientText>AI</GradientText> needs time to process your files
          </Title>
          <DescriptionText>
            {fromCustomerPage
              ? 'Go to the page of all contract files to check new data that the system will suggest creating based on your uploaded PDFs'
              : 'Refresh this window in a few moments to check new data that the system will suggest creating based on your uploaded PDFs'}
          </DescriptionText>
        </ModalBody>
        {fromCustomerPage && (
          <StyledModalFooter flexEnd>
            {onBack && (
              <ModalButton onClick={onBackHandler}>
                <ArrowNarrowLeftIcon size="16px" />
                <Spacer width="6px" />
                Edit Contract Files
              </ModalButton>
            )}
            <ModalButton data-cy="ai-processing-modal__go-to-contracts-button" primary onClick={redirectToContractPdfs}>
              Go to Contract PDFs
            </ModalButton>
          </StyledModalFooter>
        )}
      </CenteredModal>
    </ModalContainer>
  );
};

export const useAIProcessingModal = ({ finishedProcessing, setProcessingContractIds } = {}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(
    // clearProcessingContractIds will be false when closeModal called from the back button handler
    ({ clearProcessingContractIds = true } = {}) => {
      setShowModal(false);
      if (clearProcessingContractIds && finishedProcessing) {
        setProcessingContractIds([]);
      }
    },
    [setShowModal, setProcessingContractIds, finishedProcessing],
  );

  const Modal = useCallback(
    ({ onBack, fromCustomerPage }) =>
      showModal ? <AIProcessingModal onClose={closeModal} onBack={onBack} fromCustomerPage={fromCustomerPage} /> : null,
    [showModal, closeModal],
  );

  return {
    openModal,
    Modal,
  };
};
