import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { getCustomerDisplayName } from 'models/customer';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { ReactComponent as ArchiveIcon } from 'images/actions-dropdown__archive.svg';
import { ReactComponent as EditIcon } from 'images/actions-dropdown__edit.svg';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { BaseNoHeader } from 'shared/Layout';
import { ActionsPopover, SyncEntityButton, SYNC_ENTITIES } from 'shared/Common';
import { FlexerRow, Row, Spacer } from 'components/Core';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { FilePlus } from 'components/Icons';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { BillingProvider } from 'views/Billing/BillingProvider';
import { Filters } from 'shared/Filters';
import { useAIContractReaderModals } from 'views/Contracts';
import { CustomerViewContent } from './CustomerViewContent';
import { CustomersContext } from '../CustomersContext';
import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from '../CustomersActionsModal';

const CustomerName = styled.div`
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 20px 40px 24px 40px;

  @media (max-width: 1439px) {
    padding: 20px 60px 24px 40px;
  }
`;

export const CustomerSingle = () => {
  const { organizations, orgConfigs } = useContext(AppContext);
  const pageState = useContext(CustomersContext);

  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({});
  const [modalAction, setModalAction] = useState();

  const {
    ContractFilesModal,
    ContractAIPreviewModal,
    ContractTransactionsCreatedModal,
    AIReaderLockedModal,
    AIProcessingModal,
    openContractFilesModal,

    AIContractReaderButton,
  } = useAIContractReaderModals({ customer });

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  const handleCreateInvoicesClick = () => {
    openInvoicingScheduleModal({ customer });
  };

  const handleCustomerFetched = (customer) => {
    setCustomer(customer);
  };

  return (
    <BaseNoHeader noPaddings layoutProps={{ active: 'customers', noBorder: true }}>
      <BillingProvider>
        <GlobalTogglesContextProvider urlState={pageState}>
          <GlobalTogglesFloatingContainer showAfterScrolling={0}>
            <Row horizontal="space-between" style={{ margin: '20px 0', padding: '0 40px' }}>
              <Row horizontal="start" gap="8px">
                <LinkBackButton data-cy="page-header-back-button" to="/customers">
                  All Customers
                </LinkBackButton>

                <CustomerName>
                  {getCustomerDisplayName({ customerName: customer?.name, customerId, customerEmail: customer?.email })}
                </CustomerName>

                <SyncEntityButton entity={SYNC_ENTITIES.CUSTOMERS} entityId={customerId} />
              </Row>

              <FlexerRow justifyContent="end" gap="12px">
                <AIContractReaderButton />

                {isFeatureActive({ feature: FEATURES.BILLING, orgConfigs }) && (
                  <IconButton
                    data-cy="customer-page__create-invoice"
                    icon={<FilePlus />}
                    iconFillColor="var(--primaryGreen)"
                    onClick={handleCreateInvoicesClick}
                  >
                    Create Invoices
                  </IconButton>
                )}

                <ActionsPopover
                  actions={{
                    Edit: {
                      icon: <EditIcon />,
                      cb: () => {
                        setModalAction(CUSTOMERS_MODAL_ACTIONS.EDIT);
                        setDataForModal(customer);
                        setShowCustomersActionModal(true);
                      },
                    },

                    Remove: {
                      icon: <ArchiveIcon />,
                      cb: () => {
                        setModalAction(CUSTOMERS_MODAL_ACTIONS.DELETE);
                        setDataForModal(customer);
                        setShowCustomersActionModal(true);
                      },
                    },
                  }}
                />
              </FlexerRow>
            </Row>
            <Wrapper>
              <Filters hideFilters hideTimeSelection showGlobalToggles={true}></Filters>
            </Wrapper>
            <CustomerViewContent id={customerId} onCustomerFetched={handleCustomerFetched} />
            {showCustomersActionModal && (
              <CustomersActionsModal
                isPageView
                organizationId={organizations?.[0].id}
                closeModal={() => setShowCustomersActionModal(false)}
                modalAction={modalAction}
                customer={dataForModal}
              />
            )}
            <ContractFilesModal />
            <ContractAIPreviewModal />
            <ContractTransactionsCreatedModal />
            <AIReaderLockedModal />
            <AIProcessingModal onBack={openContractFilesModal} fromCustomerPage={true} />
            <Spacer height="60px" />
          </GlobalTogglesFloatingContainer>
        </GlobalTogglesContextProvider>

        <InvoicingScheduleModal />
      </BillingProvider>
    </BaseNoHeader>
  );
};
