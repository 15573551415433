import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { PAGE_CONTEXT_VALUES, usePageContext, useStateWithStorage } from 'utils/hooks';
import { formatYearMonth } from 'utils/dateUtils';
import { HeaderReports } from 'shared/Reports';
import { HEADER_TITLES } from 'shared/Layout';
import { useSyncedExport } from 'shared/SyncedExports';
import { NoIntegrations } from 'shared/ErrorBars';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Filters } from 'shared/Filters';
import { Spacer } from 'components/Core';
import RevenueDetailsContent from './RevenueDetailsContent';
import { PaddingWrapper } from './styled';
import { useLinkShare } from 'shared/LinkShare';

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  min-height: 700px;
`;

const RevenueDetails = () => {
  const { organizations, integrations, orgConfigs } = useContext(AppContext);
  const appIsARR = orgConfigs.custom_settings.isARR.value;
  const [revenueDetailsIsARR, setRevenueDetailsIsARR] = useStateWithStorage('revenueDetailsIsARR', appIsARR);

  const { urlState: pageState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.revenue_details.key,
    replace: {
      isARR: [revenueDetailsIsARR, setRevenueDetailsIsARR],
    },
  });

  const {
    isARR,
    isCommitted,
    rollup,
    optimisticAnalytics,
    setOptimisticAnalytics,
    dataFilter,
    metadataFilter,
    setDataFilter,
    setMetadataFilter,
  } = pageState;
  const exportScreenshotRef = useRef();

  // The reason this is here is that some components that show RevenueDetailsContent
  //  need to know if it's showing a transaction.
  const [transactionToShow, setTransactionToShow] = useState(null);
  const [customerIdToShow, setCustomerIdToShow] = useState(null);

  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.revenueDetails });

  const revenueDetailsMonth = dataFilter.revenueMonth.getMonth() + 1;
  const revenueDetailsYear = dataFilter.revenueMonth.getFullYear();

  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    exportScreenshotRef,
    orgId: organizations[0].id,
    type: 'revenue_details',
    customization: ({ selectAllData }) => ({
      month: formatYearMonth({ year: revenueDetailsYear, month: revenueDetailsMonth }),
      isARR,
      isCommitted,
      rollup,
      optimisticAnalytics,
      splitUpsell: true,
      ...(!selectAllData && metadataFilter),
    }),
    isFilterOn: !!metadataFilter && Object.values(metadataFilter).length !== 0,
  });

  let hasTransactions = organizations && parseInt(organizations[0]?.transactionCount) > 0;
  if (integrations.length > 0 && !hasTransactions) {
    return <NoIntegrations />;
  }

  return (
    <>
      <PaddingWrapper>
        <HeaderReports
          activePage="revenue"
          headerTitle={HEADER_TITLES.revenue}
          headerRight={
            <>
              <LinkShareButton />
              <Spacer width="10px" />
              <ExportButton />
            </>
          }
          page={PAGE_CONTEXT_VALUES.revenue_details.key}
          pageState={pageState}
        />
      </PaddingWrapper>

      <MainContainer>
        <GlobalTogglesContextProvider urlState={pageState}>
          <PaddingWrapper>
            <Filters
              hidePeriodSelection
              hideTimeSelection
              showSelectSpecificMonth
              showConditions
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              metadataFilter={metadataFilter}
              setMetadataFilter={setMetadataFilter}
            />
            <SyncedExportInfoBar />
          </PaddingWrapper>

          <GlobalTogglesFloatingContainer>
            <RevenueDetailsContent
              exportScreenshotRef={exportScreenshotRef}
              organization={organizations[0]}
              revenueDetailsMonth={revenueDetailsMonth}
              revenueDetailsYear={revenueDetailsYear}
              transactionToShow={transactionToShow}
              setTransactionToShow={setTransactionToShow}
              viewAsARR={isARR}
              customerIdToShow={customerIdToShow}
              setCustomerIdToShow={setCustomerIdToShow}
              optimisticAnalytics={optimisticAnalytics}
              setOptimisticAnalytics={setOptimisticAnalytics}
            />
          </GlobalTogglesFloatingContainer>
        </GlobalTogglesContextProvider>

        <LinkShareModal />
        <SyncedExportModal />
      </MainContainer>
    </>
  );
};

export default RevenueDetails;
