import React, { useCallback, useState } from 'react';
import { BusinessSnapshotSettingsModal } from './BusinessSnapshotSettingsModal';

export const useBusinessSnapshotSettingsModal = ({ selectedMetrics, selectedColumns, setSelected }) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(
    ({ selectedColumns } = {}) => {
      if (selectedColumns) {
        setSelected({
          selectedMetrics,
          selectedColumns,
        });
      }
      setShowModal(!showModal);
    },
    [selectedMetrics, setSelected, showModal],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <BusinessSnapshotSettingsModal
          toggleModal={toggleModal}
          selectedMetrics={selectedMetrics}
          selectedColumns={selectedColumns}
          setSelected={setSelected}
        />
      ) : (
        <></>
      ),
    [showModal, toggleModal, selectedMetrics, selectedColumns, setSelected],
  );

  return {
    toggleModal,
    showModal,
    Modal,
  };
};
