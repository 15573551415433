import { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { FormikCustomCheckbox, FormikCustomSelector } from 'components/Controls';
import { Flexer } from 'components/Core';
import { MAGIC_METADATA } from 'consts/global';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { ExternalLinkFullIcon } from 'components/Icons';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { groupBy } from 'utils/arrayUtils';
import { createCustomerInGl } from 'api/customers/requests';
import { CircleLoader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';
import { getCustomersFromSearch } from 'shared/TransactionContent';

import { GLCustomerSelector } from './GLCustomerSelector';
import { ExternalLink } from '../styles';
import { buildExternalCustomerUrl } from '../utils';

export const ParentCustomerSelector = ({
  glIntegrations,
  addCustomer,
  isFetchingCustomer,
  handleBillWithParentChange,
  isExternalParent,
}) => {
  const { orgId } = useContext(AppContext);

  const { values, setFieldValue } = useFormikContext();

  const getOptions = ({ name, id, isValue }) => {
    const option = {
      label: `${name || '<No Name>'} / ${id}`,
      value: id,
      customer: {
        id,
        name,
      },
    };
    if (isValue) return option;
    return id ? [option] : [];
  };

  const externalParentCustomerId = values?.metadata?.[MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID];

  const [hasParent, setHasParent] = useState(!!values?.metadata?.[MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID]);
  const [searchName, setSearchName] = useState(values?.invoicing_details?.external_parent_customer_name ?? '');
  const [initialOptions, setInitialOptions] = useState(
    getOptions({ name: values?.invoicing_details?.external_parent_customer_name, id: externalParentCustomerId }),
  );

  const selectedGlIntegrationId = values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID];
  const glIntegrationById = groupBy(glIntegrations, 'id', { uniqueness: true });
  const selectedIntegration = glIntegrationById[selectedGlIntegrationId];
  const invoicingServiceLabel = getIntegrationDisplayName(selectedIntegration);

  const externalParentCustomerUrl = externalParentCustomerId
    ? buildExternalCustomerUrl({
        service: getServiceCategory(selectedIntegration?.service),
        portalId: selectedIntegration?.portal_id,
        externalId: externalParentCustomerId,
      })
    : '';

  const handleInputChange = async (option) => {
    const externalParentCustomerId = option?.value ?? null;
    setFieldValue(`metadata.${MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID}`, externalParentCustomerId);
    setSearchName(option?.customer?.name ?? '');

    if (option?.value) {
      setInitialOptions(getOptions({ name: option.customer?.name, id: option.value }));
    } else {
      await handleBillWithParentChange({
        values,
        updateData: {
          metadata: {
            [MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID]: externalParentCustomerId,
          },
        },
        setFieldValue,
        billWithParent: false,
      });
    }
  };

  const handleUpsertExternalCustomer = async ({ selectorProps, customerName }) => {
    const response = await createCustomerInGl({
      orgId,
      body: {
        integrationId: selectedGlIntegrationId,
        externalParentCustomer: {
          name: customerName,
          invoicing_details: {
            ...values?.invoicing_details,
          },
        },
      },
    });
    setFieldValue(`metadata.${MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID}`, response?.customerExternalId ?? null);

    if (response?.customerExternalId) {
      setInitialOptions(getOptions({ name: customerName, id: response.customerExternalId }));
      selectorProps.setValue(getOptions({ name: customerName, id: response.customerExternalId, isValue: true }));
    }
  };

  useEffect(() => {
    if (!externalParentCustomerId) setSearchName('');
  }, [externalParentCustomerId]);

  useEffect(() => {
    if (externalParentCustomerId && !hasParent) setHasParent(true);
  }, [externalParentCustomerId, hasParent]);

  useEffect(() => {
    if (
      externalParentCustomerId &&
      hasParent &&
      !initialOptions.some((option) => option.value?.toString() === externalParentCustomerId?.toString())
    )
      setInitialOptions(
        getOptions({ name: values?.invoicing_details?.external_parent_customer_name, id: externalParentCustomerId }),
      );
  }, [externalParentCustomerId, hasParent, initialOptions, values?.invoicing_details?.external_parent_customer_name]);

  return isExternalParent ? (
    [INTEGRATION_SERVICES.QUICKBOOKS].includes(getServiceCategory(selectedIntegration?.service)) && (
      <Flexer width={hasParent ? '100%' : 'auto'} gap="10px" minHeight="38px" alignItems="center">
        <FormikCustomCheckbox
          label="Has a parent in Quickbooks"
          name="hasExternalParent"
          checked={hasParent}
          onChange={async (val) => {
            setHasParent(val.target.checked);
            if (!val.target.checked) {
              setFieldValue(`metadata.${MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID}`, null);
              await handleBillWithParentChange({
                values,
                updateData: {
                  metadata: {
                    [MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID]: null,
                  },
                },
                setFieldValue,
                billWithParent: false,
              });
            }
          }}
          data-cy="customers-actions-modal--has-gl-parent__checkbox"
        />

        {hasParent && (
          <>
            <Flexer flexGrow={1} gap="4px">
              <GLCustomerSelector
                name={`metadata.${MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID}`}
                handleInputChange={handleInputChange}
                initialOptions={initialOptions}
                integrationId={selectedIntegration?.id}
                handleUpsertExternalCustomer={handleUpsertExternalCustomer}
                setSearchQuery={setSearchName}
                customerName={searchName}
                invoicingServiceLabel={invoicingServiceLabel}
              />
              {externalParentCustomerUrl && (
                <ExternalLink onClick={() => window.open(externalParentCustomerUrl, '_blank')}>
                  <ExternalLinkFullIcon />
                </ExternalLink>
              )}
            </Flexer>
            {!!externalParentCustomerId && (
              <Flexer alignItems="center" gap="4px">
                <FormikCustomCheckbox
                  label="Bill parent customer"
                  name={`metadata.${MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT}`}
                  data-cy="customers-actions-modal--bill-with-parent__checkbox"
                  onChange={async (val) => {
                    await handleBillWithParentChange({
                      values,
                      setFieldValue,
                      billWithParent: val.target.checked,
                    });
                  }}
                />

                {isFetchingCustomer && (
                  <TooltipContainer toolTipContent="Is syncing customer" fontSize="12px" width={200}>
                    <CircleLoader width="16px" height="16px" />
                  </TooltipContainer>
                )}
              </Flexer>
            )}
          </>
        )}
      </Flexer>
    )
  ) : (
    <FormikCustomSelector
      creatable
      label="Subscript Parent"
      placeholder="Select customer..."
      value={
        values.parent_customer_id
          ? {
              label: values.parent_customer_name,
              value: values.parent_customer_id,
            }
          : null
      }
      name="parent_customer_id"
      handleChange={(option) => {
        if (option) {
          setFieldValue('parent_customer_id', option.value);
          setFieldValue('parent_customer_name', option.label);
        } else {
          setFieldValue('parent_customer_id', null);
          setFieldValue('parent_customer_name', null);
        }
      }}
      onCreateOption={async (newCustomerName) => {
        const newCustomer = await addCustomer({
          data: {
            name: newCustomerName,
          },
        });
        setFieldValue('parent_customer_id', newCustomer.id);
        setFieldValue('parent_customer_name', newCustomer.name);
      }}
      loadOptions={(searchQuery, prevOptions, additional) =>
        getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
      }
      isPaginateable
      isClearable
      containerWidth="100%"
    />
  );
};
