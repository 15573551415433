import styled from 'styled-components';

const ButtonWrapper = styled.div`
  padding: 10px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 20px;
  background: var(--primaryBlack3);
  border: 1px solid var(--neutralGray);
  border-radius: 100px;
  margin-right: 8px;
  cursor: pointer;
`;

export const DuplicateButton = ({ onClick, ...rest }) => {
  return (
    <ButtonWrapper onClick={onClick} {...rest}>
      Duplicate
    </ButtonWrapper>
  );
};
