import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useConfirmModal } from 'shared/ConfirmModal';
import { useToasts } from 'components/Toasts';
import { useImportsAPI } from 'api/imports';

const INITIAL_MODAL_STATE = {
  integrationDisplayName: null,
  importId: null,
  onDelete: null,
};

export const useRemoveImportConfirmModal = () => {
  const { orgId } = useContext(AppContext);
  const { pushError } = useToasts();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const resetModalState = () => setModalState(INITIAL_MODAL_STATE);

  const {
    operations: { deleteImport },
  } = useImportsAPI({
    orgId,
    autoFetch: false,
  });

  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    title: 'Delete product association?',
    content: <div>Are you sure you want to delete this {modalState.integrationDisplayName} product association?</div>,
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, delete',
    onConfirm: async () => {
      try {
        await deleteImport.mutateAsync({
          importId: modalState.importId,
        });
        modalState.onDelete?.();
      } catch (err) {
        pushError(err);
      } finally {
        resetModalState();
      }
    },
    onClose: resetModalState,
  });

  return {
    RemoveImportConfirmModal: ConfirmModal,
    openRemoveImportConfirmModal: ({ integrationDisplayName, importId, onDelete }) => {
      setModalState({
        integrationDisplayName,
        importId,
        onDelete,
      });
      openConfirmModal();
    },
  };
};
