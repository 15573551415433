import React, { useContext, useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';

import { Flexer } from 'components/Core';
import { NoDataFound } from 'shared/ErrorBars';
import { useSyncedExport } from 'shared/SyncedExports';
import { useInvariantCheck } from 'utils/hooks';
import { exportDashboardToExcel } from 'utils/export';
import { useCachedMetrics, useRecurringRevenueCachedMetrics } from 'utils/hooks';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';

import { SnapshotTable } from './SnapshotTable';
import { LoadingTable } from './LoadingTable';
import { reshapeSnapshotDataForTable } from './reshapeSnapshotDataForTable';
import { DashboardContext } from '../DashboardContext';
import { DashboardData, DataHead, DataInfo, DataPill } from '../Common/styled';
import {
  checkUpForRenewalLessThanPreviousPeriodRecurring,
  checkSummaryColumnsRelativeToMonthlyMetrics,
  checkRetentionMetricLimits,
  checkRecurringCustomerVersusAllCustomerCounts,
} from './invariantChecks';

export const BusinessSnapshotDataTable = ({
  name,
  calculatedSummaryStats,
  calculatedRevenueNURDCStats,
  calculatedSaasStats,
  selectedMetrics,
  selectedColumns,
  SettingsButton,
}) => {
  const { organizations, dataFilter } = useContext(AppContext);
  const {
    loading,
    customerNURDCStats,
    datesError,
    revenueNURDCStats,
    saasStats,
    seatNURDCStats,
    isARR,
    setIsARR,
    isCommitted,
    optimisticAnalytics,
    metadataFilter,
    growthType,
    rollup,
    countInfluxAsRenewed,
    lastTransactionUpdatedAt,
    hasConfirmedTransactions,
    respondedAt,
  } = useContext(DashboardContext);

  const tableRef = useRef();
  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');
  const influxMonths = getAppSetting('influxMonths');
  const winbackMonths = getAppSetting('winbackMonths');

  const { SyncedExportInfoBar, SyncedExportModal, showSyncedExportModal, exportCSV, exportPNG } = useSyncedExport({
    exportScreenshotRef: tableRef,
    orgId: organizations[0].id,
    type: 'business_snapshot',
    customization: {
      startMonth: dayjs(dataFilter.startMonth).format('YYYY-MM'),
      ...metadataFilter,
      isCommitted,
      optimisticAnalytics,
      isARR,
      rollup,
      influxMonths,
      quarters,
      quartersYearOffset,
      countInfluxAsRenewed,
    },
    // TODO: In https://github.com/ChifferCo/chiffer/issues/1242, just remove this onExport and all the frontend exporting
    //  functions. Keeping it for now because the frontend is producing a nicer file for Excel with colors and formatting.
    onExport: () => {
      exportDashboardToExcel({
        reshapeData: calculateSnapshotDataForTable,
        title: 'Business Snapshot',
        useColumnsForName: true,
      });
    },
  });

  const canConstructSpreadTable = revenueNURDCStats && customerNURDCStats && seatNURDCStats && saasStats;

  // This variable controls what options show up in the Business Snapshot Settings Modal
  const calculateSnapshotDataForTable = useMemo(() => {
    if (canConstructSpreadTable) {
      return reshapeSnapshotDataForTable({
        revenueStats: calculatedRevenueNURDCStats,
        customerStats: customerNURDCStats,
        seatStats: seatNURDCStats,
        saasStats: calculatedSaasStats,
        summaryStats: calculatedSummaryStats,
        selectedColumns: selectedColumns,
        quarters,
        quartersYearOffset,
        isARR,
        growthType,
        hasCostAttribution: organizations[0]?.hasCostAttribution,
        winbackMonths,
      });
    }
  }, [
    calculatedRevenueNURDCStats,
    customerNURDCStats,
    seatNURDCStats,
    calculatedSaasStats,
    calculatedSummaryStats,
    selectedColumns,
    quarters,
    quartersYearOffset,
    canConstructSpreadTable,
    isARR,
    growthType,
    organizations,
    winbackMonths,
  ]);

  useInvariantCheck({
    readyData:
      !loading && canConstructSpreadTable && !datesError && calculatedRevenueNURDCStats
        ? calculatedRevenueNURDCStats
        : null,
    checkers: [checkUpForRenewalLessThanPreviousPeriodRecurring],
  });

  useInvariantCheck({
    readyData:
      !loading &&
      canConstructSpreadTable &&
      !datesError &&
      calculatedSummaryStats &&
      Object.keys(customerNURDCStats).length
        ? { customerNURDCStats, calculatedSummaryStats }
        : null,
    checkers: [checkRecurringCustomerVersusAllCustomerCounts],
    checkerContext: { countInfluxAsRenewed },
  });

  useInvariantCheck({
    readyData:
      !loading && canConstructSpreadTable && !datesError && calculatedSummaryStats && calculatedSaasStats
        ? { calculatedSummaryStats, calculatedSaasStats }
        : null,
    checkers: [checkSummaryColumnsRelativeToMonthlyMetrics, checkRetentionMetricLimits],
    checkerContext: { quarters, quartersYearOffset },
  });

  useRecurringRevenueCachedMetrics({
    readyData:
      !loading &&
      !countInfluxAsRenewed &&
      canConstructSpreadTable &&
      !datesError &&
      Object.keys(calculatedRevenueNURDCStats).length
        ? calculatedRevenueNURDCStats
        : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, revenueStats]) => Object.assign(acc, { [month]: revenueStats.Total }),
        {},
      ),
    context: 'Dashboard Snapshot Data Table',
    isCommitted,
    rollup,
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  useCachedMetrics({
    readyData:
      !loading &&
      !countInfluxAsRenewed &&
      canConstructSpreadTable &&
      !datesError &&
      Object.keys(customerNURDCStats).length
        ? customerNURDCStats
        : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, customerStats]) => Object.assign(acc, { [month]: customerStats.Total }),
        {},
      ),
    description: `Recurring customer count`,
    storageKey: `recurringCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}`,
    context: 'Dashboard Snapshot Data Table',
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  useCachedMetrics({
    readyData:
      !loading &&
      !countInfluxAsRenewed &&
      canConstructSpreadTable &&
      !datesError &&
      Object.keys(customerNURDCStats).length
        ? customerNURDCStats
        : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, customerStats]) => Object.assign(acc, { [month]: customerStats.allCustomers }),
        {},
      ),
    description: `All customer count`,
    storageKey: `allCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}`,
    context: 'Dashboard Snapshot Data Table',
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  useCachedMetrics({
    readyData: !loading && canConstructSpreadTable && !datesError && calculatedSaasStats ? calculatedSaasStats : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, saasStats]) => Object.assign(acc, { [month]: saasStats[SNAPSHOT_METRICS.ARPA_NEW.monthDataKey] }),
        {},
      ),
    description: `ARPA New from Dashboard`,
    storageKey: `dashboardNewARPA`,
    context: 'Dashboard Snapshot Data Table',
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  if (!canConstructSpreadTable || datesError) return <LoadingTable />;

  return (
    <DashboardData data-cy="business-snapshot-data-table">
      <DataHead>
        <Flexer gap="8px">
          <DataPill data-cy="business-snapshot-data-table__metrics-count">{selectedMetrics?.length}</DataPill>
          <DataInfo>{name ?? 'Business Snapshot'}</DataInfo>
        </Flexer>

        {SettingsButton && (
          <SettingsButton showSyncedExportModal={showSyncedExportModal} exportCSV={exportCSV} exportPNG={exportPNG} />
        )}
      </DataHead>

      <SyncedExportInfoBar />

      {!hasConfirmedTransactions && <NoDataFound />}

      {hasConfirmedTransactions && (
        <SnapshotTable
          tableRef={tableRef}
          dataForTable={calculateSnapshotDataForTable}
          selectedRows={selectedMetrics}
          isARR={isARR}
          setIsARR={setIsARR}
        />
      )}
      <SyncedExportModal />
    </DashboardData>
  );
};
