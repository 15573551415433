import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { ORG_CONFIGS } from 'consts/global';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { humanize } from 'utils/stringUtils';
import { Centerer, FlexBetweenContainer, Flexer, FlexerRow } from 'components/Core';
import { BinIcon, EditCircleIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { SERVICE_WITH_TRACKING_CATEGORIES } from 'views/Billing/consts';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { HeaderLine, HeaderName, ItemCount, ItemsHeader } from 'views/Wizard/steps/SubscriptionData/styles';
import { AddButton, ItemName } from '../../styles';
import { METADATA_FIELD_TYPE, metadataToOptions } from '../utils';
import useDeleteModal from './useDeleteModal';
import { useUpsertModal } from './useUpsertModal';
import { SyncTrackingCategoriesButton } from './SyncTrackingCategoriesButton';

const MAX_TRACKING_CATEGORIES_BY_INTEGRATION = {
  // Per Xero docs:https://developer.xero.com/documentation/api/accounting/invoices
  // Tracking: Section for optional Tracking Category – see TrackingCategory.Any LineItem can have a maximum of 2 TrackingCategory elements.
  [INTEGRATION_SERVICES.XERO]: 2,
};

const BillingTrackingCategories = ({ integration, service, values, setFieldValue }) => {
  const {
    orgConfigs: { transactionCustomFields, customerCustomFields, productCustomFields },
  } = useContext(AppContext);
  const trackingCategories = integration?.metadata?.tracking_categories ?? [];

  const disabledTrackingCategories =
    (MAX_TRACKING_CATEGORIES_BY_INTEGRATION[service] ?? Infinity) <=
    Object.keys(values.configs[ORG_CONFIGS.BILLING_TRACKING_CATEGORIES] ?? {}).length;

  const glTrackingCategories = trackingCategories.map((category) => ({
    label: `${category.name} (${humanize(service)} tracking category)`,
    value: category.id,
  }));

  const trackingCategoryOptions = [
    ...metadataToOptions({
      metadataFields: Object.keys(transactionCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.TRANSACTION,
    }),
    ...metadataToOptions({
      metadataFields: Object.keys(customerCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.CUSTOMER,
    }),
    // NOTE: transaction_customer means "when billing using parent customer, use this custom field from the transaction customer instead"
    ...metadataToOptions({
      metadataFields: Object.keys(customerCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.TRANSACTION_CUSTOMER,
    }),
    ...metadataToOptions({
      metadataFields: Object.keys(productCustomFields ?? {}),
      type: METADATA_FIELD_TYPE.PRODUCT,
    }),
  ];

  const { Modal: UpsertFieldModal, openModal: openUpsertFieldModal } = useUpsertModal({
    glTrackingCategories,
    trackingCategoryOptions,
    onUpsert: ({ glField, subscriptField }) => {
      setFieldValue(`configs.${ORG_CONFIGS.BILLING_TRACKING_CATEGORIES}`, {
        ...values.configs[ORG_CONFIGS.BILLING_TRACKING_CATEGORIES],
        [glField]: subscriptField,
      });
    },
  });

  const { Modal: DeleteFieldModal, openModal: openDeleteFieldModal } = useDeleteModal({
    onDelete: ({ glField }) => {
      const updatedFields = { ...values.configs[ORG_CONFIGS.BILLING_TRACKING_CATEGORIES] };
      delete updatedFields[glField];
      setFieldValue(`configs.${ORG_CONFIGS.BILLING_TRACKING_CATEGORIES}`, updatedFields);
    },
  });

  return (
    <FieldBody>
      <FlexBetweenContainer centerer marginBottom="24px">
        <FieldTitle noMarginBottom>Manage tracking categories for {humanize(service)}</FieldTitle>
        <FlexerRow gap="12px">
          <SyncTrackingCategoriesButton integration={integration} />
          <TooltipContainer
            width={200}
            isVisible={disabledTrackingCategories}
            toolTipContent={`A maximum of ${
              MAX_TRACKING_CATEGORIES_BY_INTEGRATION[service] ?? Infinity
            } tracking categories can be selected`}
          >
            <AddButton onClick={openUpsertFieldModal} disabled={disabledTrackingCategories}>
              Add tracking category field
            </AddButton>
          </TooltipContainer>
        </FlexerRow>
      </FlexBetweenContainer>

      <ItemsHeader>
        <HeaderLine />
        <FlexBetweenContainer width="100%">
          <HeaderName style={{ marginRight: '42px' }}>Name</HeaderName>
          <HeaderName style={{ marginRight: '120px' }}>Field Name in your GL</HeaderName>
        </FlexBetweenContainer>
      </ItemsHeader>

      {Object.entries(values.configs[ORG_CONFIGS.BILLING_TRACKING_CATEGORIES] ?? {}).map(
        ([glField, subscriptField], index) => {
          const glFieldLabel = glTrackingCategories.find(({ value }) => value === glField)?.label ?? glField;
          const subscriptFieldLabel =
            trackingCategoryOptions.find(({ value }) => value === subscriptField)?.label ?? subscriptField;

          const handleOpenCustomField = () => openUpsertFieldModal({ glField, subscriptField });
          const handleDeleteCustomField = () => openDeleteFieldModal({ glField, glFieldLabel, subscriptFieldLabel });

          return (
            <Flexer key={subscriptField} width="100%" marginTop="20px" alignItems="center">
              <ItemName
                width="270px"
                fontWeight="700"
                padding="0px 0px 0px 34px"
                cursor="pointer"
                color="var(--primaryBlue)"
                onClick={handleOpenCustomField}
              >
                <Flexer gap="8px" alignItems="center" justifyContent="flex-start">
                  <ItemCount>{index + 1}</ItemCount>
                  {subscriptFieldLabel}
                </Flexer>
              </ItemName>
              <ItemName flex="1">{glFieldLabel}</ItemName>

              <Centerer gap="8px">
                <EditCircleIcon style={{ cursor: 'pointer' }} onClick={handleOpenCustomField} />
                <BinIcon style={{ cursor: 'pointer' }} onClick={handleDeleteCustomField} />
              </Centerer>
            </Flexer>
          );
        },
      )}

      <UpsertFieldModal />
      <DeleteFieldModal />
    </FieldBody>
  );
};

// With this wrapper we ensure that the component is only rendered for services that support tracking categories
const WrappedBillingTrackingCategories = ({ integration, service, values, setFieldValue }) =>
  !SERVICE_WITH_TRACKING_CATEGORIES.includes(service) ? null : (
    <BillingTrackingCategories
      integration={integration}
      service={service}
      values={values}
      setFieldValue={setFieldValue}
    />
  );

export { WrappedBillingTrackingCategories as BillingTrackingCategories };
