import styled from 'styled-components';
import { Centerer, FlexEndContainer, Row } from 'components/Core';

export const ReactTable = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  max-height: 80vh;
  border-radius: 16px;
`;

export const TableHeaderActions = styled(Row)`
  margin-top: 32px;
  padding-bottom: 14px;
  box-shadow: 2px 20px 48px rgba(0, 21, 46, 0.028);
`;

export const StyledTableName = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px 6px;
`;

export const ItemWithActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;

  svg {
    margin-left: 8px;
    opacity: 0.2;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledTableNameTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  white-space: ${(props) => (props.wrapText ? 'unset' : 'nowrap')};
  color: ${(props) => props.color ?? 'var(--primaryBlack)'};
  cursor: ${(props) => props.clickable && 'pointer'};
`;

export const TableSubRowsCountArrow = styled.div`
  margin-left: 8px;
  border-left: ${(props) => !props.dashboardTable && '1px solid var(--primaryBlack10)'};
  padding-left: ${(props) => !props.dashboardTable && '8px'};

  svg {
    transition: transform ease 0.4s;
    transform: ${(props) => props.isHidden && 'rotate(180deg)'};
  }
`;

export const TableSubRowsCount = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  font-weight: 900;
  font-size: 12px;
  background: ${(props) => (props.isHidden ? 'var(--primaryBlack5);' : 'white')};
  border: 1px solid #d0d0d0;
  border-radius: 100px;
  padding: 0 6px;
  cursor: pointer;
`;

export const TableSettingsTitle = styled.div`
  position: relative;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100px;
  padding: 0 4px;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  border: 1px solid #f3f3f3;
  height: 32px;
  min-width: 104px;

  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};

  svg path {
    fill: var(--primaryBlue);
  }

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const ReactTableHeader = styled.div`
  display: flex;
  min-width: 100%;
  ${({ notSticky }) => (notSticky ? '' : 'position: sticky;')}
  top: ${({ top }) => top ?? '0'};
  z-index: 15;
  background-color: ${(props) => props.backgroundColor ?? 'var(--primaryGray)'};
  width: fit-content;
  border-left: none;
  border-right: none;
  border-bottom: ${({ borderBottom }) => borderBottom ?? 'none'};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 'none'};
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom};` : '')}
  padding: ${({ padding }) => padding};
`;

export const RowsCount = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlack50);
  min-width: fit-content;
`;

export const ReactTableColumnWithTooltip = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: sub;
    cursor: pointer;
    g {
      transition: opacity ease 0.4s;
    }
    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const ReactTableHeaderRow = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height ?? '60px'};
  justify-content: ${(props) => (props.noSpaceBetween ? undefined : 'space-between')};
  padding: ${({ padding }) => padding ?? '14px 14px 20px 0'};
  padding-top: ${(props) => props.pageView && '16px'};
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 16px 16px 0 0;
  margin-bottom: -6px;
  border-bottom: none;

  &:first-child {
    padding-left: 14px;
  }

  section {
    display: none;
  }

  &:hover {
    section {
      display: unset;
    }
  }
`;

export const ReactTableHeaderColumn = styled.div`
  display: flex;
  width: 100%;
  color: ${({ color }) => color ?? 'var(--primaryBlack50)'};
  align-items: center;
  max-width: ${(props) =>
    props.customWidth && `${props.customWidth}${typeof props.customWidth === 'number' ? 'px' : ''}`};
  min-width: ${(props) =>
    props.customWidth && `${props.customWidth}${typeof props.customWidth === 'number' ? 'px' : ''}`};
  padding-right: 20px;
  font-size: 10px;
  justify-content: ${({ alignRight }) => alignRight && 'flex-end'};
  line-height: 12px;
  white-space: nowrap;

  &:last-child {
    padding-right: 0;
  }
`;

export const HeaderCellWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin-left: -8px;
  border: ${({ isSorted }) => (isSorted ? '1px solid var(--neutralGray)' : '1px solid transparent')};
  background-color: ${({ isSorted }) => isSorted && 'var(--primaryBlue10)'};
  border-radius: 100px;
  color: ${({ isSorted }) => isSorted && 'var(--secondaryBlue)'};
  border: ${({ isSortable }) => isSortable && '1px solid var(--neutralGray)'};

  ${ReactTableHeader}:hover && {
    border: ${({ isSortable }) => isSortable && '1px solid var(--neutralGray)'};
  }
`;

export const ReactTableBody = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
  background: ${({ background }) => background ?? 'white'};
  ${({ noBorder }) => (noBorder ? '' : 'border: 1px solid var(--accentGraySecond);')}
  border-radius: ${({ noBorder }) => (noBorder ? '0px' : '16px')};
  padding: ${({ padding }) => padding};
`;

export const SortIconWrapper = styled.div`
  display: inline-block;
  max-height: 16px;
  margin-left: 6px;

  svg {
    vertical-align: baseline;
    max-height: 16px;
    max-width: 16px;
    transform: ${(props) => (props.isSortedDesc ? 'rotateX(0)' : 'rotateX(180deg)')};
  }
`;

export const TablePageSeparator = styled.div`
  position: relative;
  left: -70px;
  width: 120%;
  height: 1px;
  background-color: var(--primaryBlack3);
`;

export const ReactTableRow = styled.div`
  display: flex;
  width: 100%;
  visibility: ${({ hidden }) => hidden && 'hidden'};
  height: ${(props) => props.height ?? '100%'};
  min-height: ${(props) => props.minHeight ?? props.height ?? (props.subRow ? '48px' : '58px')};
  justify-content: ${(props) => (props.noSpaceBetween ? undefined : 'space-between')};
  font-size: 12px;
  line-height: 16px;
  background: ${(props) =>
    props.subRow ? props.subRowBackgroundColor ?? 'var(--primaryBlack2)' : props.rowBackgroundColor ?? 'white'};
  padding: ${(props) => props.padding ?? (props.subRow ? '14px 10px 14px 22px' : '19px 14px')};
  border-bottom: ${(props) =>
    props.noBorderBottom ? 'none' : `1px solid ${props.borderColor ?? 'var(--primaryBlack5)'}`};
  border-top: ${(props) => (props.borderTop ? `1px solid ${props.borderColor ?? 'var(--primaryBlack5)'}` : 'none')};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};

  ${({ ignoreChildStyles }) =>
    !ignoreChildStyles &&
    `
    &:first-child {
      border-radius: 16px 16px 0 0;
    }
  `}

  ${({ ignoreChildStyles }) =>
    !ignoreChildStyles &&
    `
    &:last-child {
      border-radius: 0 0 16px 16px;
      border-bottom: none;
    }
  `}

  &:hover {
    background: ${(noRowHover) =>
      !noRowHover && 'linear-gradient(rgba(235, 246, 251, 1), rgba(235, 246, 251, 1)), var(--accentGrayer)'};
  }
`;

export const ReactTableCell = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  display: flex;
  width: 100%;
  align-items: center;
  max-width: ${(props) =>
    props.customWidth && `${props.customWidth}${typeof props.customWidth === 'number' ? 'px' : ''}`};
  min-width: ${(props) =>
    props.customWidth && `${props.customWidth}${typeof props.customWidth === 'number' ? 'px' : ''}`};
  justify-content: ${({ alignRight }) => alignRight && 'flex-end'};
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }
`;

export const TableRowTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${({ autoWidth }) =>
    !autoWidth &&
    `
    width: 220px;
    padding-left: 20px;
  `}
`;

export const TableRowTag = styled.span`
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: var(--primaryBlack);
  text-transform: uppercase;
  margin-left: 8px;

  background: var(--accentGrayFourth);
  border-radius: 100px;
  padding: 8px;
`;

export const TableRowTagWaterfall = styled.span`
  display: flex;
  padding: 8px;
  ${({ noBackgroundColor }) => (noBackgroundColor ? '' : 'background-color: var(--accentGrayFourth);')}
  border-radius: 100px;
  align-items: center;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  text-transform: uppercase;
  margin-left: 8px;
`;

export const TableSettingsWrapper = styled.div`
  width: 100%;
`;

export const TableActionCell = styled(FlexEndContainer)`
  width: 100%;
`;

export const EmptyTableContent = styled(Centerer)`
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  color: var(--primaryBlack50);
  margin: 22px 0;
  padding: 26px;
  font-size: 12px;
  line-height: 16px;
`;

export const EmptyTableContentAction = styled.span`
  margin-left: 4px;
  color: var(--primaryBlue);
  cursor: pointer;
`;
