import { useState, useEffect, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckmarkIcon } from 'images/active-check.svg';
import { ReactComponent as DangerIcon } from 'images/danger-icon-white.svg';
import { ToastContainer, ToastText, DismissButton, Wrapper } from './styles';

const Toast = ({ toast, onDismiss }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (toast.timeout >= 0) {
      setTimeout(() => {
        setIsVisible(false);
      }, toast.timeout - 600);
    }
  }, [toast.timeout]);

  useEffect(() => {
    if (toast.timeout >= 0) {
      setTimeout(() => {
        onDismiss(toast.id);
      }, toast.timeout - 200);
    }
  }, [toast.id, toast.timeout, onDismiss]);

  const defaultToast = (
    <ToastContainer type={toast.type}>
      {toast.type === 'success' && <CheckmarkIcon width="22px" height="22px" />}
      {toast.type === 'error' && <DangerIcon width="22px" height="22px" />}
      {isValidElement(toast.message) ? cloneElement(toast.message) : <ToastText>{toast.message}</ToastText>}
      {toast.timeout === -1 && (
        <DismissButton data-cy="toast-container__dismiss-button" onClick={() => onDismiss(toast.id)}>
          Dismiss
        </DismissButton>
      )}
    </ToastContainer>
  );

  // We use clone element to pass an additional prop to the already-defined
  // custom toast. This allows us to pass the onDismiss function
  const inner = toast.CustomToast
    ? cloneElement(toast.CustomToast, { onDismiss: () => onDismiss(toast.id) })
    : defaultToast;

  return <Wrapper isVisible={isVisible}>{inner}</Wrapper>;
};

Toast.propTypes = {
  /**
   * Toast attributes
   */
  toast: PropTypes.shape({
    /**
     * Toast id
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Toast timeout
     */
    timeout: PropTypes.number.isRequired,
    /**
     * Toast Type
     */
    type: PropTypes.oneOf(['success', 'error']),
    /**
     * Toast Message
     */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
  onDismiss: PropTypes.func.isRequired,
};

export default Toast;
