import { useContext, useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { NIL_LABELS } from 'consts/global';
import { ReactComponent as RemoveIcon } from 'images/circle-x.svg';
import {
  CustomDatePicker,
  FormikCustomInput,
  FormikCustomSelector,
  FormikOnFormChangeEffect,
} from 'components/Controls';
import { getSelectWidth } from 'components/Controls/FormikCustomSelector/utils';
import { InlineButton } from 'components/Buttons';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { getMetadataOptions } from 'api/metadata';
import { getCustomer } from 'api/customers/requests';

import { DataFilter, FormFooter, GrayText, FilterGroup, AdvancedFilterDescription } from './styles';
import {
  emptyFilter,
  MULTI_VALUE_FIELDS,
  MULTI_VALUE_OPERATORS,
  FILTER_TYPE_OPTIONS,
  METADATA_FILTER_TYPES,
  DATES_FIELDS,
  MAX_DROPDOWN_OPTIONS,
  NULLABLE_DATES_FIELDS,
} from './consts';
import { addEmptyFieldsToMetadataOption, getFieldOptions, getValueOptions } from './utils';
import { GroupConditions, ListDot, TrashButton } from '../styles';

const FIELD_TYPE = {
  NUMBER: 'number',
  MULTI_VALUES: 'multiValues',
  NULLABLE_DATE: 'nullableDate',
  DATE: 'date',
};

const OPERATOR_TYPE = {
  NOT_IN: 'notIn',
  IN: 'in',
  MORE_THAN: '>',
  LESS_THAN: '<',
  NONE: 'none',
  DEFINED: 'defined',
};

const getOperatorOptions = ({ fieldType }) => {
  switch (fieldType) {
    case FIELD_TYPE.NUMBER:
      return [
        { value: OPERATOR_TYPE.NOT_IN, label: 'not equals' },
        { value: OPERATOR_TYPE.IN, label: 'equals' },
        { value: OPERATOR_TYPE.MORE_THAN, label: 'more than' },
        { value: OPERATOR_TYPE.LESS_THAN, label: 'less than' },
      ];

    case FIELD_TYPE.NULLABLE_DATE:
      return [
        { value: OPERATOR_TYPE.NOT_IN, label: 'not equals' },
        { value: OPERATOR_TYPE.IN, label: 'equals' },
        { value: OPERATOR_TYPE.MORE_THAN, label: 'after' },
        { value: OPERATOR_TYPE.LESS_THAN, label: 'before' },
        { value: OPERATOR_TYPE.NONE, label: 'none' },
        { value: OPERATOR_TYPE.DEFINED, label: 'defined' },
      ];

    case FIELD_TYPE.DATE:
      return [
        { value: OPERATOR_TYPE.NOT_IN, label: 'not equals' },
        { value: OPERATOR_TYPE.IN, label: 'equals' },
        { value: OPERATOR_TYPE.MORE_THAN, label: 'after' },
        { value: OPERATOR_TYPE.LESS_THAN, label: 'before' },
      ];

    case FIELD_TYPE.MULTI_VALUES:
    default:
      return [
        { value: OPERATOR_TYPE.NOT_IN, label: 'exclude' },
        { value: OPERATOR_TYPE.IN, label: 'include' },
      ];
  }
};

export const MetadataFilter = ({
  setFiltersValid,
  isDisabled,
  metadataFilterFormValues,
  setMetadataFilterFormValues,
  metadataFilterTypes,
}) => {
  const [metadataOptions, setMetadataOptions] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState();
  const [selectedParentCustomers, setSelectedParentCustomers] = useState();
  const { organizations, glIntegrationsById } = useContext(AppContext);

  useEffect(() => {
    const fetchMetadataOptions = async () => {
      // TODO: Rewrite to use useMetadataOptionsAPI hook
      const metadataOptions =
        (await getMetadataOptions({
          orgId: organizations[0].id,
          types: [METADATA_FILTER_TYPES.TRANSACTIONS, METADATA_FILTER_TYPES.CUSTOMERS],
        })) || {};
      metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS] = metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS] ?? {};
      metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS] = metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS] ?? {};
      addEmptyFieldsToMetadataOption({ metadataOptions });
      setMetadataOptions(metadataOptions);
    };

    if (organizations?.[0]?.id) {
      fetchMetadataOptions();
    }
  }, [organizations, metadataFilterFormValues]);

  // Fetch and set selected customers so we can show their names in the selector
  useEffect(() => {
    const fetchAndSetCusomers = async () => {
      const customersFilter = (metadataFilterFormValues?.filters ?? []).find(
        (filter) => filter.field === 'topLevel__id',
      );

      if (customersFilter) {
        const fetchedCustomers = await Promise.all(
          customersFilter.value
            .filter((value) => Boolean(value) && !Array.isArray(value))
            .map((customerId) => getCustomer({ orgId: organizations?.[0]?.id, customerId })),
        );

        setSelectedCustomers(
          fetchedCustomers.map((customer) => ({ label: customer?.name ?? customer?.id, value: customer?.id })),
        );
      }

      const parentCustomersFilter = (metadataFilterFormValues?.filters ?? []).find(
        (filter) => filter.field === 'topLevel__root_customer_id',
      );

      if (parentCustomersFilter) {
        const fetchedParentCustomers = await Promise.all(
          parentCustomersFilter.value
            .filter((value) => Boolean(value) && !Array.isArray(value))
            .map((customerId) => getCustomer({ orgId: organizations?.[0]?.id, customerId })),
        );

        setSelectedParentCustomers(
          fetchedParentCustomers.map((customer) => ({ label: customer?.name ?? customer?.id, value: customer?.id })),
        );
      }
    };
    fetchAndSetCusomers();
  }, [metadataFilterFormValues?.filters, organizations]);

  const filterTypeOptions = FILTER_TYPE_OPTIONS.filter((type) => metadataFilterTypes.includes(type.value));

  return (
    <DataFilter data-cy="data-table-metadata-filter" isDisabled={isDisabled}>
      {metadataOptions && (
        <Formik
          enableReinitialize={true}
          initialValues={metadataFilterFormValues}
          validationSchema={Yup.object({
            filters: Yup.lazy((values) => {
              const duplicatedFields = values
                .map((value) => `${value.field}##${value.filterType}`)
                .filter((e, index, arr) => arr.indexOf(e) !== index)
                // We count field as duplicated only if this field is under the same filterType
                .map((duplicatedField) => duplicatedField.split('##')[0]);

              return Yup.array().of(
                Yup.object({
                  filterType: Yup.string().required('Please, select filters group'),
                  field: Yup.string()
                    .notOneOf(duplicatedFields, 'You already have filter with this field')
                    .required('Please, select field to filter'),
                  _operator: Yup.string().required('Please, select operator'),
                  value: Yup.mixed().when('_operator', (operator) => {
                    return Yup.lazy((val) => {
                      if ([OPERATOR_TYPE.NONE, OPERATOR_TYPE.DEFINED].includes(operator)) {
                        return Array.isArray(val) ? Yup.array().nullable() : Yup.string().nullable();
                      }
                      return Array.isArray(val)
                        ? Yup.array().required().min(1, 'Please, select at least one value to filter')
                        : Yup.string().nullable().required('Please, select value to filter');
                    });
                  }),
                }),
              );
            }),
          })}
        >
          {({ values, setFieldValue, getFieldMeta, validateForm }) => (
            <Form>
              <FieldArray name="filters">
                {({ remove, push }) => (
                  <>
                    {values.filters &&
                      values.filters.map((condition, index) => {
                        const availableOptions = getValueOptions({
                          filterType: condition.filterType,
                          fieldValue: condition.field,
                          metadataOptions,
                          organizations,
                          glIntegrationsById,
                        });
                        const isCustomerIdField =
                          condition.field === 'topLevel__id' || condition.field === 'topLevel__root_customer_id';
                        const isMultiValues =
                          MULTI_VALUE_OPERATORS.includes(condition._operator) &&
                          (availableOptions.length > 0 || MULTI_VALUE_FIELDS.includes(condition.field));
                        if (isMultiValues) {
                          availableOptions.unshift({ label: 'None', value: null });
                        }
                        const isNumberSelector =
                          !MULTI_VALUE_FIELDS.includes(condition.field) &&
                          availableOptions
                            .filter((option) => !NIL_LABELS.includes(option.label))
                            .every((option) => !isNaN(Number(option.label)));

                        const isDateSelector =
                          DATES_FIELDS.includes(condition.field) || condition.field.toLowerCase().includes('date');

                        const isNullableDateSelector = NULLABLE_DATES_FIELDS.includes(condition.field);

                        let fieldType = FIELD_TYPE.MULTI_VALUES;
                        if (isNullableDateSelector) fieldType = FIELD_TYPE.NULLABLE_DATE;
                        else if (isDateSelector) fieldType = FIELD_TYPE.DATE;
                        else if (isNumberSelector) fieldType = FIELD_TYPE.NUMBER;

                        const selectedValue =
                          condition.field === 'topLevel__id'
                            ? selectedCustomers
                            : condition.field === 'topLevel__root_customer_id'
                            ? selectedParentCustomers
                            : isNumberSelector && !isMultiValues
                            ? { label: condition.value, value: condition.value }
                            : null;

                        return (
                          <div style={{ marginBottom: 20 }} key={index}>
                            <FilterGroup>
                              <GroupConditions>
                                <ListDot />

                                <FormikCustomSelector
                                  blueVer
                                  floatErrors
                                  width={170}
                                  placeholder="Select filters group"
                                  customBorderRadius={4}
                                  handleChange={(option) => {
                                    setFieldValue(`filters.${index}.filterType`, option.value);
                                    setFieldValue(`filters.${index}.field`, '');
                                    setFieldValue(`filters.${index}.value`, []);
                                  }}
                                  name={`filters.${index}.filterType`}
                                  options={filterTypeOptions}
                                />
                                {condition.filterType && (
                                  <>
                                    <GrayText>:</GrayText>
                                    <FormikCustomSelector
                                      blueVer
                                      floatErrors
                                      width={getSelectWidth({
                                        options: getFieldOptions({
                                          filterType: condition.filterType,
                                          metadataOptions,
                                        }),
                                        baseWidth: 120,
                                      })}
                                      placeholder="Select filter"
                                      customBorderRadius={4}
                                      name={`filters.${index}.field`}
                                      handleChange={(option) => {
                                        setFieldValue(`filters.${index}.field`, option.value);
                                        setFieldValue(`filters.${index}.value`, []);
                                      }}
                                      options={getFieldOptions({
                                        filterType: condition.filterType,
                                        metadataOptions,
                                      })}
                                    />
                                  </>
                                )}

                                {condition.field && (
                                  <>
                                    <FormikCustomSelector
                                      blueVer
                                      floatErrors
                                      width={100}
                                      customBorderRadius={4}
                                      placeholder="operator"
                                      name={`filters.${index}._operator`}
                                      options={getOperatorOptions({ fieldType })}
                                    />
                                    {isNullableDateSelector &&
                                    [OPERATOR_TYPE.DEFINED, OPERATOR_TYPE.NONE].includes(condition._operator) ? (
                                      <></>
                                    ) : isDateSelector ? (
                                      <CustomDatePicker
                                        floatErrors
                                        formik
                                        onChange={setFieldValue}
                                        selected={
                                          condition.value && dayjs(condition.value).isValid()
                                            ? dayjs(condition.value).toDate()
                                            : ''
                                        }
                                        name={`filters.${index}.value`}
                                        meta={getFieldMeta(`filters.${index}.value`)}
                                      />
                                    ) : isNumberSelector && !isMultiValues ? (
                                      <FormikCustomInput
                                        data-cy={`filters.${index}.value`}
                                        blueVer
                                        placeholder="Enter value"
                                        minWidth={160}
                                        type="number"
                                        floatErrors
                                        name={`filters.${index}.value`}
                                      />
                                    ) : (
                                      <FormikCustomSelector
                                        blueVer
                                        floatErrors
                                        width="100%"
                                        minWidth={getSelectWidth({
                                          options: availableOptions,
                                          baseWidth: 120,
                                        })}
                                        placeholder="Select values"
                                        customBorderRadius={4}
                                        name={`filters.${index}.value`}
                                        options={availableOptions}
                                        loadOptions={(inputValue) => {
                                          const lowerCaseInputValue = inputValue.toLowerCase();

                                          return isCustomerIdField
                                            ? getCustomersFromSearch({
                                                searchQuery: inputValue,
                                                orgId: organizations[0]?.id,
                                                paginated: false,
                                              })
                                            : Promise.resolve(
                                                availableOptions
                                                  .filter(
                                                    (option) =>
                                                      option &&
                                                      option.label &&
                                                      String(option.label).toLowerCase().includes(lowerCaseInputValue),
                                                  )
                                                  .slice(0, MAX_DROPDOWN_OPTIONS),
                                              );
                                        }}
                                        value={selectedValue}
                                        creatable={isNumberSelector && !isMultiValues}
                                        onCreateOption={(option) => {
                                          setFieldValue(`filters.${index}.value`, option);
                                        }}
                                        handleChange={(option) => {
                                          setFieldValue(
                                            `filters.${index}.value`,
                                            isMultiValues ? option?.map(({ value }) => value) ?? [] : option?.value,
                                          );
                                        }}
                                        isMulti={isMultiValues}
                                      />
                                    )}
                                  </>
                                )}

                                <TrashButton
                                  data-cy="metadata-filters__delete-button"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <RemoveIcon />
                                </TrashButton>
                              </GroupConditions>
                            </FilterGroup>

                            {Boolean(condition?.value) && Boolean(condition.value.length) && (
                              <AdvancedFilterDescription>
                                {condition.filterType === METADATA_FILTER_TYPES.TRANSACTIONS
                                  ? 'Only transactions that match your filter will be included. If a customer has multiple transactions, only the ones that match your filter will be included.'
                                  : 'Only customers that match your filter will be included.'}
                              </AdvancedFilterDescription>
                            )}
                          </div>
                        );
                      })}

                    <FormFooter>
                      <InlineButton
                        data-cy="metadata-filter__add-filter"
                        withBackground
                        lineHeight="20px"
                        isSecondary
                        fontSize="14px"
                        onClick={() => push(emptyFilter)}
                      >
                        + Add Filter
                      </InlineButton>
                    </FormFooter>
                  </>
                )}
              </FieldArray>

              <FormikOnFormChangeEffect
                onChange={async () => {
                  setMetadataFilterFormValues(values);

                  if (values?.filters?.length) {
                    const errors = await validateForm(values);
                    setFiltersValid((prevState) => ({ ...prevState, metadata: isEmpty(errors) }));
                  } else {
                    setFiltersValid((prevState) => ({ ...prevState, metadata: true }));
                  }
                }}
              />
            </Form>
          )}
        </Formik>
      )}
    </DataFilter>
  );
};
