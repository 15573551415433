import React, { useContext, useMemo } from 'react';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { BusinessSnapshotDataTable } from './BusinessSnapshotDataTable';
import { calculateSummaryStats, calculateRevenueNURDCStats, calculateSaasStats } from './DashboardTables.utils';

export const DashboardTables = ({ name, selectedMetrics, selectedColumns, SettingsButton }) => {
  const { isARR, revenueNURDCStats, saasStats, summaryStats } = useContext(DashboardContext);

  const calculatedSummaryStats = useMemo(
    () =>
      calculateSummaryStats({
        isARR,
        summaryStats,
      }),
    [isARR, summaryStats],
  );

  const calculatedRevenueNURDCStats = useMemo(() => calculateRevenueNURDCStats({ isARR, revenueNURDCStats }), [
    isARR,
    revenueNURDCStats,
  ]);

  const calculatedSaasStats = useMemo(() => calculateSaasStats({ isARR, saasStats }), [isARR, saasStats]);

  return (
    <BusinessSnapshotDataTable
      name={name}
      calculatedSummaryStats={calculatedSummaryStats}
      calculatedRevenueNURDCStats={calculatedRevenueNURDCStats}
      calculatedSaasStats={calculatedSaasStats}
      selectedMetrics={selectedMetrics}
      selectedColumns={selectedColumns}
      SettingsButton={SettingsButton}
    />
  );
};
