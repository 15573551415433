import styled, { css } from 'styled-components';
import { FlexerColumn } from 'components/Core';

export const ExternalUpdateBody = styled.div`
  padding: 12px 0;
  padding-right: 12px;
  border-radius: 12px;
  border: 1px solid var(--primaryBlack3);
  background: var(--primaryBlack2);
  width: 100%;
  margin-top: 8px;
`;

export const ExternalUpdateBodyTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 12px;
  color: var(--primaryBlack50);
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  text-align: left;

  span {
    font-style: normal;
    font-weight: 600;
  }
`;

export const InvoiceItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  position: relative;
  margin-bottom: 8px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 11px;
    height: 1px;
    background-color: var(--primaryBlack10);
  }
`;

const baseLabelStyles = css`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 133.333% */
  text-transform: uppercase;
  border-radius: 4px;
  background: white;
  padding: 2px 4px;
  margin-right: 8px;

  ${(props) =>
    !props.active &&
    css`
      text-decoration: line-through;
      opacity: 0.5;
    `}
`;

export const RemoveLabel = styled.div`
  ${baseLabelStyles}
  color: var(--tertiaryRed70);
  border: 1px solid var(--newRed30);
`;

export const CreateLabel = styled.div`
  ${baseLabelStyles}
  color: var(--primaryGreen70);
  border: 1px solid var(--primaryGreen30);
`;

export const ChangeLabel = styled.div`
  ${baseLabelStyles}
  color: var(--primaryBlack50);
  border: 1px solid var(--neutralGray);
`;

export const DismissButton = styled.div`
  padding: 5px 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primaryBlack5);
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 14px;
  margin-left: 12px;
  margin-right: 8px;
  margin-top: 8px;
`;

export const ApplyButton = styled.div`
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primaryBlue10);
  background: var(--primaryBlue5);
  color: var(--primaryBlue);
  cursor: pointer;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 14px;
  margin-top: 8px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    `}

  &:hover {
    background: var(--primaryBlue10);
  }

  svg {
    margin-left: 4px;
  }
`;

export const InvoiceName = styled.div`
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
`;

export const InvoiceItemsCheck = styled.div`
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.selected ? 'var(--neutralGray)' : 'transparent')};
  background: ${(props) => props.selected && '#ffffff'};
  box-shadow: ${(props) => props.selected && '2px 2px 12px 0px rgba(0, 21, 46, 0.02)'};
  font-size: 12px;
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 14px;
  cursor: pointer;

  svg {
    margin-right: 4px;
  }

  span {
    color: var(--tertiaryRed);
    margin: 0 3px;
  }

  :hover {
    background: #ffffff;
    border: 1px solid var(--neutralGray);
    box-shadow: ${(props) =>
      props.selected ? '0px 4px 20px 0px rgba(0, 21, 46, 0.10)' : '4px 4px 24px 0px rgba(0, 21, 46, 0.04)'};
  }
`;

export const FirstLastInvoice = styled(FlexerColumn)`
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  padding: 12px;
`;

export const InvoicePreviousValue = styled.span`
  color: var(--primaryBlack50) !important;
  text-decoration: line-through;
`;

export const InvoiceNewValue = styled.div`
  color: var(--primaryBlack);
  background-color: var(--primaryYellow);
  border-radius: 2px;
  line-height: 16px;
  padding: 0px 2px;
  margin-left: 4px;
`;

export const ModalFooter = styled.div`
  padding: 20px 36px;
  background-color: var(--primaryBlack2);
  display: flex;
  align-items: center;
  justify-content: ${({ custom }) => (custom ? 'space-between' : 'flex-end')};
`;

export const InlineFlexWarnings = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
`;

export const UnmatchedAmountsWarning = styled.div`
  text-align: left;
  margin-top: 8px;
  max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '620px')};
`;
