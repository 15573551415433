import React, { useCallback, useState } from 'react';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { DASHBOARD_CHART_CARDS_METRICS } from 'views/Dashboard/DashboardOverview/consts';
import { CARD_SIZES } from 'views/Dashboard/Common/Card';
import { WIDGET_TYPES } from '../consts';
import { WidgetModal } from './WidgetModal';

const METRIC_OPTIONS = DASHBOARD_CHART_CARDS_METRICS?.map((key) => ({
  label: SNAPSHOT_METRICS[key].label,
  value: key.toLowerCase(),
  hasTTMOption: !!SNAPSHOT_METRICS[`${key}_TTM`],
}));

export const useWidgetModal = ({ onAdd, onEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState();
  const [metric, setMetric] = useState();
  const [addInRowIndex, setAddInRowIndex] = useState();
  const [editIndex, setEditIndex] = useState(null);

  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal]);

  const openAddWidgetModal = ({ key, addInRowIndex }) => {
    setType(key);
    if (key === WIDGET_TYPES.CHART_VISUALIZER) {
      setMetric({
        id: WIDGET_TYPES.CHART_VISUALIZER,
        size: CARD_SIZES.HUGE,
      });
    } else if (key === WIDGET_TYPES.TEXT_SECTION) {
      setMetric({
        id: WIDGET_TYPES.TEXT_SECTION,
        size: CARD_SIZES.FULL_ROW,
      });
    } else if (key === WIDGET_TYPES.DATA_TABLE) {
      setMetric({
        id: WIDGET_TYPES.DATA_TABLE,
        size: CARD_SIZES.FULL_ROW,
      });
    }
    setEditIndex(null);
    setAddInRowIndex(addInRowIndex);
    toggleModal();
  };

  const openEditWidgetModal = ({ metricIndex, metric }) => {
    setType(
      WIDGET_TYPES.CHART_VISUALIZER === metric.id
        ? metric.id
        : WIDGET_TYPES.TEXT_SECTION === metric.id
        ? WIDGET_TYPES.TEXT_SECTION
        : WIDGET_TYPES.DATA_TABLE === metric.id
        ? WIDGET_TYPES.DATA_TABLE
        : WIDGET_TYPES.METRIC,
    );
    setMetric(metric);
    setEditIndex(metricIndex);
    toggleModal();
  };

  const handleOnSubmit = useCallback(
    (data) => {
      if (editIndex !== null) {
        onEdit(data, editIndex);
        setEditIndex(null);
      } else {
        onAdd(data, addInRowIndex);
        setAddInRowIndex(null);
      }
      setMetric(null);
      toggleModal();
    },
    [editIndex, toggleModal, onEdit, onAdd, addInRowIndex],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <WidgetModal
          widgetType={type}
          onClose={toggleModal}
          onSubmitted={handleOnSubmit}
          metric={metric}
          metricOptions={METRIC_OPTIONS}
          editMode={editIndex !== null}
        />
      ) : (
        <></>
      ),
    [showModal, type, toggleModal, handleOnSubmit, metric, editIndex],
  );

  return { openAddWidgetModal, openEditWidgetModal, WidgetModal: Modal };
};
