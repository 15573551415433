import { useCallback, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { AppContext } from 'AppContext';
import { getOrganizations } from 'api/organizations';
import { useToasts } from 'components/Toasts';
import { CACHE_KEY as METADATA_CACHE_KEY } from 'api/metadata';
import { CACHE_KEY as CONTRACTS_CACHE_KEY } from 'api/contracts';
import {
  getProducts,
  createProduct as _createProduct,
  updateProduct as _updateProduct,
  deleteProduct as _deleteProduct,
  getProductCategories,
  createProductCategory,
  updateProductCategory,
  bulkUpdateProducts,
  bulkDeleteProducts,
  bulkCreateProducts,
} from './requests';

export const CACHE_KEY = 'products';
export const PRODUCT_CATEGORIES_CACHE_KEY = 'productCategories';

export const useProductsAPI = ({ orgId, params, enableToasts = true, autoFetch = true }) => {
  const { setOrganizations } = useContext(AppContext);

  const dataKey = [CACHE_KEY, orgId, params];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => {
      const products = await getProducts({ orgId, params });
      const productCategories = await getProductCategories({ orgId });
      let productsMetadata = null;
      return { products, productCategories, productsMetadata };
    },
    {
      enabled: autoFetch,
    },
  );

  // TODO [TC 2023-04-24]: This is for backwards compatibility, since there are many spots
  // in the app where we use `organizations[0].products` directly instead of this hook.
  // We should remove this once we've updated all those spots.
  const oldRefetch = useCallback(async () => {
    try {
      refetch();
      const backendOrganizations = await getOrganizations();
      if (backendOrganizations) {
        setOrganizations(backendOrganizations);
      }
    } catch (err) {
      console.error({ message: err.message, component: 'useProductsAPI.js', stack: err });
    }
  }, [setOrganizations, refetch]);
  const invalidateAndRefetch = useCallback(async () => {
    await queryClient.invalidateQueries(CACHE_KEY);
    await queryClient.invalidateQueries(PRODUCT_CATEGORIES_CACHE_KEY);
    await queryClient.invalidateQueries(METADATA_CACHE_KEY);
    await oldRefetch();
  }, [queryClient, oldRefetch]);

  const createProduct = useMutation(({ data }) => _createProduct({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create product');
    },

    onSuccess: () => {
      pushToast('Successfully created product!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const updateProduct = useMutation(({ productId, data }) => _updateProduct({ orgId, productId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to update product');
    },

    onSuccess: () => {
      pushToast('Successfully updated product!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const deleteProduct = useMutation(({ productId }) => _deleteProduct({ orgId, productId }), {
    onError: (err) => {
      pushError(err, 'Failed to delete product.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted product!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const createCategory = useMutation(({ data }) => createProductCategory({ orgId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to create category.');
    },

    onSuccess: () => {
      pushToast('Successfully created category!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const updateCategory = useMutation(({ data, categoryId }) => updateProductCategory({ orgId, categoryId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to edit category.');
    },

    onSuccess: () => {
      pushToast('Successfully edited category!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const bulkCreate = useMutation(({ data, params }) => bulkCreateProducts({ orgId, data, params }), {
    onError: (err) => {
      pushToast(err, 'Failed to bulk create.');
    },

    onSuccess: () => {
      pushToast('Successfully created products!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
      queryClient.invalidateQueries(CONTRACTS_CACHE_KEY);
    },
  });

  const bulkUpdate = useMutation(({ data, productIds }) => bulkUpdateProducts({ orgId, data, productIds }), {
    onError: (err) => {
      pushToast(err, 'Failed to bulk update.');
    },

    onSuccess: () => {
      pushToast('Successfully updated products!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  const bulkDelete = useMutation(({ productIds }) => bulkDeleteProducts({ orgId, productIds }), {
    onError: (err) => {
      pushToast(err, 'Failed to bulk delete.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted products!', 'success');
    },

    onSettled: () => {
      invalidateAndRefetch();
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetch: oldRefetch,
    operations: {
      createProduct,
      updateProduct,
      deleteProduct,
      bulkDelete,
      bulkUpdate,
      bulkCreate,
      createCategory,
      updateCategory,
    },
  };
};

export const useProductCategoriesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [PRODUCT_CATEGORIES_CACHE_KEY, orgId];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getProductCategories({ orgId }),
    {
      enabled: autoFetch,
    },
  );

  return { data, error, isLoading, isFetching, refetch };
};
