import React, { useCallback, useContext, useMemo } from 'react';
import { uniq } from 'lodash';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useAiDiscrepanciesAPI } from 'api/aiDiscrepancies';
import { Centerer } from 'components/Core';
import { TimeLoader } from 'components/Loaders';
import { TimeLoaderContainer } from 'components/Loaders';
import { generateChanges } from 'views/ExternalUpdates/utils';
import { AI_DESCREPANCIES_TABLE_COLUMNS } from './consts';
import { generateColumns } from './columns';
import { Table } from './Table';

const EmptyStateLabel = styled.div`
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
`;

export const AIDescrepanciesTab = () => {
  const { orgId } = useContext(AppContext);

  const { data, isLoading } = useAiDiscrepanciesAPI({
    orgId,
    params: { objectType: 'transaction' },
  });

  const handleOnSelectedRowsChange = (selectedRowIds) => {
    console.log(selectedRowIds);
  };

  const handleOnRowAction = useCallback(({ data }) => {
    console.log(data);
  }, []);

  const dataForTable = useMemo(() => {
    return data?.data?.map((datum) => {
      const returned = {
        ...datum,
        metadata: {
          keys: uniq([
            ...Object.keys(datum.targetObject.metadata ?? {}),
            ...Object.keys(datum.aiDiscrepancy.update_data.metadata ?? {}),
          ]),
          original: datum.targetObject.metadata ?? {},
          updated: datum.aiDiscrepancy.update_data.metadata ?? {},
        },
        changedFields: {},
      };

      const changedFields = generateChanges({
        source: datum.targetObject,
        target: datum.aiDiscrepancy.update_data,
        fields: Object.keys(AI_DESCREPANCIES_TABLE_COLUMNS),
      });

      for (const key in changedFields) {
        if (changedFields[key]) {
          returned.changedFields[key] = true;
        }
      }

      return returned;
    });
  }, [data]);

  const columns = useMemo(() => generateColumns({ onAction: handleOnRowAction }), [handleOnRowAction]);

  return (
    <Centerer width="100%" height="100%" data-cy="transactions-ai-descrepencies-tab">
      <TimeLoaderContainer isLoading={isLoading || !dataForTable}>
        {isLoading || !dataForTable ? (
          <TimeLoader pageName="externalUpdates" />
        ) : dataForTable?.length > 0 ? (
          <Table data={dataForTable} columns={columns} onSelectedRowsChange={handleOnSelectedRowsChange} />
        ) : (
          <EmptyStateLabel>All discrepancies between contract and transaction fields are resolved</EmptyStateLabel>
        )}
      </TimeLoaderContainer>
    </Centerer>
  );
};
