import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useField } from 'formik';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';

import { BILLING_SCHEME_TAB_VALUES } from './consts';
import { PerUnitContent } from './PerUnitContent';
import { TieredContent } from './TieredContent';
import { isTieredPlan } from '../../utils';
import { initialTiersValue } from '../consts';

const Wrapper = styled(FlexerColumn)`
  gap: 16px;
  padding: 20px 36px;
  background-color: var(--secondaryGray);
  border-block: 1px solid var(--primaryBlack3);
`;

const Header = styled(Flexer)`
  gap: 15px;
`;

const Title = styled(Centerer)`
  gap: 8px;
  font-weight: 700;
  font-size: 14px;
  width: fit-content;
`;

const StyledReceiptIcon = styled(ReceiptIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const TabsWrapper = styled(FlexBetweenContainer)`
  gap: 8px;
  flex-grow: 1;
`;

const Tab = styled(Centerer)`
  flex-grow: 1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);

  border: 1px solid var(--accentGraySecond);
  background-color: white;

  &:hover {
    cursor: pointer;
    background-color: var(--primaryGray);
  }

  ${({ active }) =>
    active &&
    css`
      border: transparent;
      background-color: var(--primaryBlue10);
      color: var(--primaryBlue);

      &:hover {
        background-color: var(--primaryBlue10);
      }
    `}
`;

export const BillingSchemeSection = ({ name, currency, productIdsFieldName }) => {
  const productIdsFieldMeta = useField(productIdsFieldName);
  const { value: productIds } = productIdsFieldMeta[0];

  const meta = useField(name);
  const { value } = meta[0];
  const { setValue } = meta[2];

  const initialTabRef = useRef(
    value && isTieredPlan({ tiers: value }) ? BILLING_SCHEME_TAB_VALUES.TIERED : BILLING_SCHEME_TAB_VALUES.PER_UNIT,
  );

  const [currentBillingSchemeTab, setCurrentBillingSchemeTab] = useState(initialTabRef.current);

  const [draftPerUnitValue, setDraftPerUnitValue] = useState(
    initialTabRef.current === BILLING_SCHEME_TAB_VALUES.PER_UNIT && value ? value : initialTiersValue,
  );
  const [draftTieredValue, setDraftTieredValue] = useState(
    initialTabRef.current === BILLING_SCHEME_TAB_VALUES.TIERED && value ? value : initialTiersValue,
  );

  const TABS = [
    {
      label: 'Per Unit',
      value: BILLING_SCHEME_TAB_VALUES.PER_UNIT,
    },
    {
      label: 'Tiered',
      value: BILLING_SCHEME_TAB_VALUES.TIERED,
    },
  ];

  const renderTabContent = () => {
    switch (currentBillingSchemeTab) {
      case BILLING_SCHEME_TAB_VALUES.TIERED:
        return (
          <TieredContent
            name={name}
            productIds={productIds}
            draftTieredValue={draftTieredValue}
            handleChange={(newTiers) => setDraftTieredValue(newTiers)}
            currency={currency}
            setDraftTieredValue={setDraftTieredValue}
          />
        );
      default:
        return (
          <PerUnitContent
            name={`${name}`}
            draftPerUnitValue={draftPerUnitValue}
            handleChange={(value) => setDraftPerUnitValue(value)}
            currency={currency}
          />
        );
    }
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          <StyledReceiptIcon />
          <span>Choose billing scheme:</span>
        </Title>
        <TabsWrapper>
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              active={tab.value === currentBillingSchemeTab}
              onClick={() => {
                setCurrentBillingSchemeTab(tab.value);
                setValue(tab.value === BILLING_SCHEME_TAB_VALUES.TIERED ? draftTieredValue : draftPerUnitValue);
              }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabsWrapper>
      </Header>

      {renderTabContent()}
    </Wrapper>
  );
};
