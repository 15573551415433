import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { EVENTS } from 'consts/analytics';
import { ReactComponent as WalletIcon } from 'images/wallet.svg';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-icon-2.svg';
import { Tabs, Tab } from 'components/Tabs';
import { BILLING_PAGES_ROUTES } from 'views/Billing/consts';
import { BillingContext } from 'views/Billing/BillingContext';
import { useAnalytics } from 'utils/hooks';

import { USAGE_BASED_ENGINE_TABS } from './consts';

export const UsageBasedEngineTabs = () => {
  const history = useHistory();
  const { currentUsageBasedEngineTab } = useContext(BillingContext);
  const { trackEvent } = useAnalytics();

  const usageBasedEngineTabs = [
    {
      label: 'Usage-based Subscriptions',
      value: USAGE_BASED_ENGINE_TABS.USAGE_SUBSCRIPTIONS,
      icon: <WalletIcon />,
    },
    {
      label: 'Pricing Plans',
      value: USAGE_BASED_ENGINE_TABS.PRICING_PLANS,
      icon: <ReceiptIcon />,
    },
    {
      label: 'Usage Events',
      value: USAGE_BASED_ENGINE_TABS.USAGE_EVENTS,
      icon: <CalendarIcon />,
    },
  ];

  const handleTabClick = (tabValue) => {
    trackEvent({ name: EVENTS.VIEW_USAGE_BASED_ENGINE_TAB, properties: { tab: tabValue } });
    history.push(`${BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE}?tab=${tabValue}`);
  };

  return (
    <Tabs>
      {usageBasedEngineTabs.map((tab) => (
        <Tab
          key={tab.value}
          selected={tab.value === currentUsageBasedEngineTab}
          onClick={() => handleTabClick(tab.value)}
          data-cy={`billing__usage-based-engine__tab--${tab.value}`}
        >
          <span>{tab.label}</span>
          {tab.icon}
        </Tab>
      ))}
    </Tabs>
  );
};
