/**
 * Access Filters elements
 * TODO: migrate all public components to here
 */
import React from 'react';

import { FilterContainer, FilterHead, FilterHeadInner, FilterConditionHead, FilterWrapper } from './styles';
import { ResolutionSelectorPopover, RESOLUTION_OPTIONS } from './ResolutionSelectorPopover';
import { Filters } from './Filters';
import { WaterfallTypeFilter, WATERFALL_TYPE_OPTIONS } from './WaterfallTypeFilter';
import { SegmentByFilter } from './SegmentByFilter';
import { RevenueTypeFilter } from './RevenueTypeFilter';
import { DataTypeFilter } from './DataTypeFilter.js';
import { CustomerTypeFilter, CUSTOMER_TYPES_OPTIONS } from './CustomerTypeFilter';
import { PERIOD_OPTIONS, onSelectPeriod } from './utils';

// This component allows for a composable Filters
const FiltersWrapper = ({ children }) => (
  <FilterContainer>
    <FilterHead>
      <FilterHeadInner>{children}</FilterHeadInner>
    </FilterHead>
  </FilterContainer>
);

export {
  Filters,
  WaterfallTypeFilter,
  SegmentByFilter,
  RevenueTypeFilter,
  DataTypeFilter,
  FiltersWrapper,
  PERIOD_OPTIONS,
  onSelectPeriod,
  ResolutionSelectorPopover,
  FilterConditionHead,
  RESOLUTION_OPTIONS,
  WATERFALL_TYPE_OPTIONS,
  FilterWrapper,
  CustomerTypeFilter,
  CUSTOMER_TYPES_OPTIONS,
};
