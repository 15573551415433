import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Centerer, Spacer } from 'components/Core';
import { CardsGrid } from '../Common/Card';
import { DashboardContext } from '../DashboardContext';
import { DashboardCard } from '../DashboardOverview/DashboardCard';
import { DashboardCustomersCard } from '../DashboardOverview/DashboardCustomersCard';
import { DashboardRecurringRevenueCard } from '../DashboardOverview/DashboardRecurringRevenueCard';
import { DashboardCompanyDataCard } from '../DashboardOverview/DashboardCompanyDataCard';
import { DashboardChartVisualizerCard } from '../DashboardOverview/DashboardChartVisualizerCard';
import { DashboardRevenueWaterfallCard } from '../DashboardOverview/DashboardRevenueWaterfallCard';
import { DashboardTextSectionCard } from '../DashboardOverview/DashboardTextSectionCard';
import { DashboardDataTableCard } from '../DashboardOverview/DashboardDataTableCard';
import { ReportBuilderContext } from './ReportBuilderContext';
import { AddWidgetDropdown } from './AddWidgetDropdown';
import { EditWidgetDropdown, POPOVER_ACTIONS, POPOVER_OPTIONS } from './EditWidgetDropdown';
import { WIDGET_TYPES, COMMON_METRICS_DASHBOARD_ID } from './consts';

const AddWidgetButtonRightWrapper = styled(Centerer)`
  position: absolute;
  top: 0px;
  right: -40px;
  height: 100%;
`;

const ReportBuilderGrid = styled(CardsGrid)`
  padding-right: ${({ isEditable }) => (isEditable ? '40px' : '0')};

  ${AddWidgetButtonRightWrapper} {
    display: none;
  }

  &:hover {
    ${AddWidgetButtonRightWrapper} {
      display: block;
    }
  }
`;

const AddWidgetButtonBottom = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack50)')};
  background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'transparent')};
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)')};
    color: var(--primaryBlue);
  }
`;

const AddWidgetButtonRight = styled(Centerer)`
  width: 32px;
  height: 100%;
  background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
    }
  }

  &:hover {
    background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)')};

    svg {
      path {
        fill: var(--primaryBlue);
      }
    }
  }
`;

const getDashboardMetricCard = (metricId) => {
  // These are special METRIC_CARDS with custom logic
  switch (metricId) {
    case 'customers':
      return DashboardCustomersCard;
    case 'recurring_revenue':
      return DashboardRecurringRevenueCard;
    case 'company_data':
      return DashboardCompanyDataCard;
    case 'chart_visualizer':
      return DashboardChartVisualizerCard;
    case 'revenue_waterfall':
      return DashboardRevenueWaterfallCard;
    case 'text_section':
      return DashboardTextSectionCard;
    case 'data_table':
      return DashboardDataTableCard;

    default:
      // Here is the default
      return DashboardCard;
  }
};

const GridCardWidget = ({ metricIndex, metric, AddGridCardButton }) => {
  const { openCardChartModal } = useContext(DashboardContext);
  const { onEditWidget, onDeleteWidget } = useContext(ReportBuilderContext);

  const widgetMetric =
    metric?.id === WIDGET_TYPES.CHART_VISUALIZER ? metric.selectedMetrics[1] ?? metric.selectedMetrics[0] : metric;

  const metricId = widgetMetric.id.toUpperCase();

  const handleOptionClick = (action) => {
    switch (action) {
      case POPOVER_ACTIONS.OPEN:
        openCardChartModal({
          metricKey: widgetMetric?.isTTM ? SNAPSHOT_METRICS[`${metricId}_TTM`].key : SNAPSHOT_METRICS[metricId].key,
        });
        break;
      case POPOVER_ACTIONS.EDIT:
        onEditWidget({ metricIndex, metric });
        break;
      case POPOVER_ACTIONS.DELETE:
        onDeleteWidget({ metricIndex, metric });
        break;
      default:
        break;
    }
  };
  const handleTTMClick = (event) => {
    event?.stopPropagation();
    handleOptionClick(POPOVER_ACTIONS.EDIT);
  };

  const Component = getDashboardMetricCard(metric.id);
  return (
    <Component
      metric={metric}
      metricId={metricId}
      metricIndex={metricIndex}
      columnStart={metric.columnStart}
      size={metric.size}
      rowStart={metric.rowStart}
      key={metric.id}
      isTTM={metric.isTTM}
      markdown={metric.markdown}
      name={metric.name}
      selectedMetrics={metric.selectedMetrics}
      selectedColumns={metric.selectedColumns}
      onOptionClick={handleOptionClick}
      SettingsButton={
        <EditWidgetDropdown
          onOptionClick={handleOptionClick}
          popoverOptions={
            metric.id === WIDGET_TYPES.REVENUE_WATERFALL
              ? [POPOVER_OPTIONS[POPOVER_OPTIONS.length - 1]]
              : metric.id === WIDGET_TYPES.TEXT_SECTION
              ? [POPOVER_OPTIONS[1], POPOVER_OPTIONS[2]]
              : POPOVER_OPTIONS
          }
        />
      }
      onTTMClick={handleTTMClick}
      version={2}
      AddGridCardButton={AddGridCardButton}
    />
  );
};

export const ReportBuilder = () => {
  const { report, pageReportState } = useContext(ReportBuilderContext);
  const { CardChartModal } = useContext(DashboardContext);
  const metricSetting = useMemo(() => pageReportState?.metricsSetting ?? [], [pageReportState]);

  return (
    <GlobalTogglesFloatingContainer showAfterScrolling={800}>
      <ReportBuilderGrid version={2} isEditable={report?.id !== COMMON_METRICS_DASHBOARD_ID}>
        {metricSetting.map((metric, index, metricList) => (
          <GridCardWidget
            key={`${metric.id}-${metric.rowStart}-${metric.columnStart}`}
            metricIndex={index}
            metric={metric}
            AddGridCardButton={
              // Are we on the last metric or in the last metric of the row?
              (index === metricList.length - 1 || metric.rowStart < metricList[index + 1].rowStart) &&
              report?.id !== COMMON_METRICS_DASHBOARD_ID && (
                <AddWidgetButtonRightWrapper>
                  <AddWidgetDropdown
                    height="100%"
                    YOffset="0"
                    XOffset="-108"
                    addInRowIndex={metric.rowStart}
                    addWidgetButton={({ active, onClick }) => (
                      <AddWidgetButtonRight active={active} onClick={onClick}>
                        <PlusIcon />
                      </AddWidgetButtonRight>
                    )}
                  />
                </AddWidgetButtonRightWrapper>
              )
            }
          />
        ))}
      </ReportBuilderGrid>

      <Spacer height="24px" />

      {report?.id !== COMMON_METRICS_DASHBOARD_ID && (
        <AddWidgetDropdown
          XOffset="100"
          addWidgetButton={({ active, onClick }) => (
            <AddWidgetButtonBottom active={active} onClick={onClick} data-cy="report-builder__add-widget-button">
              + Add widgets...
            </AddWidgetButtonBottom>
          )}
        />
      )}
      <CardChartModal />
    </GlobalTogglesFloatingContainer>
  );
};
