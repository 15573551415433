import { useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';
import { Row, Spacer, TextBox } from 'components/Core';
import { useSubscriptionEditModal } from 'views/Billing/UsageBasedEngine/Subscriptions/SubscriptionEditModal';

import { PreviewTable } from './PreviewTable';
import { generateColumns } from './subscriptionColumns';

export const SubscriptionsPreviewTable = ({ data = [], setData, setDataHook }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const [tableData, setTableData] = useState(data);
  const { openModal, SubscriptionEditModal } = useSubscriptionEditModal();

  // for updating missingPricingPlan
  useEffect(() => {
    setTableData((prevTableData) =>
      prevTableData.map((value, index) => ({
        ...value,
        missingCustomer: data[index].missingCustomer && !value.customer_id,
        missingPricingPlan: data[index].missingPricingPlan && !value.pricing_plan_id,
      })),
    );
  }, [data]);

  const columns = useMemo(
    () =>
      generateColumns({
        formatDateWithLocale,
        onEditClick: (row) => {
          const selectedGroup = row.original;
          openModal({
            selectedGroup,
            saveSubscription: false,
            onSubscriptionCreated: (data) => {
              const tableDataClone = [...tableData];
              tableDataClone[row.index] = {
                ...tableDataClone[row.index],
                ...data,
              };
              delete tableDataClone[row.index].customer_confidence;
              delete tableDataClone[row.index].pricing_plan_confidence;
              setTableData(tableDataClone);
              setData(tableDataClone);
            },
          });
        },
      }),
    [formatDateWithLocale, openModal, tableData, setData],
  );

  return (
    <PreviewTable
      entityName="usage-based subscriptions"
      columns={columns}
      tableData={tableData}
      setDataHook={setDataHook}
      title={
        <Row horizontal="flex-start">
          <TextBox bold fontSize={'18px'}>
            Review the
          </TextBox>
          <Spacer width="5px" />
          <TextBox bold italic opacity="0.5" fontSize={'18px'}>
            usage-based subscriptions
          </TextBox>
          <Spacer width="8px" />
          <TextBox bold fontSize={'18px'}>
            that we'll create
          </TextBox>
        </Row>
      }
    >
      <SubscriptionEditModal />
    </PreviewTable>
  );
};
